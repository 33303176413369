<template>
<div class="container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 py-6 gap-8">
  <div>
    <div class="bg-white rounded-lg shadow-sm border p-6 mb-8">
      <div class="flex flex-col md:flex-row justify-between mb-6">
        <div class="w-full md:w-1/2 mb-4 md:mb-0 md:mr-4">
          <p class="text-gray-700 mb-2">E-mail address</p>
          <input type="email" placeholder="Type e-mail address" v-model.trim="emailAddress"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
        <div class="w-full md:w-1/2">
          <p class="text-gray-700 mb-2">Select type of Company</p>
          <select v-model="portfolioType"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="" disabled>Select portfolio type</option>
            <option v-for="type in portfolioTypes" :key="type" :value="type">{{ type }}</option>
          </select>
        </div>
      </div>
      <div class="text-right">
        <button :class="{ 'bg-[#19486A]': isFormValid, 'bg-gray-300 cursor-not-allowed': !isFormValid }"
          :disabled="!isFormValid" @click="sendInviteToCompany"
          class="px-6 py-2 text-white rounded-md transition duration-300 ease-in-out">
          Send invite
        </button>
      </div>
    </div>
    <div class="bg-white rounded-lg shadow-sm border p-4 mb-4">
      <div class="mb-2">
        <p class="text-base text-[#19486A]">
          Ready to see how your portfolio companies, your pipeline as well as
          your alumni perform across the 2X Criteria?
        </p>
      </div>
      <div class="text-right">
        <button
          class="px-6 py-2 bg-[#19486A] text-white rounded-md opacity-80"
          @click="$router.push('/2X-Ecosystem/2X-Certification/2X-Portfolio')">
          View your 2X Portfolio
        </button>
      </div>
    </div>
  </div>
  <div>
    <div class="bg-white rounded-lg shadow-sm border p-6">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-xl font-semibold">Investees ({{ filteredCompanyList.length }})</h2>
        <div class="w-64">
          <input type="text" v-model="searchCompanyQuery" placeholder="Type company's name"
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>
      </div>
      <div v-if="isLoading" class="flex justify-center items-center h-40">
        <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
      <div v-else class="overflow-auto max-h-96" id="scrollbar_style">
        <div v-for="company in filteredCompanyList" :key="company.id"
          class="flex justify-between items-center p-4 border-b border-gray-200 hover:bg-gray-50"
          :class="{ 'bg-blue-50': company.isRequestSent }">
          <div class="flex items-center">
            <div class="w-10 h-10 mr-4">
              <img :src="getCompanyImage(company.status)" :alt="company.status" class="w-full h-full object-cover rounded-full" />
            </div>
            <div>
              <div class="font-medium">{{ company.organization.name }}</div>
              <div v-if="company.portfolioType" class="text-sm text-gray-500">({{ company.portfolioType }})</div>
            </div>
          </div>
          <button :class="getRequestButtonClass(company.status)"
            class="px-4 py-2 rounded-2xl text-sm font-medium flex items-center">
            <img :src="getRequestButtonIcon(company.status)" :alt="company.status" class="w-4 h-4 mr-2" />
            <span class="rounded-lg text-white !font-semibold">{{ getRequestButtonText(company.status) }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && !filteredCompanyList.length" class="text-center py-12">
      <img src="./../../assets/images/IMM/box.svg" class="w-24 h-24 mx-auto mb-4" alt="Empty box" />
      <p class="text-gray-500">Your investees list is empty</p>
    </div>
    <div v-if="isInviteSuccess"
      class="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-sm flex items-center">
      <img src="@/assets/images/2x_certify/filled.svg" alt="Success" class="w-6 h-6 mr-3" />
      <p>Invitation was sent via e-mail</p>
    </div>
  </div>
   
  </div>
</template>

<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import apiService from "@/helpers/apiServices.js";
import auth from "@/helpers/auth";

export default {
  name: "twoXInviteCompanies",
  props: {
    isTwoxPortofolio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      companyList: [],
      emailAddress: "",
      isInviteSuccess: false,
      searchCompanyQuery: "",
      user: null,
      portfolioType: "",
      portfolioTypes: ["Alumni", "Pipeline", "Portfolio"],
      isLoading: false
    };
  },
  computed: {
    isFormValid() {
      return this.portfolioType && this.emailAddress && this.validEmail(this.emailAddress);
    },
    filteredCompanyList() {
      if (!this.searchCompanyQuery) return this.companyList;
      return this.companyList.filter(company =>
        company.organization.name.toLowerCase().includes(this.searchCompanyQuery.toLowerCase())
      );
    }
  },
  methods: {
    async getCompanyList() {
      this.isLoading = true;
      try {
        const response = await apiService.fetch2XPortfolioInvestees(this.user._id);
        this.companyList = response.data.data;
      } catch (error) {
        console.error("Failed to search companies:", error);
        this.companyList = [];
      } finally {
        this.isLoading = false;
      }
    },
    async sendInviteToCompany() {
      const payload = {
        emails: [this.emailAddress],
        portfolioType: this.portfolioType,
      };

      try {
        const response = await axios.post(`${env.apiUrl}/2x-invites/2x-investees`, payload, {
          headers: { Authorization: `Bearer ${Cookies.get("session")}` },
        });
        if (response.data.data) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
          this.portfolioType = "";
          this.emailAddress = "";
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: error.response?.data?.error || "Something went wrong!",
          showConfirmButton: true,
        });
      }
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    getCompanyImage(status) {
  return status === "Request sent"
    ? require("@/assets/images/email.png")
    : require("@/assets/images/EntrepreneurshipIcon.svg");
},
    getRequestButtonClass(status) {
      switch (status) {
        case "Request sent": return "bg-[#facc15]";
        case "Request accepted": return "bg-[#84cc16]";
        case "Request rejected": return "bg-[#ef4444]";
        default: return "";
      }
    },
    getRequestButtonIcon(status) {
      switch (status) {
        case "Request sent": return require("../../assets/images/IMM/yellow-eye.svg");
        case "Request accepted": return require("../../assets/images/IMM/white-check.svg");
        case "Request rejected": return require("../../assets/images/IMM/verification-close.svg");
        default: return "";
      }
    },
    getRequestButtonText(status) {
      switch (status) {
        case "Request sent": return "Request sent";
        case "Request accepted": return "Accepted";
        case "Request rejected": return "Rejected";
        default: return "";
      }
    }
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await this.getCompanyList();
  }
};
</script>

<style scoped>
.loader {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}
</style>
