<template>
    <div class="dimensions-container">
        <div class="flex items-center justify-between">
            <h1 class="capitalize text-3xl text-black tracking-tighter font-bold print:text-7xl">
                {{ score.subpageTitle }}</h1>
            <img v-if="score?.subpageData?.[0]?.twoXMarked" src="@/assets/images/dashboard/2xglobal.svg" alt="2x-global-icon" class="w-12 h-12" />
        </div>
        <div class="pillar_domain flex" style="width: 75%;">
            <div class="pillar_container">
                <h2>Pillar</h2>
                <div>
                    <div class="flex items-center mb-4" style="height: 50px;">
                        <img v-if="score.pillar == 'Resilience'" class="mr-3"
                            src="@/assets/images/roi/resilience.svg" />
                        <img v-if="score.pillar == 'Opportunity' || score.pillar == 'Opportunities'" class="mr-3"
                            src="@/assets/images/roi/opportunity.svg" />
                        <img v-if="score.pillar == 'Inclusion'" class="mr-3" src="@/assets/images/roi/inclusion.svg" />
                        <span>
                            {{ score.pillar }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="domain_container">
                <h2>Domain</h2>
                <div>
                    <div class="flex items-center mb-4" style="height: 50px;">
                        <img v-if="score.domain == 'Society'" class="mr-3" style="width: 40px;"
                            src="@/assets/images/society.svg" />
                        <img v-if="score.domain == 'Leadership'" class="mr-3" style="width: 40px;"
                            src="@/assets/images/leadership.svg" />
                        <img v-if="score.domain == 'Workforce'" class="mr-3" style="width: 40px;"
                            src="@/assets/images/workforce.svg" />
                        <img v-if="score.domain == 'Value Chain' || score.domain == 'Value Chains'" class="mr-3"
                            style="width: 40px;" src="@/assets/images/value_chain.svg" />
                        <span>{{ score.domain }}</span>
                    </div>
                </div>
            </div>
            <div class="dimension_container">
                <h2 class="font-bold text-xl">Dimension</h2>
                <div class="flex items-center">
                    <div>
                        <div class="flex items-center mb-4" style="height: 50px;">
                            <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Commitment'"
                                src="@/assets/images/Commitment.svg" />
                            <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Communication'"
                                src="@/assets/images/Communication.svg" />
                            <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Implementation'"
                                src="@/assets/images/Implementation.svg" />
                            <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Measurement'"
                                src="@/assets/images/Measurement.svg" />
                            <img class="mr-4" style="width: 40px;"
                                v-if="score.dimension == 'Accountability and Transparency'"
                                src="@/assets/images/Accountability_and_Transparency.svg" />
                            <img class="mr-4" style="width: 40px;" v-if="score.dimension == 'Singular'"
                                src="@/assets/images/singular.svg" />
                            <span class="font-medium text-xl">{{ score.dimension
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dimension_percentage_comparison flex mb-10">
            <div class="m-auto" v-if="(score.subpageData[1]?.score != '' || score.subpageData[2]?.score != '')">
                <div class="male_female_icons flex" style="width: 100%" v-if="score.currentPercent">
                    <div class="flex" v-if="score?.femaleIcon">
                        <img v-for="(female, femaleIndex) in score.femaleIcon" :key="'female4' + femaleIndex"
                            src="@/assets/images/icon-female-red.svg" />
                    </div>
                    <div class="flex" v-if="score?.maleIcon">
                        <img v-for="(male, maleIndex) in score.maleIcon" :key="'male4' + maleIndex"
                            src="@/assets/images/icon-female.svg" />
                    </div>
                </div>
                <div class="male_female_icons flex mt-5" style="width: 100%" v-if="score.currentPercent3">
                    <div class="flex" v-if="score?.femaleIcon3">
                        <img v-for="(female, femaleIndex) in score.femaleIcon3" :key="'female4' + femaleIndex"
                            src="@/assets/images/icon-male-blue.svg" />
                    </div>
                    <div class="flex" v-if="score?.maleIcon3">
                        <img v-for="(male, maleIndex) in score.maleIcon3" :key="'male4' + maleIndex"
                            src="@/assets/images/icon-male.svg" />
                    </div>
                </div>
            </div>
            <div class="comparison_text"
                :style="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '') ? 'width:100%' : ''">
                <span v-if="score.subpageTitle == `Women's turnover ratio` && score?.subpageData?.length == 5"
                    class="text-xl text-black tracking-tighter font-bold print:text-7xl">
                    <div
                        v-if="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '') || ((score.subpageData[1]?.score == '0.0' || score.subpageData[1]?.score == '0') && (score.subpageData[2]?.score == '0.0' || score.subpageData[2]?.score == '0'))">
                        <span v-if="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '')">Neither
                            women nor men worked at the enterprise in the past 12
                            months. Therefore, the turnover ratio is Not
                            Applicable.</span>
                        <span
                            v-if="((score.subpageData[1]?.score == '0.0' || score.subpageData[1]?.score == '0') && (score.subpageData[2]?.score == '0.0' || score.subpageData[2]?.score == '0'))">Neither
                            women nor men left the enterprise in the past 12 months.
                            Therefore, the turnover ratio is Not Applicable.</span>
                    </div>
                    <div
                        v-else-if="(score.subpageData[1]?.score != '' && score.subpageData[2]?.score != '') && ((score.subpageData[1]?.score != '0' && score.subpageData[1]?.score != '0.0') && (score.subpageData[2]?.score != '0' && score.subpageData[2]?.score != '0.0'))">
                        <span>
                            <span class="text-2xl" style="color: #FE5000"> {{
                                Math.round(score.subpageData[1]?.score * 100) }}% </span>
                            of
                            <span class="text-2xl" style="color: #FE5000">{{
                                score.subpageData[4]?.score | numberWithCommas }}</span>
                            women employees left the enterprise in the past 12 months
                        </span>
                        compared to
                        <span>
                            <span class="text-2xl" style="color: #0077C8">{{
                                Math.round(score.subpageData[2]?.score * 100) }}%</span>
                            of
                            <span class="text-2xl" style="color: #0077C8">{{
                                score.subpageData[3]?.score | numberWithCommas }}</span>
                            men employees,
                        </span>
                        resulting in a turnover ratio of
                        <span class="text-2xl" style="color: #898989"
                            v-if="((Number(score.subpageData[1]?.score).toFixed(2) / Number(score.subpageData[2]?.score).toFixed(2)) % 1) != 0">{{
                                ((score.subpageData[1]?.score / score.subpageData[2]?.score))
                                | toFixedTwo }}</span>
                        <span class="text-2xl" style="color: #898989"
                            v-if="((Number(score.subpageData[1]?.score).toFixed(2) / Number(score.subpageData[2]?.score).toFixed(2)) % 1) == 0">{{
                                (Number(score.subpageData[1]?.score).toFixed(2) /
                                    Number(score.subpageData[2]?.score).toFixed(2)) }}</span>
                    </div>
                    <div v-else>
                        <!-- verbiage for women -->
                        <span
                            v-if="score.subpageData[1]?.score != '' && (score.subpageData[1]?.score != '0' && score.subpageData[1]?.score != '0.0')">
                            <span class="text-2xl" style="color: #FE5000"> {{
                                Math.round(score.subpageData[1]?.score * 100) }}% </span>
                            of
                            <span class="text-2xl" style="color: #FE5000">{{
                                score.subpageData[4]?.score | numberWithCommas }}</span>
                            women employees left the enterprise in the past 12 months
                        </span>
                        <span
                            v-if="(score.subpageData[4]?.score == '0' || score.subpageData[4]?.score == '0.0') && (score.subpageData[6]?.score != '0' && score.subpageData[6]?.score != '0.0')">
                            No women left the enterprise in the past 12 months
                        </span>
                        <span
                            v-if="(score.subpageData[4]?.score == '0' || score.subpageData[4]?.score == '0.0') && (score.subpageData[6]?.score == '0' || score.subpageData[6]?.score == '0.0')">
                            No women worked at the enterprise in the past 12 months
                        </span>
                        <!-- {{ (score.subpageData[2]?.score == '' || (score.subpageData[2]?.score == '0' || score.subpageData[2]?.score == '0.0')) ? 'and' : 'compared to' }} -->
                        and
                        <!-- verbiage for men -->
                        <span
                            v-if="score.subpageData[2]?.score != '' && (score.subpageData[2]?.score != '0' && score.subpageData[2]?.score != '0.0')">
                            <span class="text-2xl" style="color: #0077C8">{{
                                Math.round(score.subpageData[2]?.score * 100) }}%</span>
                            of <span class="text-2xl" style="color: #0077C8">{{
                                score.subpageData[3]?.score | numberWithCommas }}</span>
                            men employees left the enterprise in the past 12 months.
                        </span>
                        <span
                            v-if="(score.subpageData[3]?.score == '0' || score.subpageData[3]?.score == '0.0')">
                            no men left the enterprise in the past 12 months.
                        </span>
                        <span
                            v-if="(score.subpageData[3]?.score == '0' || score.subpageData[3]?.score == '0.0')">
                            no men worked at the enterprise in the past 12 months.
                        </span>
                        <!-- verbiage for NA -->
                        <span
                            v-if="(score.subpageData[1]?.score == '' || score.subpageData[2]?.score == '') || ((score.subpageData[1]?.score == '0.0' || score.subpageData[1]?.score == '0') || (score.subpageData[2]?.score == '0.0' || score.subpageData[2]?.score == '0'))">Therefore,
                            the turnover ratio is
                            <span style="color: #898989">Not Applicable.</span></span>
                        <span v-else>
                            resulting in a turnover ratio of
                            <span class="text-2xl" style="color: #898989"
                                v-if="((Number(score.subpageData[1]?.score).toFixed(2) / Number(score.subpageData[2]?.score).toFixed(2)) % 1) != 0">{{
                                    ((score.subpageData[1]?.score /
                                        score.subpageData[2]?.score)) | toFixedTwo }}</span>
                            <span class="text-2xl" style="color: #898989"
                                v-if="((Number(score.subpageData[1]?.score).toFixed(2) / Number(score.subpageData[2]?.score).toFixed(2)) % 1) == 0">{{
                                    (Number(score.subpageData[1]?.score).toFixed(2) /
                                        Number(score.subpageData[2]?.score).toFixed(2)) }}</span>
                        </span>
                    </div>
                </span>
            </div>
        </div>
        <div class="dimension_inner_section" style="padding: 2rem 0;border-top: 2px solid #d0d0d0;">
            <div class="flex mb-8">

                <div class="flex" style="width:100%">
                </div>
            </div>
            <div class="dimension_inner_name flex">
                <div class="dimension_inner_left_side">
                    <div class="dimension_survey_question mt-4 mb-4 flex" v-if="score?.subpageData?.length == 5">
                        <div class="survey_question_inner">
                            <span>% of women who
                                left the company in the past 12 months
                            </span>
                        </div>
                        <div class="barchart_inner">
                            <div v-if="financialYear?.length == 1">
                                <div class="text-center"
                                    v-if="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '')">
                                    <div style="color: #FE5000">{{
                                        financialYear[0].split(" ")[0] + " " +
                                        financialYear[0].split(" ")[1]
                                    }}</div>
                                    <span>Not Applicable</span>
                                </div>
                                <turnoverBarChart v-else :chartsData="[score.subpageData[1], score.subpageData[2]]"
                                    :name="roiPageIntro.name" :chartLabels="['Women', 'Men']" :created="financialYear">
                                </turnoverBarChart>
                            </div>
                            <div v-if="financialYear?.length > 1">
                                <div
                                    v-if="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '') || ((score.subpageData[1]?.Oldscore == '' && score.subpageData[2]?.Oldscore == ''))">
                                    <div class="text-center" style="position: relative; left: 25px;"
                                        v-if="(score.subpageData[1]?.score == '' && score.subpageData[2]?.score == '')">
                                        <div style="color: #FE5000">{{
                                            financialYear[0].split(" ")[0] + " " +
                                            financialYear[0].split(" ")[1]
                                        }}</div>
                                        <span>Not Applicable</span>
                                    </div>
                                    <turnoverBarChart v-else :chartsData="[score.subpageData[1], score.subpageData[2]]"
                                        :name="roiPageIntro.name" :chartLabels="['Women', 'Men']"
                                        :created="financialYear">
                                    </turnoverBarChart>
                                    <br />
                                    <div class="text-center" style="position: relative; left: 25px;"
                                        v-if="(score.subpageData[1]?.Oldscore == '' && score.subpageData[2]?.Oldscore == '')">
                                        <div style="color: #201747">{{
                                            financialYear[1].split(" ")[0] + " " +
                                            financialYear[1].split(" ")[1]
                                        }}</div>
                                        <span>Not Applicable</span>
                                    </div>
                                    <turnoverOldestBarChartMultiple v-else
                                        :chartsData="[score.subpageData[1], score.subpageData[2]]"
                                        :name="roiPageIntro.name" :chartLabels="['Women', 'Men']"
                                        :created="financialYear">
                                    </turnoverOldestBarChartMultiple>
                                </div>
                                <turnoverBarChartMultiple v-else
                                    :chartsData="[score.subpageData[1], score.subpageData[2]]" :name="roiPageIntro.name"
                                    :chartLabels="['Women', 'Men']" :created="financialYear">
                                </turnoverBarChartMultiple>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import turnoverBarChart from "@/components/charts/roiTurnoverPillarBarChart.vue";
import turnoverBarChartMultiple from "@/components/charts/roiTurnoverPillarBarChartMultiple.vue";
import turnoverOldestBarChartMultiple from "@/components/charts/roiOldestTurnoverPillarBarChart.vue";
export default {
    name: "SingularTurnoverSection",
    data() {
        return {

        }
    },
    props: ["roiScoreData", "score", "financialYear", "roiPageIntro"],
    components: {
        turnoverBarChart,
        turnoverBarChartMultiple,
        turnoverOldestBarChartMultiple,
    },
}
</script>
<style lang="scss" scoped>
.dimensions-container {
    // padding: 2rem 0;

    .pillar_domain {
        padding: 2rem 0;

        h2 {
            font-size: 13px;
            font-weight: 700;
            padding: 1rem 0;
        }

        .pillar_container {
            width: 50%;
        }

        .domain_container {
            width: 50%;
        }
    }

    .dimension_percentage_comparison {
        .male_female_icons {
            width: 40%;

            img {
                width: 25px;
            }
        }

        .comparison_text {
            width: 60%;
        }
    }

    .dimension_inner_section {
        .dot_with_text {
            padding: 0 15px;
            margin: auto 0 auto auto;
        }

        .dimension_inner_name {
            .dimension_inner_left_side {
                width: 100%;

                .dimension_survey_question {
                    font-weight: 700;
                    color: black;
                    word-break: break-word;
                    min-height: 70px;

                    .survey_question_inner {
                        width: 60%;
                    }

                    .barchart_inner {
                        width: 40%;
                    }

                    .multi_values_inner {
                        width: 30%;
                        margin-left: 40px;
                        font-weight: 500;
                        font-size: 1rem;
                    }
                }
            }

            .dimension_inner_right_side {}
        }
    }
}
</style>