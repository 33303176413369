<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
  >
    <div class="bg-white rounded-lg p-6 w-full max-w-md">
      <template v-if="!isSubmitted">
        <h2 class="text-xl font-bold mb-4 text-center">Rate Your Experience</h2>

        <!-- SVG Star Rating Section -->
        <div class="flex justify-center mb-4">
          <span
            v-for="(star, index) in 5"
            :key="index"
            @click="setRating(index + 1)"
            @mouseover="hoverRating(index + 1)"
            @mouseleave="hoverRating(0)"
            class="cursor-pointer transition-transform duration-200 transform hover:scale-110"
          >
            <svg
              :class="
                index < (hoveredRating || rating)
                  ? 'text-[#0077C8]'
                  : 'text-gray-300'
              "
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              class="w-8 h-8"
            >
              <path
                d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
              />
            </svg>
          </span>
        </div>

        <!-- Feedback Text Area -->
        <p class="text-center mb-2" v-if="rating">
          Tell us a bit more about why you chose {{ rating }}
        </p>
        <textarea
          v-model="feedback"
          rows="4"
          class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#0077C8]"
          placeholder="Your feedback here..."
        ></textarea>

        <!-- Error Message Display -->
        <p v-if="errorMessage" class="text-red-500 mt-1">
          {{ errorMessage }}
        </p>

        <!-- Submit Button -->
        <button
          @click="submitFeedback"
          class="w-full mt-3 bg-[#0077C8] text-white py-2 px-4 rounded-lg hover:bg-[#005B9A] focus:outline-none focus:ring-2 focus:ring-[#0077C8]"
        >
          Submit
        </button>
      </template>

      <!-- Thank You Message -->
      <template v-else>
        <h2 class="text-xl font-bold mb-4 text-center">Thank You!</h2>
        <p class="text-center mb-4">We appreciate your valuable input!</p>
        <button
          @click="closeModal"
          class="w-full mt-4 bg-[#0077C8] text-white py-2 px-4 rounded-lg hover:bg-[#005B9A] focus:outline-none focus:ring-2 focus:ring-[#0077C8]"
        >
          Close
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      rating: 0,
      hoveredRating: 0,
      feedback: "",
      isSubmitted: false,
      errorMessage: "",
    };
  },
  methods: {
    setRating(star) {
      this.rating = star;
    },
    hoverRating(star) {
      this.hoveredRating = star;
    },
    submitFeedback() {
      if (this.rating === 0) {
        this.errorMessage = "Please provide a rating.";
        return;
      }
      if (this.feedback === "") {
        this.errorMessage = "Please provide your valuable feedback.";
        return;
      }

      this.errorMessage = "";

      this.$emit("submit-feedback", {
        rating: this.rating,
        comment: this.feedback,
      });

      this.isSubmitted = true;
    },
    closeModal() {
      this.isSubmitted = false;
      this.$emit("close-feedback-modal");
    },
  },
};
</script>
