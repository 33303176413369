var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',{staticClass:"card-section"},[_c('local-loader')],1):_c('div',{staticClass:"[ card-section ][ flex flex-col justify-between ] relative"},[_vm._m(0),_c('div',{staticClass:"[ h-full ][ flex flex-col justify-between ]"},[_c('div',{staticClass:"w-full h-24"},[_vm._v(" Evaluate the overall financial stability of your company, identifying strengths and areas for improvement. ")]),_c('div',{staticClass:"flex justify-between w-full items-end"},[_vm._m(1),_c('div',{staticClass:"flex flex-col justify-end gap-4 w-1/2 items-end"},[(_vm.displayViewResultsButton)?_c('router-link',{class:[
            'view-results-button',
            { 'opacity-50 cursor-not-allowed': _vm.investeeId },
          ],attrs:{"to":"/imm/dashboard/finance","disabled":_vm.investeeId}},[_vm._v(" View Results ")]):_vm._e(),(_vm.displayRepeatButton)?_c('router-link',{class:[
            'repeat-button',
            { 'opacity-50 cursor-not-allowed': _vm.investeeId },
          ],attrs:{"to":"/imm/finance-survey","disabled":_vm.investeeId}},[_vm._v(" Repeat ")]):_vm._e(),(_vm.displayLaunchButton)?_c('router-link',{class:[
            'primary-navy-button',
            { 'opacity-50 cursor-not-allowed': _vm.investeeId },
          ],attrs:{"to":"/imm/finance-survey","disabled":_vm.investeeId}},[_vm._v(" Launch ")]):_c('span',{staticClass:"text-gray-500"},[_vm._v(" This user has no financial data yet. ")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between h-10"},[_c('div',{staticClass:"font-bold text-xl"},[_vm._v("Financials")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"[ w-1/2 h-12 ][ flex justify-left items-center gap-4 ][ text-[#2177C1] font-bold text-sm ]"},[_c('a',{staticClass:"cursor-pointer",attrs:{"href":"#"}},[_vm._v("< > API integration")]),_c('a',{staticClass:"cursor-pointer flex items-center justify-center",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/images/dashboard/info.svg"),"alt":"info"}}),_vm._v(" Methodology ")])])
}]

export { render, staticRenderFns }