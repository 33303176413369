import { render, staticRenderFns } from "./my2xassesment-print.vue?vue&type=template&id=566b9d4d&scoped=true"
import script from "./my2xassesment-print.vue?vue&type=script&lang=js"
export * from "./my2xassesment-print.vue?vue&type=script&lang=js"
import style0 from "./my2xassesment-print.vue?vue&type=style&index=0&id=566b9d4d&prod&lang=scss&scoped=true"
import style1 from "./my2xassesment-print.vue?vue&type=style&index=1&id=566b9d4d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566b9d4d",
  null
  
)

export default component.exports