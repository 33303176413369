<template>
  <div v-if="loading" class="p-6 gap-6 rounded-2xl bg-[#f4f6f9]">
    <local-loader></local-loader>
  </div>
  <div v-else class="relative p-6 gap-6 rounded-2xl bg-[#f4f6f9]">
    <div class="flex justify-between">
      <div class="font-bold text-xl">2X Certification Self-Assessment</div>
      <div>{{ createdAt?.split("T")[0] || null }}</div>
    </div>
    <div class="flex gap-4">
      <elegible-badge
        :status="status"
        :elegible="elegible"
        text="2X Certified"
        alt-text="2X Eligible"
      ></elegible-badge>
      <elegible-badge
        :status="status"
        :elegible="governanceScore >= 70"
        text="2X Governance"
        alt-text="2X Governance"
        :noValue="governanceScore < 70"
      ></elegible-badge>
    </div>

    <div
      v-if="status === 'completed'"
      class="grid grid-cols-2 gap-4 text-[#717686] h-52 mb-20"
    >
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#4DC9BE]">
          {{ leadershipScore }}%
        </div>
        <div>Leadership</div>
      </div>
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#FF501C]">
          {{ entrepreneurshipScore }}%
        </div>
        <div>Entrepreneurship</div>
      </div>
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#FF501C]">
          {{ employmentScore }}%
        </div>
        <div>Employment</div>
      </div>
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#FF501C]">
          {{ productsAndServicesScore }}%
        </div>
        <div>Products & Services</div>
      </div>
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#FF501C]">
          {{ supplyChainScore }}%
        </div>
        <div>Supply Chain</div>
      </div>
      <div class="flex gap-2">
        <div class="font-bold text-base text-[#FF501C]">
          {{ portfolioScore }}%
        </div>
        <div>Portfolio</div>
      </div>
    </div>
    <div
      v-else-if="status === 'in-progress'"
      class="flex items-center justify-between h-24 mb-10"
    >
      <div class="w-3/5">
        <div class="text-right mb-1">
          <span class="text-[#4DC9BE] font-bold"
            >{{ completionPercentage }}%</span
          >
          Completed
        </div>
        <k-progress
          style="width: 110%"
          :percent="completionPercentage"
          color="#4DC9BE"
          :line-height="16"
          :show-text="false"
        ></k-progress>
      </div>
      <button class="primary-navy-button">
        <router-link
          to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
          >Continue</router-link
        >
      </button>
    </div>
    <div v-else class="mt-10 h-52">
      Evaluate your company's eligibility for 2X Certification, identifying key
      areas of progress and improvement.
    </div>
    <div class="flex justify-between absolute bottom-0 pb-5 pr-12 w-full">
      <div
        class="flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
      >
        <div>&lt; &gt; API integration</div>
        <div>
          <img src="@/assets/images/dashboard/info.svg" />
          Methodology
        </div>
      </div>
      <div v-if="status === 'completed'" class="flex gap-4">
        <router-link
          :class="[
            'secondary-navy-button',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
          to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
          :disabled="investeeId"
          >Repeat</router-link
        >
        <router-link
          class="primary-teal-button"
          to="/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results"
          >View results</router-link
        >
      </div>
      <div v-else-if="status === 'in-progress'"></div>
      <div v-else class="flex gap-4">
        <router-link
          v-if="!investeeId"
          :class="[
            'primary-navy-button',
            { 'opacity-50 cursor-not-allowed': investeeId },
          ]"
          to="/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment"
          :disabled="investeeId"
          >Launch</router-link
        >
        <span v-else class="text-gray-500">
          This user has no 2X certification data yet.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import localLoader from "@/components/local-loader.vue";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import ElegibleBadge from "./ElegibleBadge.vue";

export default {
  name: "TwoXAssessmentCard",
  components: {
    localLoader,
    ElegibleBadge,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    investeeId: {
      type: String || null,
    },
    status2xCertification: {
      type: String || null,
    },
  },
  data() {
    return {
      loading: true,
      status: null,
      elegible: false,
      leadershipScore: 0,
      entrepreneurshipScore: 0,
      employmentScore: 0,
      productsAndServicesScore: 0,
      supplyChainScore: 0,
      governanceScore: 0,
      portfolioScore: 0,
      createdAt: "",
      completionPercentage: 0,
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.loading = true;
          this.initializeData(newUser);
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async initializeData(user) {
      // 2X DATA
      await this.fetch2XUserData(user);
      // TODO Verify this string
      this.status =
        this.status2xCertification === "Completed" ||
        this.status2xCertification === "Verification in Process"
          ? "completed"
          : this.status2xCertification === "In Progress"
          ? "in-progress"
          : "not-started";

      if (this.status === "completed") {
        await this.fetch2XCertificationResults();
      }
    },
    async fetch2XUserData(user) {
      try {
        if (user.surveyData) {
          // find the most recent createdAt from user.surveyData
          const mostRecentSurvey = user.surveyData.reduce(
            (latest, survey) =>
              new Date(survey.createdAt) > new Date(latest.createdAt)
                ? survey
                : latest,
            user.surveyData[0] || {}
          );

          // count the number of incomplete surveys
          const incompleteSurveys = user.surveyData.reduce(
            (count, survey) => (!survey.isComplete ? count + 1 : count),
            0
          );

          // calculate the percentage of completion based on 10 surveys total
          const completionPercentage = ((10 - incompleteSurveys) / 10) * 100;

          const survey = user.surveyData.find(
            (survey) => survey.surveyId === "2x-intake"
          );

          if (!survey) {
            throw new Error("2x-intake survey not found");
          }

          const entityMap = {
            "financial-institution": "Financial Institution",
            "fund-manager": "Fund Manager",
            "start-up": "Start-up",
            sme: "SME",
            corporate: "Corporate",
          };

          this.entityType = entityMap[survey.tag] || survey.tag;
          this.createdAt = mostRecentSurvey.createdAt;
          this.completionPercentage = completionPercentage;
        }
      } catch (error) {
        console.error("Error fetching 2X user data:", error);
      }
    },
    async fetch2XCertificationResults() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/certify2x/2x-certification-result?entityType=${this.entityType}&assessmentType=Full`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.governanceScore =
          Math.round(Number(data["GOVACC_2X_104"])) || null;
        this.entrepreneurshipScore = Math.round(Number(data["ENTOWN_2X_104"]));
        this.employmentScore = Math.round(Number(data["EMPLOY_2X_104"]));
        this.productsAndServicesScore = Math.round(Number(data["PROD_2X_104"]));
        this.supplyChainScore = Math.round(Number(data["SUPCH_2X_104"]));
        this.leadershipScore = Math.round(Number(data["LEAD_2X_104"]));
        this.portfolioScore = Math.round(Number(data["PORT_2X_12901"]));
        const elegible =
          data["2X_2X_Score_Overall_300"] ||
          data["2X_2X_Score_Overall_301"] ||
          data["2X_2X_Score_Overall_302"];

        this.elegible = elegible === "Yes";
      } catch (error) {
        console.error("Error fetching 2X certification result:", error);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.primary-navy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #191b45;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
.secondary-navy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #fff;
  background-color: #fff;
  color: #191b45;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
.primary-teal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #4dc9be;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
</style>
