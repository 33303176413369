import BaseAPI from "@/api/index";

class UserAPI {
  resourceBasePath = "/users";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  updateTeamCurrencyPreference(currency) {
    return this.axiosInstance.patch(`${this.resourceBasePath}/team-preferences/currency`, { currency });
  }
}

export default UserAPI;
