var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'updateOpenTextViewComponents'.concat(_vm.updateKey),staticClass:"my-2"},[_c('table',[_c('tbody',_vm._l((_vm.values),function(value,k){return _c('tr',{key:k},[_c('td',{staticClass:"pr-4 pb-6"},[_c('h3',{staticClass:"font-bold"},[_vm._v(_vm._s(value.title)+":")])]),(value)?_c('td',{staticClass:"pl-4 pr-4 pb-6"},[(typeof value == 'object')?_c('div',_vm._l((value.value),function(item,i){return _c('span',{key:i},[_vm._v(_vm._s(item))])}),0):_c('span',[_vm._v(_vm._s(value.value))])]):_c('td',{staticClass:"pl-4 pr-4 pb-6"}),(
            _vm.$route.path.includes('/admin') &&
            value?.surveyQType != 'Calculated'
          )?_c('td',{staticClass:"pl-4 pr-4 pb-6",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"px-2 flex items-center justify-end"},[_c('img',{staticClass:"h-6 w-6 cursor-pointer mr-2",attrs:{"src":require("@/assets/images/edit-dark.svg"),"alt":"edit-icon"},on:{"click":function($event){return _vm.openEditModal(value)}}}),_c('verification-dropdown',{attrs:{"value":value}})],1),(
              _vm.$route.path.includes('/admin') &&
              value?.surveyQType != 'Calculated'
            )?_c('div',[_c('MLVerifierValue',{attrs:{"value":value,"teamId":_vm.$route.query?.teamId}})],1):_vm._e()]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }