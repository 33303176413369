<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";

export default {
  name: "DealPipelineForm",
  components: { EquiloButton },
  emits: ['submit'],
  data() {
    return {
      form: {
        no: "",
        lastUpdatedOn: "",
        transactionLead: "",
        status: "",
        dealStage: "",
        country: "",
        fund: "",
        comments: "",
        companyName: "",
        businessDescription: "",
        sector: "",
        subCategory: "",
        annualRevenue: "",
        annualEBITDA: "",
        impactTarget: ""
      },
      sectorOptions: [
        'Education',
        'Healthcare',
        'Sustainable Foods',
        'Climate Resilience',
        'Other'
      ]
    }
  },
  methods: {
    submitForm() {
      console.log(this.form);
      this.$emit('submit', {...this.form});
    }
  }
};
</script>

<template>
  <form @submit.prevent="submitForm">
    <div class="flex flex-col gap-4">
      <label for="no">No</label>
      <input type="text" id="no" v-model="form.no">
    </div>

    <div class="flex flex-col gap-4">
      <label for="lastUpdatedOn">Last Updated On</label>
      <input type="date" id="lastUpdatedOn" v-model="form.lastUpdatedOn">
    </div>

    <div class="flex flex-col gap-4">
      <label for="transactionLead">Transaction Lead</label>
      <input type="text" id="transactionLead" v-model="form.transactionLead">
    </div>

    <div class="flex flex-col gap-4">
      <label for="status">Status</label>
      <input type="text" id="status" v-model="form.status">
    </div>

    <div class="flex flex-col gap-4">
      <label for="dealStage">Deal Stage</label>
      <input type="text" id="dealStage" v-model="form.dealStage">
    </div>

    <div class="flex flex-col gap-4">
      <label for="country">Country</label>
      <input type="text" id="country" v-model="form.country">
    </div>

    <div class="flex flex-col gap-4">
      <label for="fund">Fund</label>
      <input type="text" id="fund" v-model="form.fund">
    </div>

    <div class="flex flex-col gap-4">
      <label for="comments">Comments / Notes</label>
      <textarea id="comments" v-model="form.comments"></textarea>
    </div>

    <div class="flex flex-col gap-4">
      <label for="companyName">Company Name</label>
      <input type="text" id="companyName" v-model="form.companyName">
    </div>

    <div class="flex flex-col gap-4">
      <label for="businessDescription">Business Description</label>
      <textarea id="businessDescription" v-model="form.businessDescription"></textarea>
    </div>

    <div class="flex flex-col gap-4">
      <label for="sector">Sector</label>
      <select id="sector" v-model="form.sector">
        <option value="">Please select a sector</option>
        <option v-for="option in sectorOptions" :key="option" :value="option">
          {{ option }}
        </option>
      </select>
    </div>

    <div class="flex flex-col gap-4">
      <label for="subCategory">Sub-Category</label>
      <input type="text" id="subCategory" v-model="form.subCategory">
    </div>

    <div class="flex flex-col gap-4">
      <label for="annualRevenue">Annual Revenue (US$)</label>
      <input type="number" id="annualRevenue" v-model="form.annualRevenue">
    </div>

    <div class="flex flex-col gap-4">
      <label for="annualEBITDA">Annual EBITDA (US$)</label>
      <input type="number" id="annualEBITDA" v-model="form.annualEBITDA">
    </div>

    <div class="flex flex-col gap-4">
      <label for="impactTarget">Impact Target (SDGs)</label>
      <input type="text" id="impactTarget" v-model="form.impactTarget">
    </div>

    <div class="flex flex-row justify-end gap-4 mt-4">
      <EquiloButton
        classes="[ h-12 ][ font-bold text-[16px] ]"
        text="Submit"
        type="submit"
        bg-color="primary"
      />
    </div>
  </form>
</template>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input,
textarea,
select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  appearance: none;
  background-color: #fff;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
