<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";
import TwoXThresholdAPI from "@/api/TwoXThresholdAPI";
import SubscriptionAPIs from "@/api/SubscriptionAPIs";

const ProductsNames = {
  singleRegion: "Single Region",
  threeRegions: "3 Regions",
  allRegions: "All Region/Global",
}

// Back-end does not support descriptions at the price level for now
const ProductsDescriptions = {
  singleRegion: "Dive into detailed data for one region of your choice.",
  threeRegions: "Expand your insights with data across three regions of your choice.",
  allRegions: "Obtain a comprehensive global perspective with our full data set.",
}

export default {
  name: "TwoXThresholdPaywall",
  components: { EquiloButton },
  data() {
    return {
      products: [],
      regions: [],
      selectedProduct: null,
      selectedRegions: [],
    };
  },
  emits: ["on-cancel"],
  methods: {
    async getStripeProducts() {
      try {
        const subscriptionAPI = new SubscriptionAPIs();
        const response = await subscriptionAPI.getThresholdProducts();
        const data = response.data;
        if (data.data.length === 0) {
          return;
        }

        const product = data.data[0];
        this.products = product.prices.map((price) => ({
          name: price.name,
          price: price.price,
          stripePriceId: price.stripePriceId,
          id: price._id,
        }));
      } catch (error) {
        console.error(`Error fetching products: ${error}`);
      }
    },
    async getRegions() {
      try {
        const twoXThresholdAPI = new TwoXThresholdAPI();
        const response = await twoXThresholdAPI.getRegions();
        this.regions = response.data.regions;
      } catch (error) {
        console.error(`Error fetching regions: ${error}`);
      }
    },
    async purchaseProducts() {
      const subscriptionAPI = new SubscriptionAPIs();
      const payload = {
        metadata: {
          regions: this.selectedRegions.join(","),
        },
        priceIds: [this.selectedProduct.stripePriceId],
        redirectUrl: window.location.href,
      };
      const response = await subscriptionAPI.createCheckoutSession("Equilo", payload);

      // Redirect user to the checkout page
      window.location.href = response.data.data;
    },
    isDisabledRegion(region) {
      if (!this.selectedProduct || this.selectedProduct.name === ProductsNames.allRegions) {
        return true;
      }

      if (this.selectedRegions.includes(region)) {
        return false;
      }

      if (this.selectedProduct.name === ProductsNames.singleRegion) {
        return this.selectedRegions.length === 1;
      }

      if (this.selectedProduct.name === ProductsNames.threeRegions) {
        return this.selectedRegions.length === 3;
      }

      return true;
    },
    productDescription(productName) {
      if (productName === ProductsNames.singleRegion) {
        return ProductsDescriptions.singleRegion;
      }
      if (productName === ProductsNames.threeRegions) {
        return ProductsDescriptions.threeRegions;
      }
      if (productName === ProductsNames.allRegions) {
        return ProductsDescriptions.allRegions;
      }
      return "";
    }
  },
  async mounted() {
    // Get Threshold Products
    await this.getStripeProducts();

    // Regions
    await this.getRegions();
  },
  computed: {
    isPurchaseButtonDisabled() {
      if (!this.selectedProduct || this.selectedRegions.length === 0) {
        return true;
      }

      if (this.selectedProduct.name === ProductsNames.singleRegion) {
        return this.selectedRegions.length !== 1;
      }

      if (this.selectedProduct.name === ProductsNames.threeRegions) {
        return this.selectedRegions.length !== 3;
      }

      if (this.selectedProduct.name === ProductsNames.allRegions) {
        return this.selectedRegions.length !== this.regions.length;
      }

      return true;
    }
  },
  watch: {
    selectedProduct: function (newVal) {
      if (newVal.name === ProductsNames.allRegions) {
        this.selectedRegions = this.regions.map(item => item);
        return;
      }

      this.selectedRegions = [];
    },
  },
};
</script>

<template>
  <div class="[ w-full h-full ][ px-2 py-4 ]">
    <!--  Header  -->
    <h3 class="font-bold text-2xl">2X Dynamic Threshold Data Set</h3>
    <div class="[ w-full ][ my-4 ]">
      <span>Equilo's curated data set is designed to help you make informed, data-driven decisions aligned with the
        2X Criteria. The data set in XLSX format is available for immediate access upon payment.
        Unlock insights with Equilo’s 2X Criteria Threshold Benchmarking Data Set now!
      </span>
    </div>

    <!--  Products list section  -->
    <div class="mt-4 mb-8 flex flex-col gap-4">
      <label
        v-for="(product, index) in products"
        :key="index"
        class="flex gap-4 items-center"
      >
        <input type="radio" name="radio" class="checkbox" :value="product" v-model="selectedProduct" />
        <div class="flex flex-col">
          <div class="flex gap-2 items-baseline">
            <span class="font-bold text-xl">{{ product.name }}</span>
            <span class="text-md">${{ product.price }}</span>
          </div>
          <span>{{ productDescription(product.name) }}</span>
        </div>
      </label>
    </div>

    <!--  Regions to select  -->
    <h3 class="font-bold text-xl">Select your regions</h3>
    <div class="mt-2 flex flex-col gap-2">
      <label
        v-for="(region, index) in regions"
        :key="index"
      >
        <input type="checkbox" :value="region" v-model="selectedRegions" :disabled="isDisabledRegion(region)"/>
        {{ region }}
      </label>
    </div>

    <!--  Footer  -->
    <div class="w-full flex justify-end">
      <div class="flex gap-2">
        <EquiloButton
          :text="'Cancel'"
          :bg-color="'cancel'"
          @on-click="$emit('on-cancel')"
        />
        <EquiloButton
          :text="'Buy'"
          :disabled="isPurchaseButtonDisabled"
          @on-click="purchaseProducts"
        />
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

</style>
