import BaseAPI from "@/api/index";

class ImmPreferenceAPI {
  resourceBasePath = "/imm-preferences";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  /**
   *
   * @param token<string>
   */
  getInvesteeInvitesVerifyToken(token) {
    return this.axiosInstance.get(`${this.resourceBasePath}/investee-invites/verify/${token}`);
  }

  /**
   *
   * @param token<string>
   */
  putAcceptInvite(token) {
    return this.axiosInstance.put(`${this.resourceBasePath}/investee-invites/verify/${token}/accept`);
  }
}

export default ImmPreferenceAPI;
