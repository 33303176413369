<template>
  <button
    @click="$emit('click')"
    class="px-4 py-2 text-gray-600 hover:text-gray-800 border-b-2 transition-colors duration-200 text-xl"
    :class="{
      'border-blue-500 text-blue-600': tabView === tab,
      'border-transparent': tabView !== tab,
      'font-bold': tabView === tab,
    }"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "TabView",
  props: {
    tabView: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
};
</script>
