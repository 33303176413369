<template>
  <div class="h-full mt-1">
    <div class="flex justify-center h-full">
      <div class="flex flex-col justify-end w-full grow shrink-0 basis-0">
        <div class="flex flex-col overflow-auto" ref="scrollContainer">
          <div class="flex flex-col items-center">
            <div>
              <div v-for="(history, index) in historyList" :key="history.id">
                <div
                  class="bg-white px-7 py-4 w-80 rounded-[16px] cursor-pointer"
                  @click="viewHistoryChat(history)"
                >
                  <div class="text-xs text-gray-400">{{ history.date }}</div>
                  <div class="font-bold text-[#201747] text-md">
                    {{
                      history.title.length > 27
                        ? history.title.slice(0, 27) + "..."
                        : history.title
                    }}
                  </div>
                  <div class="text-sm text-[#201747]">
                    {{
                      history.description.length > 45
                        ? history.description.slice(0, 45) + "..."
                        : history.description
                    }}
                  </div>
                </div>
                <div
                  class="flex justify-center"
                  v-if="index != historyList.length - 1"
                >
                  <div class="h-5 w-0.5 bg-[#201747]"></div>
                </div>
              </div>
            </div>
            <div class="text-5xl text-[#201747] font-bold">...</div>
          </div>
          <div class="px-4">
            <messageList
              :YOU="YOU"
              :messages="messages"
              :isLoadingChatResponse="isLoadingChatResponse"
              :expandedMessageIndex="expandedMessageIndex"
              @toggle-sources="toggleSources"
              @collect-feedback="collectFeedback"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageList from "../common/messageList.vue";
export default {
  name: "chatHistoryList",
  components: {
    messageList,
  },
  props: {
    YOU: {
      type: String,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    isLoadingChatResponse: {
      type: Boolean,
      required: true,
    },
    expandedMessageIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["collect-feedback", "toggle-sources", "view-history-chat"],
  data() {
    return {
      historyList: [],
    };
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    viewHistoryChat(history) {
      this.$emit("view-history-chat", history);
    },
    collectFeedback(messageId) {
      this.$emit("collect-feedback", messageId);
    },
    toggleSources(index) {
      this.$emit("toggle-sources", index);
    },
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>
