<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto px-4 lg:px-0 pb-32">
      <div v-if="isInstModal" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 -mt-3 sm:align-middle sm:w-full max-w-[50%] h-full max-h-[95vh] sm:p-4"
          >
            <div class="ModalInnerStyle">
              <div class="text-base">
                The 2X Assessment for Institutional Investors is not yet ready.
                However, you may assess your investees by proceeding to create
                an investor profile and requesting that your investees take the
                2X Challenge Self-Assessment.
              </div>
              <button
                @click="create2Xprofile()"
                class="flex justify-center m-auto mt-4 bg-[#A21942] px-4 py-2 rounded-lg text-white font-semibold"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ContentWrapper w-12/12">
        <div class="container flex items-center mb-1"></div>
        <div class="container flex items-center mb-3"></div>
        <div
          id="jotform-box"
          :class="entitySubmit ? '' : 'p-5'"
          :style="entitySubmit ? 'border: none;' : 'border: 1px solid #ccc;'"
        >
          <div
            v-if="
              !entitySubmit &&
              certificationSurveyData.length === 0 &&
              !isSurveyLoading
            "
            class="mb-5 mt-5"
          >
            <h3 class="text-center" style="font-weight: bold">
              Before we begin, what type of entity are you?
            </h3>
            <div class="mt-auto w-12/12" style="margin-left: 35%">
              <div
                class="text-sm leading-5 text-gray-900 w-full relative items-center"
              >
                <input
                  class="cursor-pointer"
                  type="radio"
                  id="start-up"
                  value="start-up"
                  v-model="entityChosen"
                />
                <label for="start-up" class="cursor-pointer">Start-up</label>
              </div>
              <div
                class="text-sm leading-5 text-gray-900 w-full relative items-center"
              >
                <input
                  class="cursor-pointer"
                  type="radio"
                  id="SME"
                  value="sme"
                  v-model="entityChosen"
                />
                <label for="SME" class="cursor-pointer">SME</label>
              </div>
              <div
                class="text-sm leading-5 text-gray-900 w-full relative items-center"
              >
                <input
                  class="cursor-pointer"
                  type="radio"
                  id="corporate"
                  value="corporate"
                  v-model="entityChosen"
                />
                <label for="corporate" class="cursor-pointer">Corporate</label>
              </div>
              <div
                class="text-sm leading-5 text-gray-900 w-full relative items-center"
              >
                <input
                  class="cursor-pointer"
                  type="radio"
                  id="fund"
                  value="fund-manager"
                  v-model="entityChosen"
                />
                <label for="fund" class="cursor-pointer">Fund</label>
              </div>
              <div
                class="text-sm leading-5 text-gray-900 w-full relative items-center"
              >
                <input
                  class="cursor-pointer"
                  type="radio"
                  id="Financial_institution"
                  value="financial-institution"
                  v-model="entityChosen"
                />
                <label for="Financial_institution" class="cursor-pointer"
                  >Financial institution</label
                >
              </div>
            </div>
            <button
              class="mx-auto mt-5 next-button px-3 py-2 text-xs gap-1 font-bold rounded-lg transition duration-200 flex flex-row items-center justify-center"
              :class="{ 'disabled-button': !entityChosen }"
              :disabled="!entityChosen"
              @click="checkForUserAgreement"
            >
              <span>Submit</span>
            </button>
          </div>
          <div class="container mx-auto" v-if="entitySubmit && !isInstModal">
            <!-- For full surveys -->
            <div
              v-if="
                this.$route.path.includes('2X-Certification-Self-Assessment')
              "
            >
              <div class="grid grid-cols-3 gap-5">
                <div
                  v-for="module in sortedModules"
                  :key="module.module"
                  class="rounded-lg shadow p-6 h-64 flex flex-col items-center justify-center relative border text-white tooltip"
                  :style="{ backgroundColor: getModuleColor(module.name) }"
                >
                  <h2 class="text-xl font-bold text-white">
                    {{ module.name }}
                  </h2>
                  <div class="absolute bottom-0 right-0 my-4 mx-6">
                    <!-- v-else -->
                    <span class="text-gray-200 flex items-center">
                      <div v-if="$route.query.twoxRepeatAsses">
                        {{
                          isFullSurveyCompleted(module.surveyUrl) &&
                          !module.isPending
                            ? "Edit"
                            : "Pending"
                        }}
                      </div>
                      <div v-else>
                        {{
                          isFullSurveyCompleted(module.surveyUrl)
                            ? "Edit"
                            : "Pending"
                        }}
                      </div>
                      <img
                        v-if="module.surveyUrl === '2x-intake'"
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else-if="
                          activeFull2xgovernance &&
                          module.surveyUrl === '2x-governance'
                        "
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else-if="activeFullRemainSurvey"
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else
                        class="ml-2 bg-gray-100 rounded-full p-0.5"
                        src="@/assets/images/lock-dark.png"
                        style="width: 24px"
                      />
                    </span>
                  </div>
                  <div
                    v-if="module.surveyUrl == '2x-intake'"
                    class="absolute transition-opacity duration-300 z-50 w-full max-w-sm mb-10 opacity-0 hover:opacity-100"
                  >
                    <div
                      class="bg-[#201747] text-white p-2 rounded-lg text-sm break-words mx-auto"
                    >
                      <span v-if="module.surveyUrl == '2x-intake'">
                        We require basic information about your entity to get
                        started. After completing the General Intake, the
                        Governance & Accountability module will unlock, and all
                        other modules will be accessible upon its completion.
                        You can then proceed with the remaining modules in any
                        order.
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="module.surveyUrl == '2x-commitment'"
                    class="absolute transition-opacity duration-300 z-50 w-full max-w-sm mb-10 opacity-0 hover:opacity-100"
                  >
                    <div
                      class="bg-[#201747] text-white p-2 rounded-lg text-sm break-words mx-auto"
                    >
                      <span v-if="module.surveyUrl == '2x-commitment'">
                        {{ commitText }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- For min req surveys -->
            <div v-else>
              <div class="grid grid-cols-3 gap-5">
                <div
                  v-for="module in sortedModules"
                  :key="module.module"
                  class="rounded-lg shadow p-6 h-64 flex flex-col items-center justify-center relative border text-white"
                  :style="{ backgroundColor: getModuleColor(module.name) }"
                >
                  <h2 class="text-xl font-bold text-white">
                    {{ module.name }}
                  </h2>
                  <div class="absolute bottom-0 right-0 my-4 mx-6">
                    <span class="text-gray-200 flex items-center">
                      <div v-if="$route.query.twoxRepeatAsses">
                        {{
                          isSurveyCompleted(module.surveyUrl) &&
                          !module.isPending
                            ? "Edit"
                            : "Pending"
                        }}
                      </div>
                      <div v-else>
                        {{
                          isSurveyCompleted(module.surveyUrl)
                            ? "Edit"
                            : "Pending"
                        }}
                      </div>
                      <img
                        v-if="module.surveyUrl === '2x-intake'"
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else-if="
                          active2xgovernance &&
                          module.surveyUrl === '2x-governance'
                        "
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else-if="activeRemainSurvey"
                        src="@/assets/images/edit_black_icon.png"
                        id="editImageIcon"
                        alt="edit-image-icon/2"
                        class="ml-2 bg-gray-100 rounded-full p-0.5 cursor-pointer"
                        @click="goToSurvey(module.surveyUrl)"
                      />

                      <img
                        v-else
                        class="ml-2 bg-gray-100 rounded-full p-0.5"
                        src="@/assets/images/lock-dark.png"
                        style="width: 24px"
                      />
                    </span>
                  </div>
                  <div
                    v-if="module.surveyUrl == '2x-intake'"
                    class="absolute transition-opacity duration-300 z-50 w-full max-w-sm mb-10 opacity-0 hover:opacity-100"
                  >
                    <div
                      v-if="!showReviewSurveyModules"
                      class="bg-[#201747] text-white p-2 rounded-lg text-sm break-words mx-auto"
                    >
                      <span v-if="module.surveyUrl == '2x-intake'">
                        We require basic information about your entity to get
                        started. After completing the General Intake, the
                        Governance & Accountability module will unlock, and all
                        other modules will be accessible upon its completion.
                        You can then proceed with the remaining modules in any
                        order.
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="module.surveyUrl == '2x-commitment'"
                    class="absolute transition-opacity duration-300 z-50 w-full max-w-sm mb-10 opacity-0 hover:opacity-100"
                  >
                    <div
                      v-if="!showReviewSurveyModules"
                      class="bg-[#201747] text-white p-2 rounded-lg text-sm break-words mx-auto"
                    >
                      <span v-if="module.surveyUrl == '2x-commitment'">
                        {{ commitText }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollSurvey">
          <div
            v-if="entitySubmit && !isInstModal"
            class="w-12/12 relative"
            style="background: transparent"
          >
            <div style="height: 60px" class="flex items-center mt-2">
              <div
                class="flex items-center gap-5"
                style="margin-left: auto; margin-right: 0"
              >
                <div class="relative">
                  <button
                    class="next-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex items-center"
                    @click="showReviewSurveyModules = allUUIDsEq"
                    :disabled="!isSubmitEnabled"
                    :class="{
                      'opacity-50 cursor-not-allowed': !isSubmitEnabled,
                    }"
                    :title="actionableMessage"
                  >
                    Review And Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="completedSurveyModal && allUUIDsEq && !showReviewSurveyModules"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div class="Modal_Readmorestyle what-modal pr-2 justify-center">
            <div class="ModalInnerStyle">
              <p class="font-semibold">
                You have successfully completed all of the
                {{
                  $route.path.includes("2X-Certification-Self-Assessment")
                    ? "2X Certification Self-Assessment"
                    : "2X Challenge Self-Assessment"
                }}. You may click on any module to review and edit your
                responses, or you may proceed to submit your results.
              </p>
              <div class="flex mt-10 justify-center gap-5">
                <button
                  style="background-color: #3f7e44; color: white"
                  class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mt-2"
                  @click="isRepeat($route.query.twoxRepeatAsses)"
                >
                  Edit Responses
                </button>
                <button
                  style="background-color: #19486a; color: white"
                  class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mt-2"
                  @click="showReviewSurveyModules = true"
                >
                  Review And Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        $route.query.twoxRepeatAsses === 'true' &&
        allUUIDsSame &&
        !$route.path.includes('2x-intake') &&
        showModal
      "
    >
      <equilo-modal
        :isVisible="showModal"
        @close="showModal = false"
        :title="repeatTitle"
        size="3xl"
        :custom-transition="true"
        enter-active-class="transition-opacity duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        :prevent-backdrop-click="true"
        :confirmText="'go to 2X intake survey'"
        @confirm="handleConfirm"
      >
        <p class="font-medium leading-8 text-base text-[#201747]">
          Repeat the
          <span class="!text-yellow-500 font-semibold"
            >{{
              this.$route.path.includes("2X-Certification-Self-Assessment")
                ? "2X Certification Self-Assessment"
                : "2X Challenge Self-Assessment"
            }}
          </span>
          by going through the General Intake module. Once you've reviewed and
          are ready to submit your data, simply click Complete.
        </p>
      </equilo-modal>
    </div>
    <transition name="modal">
      <ReviewSurveyModules
        v-if="showReviewSurveyModules"
        :uuid="baseUUID"
        @edit-module="goToSurvey"
        @submit-assessment="showConfirmationModal = true"
        @close="showReviewSurveyModules = false"
      />
    </transition>
    <!-- Survey Modules Selection Modal -->
    <ModuleSelectionModal
      v-if="$route.path.includes('2X-Challenge-Self-Assessment') && isModuleSectionOpen"
      :visible="isModuleSectionOpen"
      :surveyModules="filteredModulesForSelection"
      @close="isModuleSectionOpen = false"
      @submit="handleModuleSelectionSubmit"
    />
    <ConfirmationModal
      v-if="showConfirmationModal"
      @close="showConfirmationModal = false"
      @confirm="submitResults()"
    />
    <TwoXUserAgreement
      :user="user"
      :showAgreement="show2XUserAgreement"
      @agreement-accepted="
        () => {
          show2XUserAgreement = false;
          entityChosen ? submit() : null;
        }
      "
      @agreement-denied="show2XUserAgreement = false"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import env from "@/../env";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import apiService from "@/helpers/apiServices.js";
import EquiloModal from "./common/EquiloModal.vue";
import ModuleSelectionModal from "./2xModuleSelectionModal.vue";
import ConfirmationModal from "./ConfirmationModal.vue";
import TwoXUserAgreement from "@/pages/towxGlobalPortal/2xUserAgreement.vue";
import ReviewSurveyModules from "@/components/ReviewSurveyModules.vue";

export default {
  name: "TwoXCertSurveyModulesDashboard",
  data: function () {
    return {
      current_step: 0,
      divWidth: 0,
      current_module: -1,
      number_of_modules: 0,
      modules: [],
      entityChosen: ref(null),
      entitySubmit: false,
      timeToComplete: 45,
      certificationAssessData: [],
      certAlreadyFilled: false,
      certificationSurveyData: [],
      surveyData: null,
      isComplete: false,
      surveyId: "",
      COM_Commitment: [],
      certificationScoreData: [],
      thresholds: [],
      thresholdsData: [],
      workforceThreshold: "",
      srManagerThreshold: "",
      boardThreshold: "",
      isAllAssessCompleted: false,
      isAllMinReqSurveysCompleted: false,
      isAllFullSurveysCompleted: false,
      isFullSurveyStarted: false,
      completedSurveyModal: false,
      activeRemainSurvey: false,
      active2xgovernance: false,
      activeFullRemainSurvey: false,
      activeFull2xgovernance: false,
      isInstModal: false,
      commitText:
        "If there are Dimension(s) whose minimum requirements you do not currently meet, you can consider making a formal commitment to meeting them which may help you achieve a specific Certification level. We suggest you complete this survey once you have a clear understanding of the Dimensions whose minimum requirements you do not currently meet and whether you’d be willing to make a formal commitment toward meeting them.",
      showSelfAssessResults: false,
      actionableMessage: "",
      mismatchedSurveys: [],
      isSurveyLoading: false,
      showModal: false,
      isModuleSectionOpen: false,
      baseModules: ["2x-intake", "2x-governance", "2x-esg", "2x-portfolio"],
      showConfirmationModal: false,
      show2XUserAgreement: false,
      status2xCertification: "",
      baseUUID: null,
      showReviewSurveyModules: false,
    };
  },
  props: ["user"],
  components: {
    EquiloModal,
    ModuleSelectionModal,
    ConfirmationModal,
    TwoXUserAgreement,
    ReviewSurveyModules,
  },
  methods: {
    checkForUserAgreement() {
      if (!this.entityChosen) {
        return;
      }
      if (
        !this.$route.path.includes("2X-Certification-Self-Assessment") ||
        this.user.userAgreement2XGlobal
      ) {
        this.submit();
      }

      if (this.$route.path.includes("2X-Certification-Self-Assessment")) {
        this.$emit("entityChosen", this.entityChosen);
      }

      if (
        this.$route.path.includes("2X-Certification-Self-Assessment") &&
        !this.user.userAgreement2XGlobal
      ) {
        this.show2XUserAgreement = true;
      }
    },
    submitResults() {
      this.showConfirmationModal = false;
      this.showReviewSurveyModules = false;
      const _self = this;
      let flag = {};
      if (this.$route.query.twoxRepeatAsses) {
        let successMessage =
          "You have successfully submitted your survey responses!";
        let nextRoute = "";

        if (this.$route.path.includes("2X-Challenge-Self-Assessment")) {
          nextRoute = "/2X-Challenge/2X-Challenge-Self-Assessment-Results";
        } else if (
          this.$route.path.includes("2X-Certification-Self-Assessment")
        ) {
          nextRoute = "/2X-Ecosystem/2X-Certification/IndicativeResults";
        }

        if (nextRoute) {
          this.$swal
            .fire({
              icon: "success",
              text: successMessage,
              showConfirmButton: true,
            })
            .then(() => {
              this.$router.push(nextRoute);
              window.scrollTo(0, 0);
            });
        }
      } else {
        if (
          this.isAllMinReqSurveysCompleted &&
          !this.isAllFullSurveysCompleted
        ) {
          flag = { minSurveySubmitted: true };
        }
        if (
          this.isAllMinReqSurveysCompleted &&
          this.isAllFullSurveysCompleted
        ) {
          flag = { fullSurveySubmitted: true };
        }
        axios
          .post(
            env.apiUrl + "/certify2x/2x-certification-verification-request",
            flag,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(() => {
            this.completedSurveyModal = false;
            this.$swal
              .fire({
                icon: "success",
                text: "You have successfully submitted your survey responses!",
                showConfirmButton: true,
              })
              .then(function () {
                let url;
                if (flag?.minSurveySubmitted) {
                  url = "/2X-Challenge/2X-Challenge-Self-Assessment-Results";
                }
                if (flag?.fullSurveySubmitted) {
                  url = "/2X-Ecosystem/2X-Certification/IndicativeResults";
                }
                _self.$router.push({ path: url });
              });
          })
          .catch(() => {
            this.completedSurveyModal = false;
            this.$swal.fire({
              icon: "error",
              text: "Something went Wrong!",
              showConfirmButton: true,
            });
          });
      }
    },
    checkSurveysCompleted() {
      let minReqSurveyCount = 0;
      let fullSurveyCount = 0;

      this.certificationSurveyData.forEach((data) => {
        if (
          (data?.metadata?.minReq && data.isComplete) ||
          this.isFullSurveyCompleted("2x-intake") ||
          this.status2xCertification == "Min Survey Submitted"
        ) {
          minReqSurveyCount++;
        }
        if (data?.metadata?.fullAssess && data.isComplete) {
          fullSurveyCount++;
        }
      });
      if (minReqSurveyCount === this.modules.length) {
        this.isAllMinReqSurveysCompleted = true;
      }
      if (fullSurveyCount === this.modules.length) {
        this.isAllFullSurveysCompleted = true;
      }
      this.isFullSurveyStarted =
        (this.isFullSurveyCompleted("2x-intake") ||
          this.certificationSurveyData.filter(
            (e) => e.surveyId === "2x-intake"
          )?.[0]?.metadata?.fullAssess) &&
        this.isAllMinReqSurveysCompleted;
      if (
        this.$route.path.includes("2X-Certification-Self-Assessment") &&
        this.isFullSurveyStarted
      ) {
        this.isAllAssessCompleted = this.isAllFullSurveysCompleted;
      } else {
        this.isAllAssessCompleted = this.isAllMinReqSurveysCompleted;
      }
      if (
        this.isAllAssessCompleted &&
        this.$store.state.certPageAccess.isMinSurveyFill &&
        !this.$route.path.includes("2X-Certification-Self-Assessment") &&
        !this.$route.query.twoxRepeatAsses
      ) {
        this.$router.push({ path: this.redirectUrl });
      }
      if (
        this.$store.state.certPageAccess.isFullSurveyFill &&
        !this.$route.query.twoxRepeatAsses
      ) {
        this.$router.push({ path: this.redirectUrl });
      }
      if (
        (!this.$store.state.certPageAccess.isMinSurveyFill &&
          this.isAllMinReqSurveysCompleted) ||
        (!this.$store.state.certPageAccess.isFullSurveyFill &&
          this.isAllFullSurveysCompleted)
      ) {
        this.completedSurveyModal = true;
      }
      // Redirect to full survey dashboard page if 2x-intake is full and min is completed
      if (
        this.isFullSurveyCompleted("2x-intake") &&
        this.$route.path == "/2X-Challenge/2X-Challenge-Self-Assessment" &&
        !this.$store.state.certPageAccess.isFullSurveyFill &&
        !this.$route.query.twoxRepeatAsses
      ) {
        this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment",
        });
      }
      // Redirect to min survey dashboard page if 2x-intake is min and full is not started yet
      if (
        this.isMinSurveyCompleted("2x-intake") &&
        !this.isFullSurveyCompleted("2x-intake") &&
        this.$route.path ==
          "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment" &&
        !this.$store.state.certPageAccess.isMinSurveyFill &&
        !this.$route.query.twoxRepeatAsses
      ) {
        this.$router.push({
          path: "/2X-Challenge/2X-Challenge-Self-Assessment",
        });
      }
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$store.state.certPageAccess.isFullSurveyFill ||
            this.$route.path.includes("/2X-Certification-Self-Assessment")
            ? "Full"
            : "Min"
        )
        .then(async (res) => {
          this.certificationScoreData = res.data.data;
          await this.calculateThreshold();
        });
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.endofcreated = true;
    },
    async getThresholdsData(recordId, sector) {
      await apiService
        .fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
        });
    },
    isSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey.surveyResponse &&
          survey.surveyResponse.length > 0 &&
          survey.isComplete
      );
    },

    isFullSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey?.metadata?.fullAssess &&
          survey.isComplete
      );
    },
    isMinSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey?.metadata?.minReq &&
          survey.isComplete
      );
    },
    goToSurvey(surveyUrl) {
      const routeName = this.$route.path.includes(
        "2X-Certification-Self-Assessment"
      )
        ? "twoxCertifyFullAssessmentSurveyPage"
        : "twoxCertifyAssessmentSurveyPage";

      const query =
        this.user?.surveyData?.length &&
        this.$route.path.includes("2X-Certification-Self-Assessment")
          ? { entityType: this.entityChosen }
          : { entityType: this.entityChosen };

      if (this.$route.query.twoxRepeatAsses) {
        query.twoxRepeatAsses = "true";
      }

      this.$router.push({
        name: routeName,
        params: { surveyURL: surveyUrl },
        query: query,
      });
    },
    getModuleColor(module) {
      if (module === "General Intake") {
        return "#C2BEBA";
      } else if (module === "Governance & Accountability") {
        return "#666461";
      } else if (module === "Entrepreneurship & Ownership") {
        return "#19486A";
      } else if (module === "Leadership") {
        return "#3F7E44";
      } else if (module === "Employment") {
        return "#DDA63A";
      } else if (module === "Supply Chain") {
        return "#381B5B";
      } else if (module === "Products & Services") {
        return "#A21942";
      } else if (module === "Additional 2X Commitment(s)" || module === "2X Criteria Commitment") {
        return "#19486A";
      } else if (module === "Portfolio") {
        return "#8F210D";
      } else if (module === "Basic 2X ESG") {
        return "#67A069";
      }
      return "#67A069";
    },
    create2Xprofile() {
      this.$router.push({ path: "/2X-Ecosystem/create2Xprofile" });
      this.isInstModal = false;
    },
    async submit() {
      if (this.entityChosen == "Institutional investor") {
        if (
          this.user?.membership2xData ||
          this.$store.state.isDomainValid ||
          this.user.twoXCoInvestorPrivileges
        ) {
          this.$router.push({ path: "/submit-deal" });
        } else {
          this.isInstModal = true;
          // this.$router.push({ path: "/2X-Ecosystem/create2Xprofile" });
        }
      }
      this.current_module = 0;
      this.modules = [
        {
          name: "General Intake",
          module: "1",
          surveyUrl: "2x-intake",
          var: "GENCertify100",
        },
        {
          name: "Governance & Accountability",
          module: "2",
          surveyUrl: "2x-governance",
          var: "GOVACCCertify100",
        },
        {
          name: "Entrepreneurship & Ownership",
          module: "3",
          surveyUrl: "2x-entrepreneurship",
          var: "ENTOWNCertify100",
        },
        {
          name: "Leadership",
          module: "4",
          surveyUrl: "2x-lead",
          var: "LEADCertify100",
        },
        {
          name: "Employment",
          module: "5",
          surveyUrl: "2x-employment",
          var: "EMPLOYCertify100",
        },
        {
          name: "Supply Chain",
          module: "6",
          surveyUrl: "2x-supply-chain",
          var: "SUPCHCertify100",
        },
        {
          name: "Products & Services",
          module: "7",
          surveyUrl: "2x-products-services",
          var: "PRODCertify100",
        },
        {
          name: this.$route.path.includes('2X-Challenge/2X-Challenge-Self-Assessment') ? "2X Criteria Commitment" : "Additional 2X Commitment(s)",
          module: "8",
          surveyUrl: "2x-commitment",
        },
      ];
      if (
        this.entityChosen == "fund-manager" ||
        this.entityChosen == "financial-institution" ||
        this.entityChosen == "Institutional investor"
      ) {
        this.modules.push({
          name: "Portfolio",
          module: "9",
          surveyUrl: "2x-portfolio",
          var: "PORTCertify100",
          isPending: false,
        });
        this.timeToComplete = 50;
      } else {
        this.timeToComplete = 45;
      }
      this.modules.push({
        name: "Basic 2X ESG",
        module:
          this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor"
            ? "10"
            : "9",
        surveyUrl: "2x-esg",
        var: "",
      });
      this.number_of_modules = this.modules.length;
      sessionStorage.setItem("entityChosen", this.entityChosen);
      this.surveyId = this.modules[this.current_module]?.surveyUrl;
      this.entitySubmit = true;
      this.isModuleSectionOpen = true;
    },
    async getUserSelectedModules() {
      try {
        const response = await apiService.fetch2xModuleSelection();
        const selectedModules = response.data.data.selectedModules;
        this.modules = this.modules.filter(module => selectedModules.includes(module.surveyUrl));
      }
      catch (error) {
        console.log("Error in fetching modules: ", error);
      }
    },
    async handleModuleSelectionSubmit(selectedModules) {
      const baseModules = this.modules.filter(module => this.baseModules.includes(module.surveyUrl));
      this.modules = baseModules.concat(selectedModules);
      this.isModuleSectionOpen = false;
      let moduleNames = selectedModules.map(module => module.surveyUrl);
      moduleNames = moduleNames.concat(this.baseModules);
      try {
        await apiService.post2xModuleSelection(moduleNames);
      }
      catch (error) {
        console.log("Error in selection: ", error);
      }
    },
    allUUIDsSame(data) {
      if (data.length <= 1) return true;
      const firstUUID = data[0].uuid;
      for (let i = 1; i < data.length; i++) {
        if (data[i].uuid !== firstUUID) {
          return false;
        }
      }
      return true;
    },
    checkSurveys() {
      const baseSurvey = this.certificationSurveyData.find(
        (s) => s.surveyId === "2x-intake"
      );
      if (!baseSurvey) {
        return;
      }
      const baseUUID = baseSurvey.uuid;

      const hasFullAssess = (survey) =>
        survey.metadata?.minReq && !survey.metadata?.fullAssess;
      const hasMinAssess = (survey) =>
        !survey.metadata?.minReq && !survey.metadata?.fullAssess;
      const isUUIDMatches = (survey) =>
        survey.surveyId !== "2x-intake" && survey.uuid !== baseUUID;

      const existingSurveyIds = this.certificationSurveyData.map(
        (cert) => cert.surveyId
      );

      const missingSurveyUrls = this.modules
        .filter((module) => !existingSurveyIds.includes(module.surveyUrl))
        .map((module) => module.surveyUrl);

      if (missingSurveyUrls.length) {
        this.mismatchedSurveys = missingSurveyUrls;
      } else if (this.allUUIDsEq) {
        if (this.$route.path.includes("2X-Challenge-Self-Assessment")) {
          this.mismatchedSurveys = this.certificationSurveyData
            .filter(
              (survey) =>
                survey.surveyId && hasMinAssess(survey) && survey.isComplete
            )
            .map((survey) => survey.surveyId);
        } else {
          this.mismatchedSurveys = this.certificationSurveyData
            .filter(
              (survey) =>
                survey.surveyId && hasFullAssess(survey) && survey.isComplete
            )
            .map((survey) => survey.surveyId);
        }
      } else {
        this.mismatchedSurveys = this.certificationSurveyData
          .filter((survey) => survey.surveyId && isUUIDMatches(survey))
          .map((survey) => survey.surveyId);
      }

      if (this.mismatchedSurveys.length > 0) {
        const surveyNameMap = this.modules.reduce((map, module) => {
          if (module.surveyUrl) {
            map[module.surveyUrl] = module.name;
          }
          return map;
        }, {});

        const mismatchedSurveyNames = this.mismatchedSurveys.map(
          (surveyId) => surveyNameMap[surveyId] || surveyId
        );

        this.actionableMessage = `Please edit and submit the following surveys: ${
          mismatchedSurveyNames.length > 1
            ? mismatchedSurveyNames.slice(0, -1).join(", ") +
              " and " +
              mismatchedSurveyNames[mismatchedSurveyNames.length - 1]
            : mismatchedSurveyNames
        }`;
      } else {
        this.completedSurveyModal = true;
        this.actionableMessage = "All surveys are up to date";
      }
    },
    isRepeat(val) {
      if (val) {
        this.showModal = true;
        this.completedSurveyModal = false;
      } else {
        this.completedSurveyModal = false;
        this.showModal = false;
      }
    },
    handleConfirm() {
      const routeName = this.$route.path.includes(
        "2X-Certification-Self-Assessment"
      )
        ? "twoxCertifyFullAssessmentSurveyPage"
        : "twoxCertifyAssessmentSurveyPage";
      const query =
        this.user?.surveyData?.length &&
        this.$route.path.includes("2X-Certification-Self-Assessment")
          ? { entityType: this.entityChosen }
          : { entityType: this.entityChosen };

      if (this.$route.query.twoxRepeatAsses) {
        query.twoxRepeatAsses = "true";
      }

      this.$router
        .push({
          name: routeName,
          params: { surveyURL: "2x-intake" },
          query: query,
        })
        .then(() => {
          this.$nextTick(() => {
            window.location.reload();
          });
        });
    },
  },
  computed: {
    redirectUrl() {
      return this.showSelfAssessResults
        ? "/2X-Challenge/2X-Challenge-Self-Assessment-Results"
        : "/2X-Ecosystem/2X-Certification/IndicativeResults";
    },
    progressBarOptions() {
      return {
        text: {
          hideText: true,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 10,
          width: this.divWidth * 0.95,
          progressPadding: 0,
          type: "line",
        },
      };
    },
    isEmploymentEligible() {
      let val;
      if (this.entityChosen == "fund-manager") {
        val = this.certificationScoreData["EMPLOY_2X_304"];
      } else if (this.entityChosen == "financial-institution") {
        val = this.certificationScoreData["EMPLOY_2X_303"];
      } else if (this.entityChosen == "start-up") {
        val = this.certificationScoreData["EMPLOY_2X_300"];
      } else if (this.entityChosen == "sme") {
        val = this.certificationScoreData["EMPLOY_2X_301"];
      } else if (this.entityChosen == "corporate") {
        val = this.certificationScoreData["EMPLOY_2X_302"];
      }
      return val;
    },
    is2xgovernanceFilled() {
      return this.certificationSurveyData.filter(
        (data) =>
          data.surveyId === "2x-governance" &&
          data.surveyResponse &&
          data.surveyResponse.length &&
          data.isComplete
      ).length;
    },
    isIntakeFilled() {
      return this.certificationSurveyData.filter(
        (data) =>
          data.surveyId === "2x-intake" &&
          data.surveyResponse &&
          data.surveyResponse.length &&
          data.isComplete
      ).length;
    },
    isFull2xgovernanceFilled() {
      return this.certificationSurveyData.filter(
        (data) =>
          data.surveyId === "2x-governance" &&
          data.surveyResponse &&
          data.surveyResponse.length &&
          data?.metadata?.fullAssess &&
          data.isComplete
      ).length;
    },
    isFullIntakeFilled() {
      return this.certificationSurveyData.filter(
        (data) =>
          data.surveyId === "2x-intake" &&
          data.surveyResponse &&
          data.surveyResponse.length &&
          data?.metadata?.fullAssess &&
          data.isComplete
      ).length;
    },
    sortedModules() {
      const order = [
        "General Intake",
        "Governance & Accountability",
        "Basic 2X ESG",
        "Entrepreneurship & Ownership",
        "Leadership",
        "Employment",
        "Supply Chain",
        "Products & Services",
        "Portfolio",
        this.$route.path.includes('2X-Challenge/2X-Challenge-Self-Assessment') ? "2X Criteria Commitment" : "Additional 2X Commitment(s)",
      ];
      this.modules.forEach((module) => {
        module.isPending = this.mismatchedSurveys.includes(module.surveyUrl);
      });
      const modulesCopy = JSON.parse(JSON.stringify(this.modules));
      return modulesCopy.sort((a, b) => {
        const indexA = order.indexOf(a.name);
        const indexB = order.indexOf(b.name);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });
    },
    isSubmitEnabled() {
      return (
        this.isAllAssessCompleted &&
        this.allUUIDsEq &&
        this.mismatchedSurveys.length === 0
      );
    },
    repeatTitle() {
      return this.$route.path.includes("2X-Certification-Self-Assessment")
        ? "2X Certification Self-Assessment"
        : "2X Challenge Self-Assessment";
    },
    filteredModulesForSelection() {
      return this.modules.filter(module =>
        !this.baseModules.includes(module.surveyUrl)
      );
    },
  },
  async mounted() {
    // if (this.user?.surveyData) {
      this.isSurveyLoading = true;
      await apiService.fetch2xUserSurvey("2x", this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id).then(async (response) => {
        this.certificationSurveyData = await response.data.data;
      });
      await apiService
      .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
      .then(async (response) => {
        this.status2xCertification = response?.data?.data?.status;
      });
      this.isSurveyLoading = false;
    // }

    let surveyData = this.certificationSurveyData || [];
    this.allUUIDsEq = this.allUUIDsSame(surveyData);

    const twoXintakeSurvey = surveyData.find((s) => s.surveyId === "2x-intake");
    const twoXgovernanceSurvey = surveyData.find(
      (s) => s.surveyId === "2x-governance"
    );

    const twoXintake = twoXintakeSurvey ? twoXintakeSurvey.uuid : null;
    const twoXgovernance = twoXgovernanceSurvey
      ? twoXgovernanceSurvey.uuid
      : null;

    this.baseUUID = twoXintake;

    if (
      this.$route.query.twoxRepeatAsses &&
      this.$route.path.includes("2X-Challenge-Self-Assessment")
    ) {
      this.activeRemainSurvey =
        this.isIntakeFilled &&
        this.is2xgovernanceFilled &&
        twoXintake &&
        twoXgovernance &&
        twoXintake === twoXgovernance;
      this.active2xgovernance =
        this.isIntakeFilled &&
        twoXintake &&
        twoXgovernance &&
        twoXintake !== twoXgovernance;
    } else {
      this.active2xgovernance =
        this.isIntakeFilled && !this.is2xgovernanceFilled;
      this.activeRemainSurvey =
        this.isIntakeFilled && this.is2xgovernanceFilled;
    }

    if (
      this.$route.query.twoxRepeatAsses &&
      this.$route.path.includes("2X-Certification-Self-Assessment")
    ) {
      this.activeFull2xgovernance =
        this.isFullIntakeFilled &&
        twoXintake &&
        twoXgovernance &&
        twoXintake !== twoXgovernance;
      this.activeFullRemainSurvey =
        this.isFullIntakeFilled &&
        twoXintake &&
        twoXgovernance &&
        twoXintake === twoXgovernance;
    } else {
      this.activeFull2xgovernance =
        this.isFullIntakeFilled && !this.isFull2xgovernanceFilled;
      this.activeFullRemainSurvey =
        this.isFullIntakeFilled && this.isFull2xgovernanceFilled;
    }

    if (this.certificationSurveyData) {
      this.certificationSurveyData.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    if (this.entityChosen && this.certificationSurveyData) {
      this.entitySubmit = true;
      this.modules = [
        {
          name: "General Intake",
          module: "1",
          surveyUrl: "2x-intake",
          var: "GENCertify100",
          isPending: false,
        },
        {
          name: "Governance & Accountability",
          module: "2",
          surveyUrl: "2x-governance",
          var: "GOVACCCertify100",
          isPending: false,
        },
        {
          name: "Entrepreneurship & Ownership",
          module: "3",
          surveyUrl: "2x-entrepreneurship",
          var: "ENTOWNCertify100",
          isPending: false,
        },
        {
          name: "Leadership",
          module: "4",
          surveyUrl: "2x-lead",
          var: "LEADCertify100",
          isPending: false,
        },
        {
          name: "Employment",
          module: "5",
          surveyUrl: "2x-employment",
          var: "EMPLOYCertify100",
          isPending: false,
        },
        {
          name: "Supply Chain",
          module: "6",
          surveyUrl: "2x-supply-chain",
          var: "SUPCHCertify100",
          isPending: false,
        },
        {
          name: "Products & Services",
          module: "7",
          surveyUrl: "2x-products-services",
          var: "PRODCertify100",
          isPending: false,
        },
        {
          name: this.$route.path.includes('2X-Challenge/2X-Challenge-Self-Assessment') ? "2X Criteria Commitment" : "Additional 2X Commitment(s)",
          module: "8",
          surveyUrl: "2x-commitment",
          isPending: false,
        },
      ];
      if (
        this.entityChosen == "fund-manager" ||
        this.entityChosen == "financial-institution" ||
        this.entityChosen == "Institutional investor"
      ) {
        this.modules.push({
          name: "Portfolio",
          module: "9",
          surveyUrl: "2x-portfolio",
          var: "PORTCertify100",
          isPending: false,
        });
      }
      this.modules.push({
        name: "Basic 2X ESG",
        module:
          this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor"
            ? "10"
            : "9",
        surveyUrl: "2x-esg",
        var: "",
        isPending: false,
      });
      if (!this.$route.path.includes("2X-Certification-Self-Assessment")) {
        this.showSelfAssessResults = true;
      }
      if (this.$route.path.includes("2X-Challenge-Self-Assessment")) {
        await this.getUserSelectedModules();
      }
      await this.checkSurveysCompleted();
      this.number_of_modules = this.modules.length;
      sessionStorage.setItem("entityChosen", this.entityChosen);
      this.surveyId = this.modules[this.current_module]?.surveyUrl;
      await this.fetchCertificationData();
    }
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.checkSurveys();
  },
};
</script>

<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .SelectionBlock {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 40px 20px;
    max-width: 1000px;

    .column-wrapper {
      flex-direction: column;
    }
  }

  .estimated-time {
    background: #e5e9f1;
    border-radius: 30px;
    padding: 8px 15px;
    font-size: 10px;
    font-weight: bold;
    color: #717686;
  }

  .next-button {
    font-weight: 700;
    font-size: 16px;
    color: white;
    background: #191b45;
  }

  .standard-button {
    font-weight: bold;
    font-size: 10px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  .coming-soon-button {
    font-weight: bold;
    font-size: 10px;
    color: #717686;
    border-style: solid;
    border-color: #717686;
    border-width: 1px;
  }

  p {
    color: #717686;
  }
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  // background-color: #f2f2f2;
  .breadcrumbs-main-container {
    margin-top: 100px;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // padding-top: 170px;
    // padding: 170px 0 0;
    margin-top: 20px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      /* align-items: center; */
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .tooltip {
    position: relative;
  }

  .tooltip .general-intake-tooltiptext {
    font-family: "Montserrat", arial;
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    background-color: #201747;
    width: 550px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    right: 0px;

    bottom: 50px;
  }

  .tooltip:hover .general-intake-tooltiptext {
    visibility: visible;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #191b45;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px;

    .content {
    }

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #3f7e44;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #191b45;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}
.disabled-button {
  background-color: #cccccc;
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-enter-active,
.modal-leave-active {
  opacity: 1;
  transform: scale(1);
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.97);
}
</style>
