<template>
  <twoXDataTable :data="data" title="2X Deal Submissions" :count="count" :page="page" @sort-data="sortData"/>
</template>

<script>
import twoXDataTable from './2xDataTable.vue'
export default {
  name: "twoxDealSubmissions",
  components: {
    twoXDataTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true 
    },
    page: {
      type: Number,
      required: true
    }
  },
  methods: {
    sortData(sortOrder) {
      this.$emit('sort-deals-data', sortOrder);
    }
  }
}

</script>
