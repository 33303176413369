<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";

export default {
  name: "twoXFundManagerAUM",
  components: { EquiloButton },
  data() {
    return {
      fundManagerType: '',
      fundManagerOptions: [
        {value: 'Funds (AUM<$100M)', text: 'AUM < $100M'},
        {value: 'Funds (AUM>$100M)', text: 'AUM > $100M'},
      ],
    }
  },
  emits: ["on-continue", "on-cancel"],
  methods: {
    onClickContinue() {
      this.$emit("on-continue", this.fundManagerType);
    },
  }
}
</script>

<template>
  <div class="[ w-full h-full ]">
    <h3 class="font-bold text-2xl">Continue your 2X Certification journey now!</h3>
    <div class="[ mt-8 ][ flex flex-col gap-2 ]">
      <span class="[ w-full ][ text-base ]">
        Our product offering for funds varies according to AUM. Please select your estimated
        AUM below to proceed with the purchase of our "Level-Up Package.
      </span>
      <select
        class="[ w-64 ][ px-2 py-2 mt-2 ][ placeholder-gray-700 ][ text-gray-900 ][ rounded-md ][ border-2 border-gray-500 ][ focus:outline-none focus:shadow-outline ]"
        v-model="fundManagerType"
      >
        <option :disabled="true" value="">Please select one</option>
        <option v-for="fm in fundManagerOptions" :key="fm.value" :value="fm.value">
          {{ fm.text }}
        </option>
      </select>
    </div>
    <div class="[ flex justify-start items-center gap-2 ][ mt-8 ]">
      <EquiloButton
        :text="'Cancel'"
        :bg-color="'cancel'"
        @on-click="$emit('on-cancel')"
      />
      <EquiloButton
        :text="'Continue'"
        :disabled="!fundManagerType"
        @on-click="onClickContinue"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
