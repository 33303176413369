<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";
import TwoXThresholdAPI from "@/api/TwoXThresholdAPI";
import { saveAs } from 'file-saver';

export default {
  name: "my2xThresholdsCSV",
  components: { EquiloButton },
  setup() {
    const thresholdsAPI = new TwoXThresholdAPI();
    return { thresholdsAPI };
  },
  data() {
    return {
      isDownloading: false,
      csvData: null,
      errorCSV: "",
    };
  },
  methods: {
    async fetchCSVData() {
      this.isDownloading = true;
      try {
        const response = await this.thresholdsAPI.getCSV();
        this.csvData = response.data;
      } catch (error) {
        const self = this;
        this.errorCSV = "Sorry, something went wrong. Please try again later.";

        // Since the request failed, check if the response is a Blob
        if (error.response?.data instanceof Blob) {
          // Create a FileReader to read the Blob's textual content
          const reader = new FileReader();

          reader.onload = function(event) {
            const text = event.target.result;

            // If this text includes a message or you need to parse it as JSON
            try {
              const json = JSON.parse(text);
              self.errorCSV = json.message;
            } catch (e) {
              console.error("Could not parse the response as JSON. Response text:", text);
            }
          };

          // Read the Blob as a text
          reader.readAsText(error.response?.data);
        }
      } finally {
        this.isDownloading = false;
      }
    },
    downloadCSV() {
      saveAs(this.csvData, 'my_2x_threshold.xlsx');
    }
  },
  mounted() {
    this.$store.commit("setBreadcrumbs", [
      { name: "2X Criteria Thresholds", url: "/2X-Ecosystem/Thresholds" },
      { name: "2X Criteria Thresholds CSV", url: "/2X-Ecosystem/Thresholds/Download" },
    ]);
    this.fetchCSVData();
  }
};
</script>

<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-32">
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <div class="mt-5">
        <h1
          class="text-6xl tracking-tighter font-medium print:text-7xl text-[#21191D]"
          style="max-width: 845px"
        >
          <span style="font-family: Work Sans">2X Criteria Thresholds</span>
        </h1>

        <div class="flex flex-col mt-5 gap-4">
          <span v-if="errorCSV">{{ errorCSV }}</span>
          <span v-else>Here you can download your file</span>
          <div class="w-32">
            <EquiloButton
              @on-click="downloadCSV"
              :text="isDownloading ? 'Downloading...' : 'Download CSV'"
              :disabled="isDownloading || !!errorCSV"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ecosystem-landing {
  background-color: #f4f1ed;
  font-family: Work Sans;
  color: #21191d;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #21191d;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
