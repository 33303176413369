<template>
  <div class="gap-4">
    <div class="relative inline-block w-64 mr-2">
      <input
        v-model="searchQuery"
        @input="filterOrganizations"
        @focus="showDropdown = true"
        @blur="hideDropdownDelayed"
        placeholder="Search organization"
        class="w-full px-3 py-2 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <div v-if="showDropdown" class="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
        <div
          v-for="org in filteredOrganizations"
          :key="org._id"
          @mousedown="selectOrganization(org)"
          class="px-3 py-2 cursor-pointer hover:bg-gray-100"
        >
          {{ org.organization.name }}
        </div>
      </div>
    </div>
    <select @change="emitSelectedId" v-model="type" class="m-2 rounded-lg">
      <option value="" disabled>Select document type</option>
      <option v-for="type in typeOptions" :key="type" :value="type">
        {{ type }}
      </option>
    </select>
    <button @click="resetFilter"
      class="px-4 py-2 text-base font-medium text-gray-700 rounded-md border border-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      :class="{ 'border-[#201747] bg-[#201747] border-2 opacity-80 !text-white font-bold': type || teamId }"
      :disabled="!type && !teamId"
    >
      Clear Filters
    </button>
  </div>
</template>

<script>
export default {
  name: 'TwoxOrganizationSelect',
  props: {
    organizations: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      teamId: '',
      type: '',
      typeOptions: {
        DATA_SHARING_AGREEMENT: 'Data Sharing Agreement',
        BULK_UPLOAD: 'Bulk Upload'
      },
      searchQuery: '',
      filteredOrganizations: [],
      showDropdown: false
    }
  },
  methods: {
    emitSelectedId() {
      this.$emit('organization-selected', {
        teamId: this.teamId,
        type: this.type
      })
    },
    resetFilter() {
      this.teamId = ''
      this.type = ''
      this.searchQuery = ''
      this.$emit('organization-selected', {
        teamId: '',
        type: '',
      })
    },
    filterOrganizations() {
      const query = this.searchQuery.toLowerCase()
      this.filteredOrganizations = this.uniqueOrganizations.filter(org =>
        org.organization.name.toLowerCase().includes(query)
      )
    },
    selectOrganization(org) {
      this.teamId = org._id
      this.searchQuery = org.organization.name
      this.showDropdown = false
      this.emitSelectedId()
    },
    hideDropdownDelayed() {
      setTimeout(() => {
        this.showDropdown = false
      }, 200)
    }
  },
  computed: {
    uniqueOrganizations() {
      const uniqueNames = new Set()
      return this.organizations.filter(org => {
        if (!uniqueNames.has(org.organization.name)) {
          uniqueNames.add(org.organization.name)
          return true
        }
        return false
      })
    }
  },
  created() {
    this.filteredOrganizations = this.uniqueOrganizations
  }
}
</script>
