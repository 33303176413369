import axios from "axios";

export const userLocale = navigator.language || navigator.userLanguage;

export const pause = async () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 500);
  });
};

export const formatter = new Intl.NumberFormat(userLocale, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const fetchClientLocation =() => {
  return axios.get('https://ipapi.co/json/');
};

export const formatToCurrency = (value) => {
  if (value === undefined || value === null) {
    return '';
  }

  const number = typeof value === 'number' ? value : parseFloat(value);

  if (isNaN(number)) {
    return '0';
  }

  return `${number.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
}
