<template>
  <div class="fixed inset-0 flex items-center justify-center z-50 mt-10">
    <div
      class="bg-white rounded-lg shadow-lg p-6 w-[90vw] h-[90vh] overflow-y-auto relative"
    >
      <div>
        <h2 class="text-4xl font-semibold mb-4">Insights for Action</h2>
        <P class="text-base">
          Congratulations on completing your 2X Certification self-assessment!
          Understanding your 2X Certification Assessment results are a giant
          leap forward in improving your gender equality impact and work.
        </P>
        <p class="text-base">
          The aim of the Insights for Actions tool is to help you improve your
          2X Certifications results and your overall 2X Certification status.
          This is achieved by providing clear insights into your 2X
          Certification self-assessment results, as well as actionable
          recommendations on how to advance to the next level.
        </p>
        <h3 class="mt-2">
          <span class="text-2xl font-medium">Your current 2X status is: </span>
          <span class="text-2xl font-semibold">{{
            certificationScoreData?.level
          }}</span>
        </h3>
        <p class="text-base mt-2">
          If you would like to advance to the next level and reach the
          <i>DESIRED BADGE</i>, here are the actions you can take across
          different dimensions:
        </p>
        <table class="min-w-full border-collapse mt-4">
          <thead>
            <tr class="bg-gray-200">
              <th class="border px-4 py-2">
                <span class="inline-flex items-center">
                  <span>Areas to improve</span>
                </span>
              </th>
              <th class="border px-4 py-2">
                <span class="inline-flex items-center">
                  <span>Current Value</span>
                  <span class="ml-2 text-gray-500 cursor-pointer relative">
                    <!-- Wrap the SVG in its own span for hover behavior -->
                    <div class="tooltip mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M12 4a8 8 0 100 16 8 8 0 000-16z"
                        ></path>
                      </svg>
                      <!-- Tooltip, shown on hover of the svg (group hover) -->
                      <span
                        class="hover-text top-[20px] left-[-135px] mt-2 w-72 p-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-lg shadow-lg z-10"
                      >
                        This is your organization’s current value in this metric
                        based on your Self-assessment results.
                      </span>
                    </div>
                  </span>
                </span>
              </th>
              <th class="border px-4 py-2">
                <span class="inline-flex items-center">
                  <span>Minimum Criteria</span>
                  <span class="ml-2 text-gray-500 cursor-pointer relative">
                    <!-- Wrap the SVG in its own span for hover behavior -->
                    <div class="tooltip mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M12 4a8 8 0 100 16 8 8 0 000-16z"
                        ></path>
                      </svg>
                      <!-- Tooltip, shown on hover of the svg (group hover) -->
                      <span
                        class="hover-text top-[20px] left-[-135px] mt-2 w-72 p-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-lg shadow-lg z-10"
                      >
                        This is the minimum required value in this metric to
                        advance to the next 2X Certification level.
                      </span>
                    </div>
                  </span>
                </span>
              </th>
              <th class="border px-4 py-2">
                <span class="inline-flex items-center">
                  <span>Actions to improve</span>
                  <span class="ml-2 text-gray-500 cursor-pointer relative">
                    <!-- Wrap the SVG in its own span for hover behavior -->
                    <div class="tooltip mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M13 16h-1v-4h-1m1-4h.01M12 4a8 8 0 100 16 8 8 0 000-16z"
                        ></path>
                      </svg>
                      <!-- Tooltip, shown on hover of the svg (group hover) -->
                      <span
                        class="hover-text top-[20px] left-[-135px] mt-2 w-72 p-4 bg-white text-sm text-gray-700 border border-gray-300 rounded-lg shadow-lg z-10"
                      >
                        Improvements to be made to advance to the next 2X
                        Certification level.
                      </span>
                    </div>
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="value in arrayData"
              :key="value.key"
              class="hover:bg-gray-100"
            >
              <td class="border px-4 py-2">
                <!-- <div
                  v-for="field in value.fieldsUsedWithNames"
                  :key="field.key"
                >
                  {{ field.name && field.name.split("?")[0] }}
                </div> -->
                {{ value?.title || value?.surveyQuestion }}
              </td>
              <td class="border px-4 py-2">
                {{ value.value || value.value == 0 ? value.value : "N/A" }}
              </td>
              <td class="border px-4 py-2">
                <div>Good > 50%</div>
                <div>Advanced > 60%</div>
                <div>Best in class > 70%</div>
              </td>
              <td class="border px-4 py-2">
                <div
                  v-for="field in value.fieldsUsedWithNames"
                  :key="field.key"
                >
                  {{ field.name && field.name.split("?")[0] }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="cross_Icon" @click="closeModal">
        <img src="@/assets/images/cros-icon.svg" alt="cross_Icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsightsModal",
  props: {
    data: {
      type: Object,
      required: true,
    },
    certificationScoreData: {
      type: Object,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      values: ["No", 0, null, undefined],
      arrayData: [],
    };
  },
  mounted() {
    this.arrayData = Object.values(this.data).flatMap((indicator) =>
      Object.values(indicator)
        .flatMap((value) => value)
        .filter(
          (value) =>
            value.fieldsUsedWithNames && value.fieldsUsedWithNames.length > 0
        )
        .filter((value) =>
          value.fieldsUsedWithNames.every((field) => field.name !== null)
        )
        .filter((value) => this.values.includes(value.value))
    );
    console.log("Array Data", this.arrayData);
  },
};
</script>
<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip:hover .hover-text {
  visibility: visible;
}
.tooltip .hover-text {
  visibility: hidden;
  position: absolute;
}
.tooltip .hover-text::after {
  right: 95%;
}
.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}
</style>
