<template>
  <div class="static assementPageStyle">
    <div>
      <div class="container">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <h1 class="text-6xl tracking-tighter font-medium print:text-7xl">
              Technical Service Provider
            </h1>
          </div>
          <div>
            <div class="Poweredby_logoImg">
              <span class="powerd-logo">Powered by:</span>
              <img src="@/assets/images/2X.png" alt="background-image" style="width: 80px;"/>
              <img src="@/assets/images/logo-navy.png" alt="background-image" />
            </div>
          </div>
        </div>
        <div class="gender_smart_button">
          <div class="inner_section_gender_smart_button">
            <router-link to="/2X-Ecosystem/submitTSP">
              <button class="text-white text-sm font-medium" style="width: max-content">
                Join our TA service directory!
              </button>
            </router-link>
          </div>
        </div>
        <!-- ************************** -->
        <div class="AssementPageWrepper flex" v-if="
          endofcreated && investorsData.data
        ">
          <div class="AssementPageWrepper_Left">
            <div class="AssementPageWrepper_Box" v-if="investorsData">
              <div class="flex justify-between pb-6">
                <h3 class="text-2xl font-bold print:text-2xl Deals_Heading">
                  Potential technical service providers that meet your needs
                </h3>
                <p class="text-lg font-light" style="color: black;font-weight: 500;">
                  {{ investorsData.totalCount ? investorsData.totalCount : 0 }} results
                </p>
              </div>

              <!-- ********************* -->
              <div class="w-full h-full" v-if="investorsData.data && investorsData.data.length > 0">
                <div class="pb-10" style="height: 90%">
                  <table class="divide-y divide-gray-200 bg-white w-full">
                    <thead v-if="
                      investorsData &&
                      investorsData.data &&
                      investorsData.data.length > 0
                    ">
                      <tr>
                        <th class="px-3 py-2">Service Provider</th>
                        <!-- <th class="px-3 py-2">
                          <div class="ToopTip">
                            <div class="tooltip TooltipImgText">
                              <span>2X Status of Organizations Funded</span>
                              <span class="tooltiptext">
                                This indicates if this investor is willing to
                                fund only organizations that already meet 2X
                                eligibility, or also those with intentions to
                                become 2X eligible or not.
                              </span>
                              <img src="../../assets/images/helpblack.png" alt="background-image" />
                            </div>
                          </div>
                        </th> -->
                        <th class="px-3 py-2">Technical Services Provided</th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200" v-if="
                      investorsData &&
                      investorsData.data &&
                      investorsData.data.length > 0
                    ">
                      <tr v-for="(investor, index) in investorsData.data" :key="index">
                        <td class="px-3 py-8">
                          <div class="flex">
                            <div class="Investee-img">
                              <img v-if="
                                investor.logo &&
                                investor.logo[0].url
                              " :src="investor.logo[0].url" alt="demo img" />
                              <img v-else src="@/assets/images/assessment-logo.png" alt="like" />
                            </div>
                            <div class="Investee-detail">
                              <h6 @click="openTwoXCMembership(investor)" style="cursor: pointer">
                                {{ investor.member2xc }}
                              </h6>
                              <p>
                                Provides
                                {{
                                investor.investeeTypeRoutesToMarket
                                | removeCommas
                                }}
                                in the following areas:
                              </p>
                              <!-- <p>{{ investor.sector }}</p> -->
                              <div class="investee-text">
                                <div class="flex investee-tag">
                                  <img src="@/assets/images/folder-icon.png" alt="folder" />
                                  <span class="blue-bg" v-for="(theme, themeIndex) in investor.themesOfInterest"
                                    :key="themeIndex">{{ theme }}</span>
                                </div>
                                <div class="flex investee-tag">
                                  <img src="@/assets/images/map-pin.png" alt="map-pin" class="mappin-img" />
                                  <span class="map-location">{{
                                  investor.geographiesOfInterest
                                  | removeCommas
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <!-- <td class="px-3 py-8">
                          <div class="flex Eligible-text" v-if="investor.twoXEligible == 'Yes'">
                            <img src="@/assets/images/check_circle.png" alt="like" />

                            <span>2X Eligible</span>
                          </div>
                          <div class="flex Eligible-text" v-else>
                            <img src="@/assets/images/3rdPartyVerified.svg" alt="like" />
                            <span>Not 2X Eligible</span>
                          </div>
                          <div class="flex Eligible-text" v-if="investor.twoXIntentions == 'Yes'">
                            <img src="@/assets/images/check_circle.png" alt="like" /><span>2X Intentions</span>
                          </div>
                          <div class="flex Eligible-text" v-else>
                            <img src="@/assets/images/3rdPartyVerified.svg" alt="like" /><span>No 2X Intentions</span>
                          </div>
                          <div class="flex Eligible-text" v-if="investor.twoXValidator == 'Yes'">
                            <img src="@/assets/images/check_circle.png" alt="like" /><span>2X Verified</span>
                          </div>
                          <div class="flex Eligible-text" v-else>
                            <img src="@/assets/images/3rdPartyVerified.svg" alt="like" /><span>Not 2X Verified</span>
                          </div>
                        </td> -->

                        <td class="px-3 py-8">
                          <div class="funding-block">
                            <div class="funding-text" :class="colorClass[index]" v-for="(ticket, index) in investor
                            .taServicesOffered" :key="index" v-show="index < 3">
                              {{ ticket }}
                            </div>
                            <div v-if="investor.taServicesOffered.length > 3" class="funding-text">
                              <img src="@/assets/images/roi/dot.svg" style="padding: 0 10px" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <!-- -------------------- -->
                    </tbody>
                  </table>
                  <Button class="
                      loadmore-btn
                      flex
                      m-auto
                      justify-center
                      border border-gray-300
                      px-4
                      py-2
                      bg-white
                      text-base
                      leading-6
                      font-bold
                      text-gray-800
                      hover:text-gray-500
                      focus:outline-none
                      focus:border-blue-300
                      focus:shadow-outline-blue
                      transition
                      ease-in-out
                      duration-150
                      sm:text-sm sm:leading-5
                    " @click="loadMoreInvestors()" v-if="
                      investorsData.totalCount !== investorsData.data.length &&
                      investorsData &&
                      investorsData.data &&
                      investorsData.data.length > 0
                    ">Load more</Button>
                </div>
              </div>
              <!-- ********************* -->
              <div class="AssementPageWrepper_body_no_data_found" v-else>
                <span>{{ NoDataFoundMsg }}</span>
              </div>
            </div>
          </div>
          <div class="AssementPageWrepper_Right h-full">
            <div class="AssementPageWrepper_Box">
              <div class="
                  text-sm
                  leading-5
                  text-gray-900
                  w-full
                  relative
                  flex flex-row
                  items-center
                  mb-8
                ">
                <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <!-- <title>Artboard 329</title> -->
                  <circle cx="11.059" cy="11.059" r="7.059" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <line x1="20" y1="20" x2="16.05" y2="16.05" fill="none" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <rect width="24" height="24" fill="none" />
                </svg>
                <input type="text" class="
                    border-2 border-gray-200
                    px-3
                    py-2
                    text-sm
                    w-full
                    pl-10
                    font-light
                    focus:outline-none focus:shadow-outline
                  " v-model="query" placeholder="Search" />
              </div>
              <!-- <div class="filter flex flex-row items-center gap-3">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14 6H12V0H14V2H18V4H14V6ZM0 4V2H10V4H0ZM0 16V14H6V16H0ZM10 18V16H18V14H10V12H8V18H10ZM4 8V6H6V12H4V10H0V8H4ZM18 10V8H8V10H18Z"
                    fill="#1B1B1B" />
                </svg>
                <h5 class="text-lg font-bold leading-5">Filter by</h5>
              </div> -->
              <filter-section :query="query" :filters="filtersData" @updateResults="updateResults"
                @resetSearchBox="resetSearchBox"></filter-section>
            </div>
          </div>
          <!-- End Modal -->
        </div>
        <!-- ************************** -->
      </div>
      <transition name="fadeIn" mode="out-in">
        <!-- v-if="user.deals2xPrivacyPolicy && user.deals2xTermsOfUse" -->

        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
import pageLoader from "@/components/page-loader.vue";
import filterSection from "@/components/technicalServicesListFilterSection.vue";
export default {
  name: "twoXEcosystemTechnicalServicesList",
  data: function () {
    return {
      user: [],
      active: false,
      endofcreated: false,
      investorsData: [],
      page: 1,
      limit: 10,
      query: "",
      filterData: [],
      selectedFilters: [],
      initialFilterData: [],
      colorClass: [
        "greenbtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "greybtn",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greenbtn",
        "greybtn",
        "babypink",
        "lightblue",
        "yellolight",
        "powerBlue",
        "lightGreen",
        "purple",
        "lomengreen",
        "skyblue",
        "pinklight",
        "glassgreen",
        "pink",
        "greybtn",
      ],
      getAPIResponse: false,
    };
  },
  components: {
    pageLoader,
    filterSection
  },
  filters: {
    removeCommas(item) {
      var data = Object.values(item).join(", ");
      return data;
    }
  },
  methods: {
    resetSearchBox() {
      this.query = ""
    },
    pageReload() {
      window.location.reload();
    },

    openTwoXCMembership(investor) {
      sessionStorage.setItem('fromTechnicalService', true)
      let routeData = this.$router.resolve({
        name: "TwoXCMembership",
        params: {
          investorOrg: investor.accessMy2XTeams[0],
        },
        // query: {
        //   fromTechnicalService: true
        // }
      });
      window.open(routeData.href, "_blank");
    },

    getInvestorsData() {
      let body = {};
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        this.selectedFilters.forEach((data) => {
          Object.assign(body, data);
        });
      } else {
        body;
      }
      var offSet = this.page;
      var limit = this.limit;
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/assesment2x/technical-provider", body, {
            params: {
              page: offSet,
              limit: limit
            },
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });

    },

    async loadMoreInvestors() {
      let loadMoreInvestorsData = [];
      this.page = this.page++;
      this.limit = this.limit + 10;
      loadMoreInvestorsData = await this.getInvestorsData();
      if (loadMoreInvestorsData && this.investorsData && this.investorsData.data) {
        this.investorsData.data = [...loadMoreInvestorsData.data];
      }
      // this.getEligibleCriteriaValues();
    },


    // getEligibleCriteriaValues() {
    //   this.investorsData.data.map((data) => {
    //     if (data.twoXInvesteeEligibilityInterest) {
    //       data.twoXInvesteeEligibilityInterest.forEach((item) => {
    //         if (item.includes("eligible")) {
    //           data["twoXEligible"] = "Yes";
    //         }
    //         if (item.includes("validated")) {
    //           data["twoXValidator"] = "Yes";
    //         }
    //         if (item.includes("Intentions")) {
    //           data["twoXIntentions"] = "Yes";
    //         }
    //       });
    //     }
    //   });
    // },

    getFiltersData() {
      axios
        .get(env.apiUrl + "/assesment2x/filter-get-matched-invetstore-deals", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.filterData = res.data.data;
          this.initialFilterData = res.data.data;
        });
    },

    async updateResults(arr) {
      let tempFilterData = [];
      tempFilterData = Object.entries(this.initialFilterData);

      let tempFilterData2 = [];
      this.selectedFilters = [];
      tempFilterData.forEach((data) => {
        var selectedItem = [];
        data[1].forEach((item) => {
          arr.forEach((a) => {
            const lastIndex = a.lastIndexOf("&");
            const beforeText = a.slice(0, lastIndex);
            const afterText = a.slice(lastIndex + 1);
            if (
              item.toLowerCase() == beforeText.toLowerCase() &&
              item != "Show All"
            ) {
              let regInd = afterText;
              if (regInd && data[0] == regInd) {
                selectedItem.push(item);
              }
            }
          });
        });
        let objKey = "";
        if (data[0] == "Geographies of Interest") {
          objKey = "geographiesOfInterest";
        }
        if (data[0] == "Sectors of Interest") {
          objKey = "sectorsOfInterest";
        }
        if (data[0] == "Technical Services Provided") {
          objKey = "taServicesOffered";
        }
        if (data[0] == "what_kind_of_support_do_you_offer?") {
          objKey = "whatKindOfSupportDoYouOffer";
        }

        var obj = { [objKey]: selectedItem };
        tempFilterData2.push(obj);
      });

      tempFilterData2.forEach((data) => {
        if (Object.entries(data)[0][1].length) {
          this.selectedFilters.push(data);
        }
      });
      this.page = 1;
      this.limit = 10;
      const [investorsData] = await Promise.all([this.getInvestorsData()]);
      this.investorsData = investorsData;
      //this.getEligibleCriteriaValues();
    },
  },

  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    const [investorsData] = await Promise.all([this.getInvestorsData()]);
    this.investorsData = investorsData;
    if (this.investorsData.data.length == 0) {
      this.getAPIResponse = true
    } else {
      this.getAPIResponse = false;
    }

    //this.getEligibleCriteriaValues();

    await this.getFiltersData();

    this.$store.commit("setBreadcrumbs", [
      {
        name: "Technical Service Provider",
        url: "/2X-Ecosystem/technical-service-list",
      },
    ]);
    this.endofcreated = true;
  },
  computed: {
    filtersData() {
      var data = [];
      let tempFilterData = [];
      Object.entries(this.filterData).forEach((da) => {
        var selectedItem = [];
        da[1].forEach((item) => {
          if (item.toLowerCase().includes(this.query.toLowerCase())) {
            selectedItem.push(item);
          }
        });
        var obj = { [da[0]]: selectedItem };
        tempFilterData.push(obj);
        let tempData = [];
        tempFilterData.forEach((data) => {
          if (Object.entries(data)[0][1].length) {
            tempData.push(data);
          }
        });
        data = tempData;
      });
      return data;
    },

    NoDataFoundMsg() {
      let msg = ''
      if ((this.selectedFilters.length > 0 || this.query) && !this.getAPIResponse) {
        msg = "No technical service providers match your search criteria. Reset or clear your filters to find more."
      } else {
        if (this.getAPIResponse) {
          msg = "No technical service provider found"
        }
      }
      return msg
    }
  }
};
</script>

<style lang="scss" scoped>
.greenbtn {
  background: #71ddc3;
  border-radius: 10px;
  padding: 2px 10px 2px 8px;
}

.lightblue {
  background: #cfdfff;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.powerBlue {
  background: #d0f0fd;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.lightGreen {
  background: #c2f4e8;
  border-radius: 10px;
  padding: 2px 10px;
}

.lomengreen {
  background: #d1f7c3;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.skyblue {
  background: #9bc6ff;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.yellolight {
  background: #ffe9b6;
  border-radius: 10px;
  padding: 2px 10px 2px 7px;
}

.pinklight {
  background: #fee2d5;
  border-radius: 10px;
  padding: 2px 10px;
}

.glassgreen {
  background: #d1f7c3;
  border-radius: 10px;
  padding: 2px 10px 2px 7px;
}

.pink {
  background-color: #ffdce4;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.greybtn {
  padding: 2px 10px 2px 8px;
  background: rgba(208, 208, 208, 0.5);
  border-radius: 10px;
}

.purple {
  background-color: #cdb0ff;
  border-radius: 10px;
  padding: 2px 10px 2px 8px;
}

.babypink {
  background-color: #ffdaf6;
  border-radius: 10px;
  padding: 2px 10px 2px 9px;
}

.title-tooltip .title-tooltip-text,
.send-tooltip .send-tooltip-text {
  visibility: hidden;
  left: 20%;
  right: 20%;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.title-tooltip:hover .title-tooltip-text,
.send-tooltip:hover .send-tooltip-text {
  visibility: visible;
}

.title-tooltip {
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .container {
    margin: 0 auto;
    
    .gender_smart_button {
      .inner_section_gender_smart_button {
        text-align: left;
        margin-top: 1rem;
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;
    justify-content: space-between;

    .AssementPageWrepper_Left {
      flex: 0 0 74.5%;
    }

    .AssementPageWrepper_Box {
      .ToopTip {
        margin: auto;
        display: flex;
        align-items: center;

        .TooltipImgText {
          display: flex;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
            margin-left: 5px;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }

          .tooltiptext {
            max-width: 400px;
            top: 20px;
            left: 50px;
          }
        }
      }

      margin-bottom: 18px;
      padding: 35px;
      background: #ffffff;
      border: 1px solid #d0d0d0;
      border-radius: 10px;

      .AssementPageWrepper_body_no_data_found {
        text-align: center;
        padding: 10px;
      }
    }

    .AssementPageWrepper_Right {
      flex: 0 0 24%;

      .AssementPageWrepper_Box {
        padding: 30px;
      }
    }

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    .send-tooltip {
      width: 32px;
      height: 32px;
      /* margin: auto !important;
    margin-right: 15px !important; */
      margin-top: 10px;
      padding: 0px;
      cursor: pointer;
    }

    .send-tooltip .send-tooltip-text {
      visibility: hidden;
      width: 180px;
      /* right: 90px; */
      margin: 5px;
      background-color: #a0aec0;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
    }

    .send-tooltip:hover .send-tooltip-text {
      visibility: visible;
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }

    table {
      thead {
        th {
          font-size: 13px;
          line-height: 16px;
          color: #9d9d9c;
          font-weight: 700;
        }
      }
    }

    .Deals_Heading {
      margin-bottom: 30px;
    }

    .funding-block {
      margin-bottom: 10px;
      color: #1b1b1b;
      font-size: 13px;
      line-height: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .funding-head {
      margin-bottom: 5px;
      font-weight: bold;
    }

    .funding-text {
      font-weight: normal;
      margin: 10px;
      width: fit-content;
    }

    .Eligible-text {
      align-items: center;
      margin-bottom: 12px;

      span {
        margin-left: 5px;
        color: #1b1b1b;
        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .match-text {
      color: #1b1b1b;
      font-size: 25px;
      line-height: 30px;
      font-weight: bold;
    }

    .action-btn {
      justify-content: center;

      button {
        padding: 9px;
        height: 36px;
        width: 36px;
        border-radius: 50%;
      }

      .like-btn {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .dislike-btn {
        margin-top: 5px;
        margin-bottom: 0px;
      }

      .like-btn-bg {
        background: rgba(254, 80, 0, 0.2);
      }
    }

    .valign-top {
      vertical-align: top;
    }

    .investee-text {
      margin-top: 10px;
      max-width: 290px;
    }

    .Investee-img {
      flex: 0 0 75px;
      border-radius: 50px;
      border: 1px solid rgba(208, 208, 208, 0.5);
      width: 75px;
      height: 75px;

      img {
        width: 75px;
        height: 75px;
        object-fit: contain;
        border-radius: 50px;
      }
    }

    .Investee-detail {
      margin-left: 15px;

      h6 {
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 22px;
        color: #1b1b1b;
        font-weight: 700;
        width: 100%;
        max-width: 280px;
      }

      p {
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        font-weight: 400;
        width: 100%;
        max-width: 280px;
      }
    }

    .investee-tag {
      align-items: flex-start;
      flex-wrap: wrap;

      img {
        margin-right: 5px;
      }

      span {
        margin: 0 1.5px 5px;
        padding: 2px 10px;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        font-weight: 400;
        border-radius: 10px;

        &.map-location {
          padding-left: 0px;
          max-width: 250px;
        }
      }

      .blue-bg {
        background: rgba(100, 210, 255, 0.2);
      }

      .green-bg {
        background: rgba(0, 199, 190, 0.2);
      }

      .green-light-bg {
        background: rgba(48, 209, 88, 0.2);
      }

      .yellow-bg {
        background: rgba(255, 214, 10, 0.2);
      }

      .red-bg {
        background: rgba(255, 45, 85, 0.2);
      }

      .orange-bg {
        background: rgba(255, 159, 10, 0.2);
      }

      .brown-bg {
        background: rgba(162, 132, 94, 0.2);
      }
    }

    .loadmore-btn {
      border-radius: 20px;
    }

    .AssementPageWrepper_head {
      padding: 0 0 15px 0;
      border-bottom: 1px solid #d0d0d0;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      color: #1b1b1b;

      img {
        margin-right: 10px;
      }
    }

    .Assement-row-block {
      margin-top: 30px;
      align-items: center;

      h6 {
        margin-bottom: 6px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: #1b1b1b;
      }

      p {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #1b1b1b;

        span {
          font-weight: 700;
        }
      }

      .Assement-row-img {
        margin-right: 10px;
        flex: 0 0 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(208, 208, 208, 0.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .assementPageStyle {
    .AssementPageWrepper {
      padding: 0 20px;
    }

    .PoweredbyTitle_logo {
      padding-left: 20px;
      padding-right: 20px;
    }

    .AssementPageWrepper_Box {
      padding: 20px;
    }
  }
}
</style>
