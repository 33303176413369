<script>
import axios from "axios";

import env from "@/../env.js";
import Cookies from "@/helpers/cookies";

export default {
  name: "index",
  data: function() {
    return {
      redirectURL: "",
      teamId: "",
      userId: "",
      type: "", // 'User' | 'Verifier',
      purchaseHistory: [],
      retryMilliseconds: 6000,
      intervalId: null
    };
  },
  async mounted() {
    this.redirectURL = this.$route.query.redirectURL;
    this.teamId = this.$route.query.teamId;
    this.type = this.$route.query.type;

    if (this.redirectURL && this.teamId && this.type) {
      const self = this;
      this.intervalId = setInterval(async function() {
        await self.getPurchaseHistory();
      }, this.retryMilliseconds);
    }
    // TODO: something is wrong with the queries string params
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    async getPurchaseHistory() {
      try {
        const response = await axios.get(
          `${env.apiUrl}/subscriptions/purchase-history?type=${this.type}&teamId=${this.teamId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`
            }
          }
        );
        this.purchaseHistory = response.data;
      } catch (error) {
        console.error(error);
      }
    }
  },
  watch: {
    purchaseHistory(newVal) {
      if (newVal.length > 0) {
        const decodedRedirectURL = decodeURIComponent(this.redirectURL);
        this.$router.push({ path: decodedRedirectURL ?? "" });
      }
    }
  }
};
</script>

<template>
  <div class="static ecosystem-landing">
    <div class="container mx-auto -mt-20 px-4 lg:px-0">
      <page-loader></page-loader>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>