import BaseAPI from "@/api/index";

class TwoXThresholdAPI {
  resourceBasePath = "/thresholds2x";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  getRegions() {
    return this.axiosInstance.get(`${this.resourceBasePath}/region`);
  }

  getCSV() {
    return this.axiosInstance.get(`${this.resourceBasePath}/download-csv`, {
      responseType: 'blob'
    });
  }
}

export default TwoXThresholdAPI;
