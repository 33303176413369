import { SUBSCRIPTION_TYPE } from "./subscriptionTypes";

export function canAccess(path, subscriptionType, featureFlags = []) {
  const permits = getPermits(path);
  // change this to false when all routes are implemented
  if (!permits) {
    return true;
  }

  return (
    permits?.subscriptions?.includes(subscriptionType) ||
    featureFlags.some((flag) => permits?.featureFlags?.includes(flag))
  );
}

export function canAction(path, action, subscriptionType) {
  const actions = getActions(path);
  if (!actions) {
    return true;
  }
  return actions[action].includes(subscriptionType);
}

export function getActions(path) {
  return actions[path];
}

export function getPermits(path) {
  return permits[path];
}

// routes that are not in the permits object are accessible to all subscription types
const permits = {
  "/gbv/materiality": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv/risk-models": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv/heat-map": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv/methodology": {
    subscriptions: [
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/supergirl": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/partner-directory": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/gbv-risk/EQ/INFR_GENERL/take-action": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/roi/roiPortfolio": {
    subscriptions: [SUBSCRIPTION_TYPE.PREMIUM, SUBSCRIPTION_TYPE.ENTERPRISE],
  },
  "/2X-Ecosystem/My-2X-Assessments/contextual-analysis": {
    subscriptions: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/GenderSmartTools": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitGenderSmartTools": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitDirectory": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "2X-Ecosystem/submitTSP": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/submitGirlSource": {
    subscriptions: [
      SUBSCRIPTION_TYPE.OPEN,
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
  "/imm/dashboard": {
    featureFlags: ["imm"],
  },
  "/imm/create-investor-profile": {
    featureFlags: ["imm"],
  },
  "/imm/create-company-profile": {
    featureFlags: ["imm"],
  },
  "/imm/setup-preferences": {
    featureFlags: ["imm"],
  },
  "/imm/aggregate-portfolio/finance": {
    featureFlags: ["imm"],
  },
  "/imm/dashboard/finance": {
    featureFlags: ["imm"],
  },
  "/imm/:companyName/finance": {
    featureFlags: ["imm"],
  },
  "/imm/impact-survey": {
    featureFlags: ["imm"],
  },
  "/imm/climate-survey": {
    featureFlags: ["imm"],
  },
  "/imm/esg-survey": {
    featureFlags: ["imm"],
  },
  "/imm/finance-survey": {
    featureFlags: ["imm"],
  },
  "/imm/aggregate-portfolio/investees/impact": {
    featureFlags: ["imm"],
  },
  "/imm/investor-page/company-profile/climate": {
    featureFlags: ["imm"],
  },
  "/imm/investor-page/company-profile/climate/investee": {
    featureFlags: ["imm"],
  },
  "/imm/Investor-page/company-profile/esg": {
    featureFlags: ["imm"],
  },
  "/imm/deal-pipeline": {
    featureFlags: ["imm"],
  },
};

const actions = {
  "/roi/roiAssessment": {
    downloadPDF: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
    downloadExcel: [
      SUBSCRIPTION_TYPE.BASIC,
      SUBSCRIPTION_TYPE.PRO,
      SUBSCRIPTION_TYPE.PREMIUM,
      SUBSCRIPTION_TYPE.ENTERPRISE,
    ],
  },
};
