<template>
  <div>
    <div
      class="text-black-90 font-arial tracking-normal text-[20px] font-bold leading-[28px] flex w-full"
    >
      <div class="flex flex-1 w-full items-center justify-between mr-5">
        <h4 class="text-xl pb-6">
          {{
            indicator.title == "General"
              ? "General Information"
              : indicator.title
          }}
        </h4>
      </div>
      <router-link
        v-if="indicator.title.toLowerCase().trim() != 'general' && indicator.title.toLowerCase().trim() != '2x score overall'"
        class="pr-5 ml-auto"
        :to="
          !query
            ? `${subDimensionPath}?q=${encodeURIComponent(
                indicator.title
              )}&fromVerifier=true`
            : `${subDimensionPath}?q=${encodeURIComponent(
                indicator.title
              )}&teamId=${query}&entity=${entityChosen}&fromVerifier=true`
        "
      >
        <img
          class="cursor-pointer"
          src="@/assets/images/2x_certify/Button.svg"
        />
      </router-link>
    </div>
    <div class="flex flex-col w-full">
      <div class="flex flex-wrap w-full grid-cols-2">
        <div v-if="indicator.values && indicator.values.length > 0">
          <div v-for="(group, index) in groups" :key="index">
            <div v-if="group.type == 'Donut chart'">
              <DonutIndicatorView
                :values="group.values"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-else-if="group.type == 'Text' || group.type == 'Single select'">
              <OpenTextView
                :values="group.values"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-else-if="group.type == 'CustomTable'">
              <CustomTableView
                :values="group.values"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-else-if="group.type == 'List'">
              <ListIndicatorView
                :values="group.values"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-else-if="group.type == 'Clustered'">
              <div v-for="(value, k) in group.values" :key="k">
                <ClusteredChart
                  :info="value"
                  :certificationScoreData="certificationScoreData"
                />
              </div>
            </div>
            <div
              v-if="group.type === 'Number' || group.type === 'Bar graph'"
              class="w-full flex justify-between"
            >
              <div v-for="(value, k) in group.values" :key="k">
                <dynamic-render
                  :type="value.chartType"
                  :info="value"
                  :certificationScoreData="certificationScoreData"
                />
                <div class="flex gap-2 items-start">
                  <div
                    class="flex items-center gap-3 justify-end"
                    v-if="
                      $route.path.includes('/admin') &&
                      value?.surveyQType != 'Calculated'
                    "
                  >
                    <img
                      src="@/assets/images/edit-dark.svg"
                      alt="edit-icon"
                      class="h-6 w-6 cursor-pointer"
                      @click="openEditModal(value)"
                    />
                    <verification-dropdown :value="value" />
                    <MLVerifierValue
                      :value="value"
                      :teamId="$route.query?.teamId"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-for="(value, index) in indicator.values" :key="index">
            <div v-if="value.chartType == 'Donut chart'">
              <DonutIndicatorView
                :value="value"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-if="value.chartType == 'Text'">
              <OpenTextView
                :value="value"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-if="value.chartType == 'CustomTable'">
              <CustomTableView
                :value="value"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-if="value.chartType == 'List'">
              <ListIndicatorView
                :value="value"
                @openEditModal="openEditModal"
                @setVerifiedStatus="setVerifiedStatus"
              />
            </div>
            <div v-if="value.chartType == 'Clustered'">
              <ClusteredChart
                :info="value"
                :certificationScoreData="certificationScoreData"
              />
            </div>
            <div
              v-if="
                value.chartType === 'Number' || value.chartType === 'Bar graph'
              "
              class="w-full flex justify-between"
            >
              <dynamic-render
                :type="value.chartType"
                :info="value"
                :certificationScoreData="certificationScoreData"
              />
              <div class="flex gap-2 items-start">
                <div
                  class="flex items-center gap-3 justify-end"
                  v-if="
                    $route.path.includes('/admin') &&
                    value?.surveyQType != 'Calculated'
                  "
                >
                  <img
                    src="@/assets/images/edit-dark.svg"
                    alt="edit-icon"
                    class="h-6 w-6 cursor-pointer"
                    @click="openEditModal(value)"
                  />
                  <verification-dropdown :value="value" />
                  <MLVerifierValue
                    :value="value"
                    :teamId="$route.query?.teamId"
                  />
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- others -->
      </div>
    </div>
  </div>
</template>
<script>
import dynamicRender from "./dynamic-render.vue";
import DonutIndicatorView from "./DonutIndicatorView.vue";
import OpenTextView from "./OpenTextView.vue";
import CustomTableView from "./CustomTableView.vue";
import ListIndicatorView from "./ListIndicatorView.vue";
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import ClusteredChart from "./ClusteredChart.vue";

export default {
  name: "IndicatorView",
  data: function () {
    return {
      groups: [],
    };
  },
  props: {
    indicator: Object,
    query: String,
    entityChosen: String,
    certificationScoreData: Object,
    subDimensionPath: String,
    MLverifiedData: Array,
  },
  components: {
    "dynamic-render": dynamicRender,
    DonutIndicatorView,
    OpenTextView,
    CustomTableView,
    ListIndicatorView,
    MLVerifierValue,
    VerificationDropdown,
    ClusteredChart,
  },
  methods: {
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
    setVerifiedStatus(value, status) {
      this.$emit("setVerifiedStatus", value, status);
    },
  },
  async mounted() {
    let clone = JSON.parse(JSON.stringify(this.indicator));
    if (clone.values.length > 0) {
      let lastvalue = clone.values.shift();
      let groups = [{ type: lastvalue.chartType, values: [lastvalue] }];
      for (let index in clone.values) {
        let value = clone.values[index];
        let lastType = groups[groups.length - 1].type;
        if (value.chartType != lastType) {
          groups.push({ type: value.chartType, values: [value] });
        } else {
          groups[groups.length - 1].values.push(value);
        }
      }
      this.groups = groups;
    }
  },
};
</script>
