<template>
  <div class="static">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div id="loaded" key="content" v-if="endofcreated">
        <div class="flex flex-col items-center justify-center w-full mt-20">
          <div class="container">
            <div class="flex flex-row justify-center items-center mt-3">
              <div class="flex flex-col item-center justify-center">
                <div
                  class="flex flex-row items-center justify-center print:flex-col"
                >
                  <img
                    class="mr-5 h-16 print:h-auto print:w-64"
                    :src="country.flagUrl"
                  />
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl" v-if="current_step == 3">
                    {{ activeHeadName }}
                  </h1>
                  <h1 class="text-6xl tracking-tighter font-medium text-center print:text-7xl" v-else>
                    GBV Risk {{ activeHeadName }}
                  </h1>
                </div>
                <p
                  class="text-gray-600 print:text-2xl print:mt-10 font-medium text-center"
                  >
                  Region: {{ country.region == 'Eastern Europe & Central Asia' ? 'Europe & Central Asia' :country.region }} | Income Group:
                  {{ toTitle(country.income || "") }}
                  <span v-if="sector && sector.id"
                    >| Sector: {{ sector.name }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container mx-auto">
          <div class="flex flex-col mx-auto w-11/12 mt-12 mb-20">
            <a-steps progress-dot :current="this.current_step" size="small" @change="progressClick">
              <a-step title="Home" />
              <!-- <a-step title="Materiality" />
              <a-step title="Risk models" /> -->
              <a-step title="Risk score"/>
              <!-- <a-step title="Heat map" /> -->
              <a-step title="Indicators" />
              <a-step title="Take action" />
              <!-- <a-step title="Methodology" /> -->
            </a-steps>
          </div>
        </div>
        
        <!-- RISK SCORE -->
        <transition name="fadeIn">
        <div v-if="current_step == 1" class="flex flex-col items-center justify-center w-full">
          <section
            id="GBVIndex"
            class="w-full container mx-auto px-4 lg:px-0"
            style="max-width: 960px"
          >
            
            <h1
              class="print:text-6xl tracking-tighter font-medium flex flex-row items-center justify-center mb-3"
              style="font-size: 80px;"
              
            >
              <div class="flex items-center justify-center p-1 h-20 w-20 mr-3">
                <img v-if="risk_level == 'Extreme Risk'" class="h-20" src="../../assets/images/GBV-red.png" />
                <img v-if="risk_level == 'Severe Risk'" class="h-20" src="../../assets/images/GBV-orange.png" />
                <img v-if="risk_level == 'Significant Risk'" class="h-20" src="../../assets/images/GBV-light-orange.png" />
                <img v-if="risk_level == 'Ubiquitous Risk'" class="h-20" src="../../assets/images/GBV-yellow.png" />
              </div>

              <div v-if="risk_level == 'Extreme Risk'" style="color:#ED0C04;" class="risk-score">Extreme Risk</div>
              <div v-if="risk_level == 'Severe Risk'" style="color:#FE5000;" class="">Severe Risk</div>
              <div v-if="risk_level == 'Significant Risk'" style="color:#EDA804;" class="risk-score">Significant Risk</div>
              <div v-if="risk_level == 'Ubiquitous Risk'" style="color:#FAE053;" class="risk-score">Ubiquitous Risk</div>

            </h1>
              
            <p class="text-gray-600 gap-5 text-center font-medium text-lg print:leading-relaxed print:text-sm leading-5 custom-mb-3">
              <span v-if="risk_level == 'Extreme Risk'" style="color:#ED0C04;font-size: 30px;" class="risk-score">{{Math.round(gbvrisk.selected_country.value)}}</span>
              <span v-if="risk_level == 'Severe Risk'" style="color:#FE5000;font-size: 30px;" class="risk-score">{{Math.round(gbvrisk.selected_country.value)}}</span>
              <span v-if="risk_level == 'Significant Risk'" style="color:#EDA804;font-size: 30px;" class="risk-score">{{Math.round(gbvrisk.selected_country.value)}}</span>
              <span v-if="risk_level == 'Ubiquitous Risk'" style="color:#FAE053;font-size: 30px;" class="risk-score">{{Math.round(gbvrisk.selected_country.value)}}</span>
            </p>
            <div class="mb-10 text-gray-600" style="font-size: 15px;">
              <div class="flex items-center justify-center mx-auto mb-1">
                <img class="h-8 mr-2" src="../../assets/images/GBV-yellow.png" />
                <span>Ubiquitous Risk (&#60;20)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-light-orange.png" />
                <span>Significant Risk (20-39)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-orange.png" />
                <span>Severe Risk (40-59)</span>
                <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-red.png" />
                <span>Extreme Risk (60+)</span>
              </div>
              
            </div>
            <p class="text-gray-600 gap-5 text-center font-medium text-xs leading-5 mb-10" style="line-height: 1.2">
              <em>The GBV Risk Score is calculated specifically for {{country.name}}. 
                The <b>confidence score is {{(Math.round(gbvrisk.selected_country.confidence*100)/100).toFixed(2)}}</b> and <b>power score is {{(Math.round(gbvrisk.selected_country.power*100)/100).toFixed(2)}}</b> for this GBV Risk Score. <br>
                The Power Score tells us, out of all possible indicators, the data available for that country, while the Confidence Score tells us, out of all available indicators, the extent to which the data utilizes recent, standardized international sources (Read more about the <button @click='progressClick(7)' style='color: #201747'><em>Methodology here</em></button>).</em>
            </p>
            

          </section>
          
          <section
            id="ByDomain"
            class="w-full container mx-auto print:mt-10 block px-4 lg:px-0 border-t border-gray-300"
            style="max-width: 960px"
          >
            <div class="flex flex-col margintop3 print:mt-10">
              <h1 class="text-2xl leading-6 font-medium mb-2">
                GBV Risk Scores by
                <span class="triggerElement hoverTrigger text-2xl "
                  >Domain
                  <span
                    class="hoverMethodology absolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                  >
                    <methodology />
                  </span>
                </span>
              </h1>
              <div class="flex flex-col">
                <div class="text-gray-600 w-full mt-3">
                  <item
                    :text="secondIntro"
                    :country="country"
                    class="gap-10 text-justify print:leading-relaxed print:text-sm leading-7"
                  ></item>
                </div>
              </div>
              <div class="text-gray-600 w-full font-medium leading-7 mt-3">
                <p>
                  The following chart depicts how {{ country.humanName }} measures
                  up to other countries in the region, countries within the same
                  income group, and to all lower middle income countries globally.
                </p>
                <p class="text-sm mt-3" style="line-height: 1.5">
                  <em>Explore each
                  <span class="triggerElement hoverTrigger"
                    >domain's
                    <span
                      class="hoverMethodology absolutetop-0 top-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                    >
                      <methodology />
                    </span>
                  </span>
                  GBV Risk Score.
                  Hover on each point in the chart to expand and view the Power and Confidence Scores for each domain.
                  The Power Score tells us, out of all possible indicators, the data available for that country, while the Confidence Score tells us, out of all available indicators, the extent to which the data utilizes recent, standardized international sources (Read more about the <button @click='progressClick(7)' style='color: #201747'><em>Methodology here</em></button>).</em>
                </p>
              </div>
              <div class="block pb-i-avoid">
                <div class="flex flex-col items-center mt-16">
                  <h1 class="text-lg leading-6 font-semibold">
                    {{ theme.name }} Risk Scores by
                    <span class="triggerElement hoverTrigger"
                      >Domain
                      <span
                        class="hoverMethodology absolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                      >
                        <methodology />
                      </span>
                    </span>
                  </h1>
                  <p class="text-gray-500 text-sm font-medium mt-2">
                    Hover on the chart to get more information about the
                    <span class="triggerElement hoverTrigger"
                      >domains
                      <span
                        class="hoverMethodology abosolutetop-0 right-0 bg-white rounded-lg shadow-xl w-56 border text-sm border-gray-200"
                      >
                        <methodology />
                      </span>
                    </span>
                  </p>
                  <div class="mt-2 text-gray-500 font-medium text-sm">
                    <div class="flex items-center justify-center mx-auto mb-1">
                      <img class="h-8 mr-2" src="../../assets/images/GBV-yellow.png" />
                      <span>Ubiquitous Risk (&#60;20)</span>
                      <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-light-orange.png" />
                      <span>Significant Risk (20-39)</span>
                      <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-orange.png" />
                      <span>Severe Risk (40-59)</span>
                      <img class="h-8 mr-2 ml-4" src="../../assets/images/GBV-red.png" />
                      <span>Extreme Risk (60+)</span>
                    </div>
                  </div>
                  <br /><br />
                  <div
                    class="container mx-auto flex flex-row items-center justify-center"
                  >
                    <template>
                      <spiderchart
                        :styles="myStyles"
                        themeId="GBV Risk Score"
                        :iso="$route.params.iso2"
                        :country="country"
                        key="1"
                        id="spiderchart"
                      ></spiderchart>
                      
                    </template>
                  </div>
                  <div
                    class="w-full flex flex-row"
                    v-if="false && (theme.intersections || []).length > 2"
                  >
                    <div
                      class="flex flex-wrap w-8/12 mx-auto items-center"
                      style="justify-content: center;"
                    >
                      <div
                        v-for="(color, taab) in chartLabels"
                        v-bind:key="taab.name"
                        class="rounded-lg p-2 px-4 m-1 text-white flex flex-row"
                        :style="'color:' + color.font"
                      >
                        <div class="mr-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :style="'color:' + color.color" :fill="color.color" stroke="currentColor">
                            <line x1="0" y1="12" x2="24" y2="12" stroke-width="6"></line>
                          </svg>
                        </div>
                        <div>{{ taab }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        </transition>

        <!-- INDICATORS -->
        <transition name="fadeIn">
          <div v-show="current_step == 2" class="flex flex-col items-center justify-center w-full">
            <div
              id="SectionIndicators"
              class="w-full container mx-auto"
              style="max-width: 960px"
            >
              <div class="flex flex-col">
                <h1 class="text-2xl leading-6 font-medium mb-2">
                  Explore The Indicators
                </h1>
                <div
                  class="text-gray-600 w-full text-justify font-medium leading-7 mt-3"
                >
                  <p>
                    Scroll down to explore data related to the GBV Risk Score. Each
                    indicator includes country-specific data, accompanied by
                    regional and global averages for comparison. A short descriptive
                    narrative accompanies each statistic to help understand the "so
                    what." Most of these indicators power the GBV Risk Score
                    for this theme, although some supplementary descriptive
                    statistics may appear.
                  </p>
                </div>
              </div>
            </div>

            <!-- Domains Dropdown -->
            <div class="domainDropDownMenu">
              <select v-model="selectIndicator" @change="selectItem" class="border-2 border-gray-200 mb-4 mt-8 rounded-lg px-3 py-2 text-sm flex flex-col w-6/12 pr-5 print:pr-10">
                <option selected v-bind:key="'Explore GBV Risk Score Indicators'" :value="null">Explore GBV Risk Score Indicators</option>
                <option v-for="(indicator, index) in getUniqueIndicators" v-bind:key="indicator._id+index" :value="indicator.taab">{{indicator.taab}}</option>
              </select>
            </div>

            <section
              class="w-full container mx-auto mt-20 px-4 lg:px-20 py-10 bg-gray-100 border border-gray-200 rounded-lg shadow-2xl"
              v-if="selectIndicator"
            >
              <!-- FOR EACH INDICATOR -->
              <div v-for="(indicator, index) in modifiedIndex" v-bind:key="indicator._id">
                <div class="flex flex-row w-full mb-32 mt-12" :id="'indicator' + indicator.name.toLowerCase().trim().split(/\s+/).join('-')">
                  <div
                    class="flex flex-col w-6/12 pr-5 print:pr-10"
                  >
                    <p
                      class="tracking-tight text-lg font-medium mb-2"
                      :style="'color: ' + env.brandingColors.navy + ' !important;'"
                      :id="'indicator' + indicator.name"
                    >
                      {{ index + 1 }}. {{ indicator.name }}
                    </p>
                    <div>
                      <div
                        class="rounded-lg text-black flex flex-col text-md font-medium mt-4 gap-2"
                      >
                        <p class="flex flex-row items-center justify-start">
                          <svg
                            class="w-5 h-5  mx-2"
                            :style="'color: ' + indicatorColor(indicator.taab)"
                            version="1.1"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                            <g
                              stroke-linecap="round"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linejoin="round"
                            >
                              <path d="M19,12h-14"></path>
                              <path d="M14,17l5,-5"></path>
                              <path d="M14,7l5,5"></path>
                            </g>
                            <path fill="none" d="M0,0h24v24h-24Z"></path>
                          </svg>
                          <a
                            :href="
                              '/#/reports/' +
                                country.ISO2 +
                                '/' +
                                $route.params.sector +
                                '/domains/' +
                                indicator.taab
                            "
                            :style="'color: ' + indicatorColor(indicator.taab)"
                            >Domain: {{ indicator.taab }}</a
                          >
                        </p>
                        <p
                          v-if="indicator.meta && indicator.meta.scored || indicator.meta.scored === 'true'"
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Contributes to the GBV Risk Score</i>
                        </p>
                        <p
                          v-if="indicator.meta.scored === false || indicator.meta.scored === 'false'"
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Contextual Indicator (Non-Scored)</i>
                        </p>
                        <p
                          v-if="indicator.meta && indicator.meta.proxyUsed === 'No' || indicator.meta.proxyUsed === 'Missing'"
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Internationally Standardized Indicator</i>
                        </p>
                        <p
                          v-if="
                            indicator.meta && indicator.meta.proxyUsed === 'Alt'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Proxy Data Used (Non-Standard)</i>
                        </p>
                        <p
                          v-if="
                            indicator.meta && indicator.meta.proxyUsed === 'Old'
                          "
                          class="flex flex-row items-center justify-start"
                          :style="'color: ' + env.brandingColors.navy"
                        >
                          <i>Indicator with Outdated Data</i>
                        </p>
                      </div>
                    </div>
                    <p class="text-gray-600 mt-5 text-justify">
                      <!--<item :text="indicator.text" :country="country"></item>-->
                      <rounded-item
                        :text="indicator.text"
                        :country="country"
                      ></rounded-item>
                    </p>
                    <p class="text-gray-600 mt-5 text-justify">
                      <!--<item :text="indicator.chartText" :country="country"></item>-->
                      <rounded-item
                        :text="indicator.chartText"
                        :country="country"
                      ></rounded-item>
                    </p>
                  </div>
                  <div class="flex flex-col items-center w-6/12">
                    <div class="radarchart indicator-chart">
                      <doughnutChart
                        class="print:scale-75 transform radarchart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        v-if="indicator.chartType == 'doughnutChart'"
                      >
                      </doughnutChart>

                      <barChartVertical
                        class="print:scale-75 transform radarchart indicator-chart"
                        v-if="indicator.chartType == 'barChartVertical'"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                      >
                      </barChartVertical>

                      <radarChart
                        v-if="indicator.chartType == 'radarChart'"
                        class="print:scale-75 transform radarchart indicator-chart"
                        :index="index + 1"
                        :country="country"
                        :chartData="[...indicator.chartData]"
                        :chartLabels="[...indicator.chartLabels]"
                        :indicator="{...indicator}"
                      >
                      </radarChart>

                      <bubbleChart
                        v-if="indicator.chartType == 'bubbleChart'"
                        class="print:scale-75 transform radarchart indicator-chart"
                        :index="index + 1"
                        :country="country"
                        :chartData="[...indicator.chartData]"
                        :chartLabels="[...indicator.chartLabels]"
                        :indicator="{...indicator}"
                        
                      >
                      </bubbleChart>

                      <checkCrossChart
                        v-if="indicator.chartType == 'checkcross'"
                        class="print:scale-75 transform radarchart indicator-chart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        
                      >
                      </checkCrossChart>

                      <tierChart
                        v-if="indicator.chartType == 'tierChart'"
                        class="print:scale-75 transform radarchart indicator-chart"
                        :index="index + 1"
                        :country="country"
                        :chartData="indicator.chartData"
                        :chartLabels="indicator.chartLabels"
                        :indicator="indicator"
                        
                      >
                      </tierChart>

                      <label class="recentlyUpdatedLabel" v-if="indicator.meta && checkRecentlyUpdated(indicator.meta.createdTime)">
                          Recently updated!
                      </label>

                      <div class="histData w-full">
                        <historicalData
                          :indicator="indicator"
                          :country="country"
                        ></historicalData>
                        <p v-if="(indicator.unknownHist > 0) & (indicator.unknownHist == indicator.variables.length)" class="text-sm"><i>No historical data available for these indicators.</i></p>
                        <p v-if="(indicator.unknownHist > 0) & (indicator.unknownHist != indicator.variables.length)" class="text-sm mt-4"><i>No historical data available for the other indicators.</i></p>
                      </div>

                      <!-- <img src="@/assets/images/rwanda3.jpg" class="h-64" alt=""> -->
                    </div>
                  </div>
                </div>
              </div>
              
            </section>


          </div>
        </transition>

        <!-- TAKE ACTION -->
        <transition name="fadeIn">
          <div v-if="current_step == 3" class="flex flex-col items-center justify-center w-full">

            <section
              id="SectionWhatWorks"
              class="w-full container mx-auto px-4 lg:px-0"
              style="max-width: 960px"
            >
              <div class="flex flex-col">
                <h1 class="text-2xl leading-6 font-medium mb-2">Finance the Reduction of GBV</h1>
                <div
                  class="text-gray-600 w-full text-justify mt-3 font-medium leading-7"
                >
                  <!-- <h1>{{ takeAction.data.beforeStrategiesCardContent }}</h1> -->
                  <div v-for="(content, index) in beforeStrategiesContent" :key="index">
                    <p> {{ content }} </p>
                    <br/>
                  </div>


                  <br />
                  
                </div>
              </div>

              <div class="flex flex-row mt-5 mb-1">
                <div class="mr-4 w-6/12 items-start justify-start bg-gray-200 px-10 text-sm" :style="'page-break-inside:avoid; background-color: white; letter-spacing: 2px; color: ' + env.brandingColors.teal" style="position: relative;"> 
                  <b>STRATEGY #1</b>
                </div>
                <div class="ml-4 w-6/12 items-start justify-start bg-gray-200 px-10 text-sm" :style="'page-break-inside:avoid; background-color: white; letter-spacing: 2px; color: ' + env.brandingColors.orange" style="position: relative;"> 
                  <b>STRATEGY #2</b>
                </div>
              </div>
              <div class="flex flex-row">
                <div v-for="(card, index) in strategiesCards.slice(0, 2)" :key="index" class="mr-4 w-6/12 flex flex-col items-start justify-start bg-gray-200 px-10 py-8 ease-in-out shadow-xl border-2" :style="'page-break-inside:avoid; background-color: white' + '; border-color:' + env.brandingColors[cardColors[index]] + '; border-width: 3px'" style="border-radius: 10px; position: relative;"> 
                  <div class="flex justify-between items-center w-full flex-col lg:flex-row">
                    <div class="flex flex-col justify-start mb-auto">
                      
                      <div class="flex flex-row justify-start items-center">
                        <h3 class="text-xl font-semibold tracking-tight" :style="'color:'+ env.brandingColors[cardColors[index]]" style="line-height: 1.3">
                          {{ card[0] }}
                        </h3>
                      </div>
                      
                      <div class="flex flex-row justify-start items-center">
                        <p class="mt-5 print:mt-4 text-gray-600 text-justify" style="font-size: 15px; line-height: 1.7;">
                           {{ card[1] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-row mt-10 mb-1">
                <div class="mr-4 w-6/12 items-start justify-start bg-gray-200 px-10 text-sm" :style="'page-break-inside:avoid; background-color: white; letter-spacing: 2px; color: ' + env.brandingColors.blue" style="position: relative;"> 
                  <b>STRATEGY #3</b>
                </div>
                <div class="ml-4 w-6/12 items-start justify-start bg-gray-200 px-10 text-sm" :style="'page-break-inside:avoid; background-color: white; letter-spacing: 2px; color: ' + env.brandingColors.navy" style="position: relative;"> 
                  <b>STRATEGY #4</b>
                </div>
              </div>
              <div class="flex flex-row mb-20">
                <div v-for="(card, index) in strategiesCards.slice(2)" :key="index" class="mr-4 w-6/12 flex flex-col items-start justify-start bg-gray-200 px-10 py-8 ease-in-out shadow-xl border-2" :style="'page-break-inside:avoid; background-color: white' + '; border-color:' + env.brandingColors[cardColors[index+2]] + '; border-width: 3px'" style="border-radius: 10px; position: relative;"> 
                  <div class="flex justify-between items-center w-full flex-col lg:flex-row">
                    <div class="flex flex-col justify-start mb-auto">
                      
                      <div class="flex flex-row justify-start items-center">
                        <h3 class="text-xl font-semibold tracking-tight" :style="'color:'+ env.brandingColors[cardColors[index+2]]" style="line-height: 1.3">
                          {{ card[0] }}
                        </h3>
                      </div>
                      
                      <div class="flex flex-row justify-start items-center">
                        <p class="mt-5 print:mt-4 text-gray-600 text-justify" style="font-size: 15px; line-height: 1.7;">
                          {{ card[1] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col">
                <div
                  class="text-gray-600 w-full text-justify font-medium leading-7"
                >
                  <div v-for="(content, index) in afterStrategiesContent" :key="index">
                    <span> {{ content }} </span>
                    <router-link
                      v-if="index === 0"
                      :to="'/reports/' + country.ISO2 + '/' + $route.params.sector + '/what-works'"
                      :style="'color:' + env.brandingColors.navy">
                    here.
                    </router-link>
                    <br/>
                    <br/>
                  </div>
                </div>
              </div>

              <div
                  class="border-b flex row cursor-pointer mb-1 mt-20"
                  :style="'border-bottom-width: 2px; border-color: ' + env.brandingColors.navy"
                  @click="togglePanel('detailed-actions')"
                >
                <h1 class="text-xl leading-6 font-medium mb-1">
                  See Detailed Actions
                </h1>
                <div :style="'margin-left: auto; cursor:pointer; color: ' + env.brandingColors.navy">
                  <span v-if="openPanels.indexOf('detailed-actions') > -1 || print">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </span>
                  <span v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </span>
                </div>


              </div>
              <transition name="slide-fade">
                <div v-if="openPanels.indexOf('detailed-actions') > -1 || print" class="flex flex-col mt-10 pb-5">
                  <div class="w-full z-1" style="position: absolute; height: 40%; max-width: 960px;">
                    <div 
                      id="gbv-impact-key"
                      class="px-5 p-4 bg-white rounded-lg border border-gray-300 shadow-lg">
                        <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Impact Level Key</p>
                        <div class="text-gray-600 text-xs flex flex-row">
                          <img class="w-4 h-4 mr-2 my-2 flex flex-col" src="../../assets/images/minimum compliance - purple.png" />
                          <span class="flex flex-col justify-center"><em>Minimum Compliance</em></span>
                        </div>
                        <div class="text-gray-600 text-xs flex flex-row">
                          <img class="w-4 h-4 mr-2 my-2" src="../../assets/images/empowerment - purple.png" />
                          <span class="flex flex-col justify-center"><em>Empowerment</em></span>
                        </div>
                        <div class="text-gray-600 text-xs flex flex-row">
                          <img class="w-4 h-4 mr-2 my-2" src="../../assets/images/transformation - purple.png" />
                          <span class="flex flex-col justify-center"><em>Transformation</em></span>
                        </div>
                        <div class="text-gray-600 text-xs flex flex-row mt-3">
                          <div class="flex flex-col">
                            These categories are drawn from the ICED Gender & Inclusion Framework, developed by Caroline Moser in 2016. Read more on our <router-link to='/gesi-analysis/methodology' style='color: #201747'> Methodology page.</router-link>
                          </div>
                        </div>
                    </div>
                  </div>
                  
                   <!-- FILTERS -->
                  <div class="flex flex-col">
                    <p class="text-gray-600" style="font-size: 14px; line-height: 1.7">
                      <i><b>Disclaimer</b>: Never implement an action before consulting with a 
                      gender equality and social inclusion specialist who can advise on appropriate 
                      stakeholder consultation and tailor a bespoke project/investment- and 
                      context-specific action plan. Equilo will evolve its intelligence and 
                      features to support deeper analysis in the future.</i>
                    </p>
                    <br />
                    <p class="text-gray-600" style="font-size: 15px">
                      <item
                          text='<b>Please select actor type below to view tailored actions.</b> <i>Hover over the "i" below to read more about 
                      what this filter means.</i>'
                          :country="country"
                        ></item>
                    </p>
                    <div class="flex flex-row mb-2 mt-2">

                      <div class="flex flex-col cursor-pointer print:hidden h-5 w-5" style="transform: translateY(70%);" @mouseover="actorInfo = true" @mouseleave="actorInfo = false">
                        <svg class="absolute h-5 w-5 text-gray-500" id="info" data-name="Info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <rect width="24" height="24" fill="white" />
                            <g fill="none">
                              <path d="M0,0h24v24h-24Z"></path>
                              <!--path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z"></path-->
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12,17v-5h-1"></path>
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"></path>
                            </g>
                        </svg>
                        <div 
                        v-if="((this.selectedActor != 'Development') & (this.selectedActor != 'Financial')) & actorInfo"
                        class="absolute px-5 p-4 w-64 mt-10 ml-5 top-0 bg-white rounded-lg border border-gray-300 shadow-lg"
                        style="background-color: #f9f9f9;box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);z-index: 9999;">
                          <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Actor Type: </p>
                          <p class="text-gray-600 text-sm">
                            There are a variety of <b>actors</b> within this ecosystem, from financial institutions and national governments to development actors, and more. Tailored actions are identified so that various actors can direct their own work and influence others’ work.</p>
                        </div>
                        <div 
                        v-if="((this.selectedActor == 'Development') || (this.selectedActor == 'Financial')) && actorInfo"
                        class="absolute px-5 p-4 w-64 ml-5 top-0 bg-white rounded-lg border border-gray-300 shadow-lg"
                        style="margin-top: 98px;">
                          <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Actor Type: </p>
                          <p class="text-gray-600 text-sm">
                            There are a variety of <b>actors</b> within this ecosystem, from financial institutions and national governments to development actors, and more. Tailored actions are identified so that various actors can direct their own work and influence others’ work.</p>
                        </div>
                      </div>
                      <div class="flex flex-col mx-auto w-full mr-2" id="actor-filter">
                          <div class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center">
                              <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                                  <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor"/>
                                  <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor"/>
                                  <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor"/>
                                  <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor"/>
                                  <rect width="24" height="24" fill="none" />
                              </svg>
                              <v-select
                              @input="this.selectActor"
                              :value="this.selectedActor"
                              :options="this.actors"
                              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
                              placeholder="Select an Actor"
                              label="name"
                              :clearable="false"
                              ></v-select>
                          </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="align-items: flex-end;" >
                      
                      <div 
                        v-if="(this.selectedActor == 'Development') | (this.selectedActor == 'Financial')" 
                        class="flex flex-col w-10/12 ml-5 mr-5" id="subactor-filter">
                          <div class="text-sm leading-5 text-gray-900 w-full relative flex flex-row items-center">
                              <svg class="absolute left-0 ml-3 h-5 w-5 text-gray-600" id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 4 24 24">
                                  <rect class="fltr-line1" x="7" y="10" width="18" height="1" fill="currentColor"/>
                                  <rect class="fltr-line2" x="7" y="20" width="18" height="1" fill="currentColor"/>
                                  <circle class="fltr-crcl1" cx="13" cy="20.5" r="2.5" fill="white" stroke="currentColor"/>
                                  <circle class="fltr-crcl2" cx="19" cy="10.5" r="2.5" fill="white" stroke="currentColor"/>
                                  <rect width="24" height="24" fill="none" />
                              </svg>
                              <v-select 
                              ref="select"
                              @input="this.selectSubActor"
                              :value="this.selectedSubActor"
                              :options="this.subactors"
                              class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full pl-10 font-medium focus:outline-none focus:shadow-outline"
                              placeholder="Select a Sub-Actor"
                              label="name"
                              :clearable="false"
                              >
                                <span slot="no-options" @click="$refs.select.open = false">
                                  There are no sub-actors for this actor!
                                </span>
                              </v-select>
                          </div>
                      </div>
                      <div class="flex flex-col ml-5"></div>
                      <div class="flex flex-col w-8/12 ml-2"></div>


                    </div>
                  </div>
                  <div 
                    v-if="minComplianceInfo"
                    id="gbv-impact-hover"
                    class="absolute px-5 p-4 mt-10 top-0 bg-white rounded-lg border border-gray-300 shadow-lg"
                    :style="'position: absolute; z-index: 999; top: ' + this.impactHoverOffsetY + '; left: ' + this.impactHoverOffsetX">
                      <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Minimum Compliance</p>
                      <p class="text-gray-600 text-sm">
                        These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</p>
                  </div>
                  <div 
                    v-if="empowermentInfo"
                    id="gbv-impact-hover"
                    class="absolute px-5 p-4 mt-10 top-0 bg-white rounded-lg border border-gray-300 shadow-lg"
                    :style="'position: absolute; z-index: 999; top: ' + this.impactHoverOffsetY + '; left: ' + this.impactHoverOffsetX">
                      <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Empowerment</p>
                      <p class="text-gray-600 text-sm">
                        These actions build assets, capabilities, and opportunities for women and socially excluded groups. </p>
                  </div>
                  <div 
                    v-if="transformationInfo"
                    id="gbv-impact-hover"
                    class="absolute px-5 p-4 mt-10 top-0 bg-white rounded-lg border border-gray-300 shadow-lg"
                    :style="'position: absolute; z-index: 999; top: ' + this.impactHoverOffsetY + '; left: ' + this.impactHoverOffsetX">
                      <p class="text-gray-800 text-sm print:text-xs mb-2 font-medium">Transformation</p>
                      <p class="text-gray-600 text-sm">
                        These actions address unequal power relations and seek institutional and societal changes.</p>
                  </div>
                  
                  <h1 v-if="processTips.length > 0" class="text-xl leading-6 font-medium mb-2 mt-10" id="processTips">
                    Process-Level
                  </h1>

                  <!-- FOR EACH PROCESS-LEVEL TIP -->
                  <div
                  :style="{
                     position: (!actorInfo ? 'relative' : '' ), 
                     'border-color': env.brandingColors.navy, 
                   }"
                    class="shadow overflow-hidden rounded-lg border-2 mt-3 pb-i-avoid z-100"
                    v-for="(tip) in processTips"
                    v-bind:key="tip._id"
                  >
                    <div
                      class="px-4 py-4 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                      @click="togglePanel(tip._id)"
                    >
                      
                      <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                        <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" alt="Minimum Compliance" src="../../assets/images/minimum compliance - purple.png" title="Minimum Compliance" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="minComplianceInfo = false"/>
                        <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" alt="Empowerment" src="../../assets/images/empowerment - purple.png" title="Empowerment" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="empowermentInfo = false"/>
                        <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" alt="Transformation" src="../../assets/images/transformation - purple.png" title="Transformation" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="transformationInfo = false"/>
                      </div>
                      
                      <div class="flex flex-col w-11/12">
                        <span class="text-medium leading-6 font-medium" :style="'color: '+env.brandingColors.navy">
                          {{ tip.name }}
                        </span>
                      </div>
                      
                      <div
                        style="margin-left: auto; cursor:pointer;"
                        :style="'color: '+env.brandingColors.navy"
                      >
                        <span v-if="openPanels.indexOf(tip._id) > -1 || print">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <span v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <transition name="slide-fade">
                      <div
                        v-if="openPanels.indexOf(tip._id) > -1 || print"
                        class="flex flex-col mt-5 px-10 pb-5"
                        :style="'color: '+env.brandingColors.navy"
                      >
                        <div class="flex flex-row mb-3">
                          <div class="flex flex-col items-center justify-center h-10 w-10 print:h-10 print:w-10 border-0">
                              <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" src="../../assets/images/minimum compliance - purple.png" />
                              <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" src="../../assets/images/empowerment - purple.png" />
                              <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" src="../../assets/images/transformation - purple.png" />
                          </div>
                          <div class="flex flex-col text-sm ml-1 h-10 w-100 items-center justify-center">
                            <item v-if="tip.compliance == 'Minimum Compliance'"
                              text='<em><b>Impact Level: Minimum Compliance </b>- These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Empowerment'"
                              text='<em><b>Impact Level: Empowerment </b>- These actions build assets, capabilities, and opportunities for women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Transformation'"
                              text='<em><b>Impact Level: Transformation </b>- These actions address unequal power relations and seek institutional and societal changes.</em>[s45557]'
                              :country="country"
                            ></item>
                          </div>
                        </div>
                        <item
                          :text="tip.text"
                          :country="country"
                          class="text-sm mt-3"
                        ></item>

                        <p class=" mt-6 text-lg font-medium flex flex-row items-center justify-start">
                          <span>Learning and implementation resources</span>
                        </p>
                        <div
                          v-for="tip in tip.items"
                          v-bind:key="tip.text"
                          class="flex flex-row items-start justify-start list-decimal mt-5"
                        >
                          <div
                            v-if="tip && tip.text"
                            target="_blank"
                            :href="tip.url"
                            class="flex flex-row items-start justify-start font-medium text-gray-600"
                          >
                            <svg class="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                              <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                                <path d="M19,12h-14"></path>
                                <path d="M14,17l5,-5"></path>
                                <path d="M14,7l5,5"></path>
                              </g>
                              <path fill="none" d="M0,0h24v24h-24Z"></path>
                            </svg>
                            <item
                              :text="tip.text"
                              :country="country"
                              class="text-sm"
                            ></item>
                            <!-- <span>{{ tip.text }}</span> -->
                            </div
                          ><br />
                        </div>
                      </div>
                    </transition>
                  </div>

                  <h1 class="text-xl leading-6 font-medium mb-2 mt-10" id="systemTips">
                    System-Level
                  </h1>

                  <!-- FOR EACH SYSTEM-LEVEL TIP -->
                  <div
                    :style="{
                       position: (!actorInfo ? 'relative' : '' ), 
                       'border-color': env.brandingColors.teal, 
                     }"
                    class="shadow overflow-hidden rounded-lg border-2 mt-3 pb-i-avoid z-100"
                    v-for="(tip) in systemTips"
                    v-bind:key="tip._id"
                  >
                    <div
                      class="px-4 py-4 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                      @click="togglePanel(tip._id)"
                    >
                      
                      <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                        <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" alt="Minimum Compliance" src="../../assets/images/minimum compliance - purple.png" title="Minimum Compliance" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="minComplianceInfo = false"/>
                        <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" alt="Empowerment" src="../../assets/images/empowerment - purple.png" title="Empowerment" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="empowermentInfo = false"/>
                        <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" alt="Transformation" src="../../assets/images/transformation - purple.png" title="Transformation" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="transformationInfo = false"/>
                      </div>
                      
                      <div class="flex flex-col w-11/12">
                        <span class="text-medium leading-6 font-medium" :style="'color: '+env.brandingColors.navy">
                          {{ tip.name }}
                        </span>
                      </div>
                      
                      <div
                        style="margin-left: auto; cursor:pointer;"
                        :style="'color: '+env.brandingColors.teal"
                      >
                        <span v-if="openPanels.indexOf(tip._id) > -1 || print">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <span v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <transition name="slide-fade">
                      <div
                        v-if="openPanels.indexOf(tip._id) > -1 || print"
                        class="flex flex-col mt-5 px-10 pb-5"
                        :style="'color: '+env.brandingColors.navy"
                      >
                        <div class="flex flex-row mb-3">
                          <div class="flex flex-col items-center justify-center h-10 w-10 print:h-10 print:w-10 border-0">
                              <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" src="../../assets/images/minimum compliance - purple.png" />
                              <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" src="../../assets/images/empowerment - purple.png" />
                              <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" src="../../assets/images/transformation - purple.png" />
                          </div>
                          <div class="flex flex-col text-sm ml-1 h-10 w-100 items-center justify-center">
                            <item v-if="tip.compliance == 'Minimum Compliance'"
                              text='<em><b>Impact Level: Minimum Compliance </b>- These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Empowerment'"
                              text='<em><b>Impact Level: Empowerment </b>- These actions build assets, capabilities, and opportunities for women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Transformation'"
                              text='<em><b>Impact Level: Transformation </b>- These actions address unequal power relations and seek institutional and societal changes.</em>[s45557]'
                              :country="country"
                            ></item>
                          </div>
                        </div>
                        <item
                          :text="tip.text"
                          :country="country"
                          class="text-sm mt-3"
                        ></item>

                        <p class=" mt-6 text-lg font-medium flex flex-row items-center justify-start">
                          <span>Learning and implementation resources</span>
                        </p>
                        <div
                          v-for="tip in tip.items"
                          v-bind:key="tip.text"
                          class="flex flex-row items-start justify-start list-decimal mt-5"
                        >
                          <div
                            v-if="tip && tip.text"
                            target="_blank"
                            :href="tip.url"
                            class="flex flex-row items-start justify-start font-medium text-gray-600"
                          >
                            <svg class="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                              <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                                <path d="M19,12h-14"></path>
                                <path d="M14,17l5,-5"></path>
                                <path d="M14,7l5,5"></path>
                              </g>
                              <path fill="none" d="M0,0h24v24h-24Z"></path>
                            </svg>
                            <item
                              :text="tip.text"
                              :country="country"
                              class="text-sm"
                            ></item>
                            <!-- <span>{{ tip.text }}</span> -->
                            </div><br />
                        </div>
                      </div>
                    </transition>
                  </div>

                  <h1 class="text-xl leading-6 font-medium mb-2 mt-10" id="companyTips">
                    Company-Level 
                  </h1>

                  <!-- FOR EACH COMPANY-LEVEL TIP -->
                  <div
                    :style="{
                       position: (!actorInfo ? 'relative' : '' ), 
                       'border-color': env.brandingColors.orange, 
                     }"
                    class="shadow overflow-hidden rounded-lg border-2 mt-3 pb-i-avoid z-100"
                    v-for="(tip) in companyTips"
                    v-bind:key="tip._id"
                  >
                    <div
                      class="px-4 py-4 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                      @click="togglePanel(tip._id)"
                    >
                      
                      <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                        <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" alt="Minimum Compliance" src="../../assets/images/minimum compliance - purple.png" title="Minimum Compliance" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="minComplianceInfo = false"/>
                        <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" alt="Empowerment" src="../../assets/images/empowerment - purple.png" title="Empowerment" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="empowermentInfo = false"/>
                        <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" alt="Transformation" src="../../assets/images/transformation - purple.png" title="Transformation" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="transformationInfo = false"/>
                      </div>
                      
                      <div class="flex flex-col w-11/12">
                        <span class="text-medium leading-6 font-medium" :style="'color: '+env.brandingColors.navy">
                          {{ tip.name }}
                        </span>
                      </div>
                      
                      <div
                        style="margin-left: auto; cursor:pointer;"
                        :style="'color: '+env.brandingColors.orange"
                      >
                        <span v-if="openPanels.indexOf(tip._id) > -1 || print">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <span v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <transition name="slide-fade">
                      <div
                        v-if="openPanels.indexOf(tip._id) > -1 || print"
                        class="flex flex-col mt-5 px-10 pb-5"
                        :style="'color: '+env.brandingColors.navy"
                      >
                        <div class="flex flex-row mb-3">
                          <div class="flex flex-col items-center justify-center h-10 w-10 print:h-10 print:w-10 border-0">
                              <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" src="../../assets/images/minimum compliance - purple.png" />
                              <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" src="../../assets/images/empowerment - purple.png" />
                              <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" src="../../assets/images/transformation - purple.png" />
                          </div>
                          <div class="flex flex-col text-sm ml-1 h-10 w-100 items-center justify-center">
                            <item v-if="tip.compliance == 'Minimum Compliance'"
                              text='<em><b>Impact Level: Minimum Compliance </b>- These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Empowerment'"
                              text='<em><b>Impact Level: Empowerment </b>- These actions build assets, capabilities, and opportunities for women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Transformation'"
                              text='<em><b>Impact Level: Transformation </b>- These actions address unequal power relations and seek institutional and societal changes.</em>[s45557]'
                              :country="country"
                            ></item>
                          </div>
                        </div>
                        <item
                          :text="tip.text"
                          :country="country"
                          class="text-sm mt-3"
                        ></item>

                        <p class=" mt-6 text-lg font-medium flex flex-row items-center justify-start">
                          <span>Learning and implementation resources</span>
                        </p>
                        <div
                          v-for="tip in tip.items"
                          v-bind:key="tip.text"
                          class="flex flex-row items-start justify-start list-decimal mt-5"
                        >
                          <div
                            v-if="tip && tip.text"
                            target="_blank"
                            :href="tip.url"
                            class="flex flex-row items-start justify-start font-medium text-gray-600"
                          >
                            <svg class="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                              <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                                <path d="M19,12h-14"></path>
                                <path d="M14,17l5,-5"></path>
                                <path d="M14,7l5,5"></path>
                              </g>
                              <path fill="none" d="M0,0h24v24h-24Z"></path>
                            </svg>
                            <item
                              :text="tip.text"
                              :country="country"
                              class="text-sm"
                            ></item>
                            <!-- <span>{{ tip.text }}</span> -->
                            </div
                          ><br />
                        </div>
                      </div>
                    </transition>
                  </div>



                  <h1 class="text-xl leading-6 font-medium mb-2 mt-10" id="projectTips">
                    Project-Level
                  </h1>

                  <!-- FOR EACH PROJECT-LEVEL TIP -->

                  <div
                    :style="'position: relative; border-color:' + env.brandingColors.blue"
                    class="shadow overflow-hidden rounded-lg border-2 mt-3 pb-i-avoid z-100"
                    v-for="(tip) in projectTips"
                    v-bind:key="tip._id"
                  >
                    <div
                      class="px-4 py-4 border-b border-gray-200 sm:px-6 flex row cursor-pointer"
                      @click="togglePanel(tip._id)"
                    >
                      
                      <div class="flex flex-col w-10" style="display:inline; white-space:nowrap;">
                        <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" alt="Minimum Compliance" src="../../assets/images/minimum compliance - purple.png" title="Minimum Compliance" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="minComplianceInfo = false"/>
                        <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" alt="Empowerment" src="../../assets/images/empowerment - purple.png" title="Empowerment" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="empowermentInfo = false"/>
                        <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" alt="Transformation" src="../../assets/images/transformation - purple.png" title="Transformation" style="display:inline; white-space:nowrap;" @mouseover="impactMouseover" @mouseleave="transformationInfo = false"/>
                      </div>
                      
                      <div class="flex flex-col w-11/12">
                        <span class="text-medium leading-6 font-medium" :style="'color: '+env.brandingColors.navy">
                          {{ tip.name }}
                        </span>
                      </div>
                      
                      <div
                        style="margin-left: auto; cursor:pointer;"
                        :style="'color: '+env.brandingColors.blue"
                      >
                        <span v-if="openPanels.indexOf(tip._id) > -1 || print">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down no-print">
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </span>
                        <span v-else>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right">
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <transition name="slide-fade">
                      <div
                        v-if="openPanels.indexOf(tip._id) > -1 || print"
                        class="flex flex-col mt-5 px-10 pb-5"
                        :style="'color: '+env.brandingColors.navy"
                      >
                        <div class="flex flex-row mb-3">
                          <div class="flex flex-col items-center justify-center h-10 w-10 print:h-10 print:w-10 border-0">
                              <img v-if="tip.compliance == 'Minimum Compliance'" class="w-5 h-5" src="../../assets/images/minimum compliance - purple.png" />
                              <img v-if="tip.compliance == 'Empowerment'" class="w-5 h-5" src="../../assets/images/empowerment - purple.png" />
                              <img v-if="tip.compliance == 'Transformation'" class="w-5 h-5" src="../../assets/images/transformation - purple.png" />
                          </div>
                          <div class="flex flex-col text-sm ml-1 h-10 w-100 items-center justify-center">
                            <item v-if="tip.compliance == 'Minimum Compliance'"
                              text='<em><b>Impact Level: Minimum Compliance </b>- These actions mitigate basic risk and address practical needs and vulnerabilities of women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Empowerment'"
                              text='<em><b>Impact Level: Empowerment </b>- These actions build assets, capabilities, and opportunities for women and socially excluded groups.</em>[s45557]'
                              :country="country"
                            ></item>
                            <item v-if="tip.compliance == 'Transformation'"
                              text='<em><b>Impact Level: Transformation </b>- These actions address unequal power relations and seek institutional and societal changes.</em>[s45557]'
                              :country="country"
                            ></item>
                          </div>
                        </div>
                        <item
                          :text="tip.text"
                          :country="country"
                          class="text-sm mt-3"
                        ></item>

                        <p class=" mt-6 text-lg font-medium flex flex-row items-center justify-start">
                          <span>Learning and implementation resources</span>
                        </p>
                        <div
                          v-for="tip in tip.items"
                          v-bind:key="tip.text"
                          class="flex flex-row items-start justify-start list-decimal mt-5"
                        >
                          <div
                            v-if="tip && tip.text"
                            target="_blank"
                            :href="tip.url"
                            class="flex flex-row items-start justify-start font-medium text-gray-600"
                          >
                            <svg class="h-5 w-5 text-indigo-500 mr-2 flex-shrink-0" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <!--Generated by IJSVG (https://github.com/iconjar/IJSVG)-->
                              <g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round">
                                <path d="M19,12h-14"></path>
                                <path d="M14,17l5,-5"></path>
                                <path d="M14,7l5,5"></path>
                              </g>
                              <path fill="none" d="M0,0h24v24h-24Z"></path>
                            </svg>
                            <item
                              :text="tip.text"
                              :country="country"
                              class="text-sm"
                            ></item>
                            <!-- <span>{{ tip.text }}</span> -->
                            </div
                          ><br />
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>
            </section>
          </div>
        </transition>

        <section
          class="w-full container mx-auto mb-32 pb-32 px-4 lg:px-0"
          style="max-width: 960px"
        >
          <div class="flex flex-col mt-10 justify-start">
            <p class="text-xs text-gray-500">
              Data Use and Citation: Equilo grants full permission to its users
              to copy and paste information that appears in the dashboard for
              their own internal use, directly citing the original data sources
              and citing Equilo for analysis and scoring. Please request
              permission from Equilo before utilizing our content for external
              publications.
            
            <br/><br/>
              Equilo uses the TAAP framework, which stands for Transforming
              Agency, Access, and Power. Comprised of six domains, the TAAP
              framework provides a structure to analyze and amplify the voices,
              opportunities, and dignity of all people, especially those from
              socially excluded groups. The GBV Risk Score utilities five of these domains as sub-indices.
              More information about this approach can
              be found at taapinclusion.org. 
            </p>
          </div>
        </section>
        
        
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env.js";
import item from "@/components/text.vue";
import roundedItem from "@/components/text-roundnumbers.vue";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import auth from "@/helpers/auth";
import spiderchart from "@/components/charts/gbv-risk-intersections.vue";
//import heatmap from '@/components/charts/heatmap.vue';
import methodology from "@/components/methodology";
//import VueAirtableGbv from '@/components/VueAirtableGbv'

import barChartVertical from "@/components/charts/barChartVertical.vue";
import doughnutChart from "@/components/charts/doughnutChart.vue";
import radarChart from "@/components/charts/radarChart.vue";
import bubbleChart from "@/components/charts/bubbleChart.vue";
import checkCrossChart from "@/components/charts/checkCrossChart.vue";
import tierChart from "@/components/charts/tierChart.vue";
import historicalData from "@/components/historical-data.vue";

import moment from 'moment'


export default {
  name: "risk-score",
  data: function() {
    return {
      country: {},
      theme: {},
      gbvrisk: {},
      print: false,
      panel: false,
      chartLabels: {},
      openPanels: [],
      indicators: [],
      firstIndi: [],
      secondIndi: [],
      thrdIndi: [],
      fourthIndi: [],
      fifthIndi: [],
      ready: false,

     
      current_step: '',
      risk_level: "",

      firstIntro: "",
      secondIntro: "",
      thirdIntro: "",

      impact_levels: ["All Impact Levels", "Minimum Compliance", "Empowerment", "Transformation"],
      risk_categories: ["All Categories", "Political Risk", "Operational Risk", "Regulatory Risk", "Reputational Risk"],
      actors: ["All Actors", "Development", "Financial", "Private sector", "Humanitarian"],
      subactors: [],
      
      selectedImpact: "",
      selectedCategory: "",
      selectedActor: "",
      selectedSubActor: "",

      filteredTips: {},
      minComplianceTips: {},
      empowermentTips: {},
      transformationTips: {},

      projectTips: {},
      companyTips: {},
      systemTips: {},
      processTips: {},

      actorInfo: false,
      minComplianceInfo: false,
      empowermentInfo: false,
      transformationInfo: false,
      impactHoverOffsetY: '1000px',
      impactHoverOffsetX: '10px',
      
      env,
      taabColors: {
        "Access to Resources": env.taabColors.accessToResources,
        "Law & Policy": env.taabColors.lawAndPolicy,
        "Power & Decision-Making": env.taabColors.powerAndDecision,
        "Knowledge & Beliefs": env.taabColors.knowledgeAndBeliefs,
        "Human Dignity": env.taabColors.humanDignity,
        "Roles & Responsibilities": env.taabColors.rolesAndResponsibilities,
      },
      appearItems: [],
      sector: { name: "" },
      endofcreated:false,
      step5:false,
      step6:false,
      themefunvar:false,
      token:"",
      selectIndicator: null,
      takeAction: null,
      beforeStrategiesContent: [],
      afterStrategiesContent: [],
      strategiesCards: [],
      cardColors: ["teal", "orange", "blue", "navy"]
    };
  },
  components: {
    item,
    roundedItem,
    pageLoader,
    spiderchart,
    //heatmap,
    methodology,
    //VueAirtableGbv,

    barChartVertical,
    doughnutChart,
    radarChart,
    bubbleChart,
    checkCrossChart,
    tierChart,

    historicalData,
  },
  methods: {
    getTakeActionData: function() {
      const _self = this;
      return new Promise(function(resolve) {
        axios
          .get(
            env.apiUrl +
              "/gbv-risk/" +
              _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data);
            _self.themefunvar=true;
          });
      });
    },
    compareRank(a, b) {
      if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
    },
    impactMouseover(event) {
      this.impactHoverOffsetY = (event.pageY - 80) + "px";
      this.impactHoverOffsetX = (event.pageX - 260) + "px";

      if (event.srcElement.alt == "Minimum Compliance") {
        this.minComplianceInfo = true;
      } else if (event.srcElement.alt == "Empowerment") {
        this.empowermentInfo = true;
      } else if (event.srcElement.alt == "Transformation") {
        this.transformationInfo = true;
      } 
      
    },
    // We go for an array so we can have multiple panels open at the same time (eg. download PDF)
    togglePanel: function(panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
   
    value: function(input) {
      return this.format("{{" + input + "}}");
    },
    opacity: function(hex) {
      if (!hex) {
        return "#black";
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const rgb = result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
      return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 0.25)";
    },
    getCountry: function() {
      const _self = this;
      return new Promise(function(resolve) { 
        axios
          .get(
            env.apiUrl +
              "/countries/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
    getTheme: function() {
      const _self = this;
      return new Promise(function(resolve) {
        axios
          .get(
            env.apiUrl +
              "/reports/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector +
              "/themes/GBV Risk Score",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data);
            _self.themefunvar=true;
          });
      });
    },
    getGBVRisk: function() {
      const _self = this;
      return new Promise(function(resolve) { 
        axios
          .get(
            env.apiUrl +
              "/reports/" +
              _self.$route.params.iso2 +
              "/sectors/" +
              _self.$route.params.sector +
              "/gbvrisk",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function(res) {
            resolve(res.data.data);
          });
      });
    },
     forlooptheme: function() {
      const _self = this;
      return new Promise(async function(resolve) {
        if(_self.indicators.length <= 0){
            _self.theme = await _self.getTheme()
            this.country.values = {...this.country.values, ...this.theme.values}
            for (var i = 0; i < _self.theme.indicators.length; i++) {
              //const index = this.indicators.findIndex(x => x.name == this.theme.indicators[i].name);
              if (
                (_self.theme.indicators[i] &&
                _self.theme.indicators[i].name &&
                _self.theme.indicators[i].name.indexOf("Genital Cutting") > -1 &&
                _self.theme.indicators[i].meta &&
                _self.theme.indicators[i].meta.value &&
                _self.theme.indicators[i].meta.value == "Missing") //||
                //(index >= 0)
              ) {
                continue;
              } else if (_self.theme.indicators[i].meta == null) {
                continue;
              } else {

                //create flag if there is an indicator with unknown historical data
                const variables = _self.extractVars(_self.theme.indicators[i].chartText);
                let unknownHistorical = 0;
                for (let j = 0; j < variables.length; j++) {
                  if ((_self.country.values[`${variables[j]}_hist_change`] == "") || (_self.country.values[`${variables[j]}_hist_change`] == "Missing") || (this.country.values[`${variables[j]}_hist_change`] == "Unknown")) {
                    unknownHistorical += 1;
                  } 
                }

                _self.theme.indicators[i].unknownHist = unknownHistorical;
                _self.theme.indicators[i].variables = variables;

                _self.indicators.push(_self.theme.indicators[i]);
              }
            }
        }
        resolve();
      });
    },
   
    toTitle(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
   
    getSector: function() {
      const _self = this;
      return new Promise(function(resolve) {
        // Validate if you want general country information (count/base) or a specific sector
        if (["BASE", "country"].indexOf(_self.$route.params.sector) > -1) {
          resolve(false);
        } else {
          axios
            .get(env.apiUrl + "/sectors/" + _self.$route.params.sector, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso : _self.$route.params.iso2 }
            })
            .then(function(res) {
              resolve(res.data.data);
            });
        }
      });
    },
    indicatorColor(taab) {
      if (taab === "Access to Resources") {
        return env.taabColors.accessToResources;
      } else if (taab === "Law & Policy") {
        return env.taabColors.lawAndPolicy;
      } else if (taab === "Power & Decision-Making") {
        return env.taabColors.powerAndDecision;
      } else if (taab === "Knowledge & Beliefs") {
        return env.taabColors.knowledgeAndBeliefs;
      } else if (taab === "Human Dignity") {
        return env.taabColors.humanDignity;
      } else if (taab === "Roles & Responsibilities") {
        return env.taabColors.rolesAndResponsibilities;
      }
      return true;
    },
    async progressClick(step) {
      this.current_step = step;
      
      if(this.current_step == 0){
        this.$router.push({path: "/gbv-risk/" + this.$route.params.iso2 +'/'+this.$route.params.sector+ "/home"});
      }else if (this.current_step == 1) {
        window.history.pushState("Risk Score", "Risk Score", "/#/gbv-risk/"+ this.$route.params.iso2 +'/'+this.$route.params.sector+ "/risk-score");
      } else if (this.current_step == 2) {
        window.history.pushState("Risk Score", "Risk Score", "/#/gbv-risk/"+ this.$route.params.iso2 +'/'+this.$route.params.sector+ "/indicators");
        if(!this.step5){
          if(!this.themefunvar){
                this.theme = await this.getTheme();
                this.country.values = {...this.country.values, ...this.theme.values}

          }
          if(this.indicators.length <= 0){
            for (var i = 0; i < this.theme.indicators.length; i++) {
              //const index = this.indicators.findIndex(x => x.name == this.theme.indicators[i].name);
              if (
                (this.theme.indicators[i] &&
                this.theme.indicators[i].name &&
                this.theme.indicators[i].name.indexOf("Genital Cutting") > -1 &&
                this.theme.indicators[i].meta &&
                this.theme.indicators[i].meta.value &&
                this.theme.indicators[i].meta.value == "Missing") //||
                //(index >= 0)
              ) {
                continue;
              } else if (this.theme.indicators[i].meta == null) {
                continue;
              } else {
                //create flag if there is an indicator with unknown historical data
                const variables = this.extractVars(this.theme.indicators[i].chartText);
                let unknownHistorical = 0;
                for (let j = 0; j < variables.length; j++) {
                  if ((this.country.values[`${variables[j]}_hist_change`] == "") | (this.country.values[`${variables[j]}_hist_change`] == "Missing") | (this.country.values[`${variables[j]}_hist_change`] == "Unknown")) {
                    unknownHistorical += 1;
                  } 
                }

                this.theme.indicators[i].unknownHist = unknownHistorical;
                this.theme.indicators[i].variables = variables;
                this.indicators.push(this.theme.indicators[i]);
              }
            }

          }
        }
      } else if (this.current_step == 3) {
          window.history.pushState("Risk Score", "Risk Score", "/#/gbv-risk/"+ this.$route.params.iso2 +'/'+this.$route.params.sector+ "/take-action");
          if(!this.step6){
              if(!this.themefunvar){
                  this.theme = await this.getTheme(); // lets do an await before rendering other items.
                  this.country.values = {...this.country.values, ...this.theme.values}
              }
  
              // WHAT WORKS FILTERING
              //this.theme.tips = this.theme.tips.filter(a => (a.risk_level));
              //this.theme.tips = this.theme.tips.filter(a => a.risk_level.includes(this.risk_level));
              this.theme.tips = this.theme.tips.filter(a => a.compliance);
              this.theme.tips = this.theme.tips.filter(a => a.implementation);
              this.filteredTips = this.theme.tips;
              this.filteredTips.sort(this.compareRank);
  
              this.minComplianceTips = this.filteredTips.filter(a => a.compliance.includes("Minimum Compliance"));
              this.empowermentTips = this.filteredTips.filter(a => a.compliance.includes("Empowerment"));
              this.transformationTips = this.filteredTips.filter(a => a.compliance.includes("Transformation"));
  
              this.projectTips = this.filteredTips.filter(a => a.implementation.includes("Project"));
              this.companyTips = this.filteredTips.filter(a => a.implementation.includes("Company"));
              this.systemTips =  this.filteredTips.filter(a => a.implementation.includes("System"));
              this.processTips =  this.filteredTips.filter(a => a.implementation.includes("Process"));
          }
      } 
    },
    selectActor(value) {
      this.selectedActor = value;

      if (this.selectedActor == "Development") {
        this.subactors = ["All Sub-Actors", "Aid Agencies", "Implementing Partners & NGOs", "National Governments", "CBOs, Networks, & Associations"];
      } else if (this.selectedActor == "Financial") {
        this.subactors = ["All Sub-Actors", "DFIs & MFIs", "Funds", "Financial Institutions", "Private and Social Impact Investors", "National Governments", "Companies"];
      } else {
        this.subactors = [];
      }

      this.selectedSubActor = "";

      if (this.selectedActor.includes("All")) {
        this.filteredTips = this.theme.tips;

      } else {
        this.filteredTips = this.theme.tips.filter(a => (a.actor));
        this.filteredTips = this.filteredTips.filter(a => a.actor.includes(this.selectedActor));
      }

      this.minComplianceTips = this.filteredTips.filter(a => a.compliance.includes("Minimum Compliance"));
      this.empowermentTips = this.filteredTips.filter(a => a.compliance.includes("Empowerment"));
      this.transformationTips = this.filteredTips.filter(a => a.compliance.includes("Transformation"));

      this.projectTips = this.filteredTips.filter(a => a.implementation.includes("Project"));
      this.companyTips = this.filteredTips.filter(a => a.implementation.includes("Company"));
      this.systemTips =  this.filteredTips.filter(a => a.implementation.includes("System"));
      this.processTips =  this.filteredTips.filter(a => a.implementation.includes("Process"));

    },
    selectSubActor(value) {
      this.selectedSubActor = value;

      if (this.selectedSubActor.includes("All")) {
        this.filteredTips = this.theme.tips;
      } else {
        this.filteredTips = this.theme.tips.filter(a => (a.sub_actor));
        this.filteredTips = this.filteredTips.filter(a => a.sub_actor.includes(this.selectedSubActor));
      }

      if (!(this.selectedActor === "") & !this.selectedActor.includes("All")) {
        this.filteredTips = this.filteredTips.filter(a => (a.actor));
        this.filteredTips = this.filteredTips.filter(a => a.actor.includes(this.selectedActor));
      }

      this.minComplianceTips = this.filteredTips.filter(a => a.compliance.includes("Minimum Compliance"));
      this.empowermentTips = this.filteredTips.filter(a => a.compliance.includes("Empowerment"));
      this.transformationTips = this.filteredTips.filter(a => a.compliance.includes("Transformation"));

      this.projectTips = this.filteredTips.filter(a => a.implementation.includes("Project"));
      this.companyTips = this.filteredTips.filter(a => a.implementation.includes("Company"));
      this.systemTips =  this.filteredTips.filter(a => a.implementation.includes("System"));
      this.processTips =  this.filteredTips.filter(a => a.implementation.includes("Process"));
    },
    processForm(){
        if(this.$route.params.sector != ''){
            const url=env.gbvurl+"/gbvscore/"+this.$route.params.iso2+"/"+this.$route.params.sector+"?token="+this.token;
            window.open(url,'_blank');
        }else{
            const url=env.gbvurl+"/gbvscore/"+this.$route.params.iso2+"?token="+this.token;
            window.open(url,'_blank');
        }
         
    },
    extractVars: function(text){
      let arrStr = text.split(/[{{}}]/);
      arrStr = arrStr.filter(a => (a.includes('_') & !a.includes('income') & !a.includes('global') & !a.includes('region')));
      return arrStr;
    },
    checkRecentlyUpdated(createdTime) {
      if(!createdTime) 
          return false;
      return (moment().diff(moment(createdTime), 'months', true) <= 3);
    },
    selectItem() {
      this.modifiedIndex;
      return;
    }
  },
  watch: {
    indicators: function(inds){
      this.firstIndi = inds.filter(function(ind){return ind.taab == "Human Dignity"})
      this.secondIndi = inds.filter(function(ind){ return ind.taab == "Law & Policy"})
      this.thrdIndi = inds.filter(function(ind){ return ind.taab == "Knowledge & Beliefs"})
      this.fourthIndi = inds.filter(function(ind){ return ind.taab== "Access to Resources"})
      this.fifthIndi = inds.filter(function(ind){ return ind.taab== "Power & Decision-Making"})
    }
  },
  computed: {
    modifiedIndex() {
      if(this.selectIndicator) {
        var mInde = []
        if (this.firstIndi.length > 0){
          mInde = [...mInde, ...this.firstIndi]
        }
        if (this.secondIndi.length > 0){
          mInde = [...mInde, ...this.secondIndi]
        }
        if (this.thrdIndi.length > 0){
          mInde = [...mInde, ...this.thrdIndi]
        }
        if (this.fourthIndi.length > 0){
          mInde = [...mInde, ...this.fourthIndi]
        }
        if (this.fifthIndi.length > 0){
          mInde = [...mInde, ...this.fifthIndi]
        }
        mInde = mInde.filter(indi =>  {
          return indi.taab == this.selectIndicator
        });
      }
      return mInde;
    },
    citations() {
      const compare = (a, b) => {
        if (a.rank < b.rank) {
          return -1;
        }
        if (a.rank > b.rank) {
          return 1;
        }
        return 0;
      };
      const x = this.$store.state.citations;
//      const removedDuplicates = removeDuplicates(x, item => item._id)
      return x.sort(compare);
    },
    myStyles() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      };
    },
    myStyles3Cases() {
      return {
        height: "700px",
        position: "relative",
        width: "100%",
        display: "flex",
        marginBottom: "-100px",
        justifyContent: "center",
      };
    },
    myStylesLine() {
      return {
        height: "600px",
        position: "relative",
        width: "50%",
        display: "flex",
        justifyContent: "center",
      };
    },
    getUniqueIndicators() {
      const mInde = [
        ...this.firstIndi,
        ...this.secondIndi,
        ...this.thrdIndi,
        ...this.fourthIndi,
        ...this.fifthIndi
      ];
      const uniqueIndicators = mInde.filter((x, i, indicators) =>  {
        return indicators.findIndex(indicator => indicator.taab == x.taab) == i;
      });
      return uniqueIndicators;
    },
    
    activeHeadName(){
      let headName = '';
      if (this.current_step == 1) {
        headName = "score in" +" "+ this.country.humanName;
      }else if (this.current_step == 2) {
        headName = "Indicators in" +" "+ this.country.humanName;
      } else if (this.current_step == 3) {
        headName = "Take Action"
      }
      return headName;
    },
  },
  async created() {
    this.$store.commit("downloadable", false);
    this.$store.commit("resetCitations");    

    this.country = await this.getCountry(); // lets do an await before rendering other items.
    this.sector = await this.getSector(); // lets do an await before rendering other items.
    this.gbvrisk = await this.getGBVRisk(); // lets do an await before rendering other items.
    this.theme = await this.getTheme(); // lets do an await before rendering other items.

    this.country.values = {...this.country.values, ...this.theme.values}

    this.takeAction = await this.getTakeActionData();

    this.beforeStrategiesContent = this.takeAction.data.beforeStrategiesCardContent.split("<br/>");
    this.afterStrategiesContent = this.takeAction.data.afterStrategiesCardContent.split("<br/>");
    this.afterStrategiesContent[0] = this.afterStrategiesContent[0].split("gender norms")[0];
    const content = this.takeAction.data.strategiesCardContent;

    const cardRegex = /<card>([\s\S]*?)<\/card>/g;

    const cards = [];

    const matches = content.matchAll(cardRegex);

    for (const match of matches) {
      const cardContent = match[1].trim();
      
      const boldMatch = cardContent.match(/<b>([\s\S]*?)<\/b>/);
      
      if (boldMatch) {
        const boldText = boldMatch[1].trim();
        const normalText = cardContent.replace(/<b>[\s\S]*?<\/b>/, '').trim();
        cards.push([boldText, normalText]);
      } else {
        cards.push(['', cardContent]);
      }
    }

    this.strategiesCards = cards;

    // SET PROGRESS BAR
    if (this.$route.path.includes('risk-score')) {
      this.current_step = 1;
    } else if (this.$route.path.includes('indicators')) {
      this.current_step = 2;
    }  else if (this.$route.path.includes('take-action')) {
      this.current_step = 3;
    } 
    
    // validate if you have access to this country.
    this.user = await auth.me().catch(() => {});

    // RISK CATEGORIES
    if (Math.round(this.gbvrisk.selected_country.value) < 20) {
      this.risk_level = "Ubiquitous Risk";
    } else if ((Math.round(this.gbvrisk.selected_country.value) >= 20) & (Math.round(this.gbvrisk.selected_country.value) < 40)) {
      this.risk_level = "Significant Risk";
    } else if ((Math.round(this.gbvrisk.selected_country.value) >= 40) & (Math.round(this.gbvrisk.selected_country.value) < 60)) {
      this.risk_level = "Severe Risk";
    } else if (Math.round(this.gbvrisk.selected_country.value) >= 60) {
      this.risk_level = "Extreme Risk";
    }


    // SPLIT INTRO
    const firstIntroSplit = this.gbvrisk.text.intro.split('To provide');
    const secondIntroSplit = firstIntroSplit[1].split('Either the raw');
    this.firstIntro = firstIntroSplit[0];
    this.secondIntro = "To provide" + secondIntroSplit[0];
    this.thirdIntro = "Either the raw" + secondIntroSplit[1];
    if(this.current_step == 3)  {  
        if(!this.themefunvar){
            this.theme = await this.getTheme(); // lets do an await before rendering other items.
            this.country.values = {...this.country.values, ...this.theme.values}

        }
        // WHAT WORKS FILTERING
        this.theme.tips = this.theme.tips.filter(a => a.compliance);
        this.theme.tips = this.theme.tips.filter(a => a.implementation);
        this.filteredTips = this.theme.tips;
        this.filteredTips.sort(this.compareRank);

        this.minComplianceTips = this.filteredTips.filter(a => a.compliance.includes("Minimum Compliance"));
        this.empowermentTips = this.filteredTips.filter(a => a.compliance.includes("Empowerment"));
        this.transformationTips = this.filteredTips.filter(a => a.compliance.includes("Transformation"));

        this.projectTips = this.filteredTips.filter(a => a.implementation.includes("Project"));
        this.companyTips = this.filteredTips.filter(a => a.implementation.includes("Company"));
        this.systemTips =  this.filteredTips.filter(a => a.implementation.includes("System"));
        this.processTips =  this.filteredTips.filter(a => a.implementation.includes("Process"));

        this.step6=true;
    }
    
    
   
    if(this.current_step == 2){
      if(!this.themefunvar){
          this.theme = await this.getTheme(); // lets do an await before rendering other items.
          this.country.values = {...this.country.values, ...this.theme.values}

      }
      if(this.indicators.length <= 0){
        for (var i = 0; i < this.theme.indicators.length; i++) {
          //const index = this.indicators.findIndex(x => x.name == this.theme.indicators[i].name);
          if (
            (this.theme.indicators[i] &&
            this.theme.indicators[i].name &&
            this.theme.indicators[i].name.indexOf("Genital Cutting") > -1 &&
            this.theme.indicators[i].meta &&
            this.theme.indicators[i].meta.value &&
            this.theme.indicators[i].meta.value == "Missing")
          ) {
            continue;
          } else if (this.theme.indicators[i].meta == null) {
            continue;
          } else {
            //create flag if there is an indicator with unknown historical data
            const variables = this.extractVars(this.theme.indicators[i].chartText);
            let unknownHistorical = 0;
            for (let j = 0; j < variables.length; j++) {
              if ((this.country.values[`${variables[j]}_hist_change`] == "") | (this.country.values[`${variables[j]}_hist_change`] == "Missing") | (this.country.values[`${variables[j]}_hist_change`] == "Unknown")) {
                unknownHistorical += 1;
              } 
            }
            this.theme.indicators[i].unknownHist = unknownHistorical;
            this.theme.indicators[i].variables = variables;
            this.indicators.push(this.theme.indicators[i]);
          }
        }
        //var category = ["Human dignity", "Laws & Policies", "Knowledge & Beliefs", "Access to Resources", "Power & Decision Making"];
      }
      this.step5=true;
        
    }else{
       this.forlooptheme()
    }   

    
    // BY DOMAIN SPIDER CHART
    this.chartLabels[this.country.humanName] = {
      color: env.geoColors.countryspider,
      font: env.geoColors.countryspider,
      background: this.opacity(env.geoColors.countryspider),
    };
    this.chartLabels[this.country.region] = {
      color: env.geoColors.regionspider,
      font: env.geoColors.regionspider,
      background: this.opacity(env.geoColors.regionspider),
    };
    this.chartLabels[this.country.income] = {
      color: env.geoColors.incomespider,
      font: env.geoColors.incomespider,
      background: this.opacity(env.geoColors.incomespider),
    };
    this.chartLabels["LMIC"] = {
      color: env.geoColors.lmicspider,
      font: env.geoColors.lmicspider,
      background: this.opacity(env.geoColors.lmicspider),
    };
    this.chartLabels["Perfect Equality"] = {
      color: "#3F37C9",
      font: "#3F37C9",
      background: "#3F37C9",
    };
   
    if (document.location.href.indexOf("print=true") > -1) {
      this.print = true;
    }

    this.$store.commit("downloadable", false);

    this.token = Cookies.get('session');
  },
  
  mounted() {
    this.endofcreated = true;
    this.ready = true;
  },

  updated() {
      this.activeHeadName;
      var breadCrumbName = '';
      var breadCrumbUrl = '';
      if(this.current_step == 1){
        breadCrumbName = (this.$route.params.iso2 == "EQ") ? "Risk Score Demo" : "Risk Score";
        breadCrumbUrl = "risk-score";
      } else if(this.current_step == 2){
        breadCrumbName = (this.$route.params.iso2 == "EQ") ? "Indicators-Demo" : "Indicators";
        breadCrumbUrl = "indicators";
      } else if(this.current_step == 3){
        breadCrumbName =  (this.$route.params.iso2 == "EQ") ? "Take Action Demo" : "Take Action";
        breadCrumbUrl = "take-action";
      } 
      
      if (this.$route.params.iso2 == "EQ") {
       this.$store.commit("setBreadcrumbs", [
      
        {
          name: "GBV-Risk",
          url: "/gbv-risk",
        },
         {
          name: breadCrumbName,
          url:
            "/gbv-risk/" +
            this.country.ISO2 +
            "/" +
            this.$route.params.sector +
            "/" + breadCrumbUrl,
        },
    ]);
      } else {
        let breadCrumbData = []
        if(this.sector){
          breadCrumbData = [
            {
              name: "GBV-Risk",
              url: "/gbv-risk",
            },
            {
              name: this.country.humanName,
              url: "/gbv-risk/choose-country/true",
            },
            {
              name: (this.sector || { name: "country" }).name,
                url: "/gbv-risk/" + this.country.ISO2 + "/choose-sector/true",
            },
            {
              name: breadCrumbName,
              url:
                "/gbv-risk/" +
                this.country.ISO2 +
                "/" +
                this.$route.params.sector +
                "/" + breadCrumbUrl,
            },
          ]
        }else{
          breadCrumbData = [
            {
              name: "GBV-Risk",
              url: "/gbv-risk",
            },
            {
              name: this.country.humanName,
              url: "/gbv-risk/choose-country/true",
            },
            {
              name: breadCrumbName,
              url:
                "/gbv-risk/" +
                this.country.ISO2 +
                "/" +
                this.$route.params.sector +
                "/" + breadCrumbUrl,
            },
          ]
        }
        this.$store.commit("setBreadcrumbs", breadCrumbData);
      }
  }
};





</script>
<style>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
    .slide-fade-leave-to

        /* .slide-fade-leave-active below version 2.1.8 */
 {
  transform: translateY(10px);
  opacity: 0;
}

@media print {
  .radarchart {
    max-height: 400px !important;
  }
}

div.indicator-chart > div > canvas#polar-chart {
  width: 400px !important;
  height: 400px !important;
}

div.indicator-chart > div > canvas#bar-chart {
  width: 400px !important;
  height: 400px !important;
}

div#global-heatmap > div > div > div > svg {
  height: 500 !important;
}

.histData {
  max-width: 400px !important;
}

#buttonContainer {
  text-align: center;
}

#sideNav {
  position: fixed;
  margin-top: 300px;
  left: -65px;
  border-radius: 0 5px 5px 0;
}

#sideNav button {
  background-color: #201747;
  padding: 8px;
  width: 120px;
  height: 38px;
  font-size: 10px;
  color: #201747;
}

#sideNav button:hover {
  background-color: #e2e8f0;
  color: #201747;
}

.navigateButton {
  color: red;
  border: 2px solid red;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.hoverTrigger .hoverMethodology {
  visibility: hidden;
  /* Position the tooltip */
  position: absolute;
  z-index: 50;
  width: 400px;
}

.hoverTrigger {
  position: relative;
  display: inline-block;
}

.triggerElement {
  border-bottom: 1px dotted black;
}

.hoverTrigger:hover .hoverMethodology {
  visibility: visible;
  width: 550px;
}
.margintop1{
    margin-top: 1em;
}
.margintop2{
    margin-top: 2em;
}
.margintop3{
    margin-top: 3em;
}
.marginbottom3{
    margin-bottom: 3em;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 800px;
  background-color: #201747;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#gbv-impact-hover {
  position: absolute;
  width: 14%;
}

/* Make impact key sticky */
#gbv-impact-key {
  position: -webkit-sticky;
  position: sticky !important;
  top: 28em;
  width: 190px;
  float: right;
  margin-right: -215px;
  color: #201747;
}
.custom-mb-3 {
    margin-bottom: 3.25em;
}
.recentlyUpdatedLabel {
    color: darkorange;
    padding-top: 30px;
    display: block;
}
.domainDropDownMenu {
  justify-content: center;
  width: 90%;
  display: flex;
}
</style>
