<template>
  <div v-if="loading" class="card-section">
    <local-loader></local-loader>
  </div>
  <div
    v-else
    class="[ card-section ][ flex flex-col justify-between ] relative"
  >
    <div class="flex justify-between h-10">
      <div class="font-bold text-xl">Financials</div>
    </div>
    <div class="[ h-full ][ flex flex-col justify-between ]">
      <div class="w-full h-24">
        Evaluate the overall financial stability of your company, identifying
        strengths and areas for improvement.
      </div>
      <div class="flex justify-between w-full items-end">
        <div
          class="[ w-1/2 h-12 ][ flex justify-left items-center gap-4 ][ text-[#2177C1] font-bold text-sm ]"
        >
          <a class="cursor-pointer" href="#">&lt; &gt; API integration</a>
          <a class="cursor-pointer flex items-center justify-center" href="#">
            <img src="@/assets/images/dashboard/info.svg" alt="info" />
            Methodology
          </a>
        </div>
        <div class="flex flex-col justify-end gap-4 w-1/2 items-end">
          <router-link
            v-if="displayViewResultsButton"
            :class="[
              'view-results-button',
              { 'opacity-50 cursor-not-allowed': investeeId },
            ]"
            to="/imm/dashboard/finance"
            :disabled="investeeId"
          >
            View Results
          </router-link>
          <router-link
            v-if="displayRepeatButton"
            :class="[
              'repeat-button',
              { 'opacity-50 cursor-not-allowed': investeeId },
            ]"
            to="/imm/finance-survey"
            :disabled="investeeId"
          >
            Repeat
          </router-link>
          <router-link
            v-if="displayLaunchButton"
            :class="[
              'primary-navy-button',
              { 'opacity-50 cursor-not-allowed': investeeId },
            ]"
            to="/imm/finance-survey"
            :disabled="investeeId"
          >
            Launch
          </router-link>
          <span v-else class="text-gray-500">
            This user has no financial data yet.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localLoader from "@/components/local-loader.vue";
import ImmAPI from "@/api/ImmAPI";

export default {
  name: "FinanceCard",
  setup() {
    const immAPI = new ImmAPI();
    return { immAPI };
  },
  components: {
    localLoader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    investeeId: {
      type: String || null,
    },
  },
  data() {
    return {
      loading: true,
      organizationId: null,
      hasSubmittedSurvey: false,
    };
  },
  async mounted() {
    this.organizationId = this.investeeId ?? this.user.organization._id;

    // Checking if the portfolio company has already submitted the survey
    try {
      const response = await this.immAPI.getIMMFinancePeriods(
        this.organizationId
      );
      this.hasSubmittedSurvey = response.data?.length > 0;
    } catch (error) {
      console.error("Error fetching finance survey:", error);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    displayViewResultsButton() {
      return this.hasSubmittedSurvey;
    },
    displayRepeatButton() {
      return this.hasSubmittedSurvey && !this.investeeId;
    },
    displayLaunchButton() {
      return !this.hasSubmittedSurvey && !this.investeeId;
    },
  },
};
</script>

<style scoped>
.card-section {
  padding: 24px;
  gap: 24px;
  border-radius: 24px;
  background: #f4f6f9;
}

.primary-navy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #191b45;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}

.repeat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  background-color: #2177c1;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}

.view-results-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border: 1px solid #191b45;
  border-radius: 8px;
  background-color: transparent;
  color: #191b45;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  min-width: 140px;
}
</style>
