<template>
  <div :key="'updateOpenTextViewComponents'.concat(updateKey)" class="my-2">
    <table>
      <tbody>
        <tr class="" v-for="(value, k) in values" :key="k">
          <td class="pr-4 pb-6">
            <h3 class="font-bold">{{ value.title }}:</h3>
          </td>
          <td v-if="value" class="pl-4 pr-4 pb-6">
            <div v-if="typeof value == 'object'">
              <span v-for="(item, i) in value.value" :key="i">{{ item }}</span>
            </div>
            <span v-else>{{ value.value }}</span>
          </td>
          <td class="pl-4 pr-4 pb-6" v-else></td>
          <td
            class="pl-4 pr-4 pb-6"
            style="display: flex"
            v-if="
              $route.path.includes('/admin') &&
              value?.surveyQType != 'Calculated'
            "
          >
            <div class="px-2 flex items-center justify-end">
              <img
                src="@/assets/images/edit-dark.svg"
                alt="edit-icon"
                class="h-6 w-6 cursor-pointer mr-2"
                @click="openEditModal(value)"
              />
              <verification-dropdown :value="value" />
            </div>
            <div
              v-if="
                $route.path.includes('/admin') &&
                value?.surveyQType != 'Calculated'
              "
            >
              <!-- ML Verifier -->
              <MLVerifierValue :value="value" :teamId="$route.query?.teamId" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import allowList from "@/questions_users_types.json";
import { useUserStore } from "@/stores/user";

export default {
  name: "OpenTextView",
  data: function () {
    return {
      updateKey: 0,
    };
  },
  props: { values: Array },
  components: { MLVerifierValue, VerificationDropdown },
  computed: {
    filteredValues() {
      // Removing the Thematic focus from the list of items to verify
      let result = this.indicator.values;
      if (this.$route.path.includes("/admin")) {
        result = result.filter(
          (value) => !value.title.toLowerCase().includes("thematic")
        );
      }
      const user = useUserStore().user;
      if (user.twoXGlobal.length === 0) {
        result = result.filter((v) => !allowList.twoXGlobal.includes(v.key));
      }

      if (!user.isVerifier) {
        result = result.filter((v) => !allowList.verifier.includes(v.key));
      }
      return result;
    },
  },
  methods: {
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
  },
};
</script>

<style scoped>
/* Add any additional scoped styles here if needed */
</style>
