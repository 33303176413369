<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :fill="fill"
    :stroke="stroke"
    :class="classes"
    :strokeLineCap="strokeLineCap"
    :strokeLineJoin="strokeLineJoin"
    :stroke-width="strokeWidth"
  >
    <path :d="path1" />
    <path :d="path2" />
  </svg>
</template>

<script>
export default {
  name: "eqChat",
  props: {
    path1: String,
    path2: String,
    strokeLineCap: String,
    strokeLineJoin: String,
    strokeWidth:String,
    fill: String,
    stroke: String,
    classes:String
  },
};
</script>
