<template>
  <div class="p-4 bg-gray-100 rounded-xl overflow-hidden w-1/2 mt-5">
    <h3>Assessment updates</h3>
    <div class="status-items">
      <div class="status-item in-progress">
        <i class="status-icon" />
        <span>In progress</span>
        <span class="count">{{ inProgress }}</span>
      </div>
      <div class="status-item outdated">
        <i class="status-icon" />
        <span>Outdated</span>
        <span class="count">{{ outdated }}</span>
      </div>
      <div class="status-item not-started">
        <i class="status-icon" />
        <span>Not started</span>
        <span class="count">{{ notStarted }}</span>
      </div>
    </div>
    <a href="#" class="view-trend">View trend</a>
  </div>
</template>

<script>
export default {
  name: "AssessmentUpdates",
  props: {
    inProgress: {
      type: Number,
      default: 0,
    },
    outdated: {
      type: Number,
      default: 0,
    },
    notStarted: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.updates-container {
  background: white;
  padding: 16px;
  border-radius: 8px;
}

.status-items {
  display: flex;
  gap: 24px;
  margin: 16px 0;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.in-progress .status-icon {
  background: #ecc94b;
}

.outdated .status-icon {
  background: #4299e1;
}

.not-started .status-icon {
  background: #a0aec0;
}

.count {
  font-weight: bold;
}

.view-trend {
  color: #4299e1;
  text-decoration: none;
}
</style>
