<template>
  <div class="static">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        v-if="endofcreated && comapnyDetails && comapnyDetails.length > 0"
        key="content"
        style="margin-top: -50px"
      >
        <div class="flex flex-col items-center justify-center w-full">
          <div class="container">
            <div
              class="flex flex-row justify-center items-center mt-3 ml-20"
              style="margin-top: 100px"
            >
              <div class="flex flex-col item-center justify-center">
                <!-- indicator blurb start -->
                <section
                  class="
                    w-full
                    container
                    mx-auto
                    mt-20
                    px-4
                    lg:px-20
                    py-10
                    bg-gray-100
                    border border-gray-200
                    rounded-lg
                    shadow-2xl
                  "
                >
                  <div class="flex flex-col mt-10" v-scroll-reveal>
                    <h1 class="text-2xl leading-6 font-medium mb-2">
                      Explore Country- and Sector-Specific Contextual Analysis
                    </h1>
                    <div
                      class="
                        text-gray-600
                        w-full
                        text-justify
                        font-medium
                        leading-7
                        mt-3
                      "
                    >
                      <p>
                        Scroll down to explore country- and sector-specific data
                        related to each 2X Criteria. Each indicator includes
                        country-specific data, accompanied by regional, income
                        group, and global averages for comparison. A short
                        descriptive narrative accompanies each statistic to help
                        understand the "so what." This analysis is intended to
                        understand the context in which this organization
                        operates- which can influence gender equality practices
                        and outcomes at the organization.
                      </p>
                    </div>
                  </div>

                  <div
                    v-for="(indicator, index) in indicatorBlurb.indicators"
                    :key="index"
                  >
                    <p
                      class="tracking-tight text-lg font-medium mb-2"
                      style="margin-top: 3rem"
                    >
                      <b> {{ indicator.pageIntros.name }} </b>
                    </p>
                    <p class="leading-7 font-medium text-gray-600">
                      <roundedItem
                            :text="indicator.pageIntros.introText"
                            :country="country"
                            type="my2X"
                          ></roundedItem></p>
                    <div
                      class="flex flex-row w-full pb-i-avoid"
                      style="margin-top: 3rem"
                      v-for="(result, index2) in indicator.results"
                      :key="index2"
                    >
                      <div
                        class="flex flex-col w-6/12 pr-5 print:pr-10"
                        v-scroll-reveal
                      >
                      <p class="tracking-tight text-lg font-medium mb-2" style="color: rgb(32, 23, 71) !important;">
                        {{index+1}}.{{index2+1}} {{result.database_title}}
                        </p>
                        <div class="text-gray-600 mt-5 text-justify">
                          <roundedItem
                            :text="result.indicatorBlurb"
                            :country="country"
                            type="my2X"
                          ></roundedItem>
                          <br />
                          <roundedItem
                            :text="result.chartText"
                            :country="country"
                            type="my2X"
                          ></roundedItem>
                        </div>
                      </div>
                      <div
                        class="flex flex-col items-center w-6/12"
                        v-scroll-reveal
                        style="margin-top: 2rem"
                      >
                        <div class="radarchart">
                          <my2XIndicatorsBarChart
                            class="print:scale-75 transform radarchart"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            :index="index + 1"
                            v-if="result.chartType == 'barChartVertical'"
                          >
                          </my2XIndicatorsBarChart>

                          <doughnutChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            v-else-if="result.chartType == 'doughnutChart'"
                          >
                          </doughnutChart>

                          <radarChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            v-if="result.chartType == 'radarChart'"
                          >
                          </radarChart>

                          <bubbleChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            v-if="result.chartType == 'bubbleChart'"
                          >
                          </bubbleChart>

                          <checkCrossChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            v-if="result.chartType == 'checkcross'"
                          >
                          </checkCrossChart>

                          <tierChart
                            class="print:scale-75 transform radarchart"
                            :index="index + 1"
                            :country="country"
                            :chartData="JSON.parse(result.chartData)"
                            :chartLabels="JSON.parse(result.chartLabels)"
                            :indicator="result"
                            v-if="result.chartType == 'tierChart'"
                          >
                          </tierChart>
                        </div>
                        <div class="histData w-full">
                          <label class="recentlyUpdatedLabel" v-if="result.meta && checkRecentlyUpdated(result.meta.createdTime)">
                              Recently updated!
                            </label>
                          <historicalData
                            :indicator="result"
                            :country="country"
                          ></historicalData>
                          <p v-if="(result.unknownHist > 0) && result.variables && (result.unknownHist != result.variables.length)" class="text-sm mt-4"><i>No historical data available for the other indicators.</i></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- indicator blurb end -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="margin-top: 10%;">
        <div class="flex flex-row justify-center items-center mt-3 ml-20">
          <div class="flex flex-col item-center justify-center">
            <div
              class="flex flex-row items-center justify-center print:flex-col"
            >
              <img class="h-16 print:h-auto print:w-64"  src="@/assets/images/2X-assessment/My2XAssessments3b.png" alt="" />
              <h1
                class="text-6xl tracking-tighter font-medium text-center print:text-7xl ml-5"
              >
                No Data Found
              </h1>
            </div>
            <div
              class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col"
            >
              Complete a
              <a href="/#/2X-Ecosystem/New-2XAssessment">
                <b
                  style="text-decoration:underline; color:#0077C8; margin: 0 6px;"
                  >2X Assessment</b
                >
              </a>
              to see your results.
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import my2XIndicatorsBarChart from "@/components/charts/my2XIndicatorsBarChart.vue";
import roundedItem from "@/components/text-roundnumbers.vue";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import Vue from "vue";
import JsonCSV from "vue-json-csv";
import doughnutChart from "@/components/charts/doughnutChart.vue";
import radarChart from "@/components/charts/radarChart.vue";
import bubbleChart from "@/components/charts/bubbleChart.vue";
import checkCrossChart from "@/components/charts/checkCrossChart.vue";
import tierChart from "@/components/charts/tierChart.vue";
import historicalData from "@/components/historical-data.vue";
import moment from "moment";

Vue.component("downloadCsv", JsonCSV);
export default {
  name: "contextIndicatorBlurbs",
  data: function () {
    return {
      sectorAry: [
      {
          name: "Financial Services",
          label: "2X_FNCE",
        },
        {
          name: "Financial and Insurance Activities",
          label: "2X_FNCE",
        },
        {
          name: "Agribusiness & Food",
          label: "2X_AGFOOD",
        },
        {
          name: "Agriculture",
          label: "2X_AGFOOD",
        },
        {
          name: "Consumer Services",
          label: "2X_CONSUMER",
        },
        {
          name: "Education",
          label: "2X_EDUC",
        },
        {
          name: "Healthcare",
          label: "2X_HLTH",
        },
        {
          name: "Healthcare and Social work",
          label: "2X_HLTH",
        },
        {
          name: "Infrastructure",
          label: "2X_INFR",
        },
        {
          name: "Transportation and Storage Heavy",
          label: "2X_INFR",
        },
        {
          name: "Water and Waste Management", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Construction", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Manufacturing- Heavy",
          label: "2X_MANFHVY",
        },
        {
          name: "Manufacturing",
          label: "2X_MANFHVY",
        },
        {
          name: "Mining and Quarrying",
          label: "2X_MANFHVY",
        },
        {
          name: "Power",
          label: "2X_POWER",
        },
        {
          name: "Energy and Power",
          label: "2X_POWER",
        },
        {
          name: "Professional Services",
          label: "2X_PROF",
        },
        {
          name: "Professional, Scientific, and Technical activities",
          label: "2X_PROF",
        },
        {
          name: "Telecoms",
          label: "2X_TELECOMS",
        },
        {
          name: "Manufacturing- Light",
          label: "2X_MANFLT",
        },
        {
          name: "Wholesale and Retail Trade",
          label: "2X_MANFLT",
        },
      ],
      dynamicGesiURL: "",
      dynamicGbvURL: "",
      json_data: [],
      assessmentYear: [],
      sharedOrgRoute: false,
      invitedUserEmail: "",
      indicatorBlurb: [],
      comapnyDetails: [],
      active: false,
      endofcreated: false,
      country: {
        income: "",
      },
      theme: {},
      gbvrisk: {},
      sector: { name: "" },
      env,
      current_step: 1,
      risk_level: "",
      openPanels: [],
      taabs: {
        Entrepreneurship: env.taabColors.humanDignity,
        Leadership: env.taabColors.knowledgeAndBeliefs,
        Employment: env.taabColors.employment,
        Consumption: env.taabColors.accessToResources,
        "Indirect Investment": env.taabColors.indirectInvestement,
      },
      indicatorLinkList: ['Entrepreneurship', 'Leadership',
      'Employment', 'Consumption', 'Investment through Financial Intermediaries'
      ],
      selectIndicator: 'Entrepreneurship',
      isShowLoader: false,
      indicators: [],
      themesOptions: [],
      countryById: []
    };
  },
  components: {
    roundedItem,
    my2XIndicatorsBarChart,
    pageLoader,
    doughnutChart,
    radarChart,
    bubbleChart,
    checkCrossChart,
    tierChart,
    historicalData,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.organization.accessTwoXGESIContextLight == false) {
      this.$router.push({ path: "/" });
    }
    let email = this.user.email;
    let recordId;
    if (this.$route.query.recordId) {
      this.sharedOrgRoute = true;
      recordId = this.$route.query.recordId;
    }
    this.countryById = await this.getCountryById(this.$route.query.countryId);
    this.comapnyDetails = await this.getCompanyDetails(email, recordId, this.countryById.ISO2);

    if (this.comapnyDetails == undefined) {
      this.$router.push({ path: "/2X-Ecosystem" });
    }

    if (this.comapnyDetails && this.comapnyDetails.length > 0) {
      this.dynamicGesiURL = env.domainRoute + "/#/gesi-analysis/EQ/AGRI_GENERL/snapshot";
      this.dynamicGbvURL = env.domainRoute + "/#/reports/" + this.comapnyDetails[0].countryHq.iso + "/country/gbv-risk/risk-score";

      let CSVData = await this.getDownloadCSV();
      if (CSVData) {
        this.json_data.push(CSVData.fields);
      }

      this.assessmentYear.push(
        new Date(this.comapnyDetails[0].createdTime).getFullYear()
      );

      this.country = await this.getCountry(
        this.comapnyDetails[0].countryHq.iso
      );
      this.gbvrisk = await this.getGBVRisk(
        this.comapnyDetails[0].countryHq.iso
      );

      let sectorValue = "Other";
      let sectorData = this.sectorAry.find(
        (sector) => sector.name === this.comapnyDetails[0].sector
      );
      if (sectorData) {
        sectorValue = sectorData.label;
      }

      this.sector = await this.getSector(sectorValue);
      await this.selectItem(this.$route.query.indicator);
    }
      // RISK CATEGORIES
      if (Math.round(this.gbvrisk.selected_country.value) < 20) {
        this.risk_level = "Ubiquitous Risk";
      } else if (
        (Math.round(this.gbvrisk.selected_country.value) >= 20) &
        (Math.round(this.gbvrisk.selected_country.value) < 40)
      ) {
        this.risk_level = "Significant Risk";
      } else if (
        (Math.round(this.gbvrisk.selected_country.value) >= 40) &
        (Math.round(this.gbvrisk.selected_country.value) < 60)
      ) {
        this.risk_level = "Severe Risk";
      } else if (Math.round(this.gbvrisk.selected_country.value) >= 60) {
        this.risk_level = "Extreme Risk";
      }

      this.$store.commit("resetCitations");
      this.$store.commit("downloadable", false);
      this.$store.commit("isFreeState", true);
      this.$store.commit("setBreadcrumbs", [
        {
        name: "2X Assessment",
        url: "/",
        },
        {
        name: this.sharedOrgRoute
            ? this.comapnyDetails[0].organizationName
            : "My 2X Assessments",
        url: "/2X-Ecosystem/My-2X-Assessments",
        },
        {
        name: "Contextual Analysis",
        url: "/2X-Ecosystem/My-2X-Assessments/contextual-analysis?email="+this.$route.query.recordId+'&sector='
        +this.$route.query.sector+'&countryId='+this.$route.query.countryId,
        },
        {
        name: this.$route.query.indicator,
        url: "/2X-Ecosystem/My-2X-Assessments/contextual-analysis/"+this.$route.query.indicator,
        }
      ])
    // }
    this.endofcreated = true;
  },
  methods: {
    getCountryById: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/countries/country/" + val, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let usersAry = [];
        usersAry.push(this.invitedUserEmail);
        let body = {
          user_id: this.user._id,
          sharedEmails: usersAry,
        };
        this.sendInviteToUser(body);
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    getCountry: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/countries/" + val + "/sectors/country", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getGBVRisk: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/reports/" + val + "/sectors/country/gbvrisk", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getSector: function (val) {
      const _self = this;
      return new Promise(function (resolve) {
        if (val) {
          axios
            .get(env.apiUrl + "/sectors/" + val, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso : _self.comapnyDetails[0].countryHq.iso }
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        } else {
          resolve([]);
        }
      });
    },
    getCompanyDetails(email, recordId, ISO2) {
      let query = { email: email, countryIso: ISO2, recordId: recordId }

      if (this.$route.query.type) {
        query.tableName = this.$route.query.type
      }

      return new Promise((resolve) => {
        axios
          .post(
            env.apiUrl + "/assesment2x/all-assesment",
            query,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then((res) => {
            resolve(res.data.data.assesments);
          });
      });
    },
    getIndicatorBlurb(selectedIndicator) {
      this.isShowLoader = true;
      return new Promise((resolve) => {
        axios
          .get(
            env.apiUrl +
              "/assesment2x/intros?tableName=indicator_blurbs&sector=" +
              this.$route.query.sector +
              "&ISO2=" +
              this.$route.query.countryId +
              "&indicator=" + selectedIndicator,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then((res) => {
            this.isShowLoader = false;
            resolve(res.data.data);
          });
      });
    },
    // extractVars: function(text){
    //   let arrStr = text.split(/[{{}}]/);
    //   arrStr = arrStr.filter(a => (a.includes('_') & !a.includes('income') & !a.includes('global') & !a.includes('region')));
    //   return arrStr;
    // },
    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/assesment2x/send-invite", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getDownloadCSV: function () {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl +
              "/assesment2x/download-csv?tableName=" +
              _self.comapnyDetails[0].type +
              "&recordId=" +
              _self.comapnyDetails[0].id,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async selectItem(data) {
      if (data !== 'Explore 2X Criteria') {
        this.indicatorBlurb = [];
        this.indicatorBlurb = await this.getIndicatorBlurb(data);
        this.country.values = this.indicatorBlurb.values;
        if (this.indicatorBlurb &&
          this.indicatorBlurb.indicators[0] &&
          this.indicatorBlurb.indicators[0].results &&
          this.indicatorBlurb.indicators[0].results.length) {
          for (var i = 0; i < this.indicatorBlurb.indicators[0].results.length; i++) {
            if (
              this.indicatorBlurb.indicators[0].results[i] &&
              this.indicatorBlurb.indicators[0].results[i].name &&
              this.indicatorBlurb.indicators[0].results[i].name.indexOf("Genital Cutting") > -1 &&
              this.indicatorBlurb.indicators[0].results[i].meta &&
              this.indicatorBlurb.indicators[0].results[i].meta.value &&
              this.indicatorBlurb.indicators[0].results[i].meta.value == "Missing"
            ) {
              continue;
            } else if (this.indicatorBlurb.indicators[0].results[i].meta == null) {
              continue;
            } else {
              //create flag if there is an indicator with unknown historical data
              const variables = this.extractVars(this.indicatorBlurb.indicators[0].results[i].chartText);
              let unknownHistorical = 0;
              for (let j = 0; j < variables.length; j++) {
                if ((this.country.values[`${variables[j]}_hist_change`] == "") | (this.country.values[`${variables[j]}_hist_change`] == "Missing") | (this.country.values[`${variables[j]}_hist_change`] == "Unknown")) {
                  unknownHistorical += 1;
                }
              }
              this.indicatorBlurb.indicators[0].results[i].unknownHist = unknownHistorical;
              this.indicatorBlurb.indicators[0].results[i].variables = variables;
              this.indicators.push(this.indicatorBlurb.indicators[0].results[i]);
            }
          }
        }
        if (data == 'Explore 2X Criteria') {
          this.indicatorBlurb = [];
        }
      }
    },
    extractVars: function(text){
      let arrStr = text.split(/[{{}}]/);
      arrStr = arrStr.filter(a => (a.includes('_') & !a.includes('income') & !a.includes('global') & !a.includes('region')));
      return arrStr;
    },
    checkRecentlyUpdated(createdTime) {
      if (!createdTime) return false;
      return moment().diff(moment(createdTime), "months", true) <= 3;
    },
  }
};
</script>

<style scoped>
.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid rgb(32, 23, 71);
  border-bottom: 10px solid rgb(32, 23, 71);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 2s linear infinite;
}
.recentlyUpdatedLabel {
  color: darkorange;
  padding-top: 30px;
  display: block;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>