<template>
  <div class="flex flex-col items-center justify-center min-h-screen">
    <div v-if="loading" class="text-lg font-semibold">Loading...</div>
    <div v-else class="text-center">
      <div v-if="error" class="text-red-500">
        Your token is invalid. Please contact support
      </div>
      <div v-else-if="!accepted">
        <p class="text-gray-700 text-xl mb-10">
          Accept your invite clicking in the button below
        </p>
        <a
          href="https://app.equilo.io/"
          target="_blank"
          class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
          @click="acceptInvite"
        >Accept invite</a
        >
      </div>
      <div v-else>
        <p class="text-gray-700 text-xl mb-10">Your invite has been accepted</p>
      </div>
    </div>
  </div>
</template>

<script>
import ImmPreferenceAPI from "@/api/ImmPreferenceAPI";

export default {
  name: "IMMInvite",
  setup() {
    const immPreferenceAPI = new ImmPreferenceAPI();
    return { immPreferenceAPI };
  },
  data() {
    return {
      accepted: false,
      loading: true,
      error: false
    };
  },
  async mounted() {
    await this.fetchData();
    if (this.accepted) {
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  },
  methods: {
    async fetchData() {
      const token = this.$route.params.token;
      try {
        const response = await this.immPreferenceAPI.getInvesteeInvitesVerifyToken(token);
        const { data, status } = response;
        if (status === 200) {
          this.accepted = data.data.accepted;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async acceptInvite() {
      const token = this.$route.params.token;
      try {
        const response = await this.immPreferenceAPI.putAcceptInvite(token);
        const { status } = response;
        console.log("response", status);

        if (status === 200) {
          this.accepted = true;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
