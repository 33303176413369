import { defineStore } from "pinia";
import auth from "@/helpers/auth";
import { getSubscriptions } from "@/api/endpoints";
import Cookies from "@/helpers/cookies";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null,
    subscriptionType: null,
    userPurchaseHistory: null,
  }),
  getters: {},
  actions: {
    async getSession() {
      return Cookies.get("session");
    },
    async fetchUser(logUser = false, homePage = false) {
      this.user = await auth.me(logUser, homePage).catch(() => {});
    },
    async fetchSubscriptions() {
      const response = await getSubscriptions();
      if (
        Array.isArray(response?.productArr) &&
        response?.productArr?.length > 0 &&
        response?.productArr[0]?.subscription
      ) {
        this.subscriptionType = response?.productArr[0]?.subscription;
      } else {
        this.subscriptionType = "Equilo Open";
      }
    },
  },
});
