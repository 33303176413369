import BaseAPI from "@/api/index";

class SubscriptionAPIs {
  resourceBasePath = "/subscriptions";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  createCheckoutSession(accountType, payload) {
    return this.axiosInstance.post(`${this.resourceBasePath}/create-checkout-session/${accountType}`, payload);
  }

  getPurchaseHistory(type, teamId) {
    return this.axiosInstance.get(`${this.resourceBasePath}/purchase-history?type=${type}&teamId=${teamId}`);
  }

  getThresholdProducts() {
    return this.axiosInstance.get(`${this.resourceBasePath}/thresholds-products`);
  }
}

export default SubscriptionAPIs;
