<template>
  <div class="static">
    <div class="main-container mb-20">
      <BreadCrumbs class="breadcrumbs-main-container"></BreadCrumbs>
      <div class="page-title-section flex justify-between mt-6">
        <div class="page-title">
          {{ activeItem.text ? activeItem.text : activeItem }}
        </div>
        <div></div>
        <!-- <div class="download-button">
          <button class="flex">
            Customize and download the report
            <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
          </button>
        </div> -->
      </div>

      <!-- Progress Bar Charts -->
      <div
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-6 gap-8"
      >
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#2C2E35] font-bold text-base leading-6">
              Environmental
            </div>
            <div class="bar-chart-section flex justify-between items-center">
              <div class="filter-options-section flex">
                <div class="filter-options flex items-center">
                  <div class="active">Top 3</div>
                  <div>Bottom 3</div>
                </div>
                <div class="select-year">
                  <select name="year" id="year">
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <ProgressBarChart
              v-for="(item, index) in progressDataOne"
              :key="index"
              :companyName="item.companyName"
              :percentage="item.percentage"
              :color="item.color"
            />
          </div>
        </div>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#2C2E35] font-bold text-base leading-6">
              Biggest emitting companies (CO2e)
            </div>

            <div class="bar-chart-section flex justify-between items-center">
              <div class="filter-options-section flex">
                <div class="filter-options flex items-center">
                  <div class="active">Top 3</div>
                  <div>Bottom 3</div>
                </div>
                <div class="select-year">
                  <select name="year" id="year">
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8">
            <ProgressBarChart
              v-for="(item, index) in progressDataTwo"
              :key="index"
              :companyName="item.companyName"
              :percentage="item.percentage"
              :color="item.color"
            />
          </div>
        </div>
      </div>

      <!-- Time filter section -->

      <div class="flex justify-between items-center mt-12">
        <div class="bar-chart-section flex items-center">
          <div class="filter-options-section flex">
            <div class="filter-options flex">
              <div class="active">By quarters</div>
              <div>All Time</div>
            </div>
            <div class="select-year ml-4">
              <select name="year" id="year">
                <option value="2023">2023</option>
                <option value="2022">2022</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="download-button mr-3">
            <button class="flex">
              Customize and download the report
              <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
            </button>
          </div>
          <div class="download-button">
            <button class="flex">
              Upload metrics
              <img src="@/assets/images/upload.svg" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex justify-between items-center mt-8">
        <h1
          class="text-2xl font-bold"
          :class="{ 'is-fixed fixed-header': esgFixedResults }"
        >
          Total ESG results
        </h1>
        <img
          v-if="esgResults"
          @click="esgResults = !esgResults"
          src="@/assets/images/arrow-up.svg"
        />
        <img
          v-if="!esgResults"
          @click="esgResults = !esgResults"
          src="@/assets/images/arrow-down.svg"
        />
      </div>

      <div ref="esgSection" class="mt-12">
        <div v-if="esgResults">
          <div class="flex flex-wrap">
            <div class="md:w-1/5 w-full pb-6 md:pb-0 md:pr-6 mt-6">
              <div
                v-for="(item, index) in categories"
                :key="index"
                class="relative flex items-center gap-6"
              >
                <div class="w-16 h-16 mr-3 mb-2">
                  <svg
                    class="w-full h-full flex items-center"
                    viewBox="0 0 100 100"
                  >
                    <!-- Background circle -->
                    <circle
                      class="text-gray-200 stroke-current"
                      stroke-width="10"
                      cx="50"
                      cy="50"
                      r="40"
                      fill="transparent"
                    ></circle>
                    <!-- Progress circle -->
                    <circle
                      class="text-[#4DC9BE] progress-ring__circle stroke-current"
                      stroke-width="10"
                      stroke-linecap="round"
                      :cx="50"
                      :cy="50"
                      r="40"
                      fill="transparent"
                      :stroke-dashoffset="getDashOffset(item.percentage)"
                    ></circle>

                    <!-- Center text -->
                    <text
                      x="53"
                      y="53"
                      font-family="Arial"
                      font-size="16"
                      text-anchor="middle"
                      alignment-baseline="middle"
                      font-Weight="700"
                      class="font-bold flex items-center"
                    >
                      {{ item.percentage }}
                    </text>
                  </svg>
                </div>

                <div class="text-base font-bold text-[#191B45]">
                  {{ item.category }}
                </div>
              </div>
            </div>
            <div class="md:w-4/5 w-full">
              <!-- <div
                class="rounded border-gray-300 dark:border-gray-700 border-dashed border-2 h-24"
              ></div> -->
              <esg-multiple-bar-chart />
            </div>
          </div>
        </div>
      </div>

      <!--  Environmental and Social exclusions -->

      <div class="flex justify-between items-center mt-8">
        <h1
          class="text-2xl font-bold"
          :class="{ 'is-fixed fixed-header': fixedComprehensiveSection }"
        >
          Environmental and Social exclusions
        </h1>
        <img
          v-if="isComprehensiveTableVisible"
          @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
          src="@/assets/images/arrow-up.svg"
        />
        <img
          v-if="!isComprehensiveTableVisible"
          @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
          src="@/assets/images/arrow-down.svg"
        />
      </div>

      <div
        ref="comprehensiveSection"
        class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 pt-6 gap-8"
      >
        <div v-if="isComprehensiveTableVisible" class="border rounded-lg p-8">
          <h3 class="chart-title py-2">
            Total percentage of companies that adhere to exclusion criteria
          </h3>
          <esg-portfolio-line-chart />
        </div>
        <div></div>
        <!-- <div class="border rounded-lg p-8">
          <h3 class="chart-title pb-6">
            What percentage of female & male students complete / graduate from
            the education program?
          </h3>
          <StackBarChart
            :chart-data="chartDataStream"
            :chart-labels="chartLabelsStream"
          />
        </div> -->
      </div>

      <div class="mt-6">
        <div>
          <esg-table />
        </div>
      </div>

      <div class="mt-8">
        <h1 class="text-4xl font-bold">Learn more</h1>
        <ul class="mt-3">
          <li
            v-for="item in items"
            :key="item.text"
            @click="setActive(item)"
            class="learnmore text-[#2C2E35] border mb-3 py-2 px-4 rounded-lg flex items-center justify-between"
          >
            <p :class="{ active: item.isActive }" class="-ml-2">
              {{ item.overText || item.text }}
            </p>
            <img class="cursor-pointer" src="@/assets/images/ButtonArrow.svg" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import EsgTable from "../../../components/imm-components/tables/esg-table.vue";
import ProgressBarChart from "../../../components/charts/esgProgressBarChart.vue";
import EsgPortfolioLineChart from "../../../components/charts/EsgPortfolioLineChart.vue";
import EsgMultipleBarChart from "../../../components/charts/esgMultipleBarChart.vue";

export default {
  name: "ImmEsgPage",
  data() {
    return {
      progressDataOne: [
        { companyName: "Company name", percentage: 60, color: "#FFDE60" },
        { companyName: "Company name", percentage: 80, color: "#4DC9BE" },
        { companyName: "Company name", percentage: 40, color: "#43467C" },
      ],
      progressDataTwo: [
        { companyName: "Company name", percentage: 10, color: "#FFDE60" },
        { companyName: "Company name", percentage: 10, color: "#4DC9BE" },
        { companyName: "Company name", percentage: 20, color: "#43467C" },
      ],
      fixedComprehensiveSection: false,
      isComprehensiveTableVisible: true,
      esgFixedResults: false,
      esgResults: true,
      chartData: [
        {
          label: "Scope 1",
          backgroundColor: "#3284CB",
          stack: "emissions",
          data: [40, 20, 20, 10],
          barThickness: 32,
          borderRadius: 10,
        },
        {
          label: "Scope 2",
          backgroundColor: "#FFDE60",
          stack: "emissions",
          data: [10, 20, 20, 45],
          barThickness: 32,
          borderRadius: 10,
        },
        {
          label: "Scope 3",
          backgroundColor: "#4DC9BE",
          stack: "emissions",
          data: [10, 15, 10, 25],
          barThickness: 32,
          borderRadius: 10,
        },
        {
          label: "Targets",
          backgroundColor: "#fff",
          stack: "emissions",
          data: [10, 10, 20, 10],
          barThickness: 32,
          borderRadius: 10,
          borderWidth: 1,
          borderDash: [2, 2],
          borderColor: "rgba(0, 0, 0, 0.5)",
        },
      ],
      chartLabels: ["2020", "2021", "2022", "2023"],
      chartDataStream: [
        {
          label: "Graduated",
          backgroundColor: "#43467C",
          stack: "emissions",
          data: [60, 50, 40, 30],
          barThickness: 20,
          borderRadius: 50,
        },
        {
          label: "Quitted",
          backgroundColor: "#FFDE60",
          stack: "emissions",
          data: [30, 35, 40, 45],
          barThickness: 20,
          borderRadius: 50,
        },
      ],
      chartLabelsStream: ["2020", "2021", "2022", "2023"],
      categories: [
        { category: "Environmental", percentage: "90%" },
        { category: "Social", percentage: "96%" },
        { category: "Governance", percentage: "85%" },
        { category: "Total", percentage: "85%" },
      ],
      items: [
        { text: "Environmental", isActive: false, overText: "" },
        { text: "Social", isActive: false, overText: "" },
        { text: "Governance", isActive: false, overText: "" },
      ],
      activeItem: "ESG",
    };
  },
  components: {
    BreadCrumbs,
    EsgTable,
    ProgressBarChart,
    EsgPortfolioLineChart,
    EsgMultipleBarChart,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setBreadcrumbs("");
  },
  methods: {
    handleScroll() {
      if (this.$refs.comprehensiveSection.getBoundingClientRect().top <= 122) {
        this.fixedComprehensiveSection = true;
        this.esgFixedResults = false;
      } else {
        this.fixedComprehensiveSection = false;
      }
      if (this.$refs.esgSection.getBoundingClientRect().top <= 122) {
        this.fixedComprehensiveSection = false;
        this.esgFixedResults = true;
      } else {
        this.esgFixedResults = false;
      }
    },
    getDashOffset(percentage) {
      const value = parseInt(percentage, 10);
      return `calc(400 - (400 * ${value}) / 100)`;
    },
    setActive(item) {
      this.items.forEach((prevItem) => {
        prevItem.isActive = false;
        prevItem.overText = "";
      });
      item.isActive = true;
      if (item.text !== "Overall") {
        item.overText = "Overall";
      }
      this.activeItem = item;
      this.setBreadcrumbs(this.activeItem.text);
    },
    setBreadcrumbs(activeItem) {
      const currentName = activeItem;
      const breadcrumbs = [
        { name: "Dashboard", url: "/imm/landing-page" },
        { name: "Investor page", url: "/imm/Investor-page" },
        { name: "Company profile", url: "/imm/Investor-page/company-profile" },
        { name: "ESG", url: "/imm/Investor-page/company-profile/esg" },
      ];

      if (currentName) {
        breadcrumbs.push({
          name: currentName,
          url: `/imm/Investor-page/company-profile/esg/${currentName}`,
        });
      }
      this.$store.commit("setNewBreadcrumbs", breadcrumbs);
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .fixed-header {
    position: fixed;
    width: 91.6%;
    margin-top: 64px;
    padding: 20px 0;
    z-index: 10;
    transition: top 0.3s ease;
    display: flex;
    justify-content: space-between;
    height: 90px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 68%,
      rgba(255, 255, 255, 0) 83%
    );
  }

  .is-fixed {
    top: 0;
  }

  margin-left: 80px;
  margin-right: 80px;

  .breadcrumbs-main-container {
    margin-top: 100px;
  }

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .sales-table-section {
  }

  .statement-table-section {
    .statement-content-section {
      .statement-heading {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;

        img {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }

      .metrices-section {
        .charts-section {
          .line-chart-section {
            border: 1px solid #e1e3e9;
            border-radius: 16px;

            .line-chart-heading {
              //styleName: Body2 Bold;
              font-family: Arial;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #2c2e35;
            }
          }
        }
      }
    }
  }
}

.fixed-header {
  position: fixed;
  width: 91.6%;
  margin-top: 64px;
  padding: 20px 0;
  z-index: 10;
  transition: top 0.3s ease;
  display: flex;
  justify-content: space-between;
  height: 90px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 255, 255, 0) 83%
  );
}

.is-fixed {
  top: 0;
}

.download-button {
  button {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    border: 1px solid #191b45;
    gap: 6px;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #191b45;
  }
}

.bar-chart-section {
  .filter-options-section {
    gap: 12px;

    .filter-options {
      height: 32px;
      background: #f4f6f9;
      border-radius: 8px;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #9ea4ba;

      div {
        padding: 6px 12px 6px 12px;
      }

      .active {
        background: #2177c1;
        border-radius: 8px;
        color: #ffffff;
        padding: 5px 20px;
      }
    }

    .select-year {
      select {
        height: 32px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
        color: #2c2e35;
      }
    }
  }
}

.chart-title {
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.chart-subtitle {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.progress-ring__circle {
  stroke-dasharray: 400, 400;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.learnmore {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}
</style>
