

<template>
  <div class="static assementPageStyle">
    <div class="container"
      v-if="initialCompanyData && initialCompanyData.assesments && comapnyDetails && comapnyDetails[0] && comapnyDetails.length > 0">
      <!-- PDF First Page -->
      <div class="title-wrapper ">
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper" v-if="comapnyDetails && comapnyDetails[0] && comapnyDetails.length > 0">
            <div class="title">
              <div class="org_profile_image" v-if="comapnyDetails[0] &&
                comapnyDetails[0].logo &&
                comapnyDetails[0].logo[0].url
              ">
                <img :src="comapnyDetails[0].logo[0].url" alt="dfd" crossorigin="anonymous"/>
              </div>
              <h1>
                {{ comapnyDetails[0].organizationName }}'s 2X Assessment
              </h1>
            </div>
          </div>
        </div>
        <div class="AssementPageWrepper">
          <!-- Overview Block -->
          <div class="OverviewBlock">
            <div class="OverviewTitle">
              <h2>Overview</h2>
              <span></span>
            </div>
            <div class="spiderchartEligibleWrepper">
              <div class="Xeligibleconten">
                <div class="EligibleListWrepper">
                  <div class="EligibleListImg bigImg">


                    <div v-if="(comapnyDetails[0].type == 'funds' && comapnyDetails[0].myPortfolio != 'I will directly input aggregated information now' && !comapnyDetails[0].isPortfolioAvailable)">
                      <img style="border-radius:0" src="@/assets/images/help_white.svg" alt="background-image" />
                    </div>
                    <div class="progress-wrap" v-else-if="twoxEligible != -1">
                      <progress-bar :options="comapnyDetails[0].eligible2X && (comapnyDetails[0].eligible2X === 'Yes' ||
                      comapnyDetails[0].eligible2X === 'true') ? options1 : options2" :value="
                        twoxEligible | mathOnlyRound
                      " style="width: 40px; height: 40px; line-height: 0" />
                    </div>

                  </div>
                  <div class="EligibleTitle">
                    <h3
                      :class="{ 'gray-color': (comapnyDetails[0].type == 'funds' && comapnyDetails[0].myPortfolio != 'I will directly input aggregated information now'  && !comapnyDetails[0].isPortfolioAvailable) }">
                      2X Eligible</h3>
                  </div>
                </div>
                <div class="EligibleListWrepper">
                  <div class="EligibleListImg">

                    <div style="position: relative;" v-if="entrepreneurship != -1">
                      <div style="position: relative;top:6px;"  class="bigImg" v-if="entrepreneurship == 'NA'">
                        <img src="@/assets/images/NA-SVG.svg" alt="background-image" />
                      </div>
                      <div class="progress-wrap"  v-else>

                      <progress-bar
                        :options="comapnyDetails[0].entrepreneurshipCriteriaMet && (comapnyDetails[0].entrepreneurshipCriteriaMet === '1') ? options1 : options2"
                        :value="
                          entrepreneurship | mathOnlyRound
                        " style="width: 40px; height: 40px; line-height: 0" />
                      </div>
                    </div>
                  </div>
                  <div class="EligibleTitle">
                    <img src="@/assets/images/EligibleIcon1.png" alt="" />
                    <p>Entrepreneurship</p>

                  </div>
                </div>
                <div class="EligibleListWrepper">
                  <div class="EligibleListImg">

                    <span v-if="leadership != -1">

                      <div style="position: relative;top:3px" v-if="leadership == 'NA'"  class="bigImg">
                        <img src="@/assets/images/NA-SVG.svg" alt="background-image" />
                      </div>
                      <div class="progress-wrap" v-else>

                      <progress-bar 
                        :options="comapnyDetails[0].leadershipCriteriaMet && (comapnyDetails[0].leadershipCriteriaMet === '1') ? options1 : options2"
                        :value="
                          leadership | mathOnlyRound
                        " style="width: 40px; height: 40px; line-height: 0" />
                      </div>

                      </span>
                   
                  </div>
                  <div class="EligibleTitle">
                    <img src="@/assets/images/EligibleIcon2.png" alt="" />
                    <p>Leadership</p>

                  </div>
                </div>
                <div class="EligibleListWrepper">
                  <div class="EligibleListImg">
                    <!-- <img src="@/assets/images/Rightcircle.svg" style="margin-left:-3px !important"
                        alt="background-image" v-if="comapnyDetails[0].employmentCriteriaMet === '1'" />
                      <img style="border-radius:0" src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                        v-else /> -->
                        <div class="progress-wrap"  v-if="employment != -1">
                      <progress-bar
                        :options="comapnyDetails[0].employmentCriteriaMet && (comapnyDetails[0].employmentCriteriaMet === '1') ? options1 : options2"
                        :value="
                          employment | mathOnlyRound
                        " style="width: 40px; height: 40px; line-height: 0" />
                      <!-- <vue-circle :progress="employment | mathOnlyRound" :size="50" :reverse="false"
                              line-cap="round"
                              :fill="comapnyDetails[0].employmentCriteriaMet && (comapnyDetails[0].employmentCriteriaMet === '1') ? fill1 : fill2"
                              empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                              :show-percent="true"> 
                            </vue-circle>-->
                    </div>
                  </div>
                  <div class="EligibleTitle">
                    <img src="@/assets/images/EligibleIcon.3.png" alt="" />
                    <p>Employment</p>

                  </div>
                </div>
                <div class="EligibleListWrepper">
                  <div class="EligibleListImg" :class="
                    comapnyDetails[0].type != 'funds' ? 'lastAffter' : ''
                  ">

                    <div class="progress-wrap" v-if="consumption != -1">
                      <progress-bar
                        :options="comapnyDetails[0].consumptionCriteriaMet && (comapnyDetails[0].consumptionCriteriaMet === '1') ? options1 : options2"
                        :value="consumption | mathOnlyRound
                        " style="width: 40px; height: 40px; line-height: 0" />

                    </div>
                  </div>
                  <div class="EligibleTitle">
                    <img src="@/assets/images/EligibleIcon.4.png" alt="" />
                    <p>Consumption</p>

                  </div>
                </div>
                <div class="EligibleListWrepper" v-if="comapnyDetails[0].type == 'funds'">
                  <div class="EligibleListImg lastAffter">


                    <div style="position: relative;"
                      v-if="(comapnyDetails[0].type == 'funds' && comapnyDetails[0].myPortfolio != 'I will directly input aggregated information now' && !comapnyDetails[0].isPortfolioAvailable)"
                      class="bigImg">
                      <img style="border-radius:0" src="@/assets/images/help_white.svg" alt="background-image" />
                    </div>
                    <div class="progress-wrap" v-else-if="indirectInvestment != -1">
                      <progress-bar
                        :options="comapnyDetails[0].indirectInvestmentCriteriaMet && indirectInvestment != 0 && (comapnyDetails[0].indirectInvestmentCriteriaMet === '1') ? options1 : options3"
                        :value="indirectInvestment | mathOnlyRound
                        " style="width: 40px; height: 40px; line-height: 0" />
                      <!-- <vue-circle :progress="indirectInvestment | mathOnlyRound" :size="50" :reverse="false"
                              line-cap="round"
                              :fill="comapnyDetails[0].indirectInvestmentCriteriaMet && (comapnyDetails[0].indirectInvestmentCriteriaMet === '1') ? fill1 : fill2"
                              empty-fill="rgba(0, 0, 0, .1)" :animation-start-value="0.0" :start-angle="-1.5" insert-mode="append" :thickness="6"
                              :show-percent="true">
                            </vue-circle> -->
                    </div>

                  </div>
                  <div class="EligibleTitle">
                    <img src="@/assets/images/EligibleIcon.5.png" alt="" />
                    <p
                      :class="{ 'gray-color': (comapnyDetails[0].type == 'funds' && comapnyDetails[0].myPortfolio != 'I will directly input aggregated information now' && !comapnyDetails[0].isPortfolioAvailable) }">
                      Indirect Investment</p>
                  </div>
                </div>
                <div class="BorderLine"></div>
                <div class="PartyVerifiedToolTip">
                  <div class="PartyTooltipImg"
                    v-if="(comapnyDetails[0].modTwo != '' || comapnyDetails[0].modTwo != false) && comapnyDetails[0].type == 'companies'">
                    <img src="@/assets/images/Rightcircle.svg" alt="background-image"
                      style="margin-left:-3px !important;position: relative;" v-if="
                      comapnyDetails[0].twoXIntentions === 'Yes' ||
                      comapnyDetails[0].twoXIntentions === 'true' ||
                      comapnyDetails[0].twoXIntentions == 'TRUE'" />

                    <img style="border-radius:0;position: relative;"
                      src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else />
                  </div>
                  <div class="PartyTooltipImg" v-else-if="(comapnyDetails[0].modTwo != '' ||
                  comapnyDetails[0].modTwo != false) && comapnyDetails[0].type == 'funds'">
                    <img src="@/assets/images/Rightcircle.svg" alt="background-image"
                      style="margin-left:-3px !important;position: relative;" v-if="
                        comapnyDetails[0].twoXIntentions === 'Yes' ||
                        comapnyDetails[0].twoXIntentions === 'true' ||
                        comapnyDetails[0].twoXIntentions == 'TRUE'
                      " />

                    <img style="border-radius:0;position: relative;"
                      src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else />
                  </div>
                  <div class="PartyTooltipImg" v-else>
                    <img style="border-radius:0;width:20px;position: relative;"
                      src="@/assets/images/help_white.svg" alt="background-image" />
                  </div>
                  <div class="tooltip TooltipImgText">
                    <p>Plans to improve 2X Criteria</p>
                  </div>
                </div>
                <div class="BorderLine"></div>
                <div class="PartyVerifiedToolTip">
                  <div class="PartyTooltipImg">
                    <img src="@/assets/images/Rightcircle.svg"
                      style="width: 24px;height: 24px;margin: auto 0;position: relative;margin-left: 5px !important"
                      alt="background-image" v-if="comapnyDetails[0] && comapnyDetails[0].dFIOr3rdpartyvalidator &&
                        (comapnyDetails[0].dFIOr3rdpartyvalidator == 'Yes' ||
                          comapnyDetails[0].dFIOr3rdpartyvalidator == 'true')
                      " />
                    <img src="@/assets/images/3rdPartyVerified.svg"
                      style="width: 24px;height: 24px;margin: auto 0;position: relative;border-radius: 0; margin-left: 5px !important"
                      alt="background-image" v-else />
                  </div>
                  <div class="tooltip TooltipImgText">
                    <p>3rd Party Verified</p>
                  </div>
                </div>
              </div>
              <!-- spiderchart -->
              <div class="spiderchartContent" id="chart">
                <div class="z-20 relative">
                  <repeatSpiderchart v-if="comapnyDetails.length == 2" :iso="comapnyDetails[0].countryHq.iso"
                    :paddingL="40" :paddingR="40" :paddingT="40" :paddingB="40" :countData="countData"
                    :details="comapnyDetails[0]" :detailsOld="comapnyDetails[1]" class="z-10 overflow-visible"
                    style="position: relative; height:100%; width: 100%">
                  </repeatSpiderchart>
                  <spiderchart v-else :iso="comapnyDetails[0].countryHq.iso" :paddingL="40" :paddingR="40"
                    :paddingT="40" :paddingB="40" :details="comapnyDetails[0]" :countData="countData"
                    class="z-10 overflow-visible" style="position: relative; height:100%; width: 100%"></spiderchart>
                  <template v-for="(color, taab) in taabs">
                    <!--Leadership-->
                    <div v-if="color === '#2BD4C4'" class="Ind_Inv_Icon" :class="
                      comapnyDetails[0].type == 'companies'
                        ? 'leadership-taab-company'
                        : 'leadership-taab-fund'
                    " id="2uur" v-bind:key="taab.name" style="color: #2bd4c4" @click="openContextualAnalysis(taab)">
                      <img src="../assets/images/LeadershipIcon.svg" alt="Indirect Investment" />
                      <span> {{ taab }}</span>
                    </div>

                    <!--Entrepreneurship-->
                    <div v-if="color === '#0077C8'" class="Ind_Inv_Icon" id="8uur" :class="
                      comapnyDetails[0].type == 'companies'
                        ? 'entrepreneurship-taab-company'
                        : 'entrepreneurship-taab-fund'
                    " v-bind:key="taab.name" style="color: #0077c8" @click="openContextualAnalysis(taab)">
                      <img src="../assets/images/EntrepreneurshipIcon.svg" alt="Indirect Investment" />
                      <span> {{ taab }}</span>
                    </div>

                    <!--Consumption-->
                    <div v-if="color === '#F5D10F'" class="Ind_Inv_Icon" id="10uur" :class="
                      comapnyDetails[0].type == 'companies'
                        ? 'consumption-taab-company'
                        : 'consumption-taab-fund'
                    " v-bind:key="taab.name" style="color: #f5d10f" @click="openContextualAnalysis(taab)">
                      <img src="../assets/images/ConsumptionIcon.svg" alt="Indirect Investment" />
                      <span> {{ taab }}</span>
                    </div>
                    <!--Employment-->
                    <div v-if="color === '#FE5000'" class="Ind_Inv_Icon" id="12uur" :class="
                      comapnyDetails[0].type == 'companies'
                        ? 'employment-taab-company'
                        : 'employment-taab-fund'
                    " v-bind:key="taab.name" style="color: #fe5000" @click="openContextualAnalysis(taab)">
                      <img src="../assets/images/EmploymentIcon.svg" alt="Indirect Investment" />
                      <span>{{ taab }}</span>
                    </div>

                    <!--Indirect Investment-->
                    <div v-if="
                      color === 'red' && comapnyDetails[0].type == 'funds'
                    " class="Ind_Inv_Icon Indirect_Investment" id="12uur" v-bind:key="taab.name" style="color: #201747"
                      @click="openContextualAnalysis(taab)">
                      <img src="../assets/images/2X-assessment/IndirectInvestmentA.svg" alt="Indirect Investment" />
                      <span>{{ taab }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="AssementPageWrepper">
          <div class="Profile_Criteria_Wrepper">
            <!-- profile Block -->
            <div class="Profile_CountryWrepper">
              <div class="Profile_Block">
                <div class="OverviewTitle">
                  <h2>Profile</h2>
                  <span></span>
                </div>
                <!-- details in list -->
                <div class="Profile_blockListText">
                  <div class="About_CompanyListText" v-if="
                    comapnyDetails &&
                    comapnyDetails.length > 0 &&
                    comapnyDetails[0].aboutTheCompany
                  ">
                    <b>About the Organization:</b>
                    <br />
                    {{ comapnyDetails[0].aboutTheCompany }}
                  </div>
                  <div class="About_CompanyListText" v-if="
                    comapnyDetails &&
                    comapnyDetails.length > 0 &&
                    comapnyDetails[0]
                      .objectivesonGenderEqualityandWomenEmpowerment
                  ">
                    <b>Gender Equality Objectives:</b><br />
                    {{
                        comapnyDetails[0]
                          .objectivesonGenderEqualityandWomenEmpowerment
                    }}
                  </div>
                  <div class="About_CompanyListText" style="display: flex" v-if="
                    comapnyDetails &&
                    comapnyDetails.length > 0 &&
                    (comapnyDetails[0].website ||
                      comapnyDetails[0].linkedin ||
                      comapnyDetails[0].twitter)
                  ">
                    <a :href="comapnyDetails[0].website" target="_blank" v-if="comapnyDetails[0].website">
                      <img src="@/assets/images/Earth.png" alt="Earth" />
                    </a>
                    <a :href="comapnyDetails[0].linkedin" target="_blank" v-if="comapnyDetails[0].linkedin">
                      <img src="@/assets/images/linkedin.png" alt="Linkedin" />
                    </a>
                    <a :href="comapnyDetails[0].twitter" target="_blank" v-if="comapnyDetails[0].twitter">
                      <img src="@/assets/images/twitter.png" alt="Twitter" />
                    </a>
                  </div>
                  <ul>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].contactName
                    ">
                      <b>Organization Contact Name:</b>
                      {{ comapnyDetails[0].contactName }}
                    </li>
                    <!-- <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].contactEmail
                    ">
                      <b>Organization Email:</b>
                      {{ comapnyDetails[0].contactEmail }}
                    </li>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].contactPhone
                    ">
                      <b>Organization Contact no:</b>
                      {{ comapnyDetails[0].contactPhone }}
                    </li> -->
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].authorizingAgentandContactSame
                    ">
                      <b>Authorizing Agent:</b>
                      {{
                          comapnyDetails[0].authorizingAgentandContactSame ==
                            "Yes" && comapnyDetails[0].type == "companies"
                            ? comapnyDetails[0].contactName
                            : comapnyDetails[0].authorizingAgentName
                      }}
                    </li>
                    <!-- <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].authorizingAgentandContactSame
                    ">
                      <b>Agent Contact no:</b>
                      {{
                          comapnyDetails[0].authorizingAgentandContactSame ===
                            "Yes"
                            ? comapnyDetails[0].contactPhone
                            : comapnyDetails[0].authorizingAgentPhone
                      }}
                    </li>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].authorizingAgentandContactSame
                    ">
                      <b>Agent Email:</b>
                      {{
                          comapnyDetails[0].authorizingAgentandContactSame ===
                            "Yes"
                            ? comapnyDetails[0].contactEmail
                            : comapnyDetails[0].authorizingAgentEmail
                      }}
                    </li> -->
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].sector &&
                      comapnyDetails[0].type === 'companies'
                    ">
                      <b>Organization Sector:</b>
                      {{ comapnyDetails[0].sector }}
                    </li>
                    <li style="word-break: break-word" v-if="
                        comapnyDetails &&
                        comapnyDetails.length > 0 &&
                        comapnyDetails[0].sector && comapnyDetails[0].type === 'funds'
                      ">
                        <b>Organization Sector:</b>
                        {{ sectorAry[0].name }}
                      </li>

                      <li style="word-break: break-word" v-if="
                        comapnyDetails &&
                        comapnyDetails.length > 0 &&
                        comapnyDetails[0].sector && comapnyDetails[0].type === 'funds'
                      ">
                        <b>Sectors Invested in:</b>
                        {{ fundsSector }}
                      </li>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0].countryHq.countryName
                    ">
                      <b>Organization Country:</b>
                      {{ comapnyDetails[0].countryHq.countryName }}
                    </li>
                    <li v-if="
                      comapnyDetails[0].whatKindOfSupportAreYouLookingFor &&
                      comapnyDetails[0].whatKindOfSupportAreYouLookingFor
                        .length > 0
                    ">
                      <b style="width: 205px">
                        What kind of support are you looking for :</b>
                      {{ whatKindOfSupport }}
                    </li>
                  </ul>
                  <br>
                  <ul>
                    <li v-if="
                      comapnyDetails[0].interestInFunding &&
                      comapnyDetails[0].interestInFunding.length > 0
                    ">
                      <b>Types of funding interest:</b>
                      {{ fundingInterests }}
                    </li>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0]
                        .amountOfCurrentFundingDesired
                    ">
                      <b>Investment Sought $</b>
                      ${{
                          comapnyDetails[0]
                            .amountOfCurrentFundingDesired | numberWithCommas
                      }}
                    </li>
                    <li v-if="
                      comapnyDetails &&
                      comapnyDetails.length > 0 &&
                      comapnyDetails[0]
                        .dateRoundCloses
                    ">
                      <b>Round closing:</b>
                      {{
                          new Date(comapnyDetails[0]
                            .dateRoundCloses).toLocaleString().split(",")[0]
                      }}
                    </li>
                    <li v-if="comapnyDetails[0].averageTicketSizeForInvestors">
                      <b>Ticket size $:</b>
                      ${{ comapnyDetails[0].averageTicketSizeForInvestors | numberWithCommas }}
                    </li>
                  </ul>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END PDF First Page -->

      <transition name="fadeIn" mode="out-in" class="html2pdf__page-break">
        <!-- <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div> -->

        <div id="loaded" v-if="endofcreated && comapnyDetails && comapnyDetails.length > 0" key="content">
          <!-- Assement Page Wrepper -->

          <div class="AssementPageWrepper">
            <!-- Overview Block -->

            <div class="Profile_Criteria_Wrepper esg-pre-screening">
              <!-- profile Block -->

              <!-- 2xCriteria Block -->
              <div class="barchart2xCriteria_Wrepper">
                <!-- entrepreneurship barchart start -->
                <div>
                  <div class="row">
                    <div class="">
                      <div class="ChartBarContentWrepper">
                        <div class="OverviewTitle" style="width: 1100px;">
                          <h2>2X Criteria</h2>
                          <span></span>
                        </div>
                        <div class="CriteriaTitleText">
                          <img src="../assets/images/EligibleIcon1.png" alt="" />
                          <p>Entrepreneurship</p>
                        </div>
                        <div class="Chart_wrapper flex" style="width: 1100px">
                          <div v-if="comapnyDetails[0].organizationType === 'Fund'" class="mx-auto">
                            <div v-if="country" class="ChartBarContent">
                              <div class="ChartBarPercentageText">% Carry Allocation to Women</div>
                              <b v-if="comapnyDetails[0].percentShares == '' || isNaN(comapnyDetails[0].percentShares)">
                                Not Applicable
                              </b>
                              <barChartVerticalFund v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300" :height="400"
                                label1="Women" label2="Men" :totalValue="[Number(100),Number(100)]" :filledValue="[
                                                    Number(
                                                      (comapnyDetails[0].percentShares * 100)
                                                    ),comapnyDetails[1] ? Number(
                                                      comapnyDetails[1]
                                                        .percentShares*100
                                                    ) : '']">
                              </barChartVerticalFund>
                              <!-- <assessmentPiechart style="margin-left: -33px" :width="500" :height="500" :sectorLabels="['Women', 'Men']"
                                :totalValue="[
                                                  Math.round(
                                                    (comapnyDetails[0].percentShares* 100)
                                                  ),
                                                  Math.round(
                                                    100 - (comapnyDetails[0].percentShares* 100)
                                                  ),
                                                ]"></assessmentPiechart> -->
                            </div>
                          </div>
                          <div v-else class="m-auto">
                        
                            <div v-if="country" class="ChartBarContent">
                              <div class="ChartBarPercentageText">Women's Shares</div>
                              <b
                                v-if="(comapnyDetails[0].whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization == '0' ||  comapnyDetails[0].whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization == '0.0') && (comapnyDetails[0].whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen == '0' || comapnyDetails[0].whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen == '0.0')">
                                Not Applicable
                              </b>
                              <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left:-33px;" :width="300" :height="400"
                                label1="Women" label2="Men" :totalValue="[
                                                Number(
                                                  comapnyDetails[0]
                                                    .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization
                                                ),
                                                comapnyDetails[1] ? Number(
                                                  comapnyDetails[1]
                                                    .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization
                                                ) : '']
                                              " :filledValue="[
                                                Number(
                                                  comapnyDetails[0]
                                                    .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen
                                                ),
                                                comapnyDetails[1] ? Number(
                                                  comapnyDetails[1]
                                                    .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen
                                                ) : '']
                                              ">
                              </barChartVertical>
                            </div>
                          </div>
                          <!-- companies  -->
                          <div class="ChartBarContent mx-auto" v-if="
                            country &&
                            comapnyDetails[0].type === 'companies'
                          ">
                            <div v-if="
                              country &&
                              comapnyDetails[0].type === 'companies'
                            ">
                              <div>
                                <div class="Founded_WomanText">
                                  Founded by a woman
                                </div>
                                <div class="Founded_WomanImg">
                                  <img src="@/assets/images/Rightcircle.svg"
                                    style="width: 24px;height: 24px;margin: auto 0;position: relative;display: inline-flex;"
                                    alt="background-image" v-if="
                                      comapnyDetails[0].foundedByAWoman ===
                                      'Yes'
                                    " />
                                  <img src="@/assets/images/3rdPartyVerified.svg"
                                    style="width: 24px;height: 24px;margin: auto 0;position: relative;display: inline-flex;"
                                    alt="background-image" v-else />
                                  <p>{{ comapnyDetails[0].foundedByAWoman == 'Yes' ? 'Yes' : 'No' }}</p>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                          <!-- funds  -->
                          <!-- numberBusinessesTheFundFiOnLendingOrganizationHasInExistingPortfolioThatMeets2XCriteriaThatItsInvesteeWasFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrg1b -->
                          <div class="mx-auto" style="width: 330px;" v-if="comapnyDetails[0] && comapnyDetails[0].type &&
                            country && comapnyDetails[0].type !== 'companies'
                          ">
                            <div class="">
                              <div class="Founded_WomanText">
                                Founded by a woman
                              </div>
                              <div class="Founded_WomanImg flex">
                                <img src="@/assets/images/Rightcircle.svg"
                                  style="width: 24px;height: 24px;margin: auto 0;position: relative;"
                                  alt="background-image" v-if="
                                    comapnyDetails[0].foundedByAWoman === 'Yes'
                                  " />
                                <img src="@/assets/images/3rdPartyVerified.svg"
                                  style="width: 24px;height: 24px;margin: auto 0;position: relative;"
                                  alt="background-image" v-else />
                                <p>{{ comapnyDetails[0].foundedByAWoman }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="ChartBarContentWrepper">
                        <div class="CriteriaTitleText">
                          <img src="../assets/images/EligibleIcon2.png" alt="" />
                          <p>Leadership</p>
                        </div>
                        <div class="Chart_wrapper flex" style="width: 1100px">
                          <div v-if="country" class="ChartBarContent mx-auto">
                            <div class="ChartBarPercentageText">
                              Women Senior Managers
                            </div>

                            <b v-if="(comapnyDetails[0].whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners == '0' ||  comapnyDetails[0].whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners == '0.0') && (comapnyDetails[0].whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners == '0' || comapnyDetails[0].whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners == '0.0')">
                              Not Applicable
                            </b>
                            <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="300"
                              :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[
                                Number(
                                  comapnyDetails[0]
                                    .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners
                                ),
                                comapnyDetails[1]
                                  ? Number(
                                    comapnyDetails[1]
                                      .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners
                                  )
                                  : '',
                              ]" :filledValue="[
                                Number(
                                  comapnyDetails[0]
                                    .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners
                                ),
                                comapnyDetails[1]
                                  ? Number(
                                    comapnyDetails[1]
                                      .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners
                                  )
                                  : '',
                              ]">
                            </barChartVertical>
                          </div>
                          <div v-if="country" class="ChartBarContent mx-auto">
                            <div class="ChartBarPercentageText">
                              Women Board Members
                            </div>
                            <b v-if="(comapnyDetails[0].howManyBoardMembersOrInvestmentCommitteeMembersAreThere == '0' ||  comapnyDetails[0].howManyBoardMembersOrInvestmentCommitteeMembersAreThere == '0.0') && (comapnyDetails[0].howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere == '0' || comapnyDetails[0].howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere == '0.0')">
                              Not Applicable
                            </b>
                            <barChartVertical v-else :chartLabels="assessmentYear" style="margin-left: -33px" :width="430"
                              :height="400" type="leadership" label1="Women" label2="Men" :totalValue="[
                                Number(
                                  comapnyDetails[0]
                                    .howManyBoardMembersOrInvestmentCommitteeMembersAreThere
                                ),
                                comapnyDetails[1]
                                  ? Number(
                                    comapnyDetails[1]
                                      .howManyBoardMembersOrInvestmentCommitteeMembersAreThere
                                  )
                                  : '',
                              ]" :filledValue="[
                                Number(
                                  comapnyDetails[0]
                                    .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere
                                ),
                                comapnyDetails[1]
                                  ? Number(
                                    comapnyDetails[1]
                                      .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere
                                  )
                                  : '',
                              ]">
                            </barChartVertical>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- employment barchart start -->
                  <div class="ChartBarContentWrepper">
                    <div class="CriteriaTitleText">
                      <img src="@/assets/images/EligibleIcon.3.png" alt="" />
                      <p>Employment</p>
                    </div>
                    <div class="" style="">
                      <div v-if="country" class="ChartBarContent mx-auto" style="width:650px;margin:auto;">
                        <div class="ChartBarPercentageText text-center" style="font-style: normal;
                        font-weight: bold;
                        font-size: 13px;
                        line-height: 16px;
                        color: #1b1b1b;
                        margin-bottom: 10px;">
                          Women in the Workforce
                        </div>
                        <div style="font-weight: bolder;" v-if="(comapnyDetails[0].totalPtFtEmployees == '0' || comapnyDetails[0].totalPtFtEmployees == '0.0') &&
                          (comapnyDetails[0].midManagerTot == '0' || comapnyDetails[0].midManagerTot == '0.0') && 
                          (comapnyDetails[0].ftEmployees == '0' || comapnyDetails[0].ftEmployees == '0.0') &&
                          (comapnyDetails[0].ptEmployees == '0' || comapnyDetails[0].ptEmployees == '0.0') &&
                          (comapnyDetails[0].howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness == '0' || comapnyDetails[0].howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness == '0.0') && 
                          (comapnyDetails[0].howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization == '0' || comapnyDetails[0].howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization == '0.0')" class="mt-10 mb-10 text-center">
                            Not Applicable
                         </div>
                        <employmentBarChart 
                          v-else
                          :chartData="comapnyDetails" 
                          :assessmentYear="assessmentYear"
                          class="employment_bar_chart"
                          :chartLabels="['Total','Middle Managers','FT employees', 'PT employees','Contractual', 'Supplier']">
                        </employmentBarChart>
                      </div>

                      <div class="ChartBarContent mx-auto">

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="AssementPageWrepper">
                <div :class="comapnyDetails[0]
                      .policies &&
                    comapnyDetails[0]
                      .policies
                      .length > 0 ? 'esg-pre-screening' : ''" v-if="comapnyDetails.length == 1">
                  <div class="ChartBarContent mx-auto">
                    <div class="CriteriaTitleText myClass">
                      <p>
                        Employment Quality Policies & Programs
                      </p>
                      <p style="padding-left:10px">{{ assessmentYear[0] }}</p>
                    </div>
                    <div class="Consumption_ListText" style="width: 1100px">
                      <p></p>
                      <ul class="list-disc">
                      
                        <li v-for="(employment_data) in comapnyDetails[0]
                        .policies" :key="employment_data.name+2" v-show="employment_data?.type !='Other'">

                          <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                            style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                          <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                            style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                          {{ employment_data.name }}
                        </li>
                        <li v-for="(employment_data) in comapnyDetails[0]
                        .policies" :key="employment_data.name+1" v-show="employment_data.flag && employment_data?.type =='Other' ">
                          <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                            style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                          <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                            style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                          {{ employment_data.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="comapnyDetails[1] &&
                    comapnyDetails[1]
                      .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                    comapnyDetails[1]
                      .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization
                      .length > 0
                  " class="ChartBarContent mx-auto" style="margin-top:2.5rem !important">
                    <div class="Quality_EmploymentText">
                      <p>{{ assessmentYear[1] }}</p>
                      <ul class="list-disc">
                        <li
                          v-for="(employment_data, index) in comapnyDetails[1]
                          .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization"
                          :key="index">
                          <img src="@/assets/images/Rightcircle.svg"
                            style="width: 24px;height: 24px;margin: auto 0;position: relative;"
                            alt="background-image" />
                          {{ employment_data }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div :class="comapnyDetails[0]
                      .policies &&
                    comapnyDetails[0]
                      .policies
                      .length > 0 ? 'esg-pre-screening' : ''" v-else>
                  <div v-if="
                    comapnyDetails[0]
                      .policies &&
                    comapnyDetails[0]
                      .policies
                      .length > 0
                  " class="ChartBarContent mx-auto">
                    <div class="CriteriaTitleText myClass">
                      <p>
                        Employment Quality Policies & Programs
                      </p>

                    </div>
                    <div class="Consumption_ListText" style="width: 1100px">
                      <div class="multiple_employment_results_section flex mt-5">
                        <div class="assessment_year_one_results mr-2">
                          <p>{{ assessmentYear[0] }}</p>
                          <ul class="list-disc">
                            <li v-for="(employment_data) in comapnyDetails[0]
                            .policies" :key="employment_data.name+3" v-show="employment_data?.type !='Other'">
                              <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg"
                                alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ employment_data.name }}
                            </li>
                            <li v-for="(employment_data) in comapnyDetails[0]
                            .policies" :key="employment_data.name+4" v-show="employment_data?.type =='Other'">

                              <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg"
                                alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ employment_data.name }}
                            </li>
                          </ul>
                        </div>
                        <div class="assessment_year_second_results mr-2">
                          <p>{{ assessmentYear[1] }}</p>
                          <ul class="list-disc">
                            <li v-for="(employment_data) in comapnyDetails[1]
                            .policies" :key="employment_data.name+5" v-show="employment_data?.type !='Other'">
                              <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg"
                                alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ employment_data.name }}
                            </li>
                            <li v-for="(employment_data) in comapnyDetails[1]
                            .policies" :key="employment_data.name+6" v-show="employment_data?.type =='Other'">

                              <img v-if="employment_data.flag" src="@/assets/images/Rightcircle.svg"
                                alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ employment_data.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style="max-width: 1100px">
                <div class="AssementPageWrepper">
                  <div class="esg-pre-screening">
                    <div class="CriteriaTitleText myClass">
                      <img src="@/assets/images/EligibleIcon.4.png" alt="" />
                      <p>
                        {{ comapnyDetails[0].type == 'funds' ? 'Gender Lens' : 'Consumption' }}
                      </p>
                    </div>

                    <div class="Consumption_ListText mt-10" style="width: 1100px">
                      <div class="multiple_consumption_results_section flex">
                        <div class="assessment_year_one_results mr-2">
                          <p> {{ assessmentYear[0] }}</p>
                          <ul class="list-disc">
                            <li v-for="(consumption) in comapnyDetails[0]
                            .consumptionList" :key="consumption.name+1" >
                              <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ consumption.name }}
                            </li>

                            <!-- <li v-for="(consumption) in comapnyDetails[0]
                            .consumptionList" :key="consumption.name+2" v-show="!consumption.flag">
                              <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ consumption.name }}
                            </li> -->
                          </ul>
                        </div>
                        <div class="assessment_year_second_results mr-2" v-if="comapnyDetails.length == 2">
                          <p> {{ assessmentYear[1] }}</p>
                          <ul class="list-disc">
                            <li v-for="(consumption) in comapnyDetails[1]
                            .consumptionList" :key="consumption.name+3" >
                              <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ consumption.name }}
                            </li>
                            <!-- <li v-for="(consumption) in comapnyDetails[1]
                            .consumptionList" :key="consumption.name+4" v-show="!consumption.flag">
                              <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative;" />
                              <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                                style="width: 24px;height: 24px;margin: auto 0;position: relative; padding: 2px;" />
                              {{ consumption.name }}
                            </li> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="flex ChartBarContentWrepper" style="margin-top:4rem"
                      v-if="comapnyDetails[0].type === 'companies' && (comapnyDetails[0].howManyDirectCustomersOrClientsDoYouHave || (comapnyDetails[1] && comapnyDetails[1].howManyDirectCustomersOrClientsDoYouHave) || comapnyDetails[0].howManyPeopleCurrentlyUseYourProduct || (comapnyDetails[1] && comapnyDetails[1].howManyPeopleCurrentlyUseYourProduct))">
                      <div v-if="country" class="ChartBarContent m-auto">
                        <div class="ChartBarPercentageText" style="font-style: normal;
                          font-weight: bold;
                          font-size: 13px;
                          line-height: 16px;
                          color: #1b1b1b;
                          margin-bottom: 10px;">
                          Direct Customers
                        </div>
                        <b v-if="comapnyDetails[0].howManyDirectCustomersOrClientsDoYouHave == '0' ||  comapnyDetails[0].howManyDirectCustomersOrClientsDoYouHave == '0.0'">
                        Not Applicable
                        </b>
                        <span v-else>
                        <barChartVertical
                          v-if="comapnyDetails[0].howManyDirectCustomersOrClientsDoYouHave || (comapnyDetails[1] && comapnyDetails[1].howManyDirectCustomersOrClientsDoYouHave)"
                          :chartLabels="assessmentYear" style="margin-left:-33px;" label1="Women" label2="Men"
                          :width="300" :height="400"
                          :totalValue="[Number(comapnyDetails[0].howManyDirectCustomersOrClientsDoYouHave), comapnyDetails[1] ? Number(comapnyDetails[1].howManyDirectCustomersOrClientsDoYouHave) : '']"
                          :filledValue="
                            [Number(comapnyDetails[0].howManyOfYourDirectCustomersOrClientsAreWomen),
                            comapnyDetails[1] ? Number(comapnyDetails[1].howManyOfYourDirectCustomersOrClientsAreWomen) : '']
                          ">
                        </barChartVertical>
                      <b v-else> Not Applicable</b>
                      </span>
                      </div>
                      <div v-if="country" class="ChartBarContent m-auto">
                        <div class="ChartBarPercentageText" style="    font-style: normal;
                              font-weight: bold;
                              font-size: 13px;
                              line-height: 16px;
                              color: #1b1b1b;
                              margin-bottom: 10px;">
                          Product/ service Users
                        </div>
                        <b v-if="comapnyDetails[0].howManyPeopleCurrentlyUseYourProduct == '0' ||  comapnyDetails[0].howManyPeopleCurrentlyUseYourProduct == '0.0'">
                      Not Applicable
                      </b>
                      <span v-else>

                        <barChartVertical
                          v-if="comapnyDetails[0].howManyPeopleCurrentlyUseYourProduct || (comapnyDetails[1] && comapnyDetails[1].howManyPeopleCurrentlyUseYourProduct)"
                          :chartLabels="assessmentYear" style="margin-left:-33px;" label1="Women" label2="Men"
                          :width="300" :height="400"
                          :totalValue="[Number(comapnyDetails[0].howManyPeopleCurrentlyUseYourProduct), comapnyDetails[1] ? Number(comapnyDetails[1].howManyPeopleCurrentlyUseYourProduct) : '']"
                          :filledValue="
                            [Number(comapnyDetails[0].howManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen),
                            comapnyDetails[1] ? Number(comapnyDetails[1].howManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen) : '']
                          ">
                        </barChartVertical>
                      <b v-else> Not Applicable</b>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Indirect Investment barchart start -->
                <div class="AssementPageWrepper">
                  <div class="CriteriaTitleText myClass" v-if="comapnyDetails[0].type === 'funds'" style="margin-left:-6px;">
                    <img src="@/assets/images/2X-assessment/IndirectInvestmentB.png"
                      style="width:40px;height:40px;margin-right: 6px !important;" alt="" />
                    <p>
                      Indirect Investment
                    </p>
                  </div>  
                  <div class="Profile_Criteria_Wrepper">
                    <div class="barchart2xCriteria_Wrepper">
                      <div v-if="comapnyDetails[0].type === 'funds'">
                        <div class="IndirectInvestmentListContent">
                          <div class="IndirectContent">
                            <div class="IndirectInvWrepper">
                              <div class="IndirectInvText">
                                Do 30% or more of FI loan proceeds or funds support businesses that meet 2X direct criteria?
                              </div>
                              <!-- <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                  comapnyDetails[0]
                                    .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                  'Yes' ||
                                  comapnyDetails[0]
                                    .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                  '1'
                                " style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else style="margin: auto 0;position: relative;"/>
                                <span>{{
                                    comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30
                                      == 1 ? 'Yes' : 'No'
                                }}</span>
                              </div> -->
                              
                              <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                (comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == '1' ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 1) ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 'Yes'" style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else-if="
                                (comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == '0' ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 0) ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 'No'" style="margin: auto 0;position: relative;"/>
                                <div class="div_tooltip" v-else>
                                  <img src="@/assets/images/help_white.svg" alt="background-image" style="margin: auto 0;position: relative;"/>
                                </div>
                                <span v-if="(comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == '1' || 
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 1 ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 'Yes') || 
                                (comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == '0' ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 0 ||
                                comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 'No')">
                                  {{ 
                                    (comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == '1' ||
                                    comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 1) ||
                                    comapnyDetails[0].percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 == 'Yes' ? 'Yes' : 'No'
                                  }}
                                </span>
                                <span v-else>Unknown</span>
                              </div>
                            </div>
                            <div class="IndirectInvWrepper">
                              <div class="IndirectInvText">
                                Are 30% or more of future committed loan proceeds or funds to support businesses that meet 2X criteria?
                              </div>
                              <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                  comapnyDetails[0]
                                    .percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30 ==
                                  '1' ||
                                  comapnyDetails[0]
                                    .percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30 ==
                                  'Yes'
                                " style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else style="margin: auto 0;position: relative;"/>
                                <span>{{
                                    comapnyDetails[0].percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30
                                      == 1 ? 'Yes' : 'No'
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="IndirectContent">
                            <div class="IndirectInvWrepper">
                              <div class="IndirectInvText">
                                Do 30% or more of companies receiving funds/ on-lending FI loan support meet any of the 2X direct criteria?
                              </div>
                              <!-- <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                  comapnyDetails[0]
                                    .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                  '1' ||
                                  comapnyDetails[0]
                                    .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                  'Yes'
                                " style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else style="margin: auto 0;position: relative;"/>
                                <span>{{
                                    comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30
                                      == 1 ? 'Yes' : 'No'
                                }}</span>
                              </div> -->
                              
                              <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                (comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == '1' ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 1) ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 'Yes'" style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else-if="
                                (comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == '0' ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 0) ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 'No'" style="margin: auto 0;position: relative;"/>
                                <div class="div_tooltip" v-else>
                                  <img src="@/assets/images/help_white.svg" alt="background-image" style="margin: auto 0;position: relative;"/>
                                </div>
                                <span v-if="(comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == '1' || 
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 1 ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 'Yes') ||  
                                (comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == '0' ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 0 ||
                                comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 'No')">
                                  {{ 
                                    (comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == '1' ||
                                    comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 1) ||
                                    comapnyDetails[0].percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 == 'Yes' ? 'Yes' : 'No'
                                  }}
                                </span>
                                <span v-else>Unknown</span>
                              </div>
                            </div>
                            <div class="IndirectInvWrepper" style="margin-bottom: 177px;">
                              <!-- <div class="IndirectInvText">
                                Does the fund specifically target women’s economic empowerment as part of its investment strategy?                        
                              </div>
                              <div class="IndirectInvImg">
                                <img src="@/assets/images/Rightcircle.svg" alt="background-image" v-if="
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == '1' ||
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == 'Yes'" style="margin: auto 0;position: relative;"/>
                                <img src="@/assets/images/3rdPartyVerified.svg" alt="background-image" v-else-if="
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == '0' ||
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == 'No'" style="margin: auto 0;position: relative;"/>
                                <div class="div_tooltip" v-else>
                                  <img src="@/assets/images/help_white.svg" alt="background-image" style="margin: auto 0;position: relative;"/>
                                </div>
                                <span v-if="(comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == '1' ||
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == 'Yes') ||  (comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == '0' ||
                                comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == 'No')">
                                  {{ 
                                    comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == '1' ||
                                    comapnyDetails[0].doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus == 'Yes' ? 'Yes' : 'No'
                                  }}
                                </span>
                                <span v-else>Unknown</span>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <!-- Indirect Investment barchart end -->
            </div>
          </div>

          <div class="AssementPageWrepper">
            <!-- ESG Pre-screening -->
            <div class="esg-pre-screening">
              <div class="OverviewTitle">
                <h2>ESG Pre-screening</h2>
                <span></span>
              </div>
              <div class="GBVdetailsContent">
                <div class="overOverviewDeitail">
                  <span>Overview</span>
                  <p>
                    As part of any due diligence, in-depth environmental,
                    social, and governance (ESG) is best practice. These
                    responses below indicate a high-level pre-screening of key
                    ESG questions.
                  </p>
                </div>
                <div class="esg-pre-screening-results-section flex mt-5">
                  <div class="assessment_year_one_results mr-2" :class="comapnyDetails.length == 2 ? 'w-2/4' : ''">
                    <p>{{ assessmentYear[0] }}</p>
                    <ul>
                      <li v-if="comapnyDetails[0].eSClaims == 'Yes'">
                        <img src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Does have existing ESG claims, including sexual harassment.
                      </li>
                      <li v-else-if="comapnyDetails[0].eSClaims == ''">
                        <img src="@/assets/images/help_white.svg" alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Does not have existing ESG claims, including sexual harassment.
                      </li>
                      <li v-else>
                        <img src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        Does not have existing ESG claims, including sexual harassment.
                      </li>
                      <li>
                        <img v-if="comapnyDetails[0].genderInProcurementStrategy == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <img src="@/assets/images/help_white.svg" alt="background-image"
                          v-else-if="comapnyDetails[0].genderInProcurementStrategy == ''"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gender & procurement strategy
                      </li>
                      <li>
                        <img v-if="comapnyDetails[0].valueChainStrategiesThatPromoteEquality == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />

                        <img src="@/assets/images/help_white.svg" alt="background-image"
                          v-else-if="comapnyDetails[0].valueChainStrategiesThatPromoteEquality == ''"
                          style="width:24px;padding:2px;height:24px;position: relative;" />

                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gender & value chain strategy
                      </li>
                      <li>
                        <img v-if="comapnyDetails[0].initiativesToIncludeDisadvantagedGroups == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[0].initiativesToIncludeDisadvantagedGroups == ''"
                          class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Active initiatives to include disadvantaged groups
                      </li>
                      <li>
                        <img v-if="comapnyDetails[0].genderedApproachToCovid19 == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[0].genderedApproachToCovid19 == ''" class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gendered approach to COVID-19
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img
                          v-if="comapnyDetails[0].doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <img src="@/assets/images/help_white.svg" alt="background-image"
                          v-else-if="comapnyDetails[0].doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds == ''"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Adopt practices that improve agency, decision-making and empower women in their
                        communities/households
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img
                          v-if="comapnyDetails[0].workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <img src="@/assets/images/help_white.svg" alt="background-image"
                          v-else-if="comapnyDetails[0].workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners == ''"
                          style="width:24px;padding:2px;height:24px;position: relative;" />

                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Work with financing or implementation partners who work with women value chain partners
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img v-if="comapnyDetails[0].engageWomenInPaidIncomeOpportunitiesInSupplyChain == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[0].engageWomenInPaidIncomeOpportunitiesInSupplyChain == ''"
                          class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Engage women in paid income opportunities in supply chain
                      </li>
                    </ul>
                  </div>
                  <div class="assessment_year_second_results ml-2" v-if="comapnyDetails.length == 2">
                    <p>{{ assessmentYear[1] }}</p>
                    <ul>
                      <li v-if="comapnyDetails[1].eSClaims == 'Yes'">
                        <img src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Does have existing ESG claims, including sexual harassment.
                      </li>
                      <li v-else-if="comapnyDetails[1].eSClaims == ''">
                        <div class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        Does not have existing ESG claims, including sexual harassment.
                      </li>
                      <li v-else>
                        <img src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        Does not have existing ESG claims, including sexual harassment.
                      </li>
                      <li>
                        <img v-if="comapnyDetails[1].genderInProcurementStrategy == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[1].genderInProcurementStrategy == ''" class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gender & procurement strategy
                      </li>
                      <li>
                        <img v-if="comapnyDetails[1].valueChainStrategiesThatPromoteEquality == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[1].valueChainStrategiesThatPromoteEquality == ''"
                          class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gender & value chain strategy
                      </li>
                      <li>
                        <img v-if="comapnyDetails[1].initiativesToIncludeDisadvantagedGroups == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[1].initiativesToIncludeDisadvantagedGroups == ''"
                          class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Active initiatives to include disadvantaged groups
                      </li>
                      <li>
                        <img v-if="comapnyDetails[1].genderedApproachToCovid19 == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[1].genderedApproachToCovid19 == ''" class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Gendered approach to COVID-19
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img
                          v-if="comapnyDetails[1].doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div
                          v-else-if="comapnyDetails[1].doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds == ''"
                          class="div_tooltip" style="margin-right: 15px">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Adopt practices that improve agency, decision-making and empower women in their
                        communities/households
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img
                          v-if="comapnyDetails[1].workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div
                          v-else-if="comapnyDetails[1].workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners == ''"
                          class="div_tooltip" style="margin-right: 15px">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Work with financing or implementation partners who work with women value chain partners
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img v-if="comapnyDetails[1].engageWomenInPaidIncomeOpportunitiesInSupplyChain == 'Yes'"
                          src='@/assets/images/Rightcircle.svg' alt="background-image" class="orange_green_image" />
                        <div v-else-if="comapnyDetails[1].engageWomenInPaidIncomeOpportunitiesInSupplyChain == ''"
                          class="div_tooltip">
                          <img src="@/assets/images/help_white.svg" alt="background-image"
                            style="width:24px;padding:2px;height:24px;position: relative;" />

                        </div>
                        <img v-else src='@/assets/images/3rdPartyVerified.svg' alt="background-image"
                          style="width:24px;padding:2px;height:24px;position: relative;" />
                        Engage women in paid income opportunities in supply chain
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="
              AssementPageWrepper
              Gender
              Equality
              &
              Social
              Inclusion
              Context
            ">
            <div class="Profile_Block">
              <div class="OverviewTitle CountryLine">
                <h2 style="width:1165px">Gender Equality & Social Inclusion Context</h2>
                <span></span>
              </div>
              <div class="GbvRiskTitle ContryNameImg">
                <img class="mr-5 h-16 print:h-auto print:w-64"
                  :src="country.flagUrl ? country.flagUrl : country.flagurl"
                  v-if="country.flagUrl || country.flagurl" crossorigin="anonymous"/>
                <h1>
                  {{
                      comapnyDetails[0].countryHq.active == "true"
                        ? country.humanName
                        : country.countryName
                  }}
                </h1>
              </div>
              <!-- country intro start -->
              <div class="CountryOverviewText" v-if="country && Object.keys(country).length > 0">
                <h3 v-if="country.intro">Country Overview</h3>
                <transition name="slide-fade" v-if="country.intro">
                  <item :text="country.intro" :country="country" type="pdf_file"></item>
                </transition>
              </div>
              <div class="CountryOverviewText before_page mt-10" v-if="sector && Object.keys(sector).length > 0">
                <h3>Sector Overview</h3>
                <transition name="slide-fade">
                  <item :text="sector.intro" :country="sector" type="pdf_file"></item>
                </transition>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="flex flex-row justify-center items-center mt-3 ml-20">
            <div class="flex flex-col item-center justify-center">
              <div class="flex flex-row items-center justify-center print:flex-col">
                <img class="h-16 print:h-auto print:w-64" src="@/assets/images/2X-assessment/My2XAssessments3b.png"
                  alt="" />
                <h1 class="
                    text-6xl
                    tracking-tighter
                    font-medium
                    text-center
                    print:text-7xl
                    ml-5
                  ">
                  No Data Found
                </h1>
              </div>
              <div class="
                  text-gray-600
                  font-medium
                  flex flex-row
                  items-center
                  justify-center
                  print:flex-col
                ">
                Complete a
                <a href="/#/2X-Ecosystem/New-2XAssessment">
                  <b style="
                      text-decoration: underline;
                      color: #0077c8;
                      margin: 0 6px;
                    ">2X Assessment</b>
                </a>
                to see your results.
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- End get it on deal modal -->
    </div>
  </div>
</template>

<script>
// import auth from "@/helpers/auth";
// import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/my2xAssessmentSpiderChart.vue";
import repeatSpiderchart from "@/components/charts/my2xAssessmentRepeatSpiderChart.vue";
import barChartVertical from "@/components/charts/my2xAssessmentBarChart.vue";
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import item from "@/components/text.vue";
import employmentBarChart from "@/components/charts/employmentBarChart.vue";
import barChartVerticalFund from "@/components/charts/my2AssessmentBarChartFund.vue";
// import assessmentPiechart from "@/components/charts/assessmentPiechart.vue";

export default {
  name: "my2xassement-print",
  data: function () {
    return {
      sectorAry: [
      {
          name: "Financial Services",
          label: "2X_FNCE",
        },
        {
          name: "Financial and Insurance Activities",
          label: "2X_FNCE",
        },
        {
          name: "Agribusiness & Food",
          label: "2X_AGFOOD",
        },
        {
          name: "Agriculture",
          label: "2X_AGFOOD",
        },
        {
          name: "Consumer Services",
          label: "2X_CONSUMER",
        },
        {
          name: "Education",
          label: "2X_EDUC",
        },
        {
          name: "Healthcare",
          label: "2X_HLTH",
        },
        {
          name: "Healthcare and Social work",
          label: "2X_HLTH",
        },
        {
          name: "Infrastructure",
          label: "2X_INFR",
        },
        {
          name: "Transportation and Storage Heavy",
          label: "2X_INFR",
        },
        {
          name: "Water and Waste Management", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Construction", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Manufacturing- Heavy",
          label: "2X_MANFHVY",
        },
        {
          name: "Manufacturing",
          label: "2X_MANFHVY",
        },
        {
          name: "Mining and Quarrying",
          label: "2X_MANFHVY",
        },
        {
          name: "Power",
          label: "2X_POWER",
        },
        {
          name: "Energy and Power",
          label: "2X_POWER",
        },
        {
          name: "Professional Services",
          label: "2X_PROF",
        },
        {
          name: "Professional, Scientific, and Technical activities",
          label: "2X_PROF",
        },
        {
          name: "Telecoms",
          label: "2X_TELECOMS",
        },
        {
          name: "Manufacturing- Light",
          label: "2X_MANFLT",
        },
        {
          name: "Wholesale and Retail Trade",
          label: "2X_MANFLT",
        },
      ],
      dynamicGesiURL: "",
      dynamicGbvURL: "",
      json_data: [],
      assessmentYear: [],
      sharedOrgRoute: false,
      invitedUserEmail: "",
      indicatorBlurb: [],
      comapnyDetails: [],
      active: false,
      endofcreated: false,
      country: {
        income: "",
      },
      theme: {},
      gbvrisk: {},
      sector: { name: "" },
      env,
      current_step: 1,
      risk_level: "",
      openPanels: [],
      taabs: {
        Entrepreneurship: env.taabColors.humanDignity,
        Leadership: env.taabColors.knowledgeAndBeliefs,
        Employment: env.taabColors.accessToResources,
        Consumption: env.taabColors.powerAndDecision,
        "Indirect Investment": env.taabColors.indirectInvestement,
      },
      isReadMore: false,
      readMoreInfo: null,
      title: null,
      favouriteOrgData: [],
      user: [],
      consentsData: [],
      dealConsentData: [],
      selectedOrganization: "",
      showData: false,
      orgModalTitle: "",
      amountFundedData: [],
      fundedFundingAmount: 0,
      consideringFundingAmount: 0,
      committedFundingAmount: 0,
      fundingAmountValue: [],
      enteredFundedAmount: "",
      selectedFundType: "Considering",
      defaultFundTypes: ["Considering", "Committed", "Funded"],
      selectedCountryDetails: [],
      fixedAssessmentsAPIBody: [],
      downloadPDF: false,
      initialCompanyData: [],
      countData: [],
      options1: {
        text: {
          color: "#2BD4C4",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      options2: {
        text: {
          color: "#FE5000",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FE5000",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      options3: {
        text: {
          color: "#FE5000",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      criteriaValue: '',
      leadership: -1,
      employment: -1,
      entrepreneurship: -1,
      consumption: -1,
      twoxEligible: -1,
      indirectInvestment: -1,
    };
  },
  updated() {
    if (this.enteredFundedAmount < 0) {
      this.enteredFundedAmount = 0;
    }
  },
  props: {
    changeCountryData: [Object, Array, String],
    userFromParent: [Array, Object],
    favouriteOrgDataFromParent: [Array, Object],
    initialCompanyDataFromParent: [Array, Object],
    comapnyDetailsFromParent: [Array, Object],
    countDataFromParent: [Array, Object],
    selectedCountryDetailsFromParent: [Array, Object],
    consentsDataFromParent: [Array, Object],
    assessmentYearFromParent: [Array, Object],
    countryFromParent: [Array, Object],
    sectorFromParent: [Array, Object],
    amountFundedDataFromParent: [Array, Object],
  },
  components: {
    employmentBarChart,
    spiderchart,
    repeatSpiderchart,
    barChartVertical,
    item,
    // assessmentPiechart,
    barChartVerticalFund,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
  },
  async created() {
    // this.user = await auth.me().catch(() => {
    //   console.log("Error login");
    // });
    this.user = this.userFromParent;
    // let assessmentsAPIBody = {
    //   email: this.user.email,
    // };
    // if (this.$route.query.id) {
    //   this.sharedOrgRoute = true;
    //   assessmentsAPIBody = {
    //     isShared: true,
    //     userId: this.$route.query.id,
    //   };
    // }
    // if (this.$route.query.email) {
    //   this.sharedOrgRoute = true;
    //   assessmentsAPIBody = {
    //     email: this.$route.query.email,
    //   };
    // }

    // if (this.$route.query.dealId) {
    //   assessmentsAPIBody.dealId = this.$route.query.dealId
    // }

    // if (this.$route.query.recordId) {
    //   assessmentsAPIBody.recordId = this.$route.query.recordId
    //   assessmentsAPIBody.tableName = this.$route.query.type
    // }
    // this.fixedAssessmentsAPIBody = assessmentsAPIBody;
    // this.favouriteOrgData = await this.getFavouriteData();
    this.favouriteOrgData = this.favouriteOrgDataFromParent;
    let companyData = this.initialCompanyDataFromParent;
    // if (this.changeCountryData && this.changeCountryData.countryIso) {
    //   companyData = await this.getCompanyDetails(this.changeCountryData);
    // } else {
    //   companyData = await this.getCompanyDetails(assessmentsAPIBody);
    // }
    this.comapnyDetails = this.comapnyDetailsFromParent;
    this.initialCompanyData = companyData;
    // this.comapnyDetails = companyData.assesments;
    this.countData = companyData.countData;
    this.selectedCountryDetails = companyData.countries;
    if (this.initialCompanyData && this.initialCompanyData.assesments) {
      this.assessmentSavedOrNot();
    }
    // this.consentsData = await this.getConsentsData();
    this.consentsData = this.consentsDataFromParent;
    this.consentsData.map((data) => {
      data["isOrganizationSelected"] = false;
    });
    if (this.comapnyDetails && this.comapnyDetails.length > 0) {
      // this.amountFundedData = await this.getAmountFundedData();
      this.amountFundedData = this.amountFundedDataFromParent
      this.filterAmountFundedData();
    }

    if (this.comapnyDetails && this.comapnyDetails.length > 0) {
      this.dynamicGesiURL = env.domainRoute + "/#/gesi-analysis";
      this.dynamicGbvURL =
        env.domainRoute +
        "/#/reports/" +
        this.comapnyDetails[0].countryHq.iso +
        "/country/gbv-risk/risk-score";

      this.assessmentYear.push(
        new Date(this.comapnyDetails[0].createdTime).getFullYear() +
        " / " +
        new Date(this.comapnyDetails[0].createdTime).toLocaleString("en-US", {
          month: "short",
        })
      );
      if (this.comapnyDetails[1]) {
        this.assessmentYear.push(
          new Date(this.comapnyDetails[1].createdTime).getFullYear() +
          " / " +
          new Date(this.comapnyDetails[1].createdTime).toLocaleString(
            "en-US",
            { month: "short" }
          )
        );
      }
      if (
        this.comapnyDetails &&
        this.comapnyDetails[0].countryHq &&
        this.comapnyDetails[0].countryHq.iso &&
        this.comapnyDetails[0].countryHq.active == "false"
      ) {
        this.country = this.comapnyDetails[0].countryHq;
      } else {
        this.country = await this.getCountry(
          this.comapnyDetails[0].countryHq.iso
        );
      }
      if (
        this.comapnyDetails &&
        this.comapnyDetails[0].countryHq &&
        this.comapnyDetails[0].countryHq.active == "true"
      ) {
        this.gbvrisk = await this.getGBVRisk(
          this.comapnyDetails[0].countryHq.iso
        );
      }

      this.sector = this.sectorFromParent;
      this.country.values = {...this.country.values, ...this.sector.values}

      // RISK CATEGORIES
      if (
        this.comapnyDetails &&
        this.comapnyDetails[0].countryHq &&
        this.comapnyDetails[0].countryHq.active == "true"
      ) {
        if (Math.round(this.gbvrisk.selected_country.value) < 20) {
          this.risk_level = "Ubiquitous Risk";
        } else if (
          (Math.round(this.gbvrisk.selected_country.value) >= 20) &
          (Math.round(this.gbvrisk.selected_country.value) < 40)
        ) {
          this.risk_level = "Significant Risk";
        } else if (
          (Math.round(this.gbvrisk.selected_country.value) >= 40) &
          (Math.round(this.gbvrisk.selected_country.value) < 60)
        ) {
          this.risk_level = "Severe Risk";
        } else if (Math.round(this.gbvrisk.selected_country.value) >= 60) {
          this.risk_level = "Extreme Risk";
        }
      }

      this.$store.commit("resetCitations");
      this.$store.commit("downloadable", false);
      this.$store.commit("isFreeState", true);
      this.$store.commit("setBreadcrumbs", [

        {
          name: this.sharedOrgRoute
            ? this.comapnyDetails[0].organizationName
            : "My 2X Assessments",
          url: "/2X-Ecosystem/My-2X-Assessments",
        },
      ]);
    }
    this.endofcreated = true;
    // this.favouriteOrgData = await this.getFavouriteData();
    this.favouriteOrgData = this.favouriteOrgDataFromParent;
    if (this.initialCompanyData && this.initialCompanyData.assesments) {
      this.assessmentSavedOrNot();
    }
    this.criteriaCalc();
  },
  methods: {
    async criteriaCalc() {
      let companyDetail = await this.comapnyDetails[0]
    
      if (companyDetail) {

        if (companyDetail.type == 'funds') {
          this.indirectInvestment = Math.round(Number(companyDetail.indirectInvestmentPercentage))
        }

        this.leadership = companyDetail.leadershipPercentage;

        this.employment = companyDetail.employmentPercentage;

        this.entrepreneurship = companyDetail.entrepreneurshipPercentage;

        this.consumption = companyDetail.consumptionPercentage;

        this.twoxEligible = companyDetail.twoXeligibilityPercentage

      }

    },
    filterAmountFundedData() {
      this.fundingAmountValue = [];
      this.fundedFundingAmount = 0;
      this.consideringFundingAmount = 0;
      this.committedFundingAmount = 0;
      this.amountFundedData.forEach((data) => {
        data.fundingData.forEach((item) => {
          if (data.fundtype === "Funded") {
            this.fundedFundingAmount =
              this.fundedFundingAmount + item.fundingAmount;
          }
          if (data.fundtype === "Considering") {
            this.consideringFundingAmount =
              this.consideringFundingAmount + item.fundingAmount;
          }
          if (data.fundtype === "Committed") {
            this.committedFundingAmount =
              this.committedFundingAmount + item.fundingAmount;
          }
        });
      });
      this.fundingAmountValue.push(
        Math.floor(this.fundedFundingAmount),
        Math.floor(this.committedFundingAmount),
        Math.floor(this.consideringFundingAmount),
        Math.floor(this.comapnyDetails[0].amountOfCurrentFundingDesired)
      );
    },
    getAmountFundedData() {
      let recordID = this.comapnyDetails[0].recordId;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-deal-transaction", {
            params: {
              recordId: recordID,
            },
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getConsentsData() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-investors-consents-list", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getFavouriteData() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-save-assesments", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    assessmentSavedOrNot() {
      this.favouriteOrgData.map((data) => {
        if (
          this.comapnyDetails[0] &&
          data.assesments._id == this.comapnyDetails[0]._id
        ) {
          this.comapnyDetails[0]["isAssessmentSavedOrNot"] = true;
        }
      });
    },
    openContextualAnalysis(taab) {
      if (taab == "Indirect Investment") {
        taab = "Investment through Financial Intermediaries";
      }
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/My-2X-Assessments/context",
        query: {
          indicator: taab,
          email: this.comapnyDetails[0].equiloEmailId,
          sector: this.comapnyDetails[0].sector,
          countryId: this.comapnyDetails[0].countryHq.country_id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getCountry: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/countries/" + val + "/sectors/country", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getGBVRisk: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/reports/" + val + "/sectors/country/gbvrisk", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getCompanyDetails(assessmentsAPIBody) {
      let url = "/assesment2x/all-assesment";
      // if (this.$route.query.id) {
      //   url = "/assesment2x/all-assesment?teamId=" + this.$route.query.id
      // } else {
      //   url = "/assesment2x/all-assesment"
      // }
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + url, assessmentsAPIBody, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
  },
  computed: {
    averageTicketSizeForInvestors() {
      var str = this.comapnyDetails[0].averageTicketSizeForInvestors[0].replace(
        /[a-z"A-Z]/g,
        ""
      );
      return str;
    },
    fundsSector() {
      var data = Object.values(this.comapnyDetails[0].sector).join(", ");
      return data;
    },
    fundingInterests() {
      var data = Object.values(this.comapnyDetails[0].interestInFunding).join(
        ", "
      );
      return data;
    },
    whatKindOfSupport() {
      var data = Object.values(
        this.comapnyDetails[0].whatKindOfSupportAreYouLookingFor
      ).join(", ");
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.before_page {
    page-break-before: always;
}

.orange_green_image {
  width: 24px;
  // padding: 2px;
  height: 24px;
  // top: 8px;
  position: relative;
}

.myClass {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    // top: 8px;
    position: relative;
  }
}

.Consumption_ListText {
  .multiple_employment_results_section {
    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }
  }

  .multiple_consumption_results_section {
    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }
  }

  .list-disc {

    li {
      display: flex;
      margin-top: 20px;

    }


  }
}



.AssementPageWrepper {
  .Profile_Block {
    max-width: 1100px;
    width: 1100px !important;
    margin: 0 auto;

    .CountryOverviewText {
      margin-bottom: 15px;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p,
      .leading-7 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #1b1b1b;
        display: -webkit-box;
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        display: flex;
        align-items: center;

        img {
          margin-left: 5px !important;
          width: 15px !important;
          height: 15px;
          object-fit: contain;
        }
      }
    }
  }
}

.ContryNameImg {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: #1b1b1b;
  }
}

.row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.assementPageStyle {
  background: #ffffff !important;
  margin-top: 65px;

  .container {
    margin: 0 auto;
    max-width: 100%;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .Ind_Inv_Icon {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer !important;

    img {
      width: 30px !important;
      height: 30px;
      object-fit: contain;
      margin-right: 0 !important;
      margin-top: 30px !important;
    }

    span {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-left: 20px !important;
    }
  }

  .Indirect_Investment {
    top: 0;
    left: 0;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: center;

    .PoweredbyTitleWrepper {
      // max-width: 847px;
      max-width: 1100px;
      widows: 100%;
      display: flex;
      align-items: center;
      gap: 80px;
      flex-direction: column;

      .title {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          width: 150px;
          height: 70px !important;
          object-fit: contain;
        }

        h1 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 55px;
          line-height: 61px;
          color: #000;
          padding-bottom: 30px;
        }
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    .OverviewBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 0 32px 5px;
      margin-bottom: 18px;
      margin: 0 auto;
      max-width: 1100px;
      padding-top: 50px;
      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
          // top: 15px;
          position: relative;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .EligibleListImg {
              position: relative;
              z-index: 1;
              margin-right: 15px;
              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 20px;
                top: 40px;
                left: 20px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 0 !important;
              }
            }

            .bigImg {
              &::after {
                top: 40px;
                left: 20px;
                height: 20px;
              }
              img {
                width: 40px !important;
                height: 40px;
              }
            }
            .lastAffter {
              &::after {
                content: none;
              }
            }
            .EligibleTitle {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }
              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
                // margin-top: -18px;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
                position: relative;
                // margin-top: -18px;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }

            a {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }

        .spiderchartContent {
          width: 100%;
          max-width: 750px;
          margin: 0 auto;
        }
      }
    }

    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;
      flex-direction: column;
      align-items: center;

      .Profile_CountryWrepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0 auto;

        .Profile_Block {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          margin-bottom: 18px;
          max-width: 1100px;
          margin: 0 auto;
          width: 100%;

          .Profile_blockListText {
            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-bottom: 30px;

              b {
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }

              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }

            ul {
              border-top: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 30px;
              display: flex;
              flex-wrap: wrap;
              // align-items: center;
              justify-content: space-between;

              li {
                width: 48%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;
                word-break: break-all;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }
              }
            }

            .DownloadBtnDeck {
              display: flex;

              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;

                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }

                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }
          }

          .CountryOverviewText {
            margin-bottom: 30px;

            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 5px;
            }

            p,
            .leading-7 {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 18px;
              color: #1b1b1b;
              display: -webkit-box;
            }

            a {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              display: flex;
              align-items: center;

              img {
                margin-left: 5px !important;
                width: 15px !important;
                height: 15px;
                object-fit: contain;
              }
            }
          }

          .WanttoUnderstandHere {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;

            a {
              text-decoration: underline;
            }
          }

          .ContryNameImg {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 60px;
              height: 40px;
              object-fit: contain;
              margin-right: 15px;
            }

            h1 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }
          }

          .ExploreBtnDeck {
            display: flex;
            margin-top: 30px;

            div {
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }

      .barchart2xCriteria_Wrepper {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        width: 100%;

        .CriteriaTitleText {
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: contain;
            // top: 8px;
            position: relative;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }
        }

        .ChartBarContentWrepper {
          display: flex;
          flex-direction: column;
          align-items: center;

          .Chart_wrapper {
            display: flex;
            gap: 50px;

            .ChartBarContent {
              width: 330px;

              .Founded_WomanText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                color: #1b1b1b;
                margin-bottom: 15px;
              }

              .Founded_WomanImg {
                display: flex;
                align-items: center;

                img {
                  margin-right: 10px;
                  width: 20px;
                  height: 20px;
                  object-fit: contain;
                }
              }

              .ChartBarPercentageText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 10px;
              }
            }
          }
        }

        .Quality_EmploymentText {
          p {
            margin-bottom: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400; //
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .IndirectInvestmentListContent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .IndirectContent {
            width: 100%;
            .IndirectInvWrepper {
              margin-bottom: 45px;

              .IndirectInvText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 16px;
              }

              .IndirectInvImg {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .GBVdetailsBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;

      .GBVdetailsContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .esg-pre-screening {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 0;
      margin-bottom: 20px;
      page-break-before: always;
      max-width: 1100px;
      margin: 0 auto;

      .esg-pre-screening-results-section {
        p {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #1b1b1b;
        }

        .assessment_year_second_results {
          width: 53%;
        }
      }

      .OverviewTitle {
        h2 {
          width: 320px;
        }
      }

      .GBVdetailsContent {
        justify-content: space-between;

        ul {
          margin-top: 1.5rem;
        }

        ul li {
          display: flex;
          margin: 0;
        }

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .amount-funded {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      margin-top: 20px;
      max-width: 1100px;
      margin: 0 auto;

      .funding-status-profile-container {
        margin: 1rem 0 3rem 0;

        ul {
          display: flex;
          justify-content: space-between;
        }
      }

      .funding-status-title {
        h2 {
          width: 250px;
        }
      }

      .funding-bar-chart {
        width: 100%;
      }

      .funding-status-info {
        width: 50%;

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .CriteriaTitleText {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          object-fit: contain;
          // top: 8px;
          position: relative;
        }

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1b1b1b;
        }
      }

      .ChartBarContentWrepper {
        justify-content: space-between;
        margin: 0 auto 60px auto;

        .ChartBarContent {
          width: 100%;
          margin: auto;
          max-width: 400px;

          .Founded_WomanText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 15px;
          }

          .Founded_WomanImg {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }

          .ChartBarPercentageText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 10px;
          }
        }
      }

      .get-it-on-deal-button {
        text-align: right;
        margin-bottom: 20px;
      }

      .get-it-on-deal-button button {
        background: #fff;
        color: #1b1b1b;
        border-color: #201747;
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
      }

      .get-it-on-deal-button button:hover {
        background: #201747;
        color: #fff;
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 50px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 185px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
        // top: 15px;
        position: relative;
      }
    }

    .CountryLine {
      padding-top: 30px;

      h2 {
        width: 50%;
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    // top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      // margin: 0 !important;
      cursor: pointer;
    }
  }
}

.chartjs-render-monitor {
  margin-left: 0px !important;
  height: 530px !important;
  padding: 20px;
}

.employment_bar_chart {
  canvas {
    width: 1000px !important;
    height: 600px !important;
  }
}

.consumption-taab-company {
  left: 0;
  top: 0;
}

.consumption-taab-fund {
  left: 0;
  top: 120px;
}

.leadership-taab-company {
  top: 0px;
  right: 3rem;
}

.leadership-taab-fund {
  right: 10rem;
  top: 0;
}

.employment-taab-company {
  bottom: 25%;
  right: 5rem;
}

.employment-taab-fund {
  bottom: 12rem;
  right: 5rem;
}

.entrepreneurship-taab-company {
  left: 0px;
  bottom: 25%;
}

.entrepreneurship-taab-fund {
  left: 0px;
  bottom: 5.6rem;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 80px;
}

img {
  // margin: 0 !important;
  margin-right: 15px !important;
}

.bridgeContainer {
  .DownloadBtnDeck {
    display: none !important;
  }

  .readMoreBtn {
    display: none !important;
  }

  .ExploreBtnDeck {
    display: none !important;
  }

  .get-it-on-deal-button {
    display: none !important;
  }

  .leading-7 {
    -webkit-line-clamp: unset !important;
    -webkit-line-clamp: none;
    overflow: visible;
    padding-bottom: 10px;
  }

  .DownloadBtn {
    display: none !important;
  }
}
</style>

<style scoped>
.selected-country {
  pointer-events: none;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-country {
  cursor: pointer;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
}

.favorite-button-section {
  margin-top: 20px;
  display: flex;
}

.favourite-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.favourite-tooltip .favourite-tooltip-text {
  visibility: hidden;
  width: 180px;
  /* right: 90px; */
  margin: 5px;
  background-color: #a0aec0;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.favourite-tooltip:hover .favourite-tooltip-text {
  visibility: visible;
}

.gbv-country-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.gbv-country-tooltip-text {
  visibility: hidden;
  width: 180px;
  margin: 5px;
  background-color: black;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;

  font-family: "Montserrat", arial !important;
  padding: 20px;
  font-size: 12px !important;
  letter-spacing: normal;
}

.gbv-country-tooltip:hover .gbv-country-tooltip-text {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .download-csv-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 290px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -86px;
  bottom: 100%;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip .tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 20px;
  top: -160px;
}

.tooltip:hover .download-csv-tooltip {
  visibility: visible;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .download-csv-tooltip::after {
  right: 95%;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.disable-csv-download {
  pointer-events: none;
}

/* Tooltip container */
.div_tooltip {
  position: relative;
  display: inline-block;
  /* margin-right: 15px; */
}

/* Tooltip text */
.div_tooltip .div_tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.div_tooltip:hover .div_tooltiptext {
  visibility: visible;
}

.gray-color {
  color: #898989 !important;
}
</style>