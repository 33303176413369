<template>
  <div class="static assementPageStyle">
    <gbvhPrivacyModal
      v-if="user.gbvhPrivacyPolicy == false"
      @pageReload="pageReload"
    ></gbvhPrivacyModal>
    <transition name="fadeIn" mode="out-in" v-if="user.gbvhPrivacyPolicy">
      <div id="notLoaded" v-if="!endofcreated" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        class="container"
        v-if="
          endofcreated &&
          gbvhData &&
          gbvhData.gbvhAssessments &&
          gbvhData.gbvhAssessments.length &&
          gbvhData.gbvhAssessments.length > 0
        "
      >
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <img
              v-if="
                gbvhData.gbvhAssessments &&
                gbvhData.gbvhAssessments[0] &&
                gbvhData.gbvhAssessments[0].organizationsLogo &&
                gbvhData.gbvhAssessments[0].organizationsLogo[0] &&
                gbvhData.gbvhAssessments[0].organizationsLogo[0].url
              "
              class="h-16 print:h-auto print:w-64"
              :src="gbvhData.gbvhAssessments[0].organizationsLogo[0].url"
              style="height: 10rem; display: block"
            />
            <h1
              class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl"
            >
              {{ gbvhData.gbvhAssessments[0].organizationName }}'s GBVH Roadmap
              Assessment
            </h1>
          </div>
          <div class="powerd_invite-wrapper">
            <div class="powerdby_logo_wrapper">
              <div class="Poweredby_logoImg">
                <span class="powerd-logo">Powered by:</span>
                <img
                  src="@/assets/images/logo-navy.png"
                  alt="background-image"
                />
              </div>
              <div data-v-6fbe4aba="" v-if="!$route.query.recordId">
                <div data-v-6fbe4aba="" class="EmailInvitemember">
                  <div data-v-6fbe4aba="" class="EmailInput">
                    <input
                      data-v-6fbe4aba=""
                      type="email"
                      placeholder="Enter Email Address"
                      v-model="invitedUserEmail"
                    />
                  </div>
                  <div data-v-6fbe4aba="" class="InviteBtn tooltip">
                    <div data-v-6fbe4aba="" @click="onInviteClick">
                      <button
                        data-v-6fbe4aba=""
                        class="text-white text-sm font-medium"
                      >
                        Share my results
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in" v-if="user.gbvhPrivacyPolicy">
          <div id="loaded" key="content">
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper">
              <!-- <div class="flex flex-row items-center justify-center print:flex-col my-6 ">
                     <p class="text-6xl tracking-tighter font-small text-center print:text-7xl ml-5" :style="'color:' + env.brandingColors.navy">
                       {{ gbvhData[0].organizationName }}
                     </p> 
                     </div> -->
              <!-- Overview Block -->
              <div data-v-62f779d6="" class="OverviewBlock">
                <div data-v-62f779d6="" class="OverviewTitle">
                  <h2 data-v-62f779d6="">Overview</h2>
                  <span data-v-62f779d6=""></span>
                </div>
                <div data-v-6fbe4aba="" class="spiderchartEligibleWrepper">
                  <div data-v-6fbe4aba="" class="Xeligibleconten">
                    <div data-v-6fbe4aba="" class="PartyVerifiedToolTip">
                      <progress-bar
                        v-if="
                          gbvhData &&
                          gbvhData.gbvhAssessments[0] &&
                          gbvhData.gbvhAssessments[0]
                            .esgRiskStrategyCurrentScore &&
                          gbvhData.gbvhAssessments[0]
                        "
                        :options="options"
                        :value="
                          gbvhData.gbvhAssessments[0]
                            .esgRiskStrategyCurrentScore | mathRound
                        "
                        style="width: 45px; height: 45px; line-height: 0"
                      />
                      <div data-v-6fbe4aba="" class="PartyTooltipImg flex">
                        <!-- <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/group75.svg"
                          alt="background-image"
                          style="border-radius: 0"
                        /> -->
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/assetssearch.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                      </div>
                      <div data-v-6fbe4aba="" class="tooltip TooltipImgText">
                        <p data-v-6fbe4aba="">
                          Assess Project Risks in Due Diligence
                        </p>
                      </div>
                    </div>
                    <div data-v-6fbe4aba="" class="PartyVerifiedToolTip">
                      <progress-bar
                        :options="options"
                        :value="
                          gbvhData.gbvhAssessments[0]
                            .reduceCompanyGbvStrategyCurrentScore | mathRound
                        "
                        style="width: 45px; height: 45px; line-height: 0"
                      />
                      <div data-v-6fbe4aba="" class="PartyTooltipImg flex">
                        <!-- <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/reduce25.svg"
                          alt="background-image"
                          style="border-radius: 0"
                        /> -->
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/reduceflash.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                      </div>
                      <div data-v-6fbe4aba="" class="tooltip TooltipImgText">
                        <p data-v-6fbe4aba="">
                          Reduce GBV in Product & Company
                        </p>
                      </div>
                    </div>
                    <div data-v-6fbe4aba="" class="PartyVerifiedToolTip">
                      <progress-bar
                        :options="options"
                        :value="
                          gbvhData.gbvhAssessments[0]
                            .systemicRiskStrategyCurrentScore | mathRound
                        "
                        style="width: 45px; height: 45px; line-height: 0"
                      />
                      <div data-v-6fbe4aba="" class="PartyTooltipImg flex">
                        <!-- <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/address50.svg"
                          alt="background-image"
                          style="border-radius: 0"
                        /> -->
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/homeaddress.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                      </div>
                      <div data-v-6fbe4aba="" class="tooltip TooltipImgText">
                        <p data-v-6fbe4aba="">Address Systemic Risk</p>
                      </div>
                    </div>
                    <div data-v-6fbe4aba="" class="PartyVerifiedToolTip">
                      <progress-bar
                        :options="options"
                        :value="
                          gbvhData.gbvhAssessments[0]
                            .proactiveInvestmentStrategyCurrentScore | mathRound
                        "
                        style="width: 45px; height: 45px; line-height: 0"
                      />
                      <div data-v-6fbe4aba="" class="PartyTooltipImg flex">
                        <!-- <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/dollar100.svg"
                          alt="background-image"
                          style="border-radius: 0"
                        /> -->
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/dolllargbv.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                      </div>
                      <div data-v-6fbe4aba="" class="tooltip TooltipImgText">
                        <p data-v-6fbe4aba="">
                          Make Proactive Investments in GBV Reduction
                        </p>
                      </div>
                    </div>
                    <div data-v-6fbe4aba="" class="BorderLine"></div>
                    <div data-v-6fbe4aba="" class="PartyVerifiedToolTip">
                      <div data-v-6fbe4aba="" class="PartyTooltipImg flex">
                        <img
                          v-if="gbvhData.plansToImproveActions"
                          data-v-6fbe4aba=""
                          src="@/assets/images/rightsign.svg"
                          alt="background-image"
                          style="border-radius: 0"
                        />
                        <img
                          v-else
                          data-v-6fbe4aba=""
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="border-radius: 0; width: 40px"
                        />
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/planselect.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                      </div>
                      <div data-v-6fbe4aba="" class="tooltip TooltipImgText">
                        <p data-v-6fbe4aba="">
                          Plans to Improve Actions to Reduce GBV & Risk
                        </p>
                      </div>
                    </div>
                    <div v-if="json_data && json_data.length > 0">
                      <div class="DownloadBtn">
                        <div class="tooltip">
                          <download-csv
                            :data="json_data"
                            :name="
                              gbvhData.gbvhAssessments[0].organizationName +
                              '.csv'
                            "
                          >
                            <p style="cursor: pointer; margin-top: 3px">
                              <img
                                src="../../assets/images/DownloadBtn.png"
                                alt=""
                              />
                              Download CSV
                            </p>
                          </download-csv>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="spiderchart_container z-20 relative">
                    <repeatedSpiderchart
                      v-if="
                        gbvhData.gbvhAssessments[0] &&
                        gbvhData.gbvhAssessments[1]
                      "
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :details="gbvhData.gbvhAssessments"
                      class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"
                    ></repeatedSpiderchart>
                    <spiderchart
                      v-else
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :details="gbvhData.gbvhAssessments"
                      class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"
                    ></spiderchart>

                    <template v-for="(color, taab) in taabs">
                      <!--Leadership-->
                      <div
                        v-if="color === '#2BD4C4'"
                        class="Ind_Inv_Icon leadership-taab-company"
                        id="2uur"
                        v-bind:key="taab.name"
                        style="color: #1b1b1b"
                      >
                        <img
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          src="@/assets/images/userhire.svg"
                          alt="background-image"
                          style="border-radius: 0px"
                        />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Entrepreneurship-->
                      <div
                        v-if="color === '#0077C8'"
                        id="8uur"
                        class="Ind_Inv_Icon entrepreneurship-taab-company"
                        v-bind:key="taab.name"
                        style="color: #1b1b1b"
                      >
                        <img
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          src="@/assets/images/planexport.svg"
                          alt="background-image"
                          style="border-radius: 0px"
                        />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Consumption-->
                      <div
                        v-if="color === '#F5D10F'"
                        id="10uur"
                        class="Ind_Inv_Icon consumption-taab-company"
                        v-bind:key="taab.name"
                        style="color: #1b1b1b"
                      >
                        <img
                          data-v-6fbe4aba=""
                          src="@/assets/images/dolllargbv.svg"
                          alt="background-image"
                          style="border-radius: 0; margin: 0 10px"
                        />
                        <span> {{ taab }}</span>
                      </div>
                      <!--Employment-->
                      <div
                        v-if="color === '#FE5000'"
                        id="12uur"
                        class="Ind_Inv_Icon employment-taab-company"
                        v-bind:key="taab.name"
                        style="color: #1b1b1b"
                      >
                        <img
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          src="@/assets/images/bankexport.svg"
                          alt="background-image"
                          style="border-radius: 0px"
                        />
                        <span>{{ taab }}</span>
                      </div>

                      <!--Indirect Investment-->
                      <div
                        v-if="color === 'red'"
                        class="Ind_Inv_Icon Indirect_Investment"
                        id="12uur"
                        v-bind:key="taab.name"
                        style="color: #1b1b1b"
                      >
                        <img
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          src="@/assets/images/planexport.svg"
                          alt="background-image"
                          style="border-radius: 0px"
                        />
                        <span>{{ taab }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!-- <div data-v-62f779d6="" class="OverviewBlock">
                <div data-v-62f779d6="" class="OverviewTitle">
                  <h2 data-v-62f779d6="">Title</h2>
                  <span data-v-62f779d6=""></span>
                </div>
                <div
                  data-v-6fbe4aba=""
                  class="flex"
                  style="align-items: center"
                >
                  <h2 class="title" v-if=" gbvhData.gbvhAssessments && gbvhData.gbvhAssessments[0] && gbvhData.gbvhAssessments[0].organizationName">
                    Organization Title : 
                  </h2>
                  <img
                    v-if="
                      gbvhData.gbvhAssessments &&
                      gbvhData.gbvhAssessments[0] &&
                      gbvhData.gbvhAssessments[0].organizationsLogo &&
                      gbvhData.gbvhAssessments[0].organizationsLogo[0] &&
                      gbvhData.gbvhAssessments[0].organizationsLogo[0].url
                    "
                    :src="gbvhData.gbvhAssessments[0].organizationsLogo[0].url"
                    style="width: 70px; margin-left: 15px; margin-right: 5px;"
                  />
                  <h2 class="title" v-if=" gbvhData.gbvhAssessments && gbvhData.gbvhAssessments[0] && gbvhData.gbvhAssessments[0].organizationName">
                    {{ gbvhData.gbvhAssessments[0].organizationName }} GBVH Roadmap Assessment
                  </h2> 
                </div>
              </div> -->
              <div class="Profile_Criteria_Wrepper gbvh">
                <div class="barchart2xCriteria_Wrepper">
                  <div data-v-6fbe4aba="" class="OverviewTitle">
                    <h2 data-v-6fbe4aba="">GBVH Assessment</h2>
                    <span data-v-6fbe4aba=""></span>
                  </div>
                  <div class="gbvh-bread-list">
                    <span>
                      <img
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        src="@/assets/images/gbinvestor.svg"
                        alt="background-image"
                        style="border-radius: 0px"
                      />Investor/portfolio level</span
                    >
                    <span>
                      <img
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        src="@/assets/images/gbdeal.svg"
                        alt="background-image"
                        style="border-radius: 0px"
                      />Deal level</span
                    >
                  </div>
                  <div class="gbvh-progress-wrapper">
                    <div
                      class="gbvh-assess-left-section"
                      v-for="(level, index) in gbvhData.gbvhSteps"
                      :key="index"
                    >
                      <div class="gbvh-progress-head">
                        <span>
                          <b>{{ level._id }}</b></span
                        >
                      </div>
                      <div
                        class="gbvh-progress-content"
                        v-for="(progress, progressIndex) in level.steps"
                        :key="progressIndex"
                      >
                        <div class="gbvh-progress-content--left flex mr-2">
                          <img
                            v-if="progress.level == 'Investor/ portfolio level'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="mr-2"
                            src="@/assets/images/gbinvestor.svg"
                            alt="background-image"
                            style="
                              border-radius: 0px;
                              width: 24px;
                              height: 24px;
                            "
                          />
                          <img
                            v-else
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="mr-2"
                            src="@/assets/images/gbdeal.svg"
                            alt="background-image"
                            style="
                              border-radius: 0px;
                              width: 24px;
                              height: 24px;
                            "
                          />
                          <p>
                            {{ progress.uniqueId }}
                          </p>
                        </div>
                        <div class="gbvh-progress-content--right">
                          <div
                            class="flex bar-div"
                            v-if="progress.oldestAssessment"
                          >
                            <span>{{ oldestSurveyDate }}</span>
                            <k-progress
                              style="width: 100%"
                              :percent="
                                progress.oldestAssessment.currentScore
                                  | mathRound
                              "
                              color="#9D9D9C"
                            ></k-progress>
                          </div>
                          <div
                            class="flex bar-div"
                            v-if="progress.latestAssessment"
                          >
                            <span>{{ latestSurveyDate }}</span>
                            <k-progress
                              style="width: 100%"
                              :percent="
                                progress.latestAssessment.currentScore
                                  | mathRound
                              "
                              color="#0077C8"
                            ></k-progress>
                          </div>
                          <div
                            class="flex bar-div"
                            v-if="progress.latestAssessment.futureScore"
                          >
                            <span>Future</span>
                            <k-progress
                              style="width: 100%"
                              :percent="
                                progress.latestAssessment.futureScore
                                  | mathRound
                              "
                              color="#2BD4C4"
                            ></k-progress>
                          </div>
                        </div>
                      </div>
                      <div
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        class="BorderLine"
                      ></div>
                    </div>
                    <div class="Profile_Block">
                      <div data-v-6fbe4aba="" class="OverviewTitle">
                        <h2 data-v-6fbe4aba="" style="width: 1200px">
                          Anticipated Results of Actions
                        </h2>
                        <span data-v-6fbe4aba=""></span>
                      </div>
                      <div class="gbvh-right-side-bread-list">
                        <span> Results</span>
                      </div>
                      <div
                        v-for="(anti, antiIndex) in anticipatedResults"
                        :key="antiIndex"
                      >
                        <div
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          class="BorderLine"
                        ></div>
                        <div
                          data-v-f595443a=""
                          data-v-6fbe4aba=""
                          class="PartyVerifiedToolTip"
                        >
                          <div class="actions-left-side" style="width: 100%">
                            <div
                              data-v-f595443a=""
                              data-v-6fbe4aba=""
                              class="PartyTooltipImg flex"
                            ></div>
                            <div
                              data-v-f595443a=""
                              data-v-6fbe4aba=""
                              class="tooltip TooltipImgText"
                            >
                              <p data-v-f595443a="" data-v-6fbe4aba="">
                                {{ anti }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        class="
                          loadmore-btn
                          flex
                          m-auto
                          justify-center
                          border border-gray-300
                          px-4
                          py-2
                          mt-10
                          bg-white
                          text-base
                          leading-6
                          font-bold
                          text-gray-800
                          hover:text-gray-500
                          focus:outline-none
                          focus:border-blue-300
                          focus:shadow-outline-blue
                          transition
                          ease-in-out
                          duration-150
                          sm:text-sm sm:leading-5
                        "
                        style="border-radius: 20px"
                        @click="loadMoreAnticipatedData(anticipatedClick + 1)"
                        v-if="
                          gbvhData.anticipatedResults.length !==
                            anticipatedResults.length &&
                          gbvhData.anticipatedResults &&
                          gbvhData.anticipatedResults.length > 0
                        "
                        >Load more</Button
                      >
                    </div>
                  </div>
                </div>
                <div class="Profile_CountryWrepper">
                  <div class="Profile_Block">
                    <div data-v-6fbe4aba="" class="OverviewTitle">
                      <h2 data-v-6fbe4aba="">Planned Actions</h2>
                      <span data-v-6fbe4aba=""></span>
                    </div>
                    <div
                      class="gbvh-right-side-bread-list"
                      v-if="plannedActionsResults.length"
                    >
                      <span> Actions</span>
                      <span> Strategies</span>
                    </div>
                    <div
                      v-for="(plan, planIndex) in plannedActionsResults"
                      :key="planIndex"
                    >
                      <div
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        class="BorderLine"
                      ></div>
                      <div
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        class="PartyVerifiedToolTip"
                      >
                        <div class="actions-left-side">
                          <div
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="PartyTooltipImg flex"
                          ></div>
                          <div
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="tooltip TooltipImgText"
                          >
                            <p data-v-f595443a="" data-v-6fbe4aba="">
                              {{ plan.desiredAction }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="actions-right-side"
                          v-for="(strat, stratIndex) in plan.strategies"
                          :key="stratIndex"
                        >
                          <img
                            v-if="strat == 'ESG Risk'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/planexport.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Systemic Risk'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/bankexport.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Reduce Company GBV'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/userhire.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Proactive Investment'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/dolllargbv.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>
                      <div class="actions-efforts-section">
                        <span><b>EFFORT:</b></span>
                        <span style="font-weight: bolder">{{
                          plan.degreeOfDifficulty
                        }}</span>
                        <!-- <span
                          :style="
                            plan.degreeOfDifficulty == 'Low'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >LOW</span
                        >
                        <span
                          :style="
                            plan.degreeOfDifficulty == 'Medium'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >MEDIUM</span
                        >
                        <span
                          :style="
                            plan.degreeOfDifficulty == 'High'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >HIGH</span
                        > -->
                      </div>
                      <div
                        class="actions-efforts-section"
                        v-if="plan.surveyStatus"
                      >
                        <span><b>STATUS:</b></span>
                        <span>{{ plan.surveyStatus }}</span>
                      </div>
                      <div
                        class="actions-efforts-section"
                        v-if="plan.futureYear"
                      >
                        <span><b>PLANNED IMPLEMENTATION DATE:</b></span>
                        <span>{{ plan.futureYear }}</span>
                      </div>
                    </div>
                    <Button
                      class="
                        loadmore-btn
                        flex
                        m-auto
                        justify-center
                        border border-gray-300
                        px-4
                        py-2
                        mt-10
                        bg-white
                        text-base
                        leading-6
                        font-bold
                        text-gray-800
                        hover:text-gray-500
                        focus:outline-none
                        focus:border-blue-300
                        focus:shadow-outline-blue
                        transition
                        ease-in-out
                        duration-150
                        sm:text-sm sm:leading-5
                      "
                      style="border-radius: 20px"
                      @click="loadMorePlannedActionData(plannedActionClick + 1)"
                      v-if="
                        gbvhData.plannedActions.length !==
                          plannedActionsResults.length &&
                        gbvhData.plannedActions &&
                        gbvhData.plannedActions.length > 0
                      "
                      >Load more
                    </Button>
                    <div
                      class="text-center font-medium"
                      v-if="
                        plannedActionsResults == [] ||
                        !plannedActionsResults.length
                      "
                    >
                      No data available
                    </div>
                  </div>
                  <div class="Profile_Block">
                    <div data-v-6fbe4aba="" class="OverviewTitle">
                      <h2 data-v-6fbe4aba="" style="width: 950px">
                        Current Actions Taken
                      </h2>
                      <span data-v-6fbe4aba=""></span>
                    </div>
                    <div
                      class="gbvh-right-side-bread-list"
                      v-if="currentActionsResults.length"
                    >
                      <span> Actions</span>
                      <span> Strategies</span>
                    </div>
                    <div
                      v-for="(current, currentIndex) in currentActionsResults"
                      :key="currentIndex"
                    >
                      <div
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        class="BorderLine"
                      ></div>
                      <div
                        data-v-f595443a=""
                        data-v-6fbe4aba=""
                        class="PartyVerifiedToolTip"
                      >
                        <div class="actions-left-side">
                          <div
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="PartyTooltipImg flex"
                          ></div>
                          <div
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            class="tooltip TooltipImgText"
                          >
                            <p data-v-f595443a="" data-v-6fbe4aba="">
                              {{ current.desiredAction }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="actions-right-side"
                          v-for="(strat, stratIndex) in current.strategies"
                          :key="stratIndex"
                        >
                          <img
                            v-if="strat == 'ESG Risk'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/planexport.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Systemic Risk'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/bankexport.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Reduce Company GBV'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/userhire.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                          <img
                            v-if="strat == 'Proactive Investment'"
                            data-v-f595443a=""
                            data-v-6fbe4aba=""
                            src="@/assets/images/dolllargbv.svg"
                            alt="background-image"
                            style="border-radius: 0px"
                          />
                        </div>
                      </div>
                      <div class="actions-efforts-section">
                        <span><b>EFFORT:</b></span>
                        <span style="font-weight: bolder">{{
                          current.degreeOfDifficulty
                        }}</span>
                        <!-- <span
                          :style="
                            current.degreeOfDifficulty == 'Low'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >LOW</span
                        >
                        <span
                          :style="
                            current.degreeOfDifficulty == 'Medium'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >MEDIUM</span
                        >
                        <span
                          :style="
                            current.degreeOfDifficulty == 'High'
                              ? 'font-weight: bolder'
                              : ''
                          "
                          >HIGH</span
                        > -->
                      </div>
                      <div
                        class="actions-efforts-section"
                        v-if="current.surveyStatus"
                      >
                        <span><b>Status:</b></span>
                        <span>{{ current.surveyStatus }}</span>
                      </div>
                    </div>
                    <Button
                      class="
                        loadmore-btn
                        flex
                        m-auto
                        justify-center
                        border border-gray-300
                        px-4
                        py-2
                        mt-10
                        bg-white
                        text-base
                        leading-6
                        font-bold
                        text-gray-800
                        hover:text-gray-500
                        focus:outline-none
                        focus:border-blue-300
                        focus:shadow-outline-blue
                        transition
                        ease-in-out
                        duration-150
                        sm:text-sm sm:leading-5
                      "
                      style="border-radius: 20px"
                      @click="loadMoreCurrentActionData(currentActionClick + 1)"
                      v-if="
                        gbvhData.currentActions.length !==
                          currentActionsResults.length &&
                        gbvhData.currentActions &&
                        gbvhData.currentActions.length > 0
                      "
                      >Load more</Button
                    >
                    <div
                      class="text-center font-medium"
                      v-if="
                        currentActionsResults == [] ||
                        !currentActionsResults.length
                      "
                    >
                      No data available
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="container" v-else>
         <div class="flex flex-row justify-left items-center">
            <div class="flex flex-col item-center justify-left mt-10 ml-20">
              <div class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col">
                Uh-oh! You have not given Equilo the data required to populate this assessment!.
              </div>
              <div class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2">
                <router-link to="/gbvh" class="text-white text-sm font-medium mr-3"
                  style="width: max-content;">
                  Start your assessment now
                </router-link>
              </div>
              <div class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5">
                You gave us the data but it seems missing?
              </div>
              <div class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2">
                <a href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red" class="text-white text-sm font-medium mr-3"
                  style="width: max-content;">
                  Contact support now
                </a>
              </div>
            </div>
          </div>
      </div>
    </transition>
  </div>
</template>
<script>
import env from "@/../env";
import axios from "axios";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/gbvhRoadmapSpiderchart.vue";
import repeatedSpiderchart from "@/components/charts/gbvhRepeatedRoadmapSpiderchart.vue";
import gbvhPrivacyModal from "@/components/gbvhPrivacyModal.vue";
export default {
  name: "gbvhRoadmapAssess",
  data: function () {
    return {
      user: [],
      gbvhData: [],
      anticipatedResults: [],
      currentActionsResults: [],
      plannedActionsResults: [],
      endofcreated: false,
      latestSurveyDate: "",
      oldestSurveyDate: "",
      anticipatedClick: 1,
      currentActionClick: 1,
      plannedActionClick: 1,
      chunk: 5,
      taabs: {
        "Assess Project Risks in Due Diligence": env.taabColors.humanDignity,
        "Reduce GBV in Product & Company": env.taabColors.knowledgeAndBeliefs,
        "Address Systemic Risk": env.taabColors.accessToResources,
        "Make Proactive Investments in GBV Reduction":
          env.taabColors.powerAndDecision,
      },
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 10,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      invitedUserEmail: "",
      json_data: [],
    };
  },
  components: {
    spiderchart,
    repeatedSpiderchart,
    pageLoader,
    gbvhPrivacyModal,
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.gbvhPrivacyPolicy) {
      let assessmentsAPIBody = {
        recordId: "",
      };
      if (this.$route.query.recordId) {
        assessmentsAPIBody = {
          recordId: this.$route.query.recordId,
        };
      }
      await this.getGbvhData(assessmentsAPIBody);
      if (
        this.gbvhData &&
        this.gbvhData.gbvhAssessments &&
        this.gbvhData.gbvhAssessments.length
      ) {
        await this.getCSVData();
      }
    }
    let Breadcrumbs = []
    if(this.$route.query.shared_assessment){
      Breadcrumbs = [
        {
          name: "GBVH",
          url: "/gbvh",
        },
        {
          name: "Shared Assessments",
          url: "/gbvh/gbvhSharedAssessment",
        },
        {
          name: "GBVH Roadmap Assessment",
          url: "/gbvh/gbvh-roadmap-assessment",
        },
      ]
    }else{
      Breadcrumbs = [
        {
          name: "GBVH",
          url: "/gbvh",
        },
        {
          name: "GBVH Roadmap Assessment",
          url: "/gbvh/gbvh-roadmap-assessment",
        },
      ]
    }
    this.$store.commit("setBreadcrumbs", Breadcrumbs);
    this.endofcreated = true;
    if (localStorage.getItem("GbvhAssessUrl")) {
      localStorage.removeItem("GbvhAssessUrl");
    }
  },
  filters: {
    mathRound(value) {
      return Math.round(value);
    },
    textWithComma(value) {
      var data = Object.values(value).join(", ");
      return data;
    },
  },
  methods: {
    filterCSVData(result) {
      var csvData = Object.values(result.data);
      csvData.splice(0, 0, ["Survey ID", "Action Item"]);
      csvData.map((item, i) => {
        if (i > 0) {
          item.splice(1, 0, "");
        }
        result.gbvhActions.forEach((data) => {
          if (
            item[0] &&
            data.surveyQuestionId &&
            item[0].toUpperCase().includes(data.surveyQuestionId.toUpperCase())
          ) {
            let res = item[0].replace(
              data.surveyQuestionId,
              data.desiredAction
            );
            item[1] = res;
          }
        });
      });
      if (csvData) {
        csvData.forEach((data) => {
          this.json_data.push(data);
        });
      }
    },
    getCSVData() {
      let body = {
        recordId: this.$route.params.recordId
          ? this.$route.params.recordId
          : "",
      };
      return axios
        .post(env.apiUrl + "/gbvh/download-csv", body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.csvData = res.data;
          await this.filterCSVData(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.endofcreated = true;
        });
    },
    pageReload() {
      window.location.reload();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        // let usersAry = [];
        // usersAry.push(this.invitedUserEmail);
        let body = {
          // user_id: this.user._id,
          email: this.invitedUserEmail,
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.status) {
          this.$swal.fire({
            icon: "success",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/gbvh/share-gbvh-assessment", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    getGbvhData(assessmentsAPIBody) {
      return axios
        .post(
          env.apiUrl + "/gbvh/gbvh-assessment-overview",
          assessmentsAPIBody,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.gbvhData = res.data.data;
          this.anticipatedResults = this.gbvhData.anticipatedResults.slice(
            0,
            this.chunk
          );
          this.currentActionsResults = this.gbvhData.currentActions.slice(
            0,
            this.chunk
          );
          this.plannedActionsResults = this.gbvhData.plannedActions.slice(
            0,
            this.chunk
          );
          if (this.gbvhData.gbvhAssessments[0]) {
            this.latestSurveyDate =
              new Date(
                this.gbvhData.gbvhAssessments[0].dateOfSurvey
              ).toLocaleString("en-US", { month: "short" }) +
              " " +
              new Date(
                this.gbvhData.gbvhAssessments[0].dateOfSurvey
              ).getFullYear();
          }
          if (this.gbvhData.gbvhAssessments[1]) {
            this.oldestSurveyDate =
              new Date(
                this.gbvhData.gbvhAssessments[1].dateOfSurvey
              ).toLocaleString("en-US", { month: "short" }) +
              " " +
              new Date(
                this.gbvhData.gbvhAssessments[1].dateOfSurvey
              ).getFullYear();
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    loadMoreAnticipatedData(clicks) {
      this.anticipatedClick = clicks;
      var lastIndex = this.chunk * this.anticipatedClick;
      this.anticipatedResults = this.gbvhData.anticipatedResults.slice(
        0,
        lastIndex
      );
    },
    loadMoreCurrentActionData(clicks) {
      this.currentActionClick = clicks;
      var lastIndex = this.chunk * this.currentActionClick;
      this.currentActionsResults = this.gbvhData.currentActions.slice(
        0,
        lastIndex
      );
    },
    loadMorePlannedActionData(clicks) {
      this.plannedActionClick = clicks;
      var lastIndex = this.chunk * this.plannedActionClick;
      this.plannedActionsResults = this.gbvhData.plannedActions.slice(
        0,
        lastIndex
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;
    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }
    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
  a {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;
    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }
    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .download-csv-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 290px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -86px;
  bottom: 100%;
  white-space: normal;
  word-wrap: break-word;
}
.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;
  .container {
    margin: 0 auto;
    .modal {
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      padding-top: 100px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0, 0, 0); /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;
      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
    .save-opportunity-button {
      text-align: right;
    }
    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }
  .Ind_Inv_Icon {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer !important;
    img {
      width: 30px !important;
      height: 30px;
      object-fit: contain;
      margin-right: 0 !important;
      margin-top: 30px !important;
    }
    span {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-left: 20px !important;
    }
  }
  .Indirect_Investment {
    top: 0;
    left: 0;
  }
  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }
    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;
      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }
      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;
        .invite-member-btn {
        }
        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }
    .EmailInvitemember {
      display: flex;
      margin-top: 33px;
      .EmailInput {
        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;
          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }
  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }
  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;
    .OverviewBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 0 40px 40px;
      padding-top: 40px;
      margin-bottom: 18px;
      .OverviewTitle {
        display: flex;
        align-items: center;
        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }
        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }
      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .EligibleListImg {
              position: relative;
              z-index: 1;
              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;
              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }
              img {
                width: 40px !important;
                height: 40px;
              }
            }
            .lastAffter {
              &::after {
                content: none;
              }
            }
            .EligibleTitle {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }
              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }
              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }
          .bigMargin {
            margin-bottom: 25px;
          }
          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }
          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }
            .TooltipImgText {
              display: flex;
              align-items: center;
              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }
              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }
              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }
          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;
              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }
              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
        .spiderchart_container {
          width: 60%;
          .chartjs-render-monitor {
            margin-left: -33px;
            height: 530px !important;
          }
          .consumption-taab-company {
            max-width: 200px;
            left: -8%;
            top: 190px;
          }
          .consumption-taab-fund {
            max-width: 200px;
            left: 0;
            top: 120px;
          }

          .leadership-taab-company {
            max-width: 200px;
            top: -40px;
            right: 41%;
          }
          .leadership-taab-fund {
            max-width: 200px;
            right: 10rem;
            top: 0;
          }

          .employment-taab-company {
            max-width: 200px;
            right: 25px;
            top: 200px;
          }
          .employment-taab-fund {
            max-width: 200px;
            bottom: 12rem;
            right: 5rem;
          }

          .entrepreneurship-taab-company {
            max-width: 200px;
            right: 41%;
            bottom: -4%;
          }
          .entrepreneurship-taab-fund {
            max-width: 200px;
            left: 0px;
            bottom: 5.6rem;
          }
        }
        .spiderchartContent {
          width: 60%;
        }
      }
    }
    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;
      &.gbvh {
        .barchart2xCriteria_Wrepper {
          margin-left: 0;
          margin-right: auto;
        }
        .Profile_CountryWrepper {
          max-width: 523px;
          width: 100%;
        }
        h2 {
          width: 518px;
        }
        .PartyVerifiedToolTip {
          display: flex;
          align-items: flex-start;
          .PartyTooltipImg {
            margin-right: 10px;
            img {
              margin: 0;
              min-height: 35px;
              max-width: 18px;
            }
          }
        }
        .BorderLine {
          border: 1px solid #d0d0d0;
          max-width: 100%;
          margin: 30px 0;
        }
        .gbvh-right-side-bread-list {
          display: flex;
          align-items: center;
          span {
            font-size: 15px;
            font-weight: bolder;
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 30px;
            }
            &:nth-child(2) {
              margin-left: 190px;
            }
            img {
              margin-right: 10px;
              max-width: 20px;
            }
          }
        }
        .gbvh-bread-list {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          span {
            color: black;
            font-size: 13px;
            display: flex;
            align-items: center;
            &:first-child {
              margin-right: 30px;
            }
            img {
              margin-right: 10px;
              max-width: 20px;
            }
          }
        }
        .gbvh-progress-wrapper {
          .gbvh-progress-head {
            color: #1b1b1b;
            span {
              display: flex;
              img {
                margin-left: 10px;
              }
            }
          }
          .gbvh-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;
            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }
            .gbvh-progress-content--left {
              color: #1b1b1b;
            }
            .gbvh-progress-content--right {
              width: 60%;
              line-height: 1;
              .bar-div {
                span {
                  color: #646363;
                  width: 100px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      .Profile_CountryWrepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .Profile_Block {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          margin-bottom: 18px;
          max-width: 523px;
          width: 100%;
          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 100%;
            margin: 30px 0;
          }
          .actions-left-side {
            width: 260px;
          }
          .actions-right-side {
            display: flex;
            padding: 10px;
            width: 50px;
            height: 50px;
            img {
              margin: auto;
            }
          }
          .actions-efforts-section {
            padding-top: 20px;
            font-size: small;
            span {
              margin-right: 20px;
            }
          }
          .Profile_blockListText {
            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-bottom: 30px;
              b {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }
              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }
            ul {
              border-top: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 30px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              li {
                width: 48%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;
                word-break: break-all;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }
              }
            }
            .DownloadBtnDeck {
              display: flex;
              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;
                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }
                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }
          }
          .CountryOverviewText {
            margin-bottom: 30px;
            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 5px;
            }
            p,
            .leading-7 {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              display: -webkit-box;
              -webkit-line-clamp: 15;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            a {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              display: flex;
              align-items: center;
              img {
                margin-left: 5px !important;
                width: 15px !important;
                height: 15px;
                object-fit: contain;
              }
            }
          }
          .WanttoUnderstandHere {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
            a {
              text-decoration: underline;
            }
          }
          .ContryNameImg {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img {
              width: 60px;
              height: 40px;
              object-fit: contain;
              margin-right: 15px;
            }
            h1 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }
          }
          .ExploreBtnDeck {
            display: flex;
            margin-top: 30px;
            div {
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;
              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }
              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
      .barchart2xCriteria_Wrepper {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        max-width: 740px;
        width: 100%;
        margin-left: auto;
        .CriteriaTitleText {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }
          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }
        }
        .ChartBarContentWrepper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          .ChartBarContent {
            width: 100%;
            max-width: 310px;
            .Founded_WomanText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              color: #1b1b1b;
              margin-bottom: 15px;
            }
            .Founded_WomanImg {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }
            .ChartBarPercentageText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 10px;
            }
          }
        }
        .Consumption_ListText {
          margin-bottom: 60px;
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }
          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }
          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;
              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
        .IndirectInvestmentListContent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .IndirectContent {
            max-width: 301px;
            width: 100%;
            .IndirectInvWrepper {
              margin-bottom: 45px;
              .IndirectInvText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 16px;
              }
              .IndirectInvImg {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .GBVdetailsBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      .GBVdetailsContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .overOverviewDeitail {
          width: 100%;
          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }
        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;
          .step_gbvRisk {
            flex-wrap: wrap;
          }
          .GbvRiskTitleWrepper {
            margin-bottom: 40px;
            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }
              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }
          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;
              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }
              span {
                margin-right: 5px;
              }
            }
            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }
              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;
                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }
    .esg-pre-screening {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 20px;
      .OverviewTitle {
        h2 {
          width: 320px;
        }
      }
      .GBVdetailsContent {
        justify-content: space-between;
        ul {
          margin-top: 1.5rem;
        }
        ul li {
          display: flex;
          margin: 0;
        }
        .overOverviewDeitail {
          width: 100%;
          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }
        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;
          .step_gbvRisk {
            flex-wrap: wrap;
          }
          .GbvRiskTitleWrepper {
            margin-bottom: 40px;
            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }
              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }
          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;
              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }
              span {
                margin-right: 5px;
              }
            }
            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }
              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;
                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }
    .amount-funded {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      margin-top: 20px;
      .funding-status-profile-container {
        margin: 1rem 0 3rem 0;
        ul {
          display: flex;
          justify-content: space-between;
        }
      }
      .funding-status-title {
        h2 {
          width: 250px;
        }
      }
      .funding-bar-chart {
        width: 50%;
      }
      .funding-status-info {
        width: 50%;
        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }
          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;
              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .CriteriaTitleText {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        p {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1b1b1b;
        }
      }
      .ChartBarContentWrepper {
        justify-content: space-between;
        margin: 0 auto 60px auto;
        .ChartBarContent {
          width: 100%;
          margin: auto;
          max-width: 400px;
          .Founded_WomanText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 15px;
          }
          .Founded_WomanImg {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }
          .ChartBarPercentageText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 10px;
          }
        }
      }
      .get-it-on-deal-button {
        text-align: right;
        margin-bottom: 20px;
      }
      .get-it-on-deal-button button {
        background: #fff;
        color: #1b1b1b;
        border-color: #201747;
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
      }
      .get-it-on-deal-button button:hover {
        background: #201747;
        color: #fff;
      }
    }
    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 185px;
      }
      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }
    .CountryLine {
      align-items: flex-end;
      h2 {
        width: auto;
      }
      span {
        width: 110px;
      }
    }
  }
  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
      &:first-child {
        margin-right: 10px;
      }
      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>