<template>
  <div class="mt-10">
    <h1 class="text-3xl font-bold text-[#21191D]">Portfolio Surveys</h1>
    <div
      v-if="!portfolioSurveys?.length || !portfolioCompanies?.length"
      class="mt-5"
    >
      <span class="text-red-500"> No surveys or companies found </span>
    </div>
    <div class="flex flex-col space-y-4" v-else>
      <label for="company" class="text-xl font-semibold text-[#21191D] mt-4"
        >Select Company</label
      >
      <select
        v-if="portfolioCompanies?.length"
        v-model="company"
        class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg w-1/6"
      >
        <option value="" disabled selected>Select Company</option>
        <option
          v-for="company in portfolioCompanies"
          :key="company._id"
          :value="company._id"
        >
          {{ company.name }}
        </option>
      </select>
      <div v-if="company">
        <span class="text-sm text-[#21191D]">
          Click on any survey to start
        </span>
        <div class="grid grid-cols-4 gap-4 w-1/2 my-4">
          <router-link
            class="bg-[#191B45] text-white rounded-lg"
            v-for="survey in portfolioSurveys"
            :key="survey"
            :to="`imm/surveys/${survey}`"
          >
            <button class="w-full h-full px-4 py-2">
              {{ surveysMapping[survey] }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import {
  checkWhiteLabel,
  surveysMapping as mapping,
} from "@/components/imm-components/white-label";

export default {
  name: "PortfolioSurveys",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  props: {
    portfolioCompanies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      portfolioSurveys: [],
      user: this.userStore.user,
      company: "",
      surveysMapping: mapping,
    };
  },
  async mounted() {
    this.user = this.userStore.user;
    if (!this.user) {
      this.user = await this.userStore.fetchUser();
    }
    this.portfolioSurveys = this.getWhiteLabel().portfolioSurveys;
  },
  methods: {
    getWhiteLabel() {
      return checkWhiteLabel(this.user.featureFlags);
    },
  },
  watch: {
    portfolioCompanies() {
      this.company = "";
    },
  },
};
</script>
