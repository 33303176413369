import BaseAPI from "@/api/index";

class ToolAPI {
  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  getCurrencyList() {
    return this.axiosInstance.get(`/tools/currency-list`);
  }
}

export default ToolAPI;