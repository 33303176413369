<template>
  <div class="static">
    <page-loader v-if="loading" />
    <div class="main-container mt-16" v-else>
      <div class="top-heading-section">
        <div class="inner-top-heading-section">
          <h1 class="heading">Impact Management and Measurement</h1>
          <p class="text-content" v-if="investor">
            Welcome to Equilo's revolutionary solution for assessing your
            portfolio with a gender lens, managing and measuring the impact of
            investments, assessing and mitigating ESG risk, climate risk and
            adaptation, and tracking your portfolio companies’ financials.
          </p>
          <p class="text-content" v-else>
            Welcome to Equilo's revolutionary solution for assessing your
            company's gender and ESG health, and financial well-being. Our
            platform ensures compliance and alignment with your investors, while
            effortlessly tracking your impact.
          </p>
          <div>
            <button
              class="flex justify-center bg-[#191B45] text-white px-5 py-3 rounded-lg font-bold my-3"
              @click="getStarted"
              v-if="immAccess"
            >
              Get started
              <img
                class="mt-2 ml-3"
                src="./../../../assets/images/IMM/Vector.svg"
              />
            </button>
            <UpgradeButton
              route="https://info.equilo.io/meetings/matthew-aycoth/meet-w-matt-jimena"
              v-else
            ></UpgradeButton>
          </div>
        </div>
      </div>
      <div class="flex flex-col mx-20">
        <HowIMM class="how-imm-section" :user="user"></HowIMM>
        <ImpactLevels
          class="impact-level-section"
          v-if="investor"
        ></ImpactLevels>
        <div class="imm-process-section">
          <div class="heading">
            <h2>{{ getWhiteLabel().title }} process</h2>
          </div>
          <div class="steps-section">
            <CreateInvestorProfile
              v-if="investor"
              :user="user"
              :immAccess="immAccess"
            ></CreateInvestorProfile>
            <CreateCompanyProfile
              v-if="!investor && getWhiteLabel().companyLandingSurvey"
              :user="user"
              :immAccess="immAccess"
            ></CreateCompanyProfile>
            <SetUpPreferences
              v-if="investor"
              :user="user"
              :preferences="preferences"
              class="mt-10"
            ></SetUpPreferences>
            <TakeAssessments
              :immAccess="immAccess"
              :user="user"
              v-else
              class="mt-10"
            ></TakeAssessments>
          </div>
          <div
            v-if="user?.membership2xData && preferences.length && immAccess"
            class="mt-20 flex w-3/4 justify-end pr-10"
          >
            <router-link
              to="/imm/dashboard"
              class="bg-[#191B45] text-white p-5 rounded-lg font-bold"
            >
              Go to {{ getWhiteLabel().title }} Dashboard
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HowIMM from "@/components/imm-components/how-imm.vue";
import ImpactLevels from "@/components/imm-components/impact-levels-imm.vue";
import CreateInvestorProfile from "@/components/imm-components/create-investor-profile-imm.vue";
import CreateCompanyProfile from "@/components/imm-components/create-company-profile-imm.vue";
import SetUpPreferences from "@/components/imm-components/set-up-preferences-imm.vue";
import TakeAssessments from "@/components/imm-components/take-assessments-imm.vue";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import apiService from "@/helpers/apiServices.js";
import { useUserStore } from "@/stores/user";
import UpgradeButton from "@/components/Paywall/Upgrade-Button.vue";
import { checkWhiteLabel } from "@/components/imm-components/white-label";
import pageLoader from "@/components/page-loader.vue";

export default {
  name: "IMMLandingPage",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      user: [],
      loading: true,
      preferences: [],
      companySurveyProfileData: [],
      investor: false,
      immAccess: false,
    };
  },
  components: {
    HowIMM,
    ImpactLevels,
    CreateInvestorProfile,
    CreateCompanyProfile,
    SetUpPreferences,
    TakeAssessments,
    UpgradeButton,
    pageLoader,
  },
  async mounted() {
    await this.userStore.fetchUser(false, true);
    this.user = this.userStore.user;
    this.investor = this.user.investor;
    this.immAccess = this.user.featureFlags.includes("imm");
    await this.getCompanyProfile();
    const {
      data: { data },
    } = await axios.get(`${env.apiUrl}/imm-preferences/`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
      },
    });
    if (data) {
      this.preferences = data;
    }
    this.loading = false;
  },
  methods: {
    getStarted() {
      if (this.immAccess) {
        if (this?.investor) {
          if (this?.user.membership2xData || this.preferences.length) {
            this.$router.push({ path: "/imm/dashboard" });
          } else if (this?.user.membership2xData && !this.preferences.length) {
            this.$router.push({ path: "/imm/setup-preferences" });
          } else {
            this.$router.push({ path: "/imm/create-investor-profile" });
          }
        } else {
          // sweef asked to fill out the survey for the companies, so we added the getWhiteLabel().companyLandingSurvey flag
          if (
            this?.user.hasCompanyProfile ||
            !this.getWhiteLabel().companyLandingSurvey
          ) {
            this.$router.push({ path: "/imm/dashboard" });
          } else {
            this.$router.push({ path: "/imm/create-company-profile" });
          }
        }
      }
    },
    async getCompanyProfile() {
      await apiService
        .fetchCompanyProfile()
        .then(async (response) => {
          this.companySurveyProfileData = await response.data.data;
        })
        .catch(async (err) => {
          console.error("Error fetching company profile: ", err);
        });
    },
    getWhiteLabel() {
      return checkWhiteLabel(this.user.featureFlags);
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .top-heading-section {
    background-color: #ebecfa;

    .inner-top-heading-section {
      width: 1440px;
      top: 80px;
      padding: 25px 80px 25px 80px;
      gap: 16px;

      .heading {
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
      }

      .text-content {
        width: 782px;
        margin-top: 16px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }
    }
  }

  .how-imm-section {
    margin-top: 32px;
    gap: 24px;
  }

  .impact-level-section {
    margin-top: 44px;
    gap: 24px;
  }

  .imm-process-section {
    margin-top: 44px;
    margin-bottom: 115px;
    gap: 24px;

    .heading {
      margin-bottom: 24px;

      h2 {
        font-family: Montserrat;
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;
      }
    }
  }
}
</style>
