<template>
  <div class="h-full">
    <div class="flex flex-col justify-between h-full">
      <div class="w-full bg-white p-4 mb-2">
        <div class="text-xs text-gray-400">
          {{ historyDescription.date }} XXXX
        </div>
        <div class="font-bold text-[#201747] text-md">
          {{ historyDescription.title }}
        </div>
        <div class="text-sm text-[#201747]">
          {{
            historyDescription.description.length > 45
              ? historyDescription.description.slice(0, 45) + "..."
              : historyDescription.description
          }}
        </div>
        <div class="text-blue-500 cursor-pointer" @click="backToHistory">
          <span>&#8592;</span
          ><span class="text-xs font-medium ml-2">Back to history</span>
        </div>
      </div>
      <div class="flex flex-col justify-end w-full overflow-auto">
        <div class="flex flex-col overflow-auto">
          <div class="flex flex-col overflow-auto px-4">
            <messageList
              :YOU="YOU"
              :messages="messages"
              :isLoadingChatResponse="isLoadingChatResponse"
              :expandedMessageIndex="expandedMessageIndex"
              @toggle-sources="toggleSources"
              @collect-feedback="collectFeedback"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import messageList from "../common/messageList.vue";
export default {
  name: "chatHistory",
  components: {
    messageList,
  },
  props: {
    YOU: {
      type: String,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
    },
    isLoadingChatResponse: {
      type: Boolean,
      required: true,
    },
    expandedMessageIndex: {
      type: Number,
      required: true,
    },
    historyDescription: {
      type: Object,
      required: true,
    },
  },
  emits: ["collect-feedback", "toggle-sources", "back-to-history"],
  methods: {
    collectFeedback(messageId) {
      this.$emit("collect-feedback", messageId);
    },
    toggleSources(index) {
      this.$emit("toggle-sources", index);
    },
    backToHistory() {
      this.$emit("back-to-history");
    },
  },
};
</script>
