import { render, staticRenderFns } from "./my2xCertification-Verification.vue?vue&type=template&id=977c1cd4&scoped=true"
import script from "./my2xCertification-Verification.vue?vue&type=script&lang=js"
export * from "./my2xCertification-Verification.vue?vue&type=script&lang=js"
import style0 from "./my2xCertification-Verification.vue?vue&type=style&index=0&id=977c1cd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "977c1cd4",
  null
  
)

export default component.exports