var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"static"},[_c('div',{staticClass:"main-container mb-20"},[_c('BreadCrumbs',{staticClass:"breadcrumbs-main-container"}),_vm._m(0),_c('div',{staticClass:"mt-11"},[_c('div',{staticClass:"statement-heading flex justify-between",class:{ 'is-fixed fixed-header': _vm.fixedComprehensiveSection }},[_vm._v(" Sustainable Development Goals companies overall "),(_vm.isComprehensiveTableVisible)?_c('img',{attrs:{"src":require("@/assets/images/arrow-up.svg")},on:{"click":function($event){_vm.isComprehensiveTableVisible = !_vm.isComprehensiveTableVisible}}}):_vm._e(),(!_vm.isComprehensiveTableVisible)?_c('img',{attrs:{"src":require("@/assets/images/arrow-down.svg")},on:{"click":function($event){_vm.isComprehensiveTableVisible = !_vm.isComprehensiveTableVisible}}}):_vm._e()]),_c('div',{ref:"comprehensiveSection"},[(_vm.isComprehensiveTableVisible)?_c('ImpactPageBarChart',{staticClass:"mt-6",attrs:{"chartData":_vm.chartData,"chartLabels":_vm.chartLabels}}):_vm._e()],1)]),_c('div',{staticClass:"mt-11"},[_vm._m(1),_c('div',{ref:"comprehensiveSection"},[_c('ImpactTable',{staticClass:"mt-4",attrs:{"headings":[
            'SDG#4: Quality Education',
            'Positive Impact generated',
          ],"tableData":_vm.tableData}})],1)]),_vm._m(2),_c('div',{staticClass:"mt-11"},[_vm._m(3),_c('div',{ref:"isUnTableRef"},[_c('ImpactTable',{staticClass:"mt-4",attrs:{"headings":['School Enrolment: Female (PI1081)', '2022', '2023'],"tableData":_vm.tableDataIRIS}})],1)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-section flex justify-between mt-6"},[_c('div',{staticClass:"page-title"},[_vm._v("Impact")]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statement-heading flex justify-between"},[_vm._v(" UN SDGs "),_c('img',{attrs:{"src":require("@/assets/images/arrow-down.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-section flex justify-between mt-6"},[_c('div',{staticClass:"bar-chart-section flex justify-between items-center"},[_c('div',{staticClass:"filter-options-section flex"},[_c('div',{staticClass:"filter-options flex items-center"},[_c('div',[_vm._v("By quarters")]),_c('div',{staticClass:"active"},[_vm._v("All time")])]),_c('div',{staticClass:"select-year"},[_c('select',{attrs:{"name":"year","id":"year"}},[_c('option',{attrs:{"value":"2023"}},[_vm._v("2023")]),_c('option',{attrs:{"value":"2022"}},[_vm._v("2022")])])])])]),_c('div',{staticClass:"download-button"},[_c('button',{staticClass:"flex"},[_vm._v(" Customize and download the report "),_c('img',{attrs:{"src":require("@/assets/images/2x_certify/download-navy-blue.svg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statement-heading flex justify-between"},[_vm._v(" IRIS+ metrics "),_c('img',{attrs:{"src":require("@/assets/images/arrow-down.svg")}})])
}]

export { render, staticRenderFns }