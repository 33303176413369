import { render, staticRenderFns } from "./gbv-risk-detail.vue?vue&type=template&id=6dd8a73c"
import script from "./gbv-risk-detail.vue?vue&type=script&lang=js"
export * from "./gbv-risk-detail.vue?vue&type=script&lang=js"
import style0 from "./gbv-risk-detail.vue?vue&type=style&index=0&id=6dd8a73c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports