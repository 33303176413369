<template>
  <div class="criteria-content">
    <div class="eligibility">
      <span class="badge">2X Eligible</span>
    </div>

    <div class="criteria-grid">
      <div
        v-for="(value, key) in metrics"
        :key="key"
        class="criteria-item"
        :class="getCriteriaClass(value)"
      >
        <span class="percentage">{{ value }}%</span>
        <span class="label">{{ formatLabel(key) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CriteriaContent",
  props: {
    metrics: {
      type: Object,
      required: true,
      validator: function (obj) {
        // ensure all required metrics are present
        const requiredKeys = [
          "leadership",
          "employment",
          "supplyChain",
          "entrepreneurship",
          "products",
          "governance",
        ];
        return requiredKeys.every((key) => key in obj);
      },
    },
  },
  methods: {
    formatLabel(key) {
      // convert camelCase to space-separated words
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },
    getCriteriaClass(value) {
      // add classes based on value ranges
      if (value >= 40) return "high-impact";
      if (value >= 20) return "medium-impact";
      return "low-impact";
    },
  },
};
</script>

<style scoped>
.criteria-content {
  padding: 16px 0;
}

.eligibility {
  margin-bottom: 24px;
}

.badge {
  background: #48bb78;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}

.criteria-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 16px;
}

.criteria-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.percentage {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 4px;
}

.label {
  font-size: 14px;
  color: #718096;
  line-height: 1.4;
}

/* impact level styling */
.high-impact .percentage {
  color: #48bb78;
}

.medium-impact .percentage {
  color: #4299e1;
}

.low-impact .percentage {
  color: #718096;
}

/* hover effects */
.criteria-item {
  transition: transform 0.2s ease;
}

.criteria-item:hover {
  transform: translateY(-2px);
}

/* responsive adjustments */
@media (max-width: 640px) {
  .criteria-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
