<template>
  <div class="static">
    <div class="main-container pb-20 mx-20">
      <BreadCrumbs></BreadCrumbs>
      <div class="page-title-section flex justify-between mt-6">
        <div class="page-title">My {{ title }} Dashboard</div>
        <div
          v-if="user?.investor && selectedPortfolio && tabView === 'portfolio'"
          class="page-figures cursor-pointer"
          @click="showPortfolioDetails = !showPortfolioDetails"
        >
          <img src="@/assets/images/IMM/setting-grey.svg" />
        </div>
      </div>

      <!--Menu-->
      <div class="mt-4">
        <div class="time-filter-section flex justify-between">
          <div class="download-button flex gap-4">
            <router-link
              :to="user?.membership2xData ? `/2X-Ecosystem/2XC-Membership/${user.organization._id}` : '/imm/create-investor-profile'"
            >
              <button>{{ user?.membership2xData ? 'View my investor profile' : 'Create investor profile'}}</button>
            </router-link>
            <button class="flex opacity-50 cursor-not-allowed" disabled>
              Download
              <img src="@/assets/images/download-white.svg" />
            </button>
          </div>
        </div>
      </div>

      <!--COMPANIES-->
      <div v-if="isPortfolioCompanyView">
        <!--Menu-->
        <div class="mt-8">
          <div class="time-filter-section flex justify-between">
            <div class="filter-options-section flex">
              <div class="filter-options flex">
                <div>Quarters</div>
                <div>Annual</div>
              </div>
              <div class="select-year">
                <select name="year" id="year" disabled>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                </select>
              </div>
            </div>
            <div class="download-button">
              <button class="flex opacity-50 cursor-not-allowed" disabled>
                Download
                <img src="@/assets/images/download-white.svg" />
              </button>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <local-loader v-if="loading"></local-loader>
          <div v-if="user && !loading" class="flex flex-col gap-6">
            <div v-if="error" class="text-red-500">
              {{ error.message }}
            </div>

            <!--Company Cards-->
            <div class="grid grid-cols-1 gap-6" v-if="investeeId">
              <div class="bg-white rounded-xl shadow-md overflow-hidden">
                <div class="p-6">
                  <div class="text-2xl font-bold mb-4">
                    About the Organization
                  </div>
                  <!--About the Organization Section-->
                  <div>
                    <div class="flex justify-between items-center mb-4">
                      <h3 class="text-xl font-bold">
                        {{ companyProfile?.name }}
                      </h3>
                    </div>
                    <div
                      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6"
                    >
                      <div>
                        <div class="text-gray-500 text-sm mb-1">
                          Entity Type
                        </div>
                        <div class="font-medium">
                          {{ companyProfile?.entityType }}
                        </div>
                      </div>

                      <div>
                        <div class="text-gray-500 text-sm mb-1">Sector</div>
                        <div class="font-medium">
                          {{ companyProfile?.sectors }}
                        </div>
                      </div>

                      <div>
                        <div class="text-gray-500 text-sm mb-1">Sub-Sector</div>
                        <div class="font-medium">
                          {{ companyProfile?.subSectors }}
                        </div>
                      </div>
                    </div>
                    <!--More about the entity-->
                    <div class="flex justify-end mt-4">
                      <button
                        class="text-blue-500 hover:text-blue-700 text-sm font-medium"
                        @click="showEntityDetails = !showEntityDetails"
                      >
                        {{
                          showEntityDetails
                            ? "Show less"
                            : "More about the entity"
                        }}
                      </button>
                    </div>

                    <!--Expanded Details-->
                    <div v-if="showEntityDetails" class="mt-4 border-t pt-4">
                      <div
                        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                      >
                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            Countries of Operation
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.country }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            Currency for Financial Reporting
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.currency }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            # of FTEs
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.fte }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">Website</div>
                          <div class="font-medium">
                            {{ companyProfile?.website }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            Contact Name
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.contactName }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            Contact Title
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.contactTitle }}
                          </div>
                        </div>

                        <div>
                          <div class="text-gray-500 text-sm mb-1">
                            Contact Email
                          </div>
                          <div class="font-medium">
                            {{ companyProfile?.contactEmail }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--From Prefs-->
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <component
                :is="getComponentName(module)"
                v-for="module in modulesFromPreferences"
                :key="module"
                :user="user"
                :investeeId="investeeId ? user._id : null"
                :status2xCertification="status2xCertification"
              ></component>
              <component
                v-if="user.featureFlags.includes('imm-sweef')"
                :is="getComponentName('Energy')"
                :user="user"
                :investeeId="user._id"
                :status2xCertification="status2xCertification"
              ></component>
            </div>
            <!--Due Dilligence-->
            <div v-if="user.featureFlags.includes('imm-sweef')">
              <span class="text-2xl font-bold">Due Dilligence:</span>
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-10"
              >
                <component
                  :is="getComponentName(module)"
                  v-for="module in ['Climate', 'E&S', 'EnvExclusions']"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                  :status2xCertification="status2xCertification"
                ></component>
              </div>
            </div>
            <!-- Not prefs-->
            <div class="mt-20" v-if="extraModules.length > 0">
              <span class="text-2xl font-bold"
                >You may also be interested in:</span
              >
              <div
                class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-6 my-10"
              >
                <component
                  :is="getComponentName(module)"
                  v-for="module in extraModules"
                  :key="module"
                  :user="user"
                  :investeeId="investeeId ? user._id : null"
                  :status2xCertification="status2xCertification"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--INVESTOR-->
      <div v-else>
        <!--Tab selector between portfolio and deals-->
        <div class="flex gap-4 border-b border-gray-200 mt-4">
          <TabView
            :tab-view="tabView"
            tab="portfolio"
            label="Portfolio"
            @click="tabView = 'portfolio'"
          />
          <TabView
            :tab-view="tabView"
            tab="surveys"
            label="Surveys"
            @click="tabView = 'surveys'"
          />
          <TabView
            :tab-view="tabView"
            tab="deals"
            label="Deals"
            @click="tabView = 'deals'"
          />
        </div>
        <!--Portfolio-->
        <div v-if="tabView === 'portfolio'">
          <!--Portfolios Dropdown-->
          <!--We need to refactor this into a new component-->
          <div
            class="mt-10 w-1/3 sticky top-16 backdrop-blur-sm bg-white/30 z-50"
          >
            <div class="flex flex-col space-y-4">
              <label for="portfolio" class="text-3xl font-bold text-[#21191D]"
                >Select Portfolio or Fund</label
              >
              <select
                name="portfolio"
                id="portfolio"
                v-model="selectedPortfolio"
                class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg"
                @change="fetchByPortfolio"
              >
                <option value="" disabled selected>
                  Select Portfolio or Fund
                </option>
                <option
                  v-for="portfolio in investorPreferences"
                  :key="portfolio._id"
                  :value="portfolio"
                >
                  {{ portfolio.portfolioName }}
                </option>
              </select>
            </div>
          </div>

          <!--Aggregation Results-->
          <div v-if="selectedPortfolio" class="mt-10 py-4 rounded-xl">
            <!-- under construction banner -->
            <div class="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <svg
                    class="h-5 w-5 text-yellow-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <div class="ml-3">
                  <p class="text-sm text-yellow-700">
                    This section is currently under construction
                  </p>
                </div>
              </div>
            </div>
            <h3 class="text-2xl font-bold mb-4 mt-2 text-[#21191D]">
              Aggregated Portfolio Performance
            </h3>
            <AggregatedPortfolio
              :portfolio-name="selectedPortfolio.portfolioName"
            />
          </div>

          <!--No Investee Details-->
          <div
            v-if="selectedPortfolio && !portfolioDetails.length"
            class="mt-10 flex flex-col w-1/4 py-3 bg-red-100 rounded-xl px-5"
          >
            There's no data available for this portfolio yet. Invite a company
            to your portfolio to get started.
            <button
              class="bg-[#191B45] text-white p-2 rounded-lg mt-5 font-semibold"
              @click="showAddInvesteesModal = true"
            >
              Invite Company
            </button>
          </div>

          <!--Add investees modal-->
          <div
            v-if="showAddInvesteesModal"
            class="fixed inset-0 flex items-center justify-center z-50"
          >
            <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
              <h2 class="text-xl font-semibold mb-4">Add New Investee</h2>
              <div class="flex flex-col gap-4">
                <div>
                  <label for="company" class="my-2 block">Company Name</label>
                  <input
                    v-model="newInvesteeCompany"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label for="email" class="my-2 block">Email</label>
                  <input
                    v-model="newInvesteeEmail"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label for="portfolioType" class="my-2 block"
                    >Portfolio Type</label
                  >
                  <select
                    v-model="newInvesteePortfolioType"
                    class="w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="" disabled selected>
                      Select a portfolio type
                    </option>
                    <option value="Portfolio">Portfolio</option>
                    <option value="Alumni">Alumni</option>
                    <option value="Pipeline">Pipeline</option>
                  </select>
                </div>
              </div>
              <div class="flex justify-end mt-5">
                <button
                  @click="showAddInvesteesModal = false"
                  class="bg-gray-500 text-white px-3 py-2 rounded-md mr-2 font-semibold"
                >
                  Cancel
                </button>
                <button
                  @click="addInvestee"
                  class="bg-blue-500 text-white px-3 py-2 rounded-md font-semibold"
                >
                  Send invite
                </button>
              </div>
            </div>
            <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
          </div>

          <!--Portfolio Details-->
          <div
            v-if="showPortfolioDetails && selectedPortfolio"
            class="fixed inset-0 flex items-center justify-center z-50"
          >
            <div class="bg-white p-6 rounded-xl shadow-lg w-3/4 z-50 relative">
              <div
                class="absolute right-10 cursor-pointer"
                @click="showPortfolioDetails = false"
              >
                <img
                  src="@/assets/images/close-icon.svg"
                  alt="Close"
                  width="20px"
                  height="20px"
                />
              </div>
              <div class="bg-gray-100 rounded-xl mt-4 px-5">
                <div class="mt-10 py-5">
                  <div class="flex gap-20">
                    <h3 class="text-xl font-semibold mb-4">
                      Portfolio Details
                    </h3>
                  </div>
                  <!--Portfolio Name and Level-->
                  <div
                    class="flex flex-col justify-center gap-4 bg-gray-200 rounded-lg px-3 w-2/6 h-44 mb-4 shadow-md"
                  >
                    <div
                      v-if="!editPorftolioDetails"
                      class="flex flex-col justify-center gap-4"
                    >
                      <h2 class="text-lg font-semibold">
                        Name:
                        <span class="font-normal">{{
                          selectedPortfolio.portfolioName
                        }}</span>
                      </h2>
                      <h2 class="text-lg font-semibold">
                        Level:
                        <span class="font-normal">{{
                          selectedPortfolio.level
                        }}</span>
                      </h2>
                    </div>
                    <div v-else class="flex flex-col justify-center gap-2">
                      <input
                        v-model="selectedPortfolio.portfolioName"
                        class="p-2 border border-gray-300 rounded-md"
                        placeholder="Portfolio Name"
                      />
                      <select
                        v-model="selectedPortfolio.level"
                        class="p-2 border border-gray-300 rounded-md"
                      >
                        <option value="Compliance">Compliance</option>
                        <option value="Risk">Risk</option>
                        <option value="Performance">Performance</option>
                        <option value="Impact">Impact</option>
                      </select>
                    </div>
                    <!--Edit Button-->
                    <div class="flex gap-4">
                      <button
                        v-if="!editPorftolioDetails"
                        @click="editPorftolioDetails = true"
                        class="bg-[#2177c1] text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Edit details
                      </button>
                      <button
                        v-else
                        @click="updatePortfolio"
                        class="bg-green-500 text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Save details
                      </button>
                      <button
                        v-if="editPorftolioDetails"
                        @click="editPorftolioDetails = false"
                        class="bg-red-500 text-white p-2 rounded-lg mb-2 font-semibold"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                  <table
                    class="min-w-full bg-white border rounded-xl shadow-lg"
                  >
                    <thead class="bg-gray-200">
                      <tr>
                        <th class="py-3 px-5 border-b">Module</th>
                        <th class="py-3 px-5 border-b">Frequency</th>
                        <!--  <th class="py-3 px-5 border-b">Delete?</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="portfolio in selectedPortfolio.preferences"
                        :key="portfolio._id"
                        class="hover:bg-gray-100"
                      >
                        <td class="py-3 px-5 border-b">
                          {{ portfolio.title }}
                        </td>
                        <td class="py-3 px-5 border-b">
                          {{ portfolio.frequency }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    v-if="computeVariable().length"
                    @click="showAddModulesModal = true"
                    class="mt-4 bg-[#2177c1] text-white p-2 rounded-lg font-semibold"
                  >
                    Add new module
                  </button>
                  <!--Modules Modal-->
                  <div
                    v-if="showAddModulesModal"
                    class="fixed inset-0 flex items-center justify-center z-50"
                  >
                    <div class="bg-white p-6 rounded-xl shadow-lg w-1/3 z-50">
                      <h2 class="text-xl font-semibold mb-4">Add New Module</h2>
                      <div class="mb-4">
                        <label class="block text-gray-700">Module Name</label>
                        <select
                          v-model="newModuleName"
                          class="w-full p-2 border border-gray-300 rounded-md"
                        >
                          <option value="" disabled selected>
                            Select a module
                          </option>
                          <option
                            v-for="variable in computeVariable()"
                            :key="variable"
                            :value="variable"
                          >
                            {{ variable }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-4">
                        <label class="block text-gray-700">Frequency</label>
                        <select
                          v-model="newModuleFrequency"
                          class="w-full p-2 border border-gray-300 rounded-md"
                        >
                          <option value="" disabled selected>
                            Select a frequency
                          </option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="Annually">Annually</option>
                        </select>
                      </div>
                      <div class="flex justify-end">
                        <button
                          @click="showAddModulesModal = false"
                          class="bg-gray-500 text-white p-2 rounded-md mr-2"
                        >
                          Cancel
                        </button>
                        <button
                          @click="addModule"
                          class="bg-blue-500 text-white p-2 rounded-md"
                        >
                          Add Module
                        </button>
                      </div>
                    </div>
                    <div class="fixed inset-0 bg-black opacity-50 z-40"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="fixed inset-0 bg-black opacity-50 z-40"
              @click="showPortfolioDetails = false"
            ></div>
          </div>

          <!-- Portfolio Companies Table-->
          <div class="mt-4">
            <ImmPortfolioCompaniesTable
              :companies="portfolioCompanies"
              @invite-company="showAddInvesteesModal = true"
            />
          </div>
        </div>

        <!--Deals-->
        <div v-if="tabView === 'deals'">
          <DealsTable
            :companies="portfolioCompanies"
            @invite-company="showAddInvesteesModal = true"
          />
        </div>

        <!--Surveys-->
        <div v-if="tabView === 'surveys'">
          <div
            class="mt-10 w-1/3 sticky top-16 backdrop-blur-sm bg-white/30 z-50"
          >
            <div class="flex flex-col space-y-4">
              <label for="portfolio" class="text-3xl font-bold text-[#21191D]"
                >Select Portfolio or Fund</label
              >
              <select
                name="portfolio"
                id="portfolio"
                v-model="selectedPortfolio"
                class="focus:outline-none cursor-pointer p-4 border border-[#E1E3E9] text-[#21191D] font-medium rounded-lg"
                @change="fetchByPortfolio"
              >
                <option value="" disabled selected>
                  Select Portfolio or Fund
                </option>
                <option
                  v-for="portfolio in investorPreferences"
                  :key="portfolio._id"
                  :value="portfolio"
                >
                  {{ portfolio.portfolioName }}
                </option>
              </select>
            </div>
          </div>
          <PortfolioSurveys :portfolio-companies="portfolioCompanies" />
        </div>

        <!--TOAST-->
        <div
          v-if="showToast"
          class="fixed top-20 right-10 bg-green-500 text-white p-4 rounded-md shadow-md transition ease-in-out"
        >
          {{ toastMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import twoXCard from "@/components/imm-components/dashboard/2XCard.vue";
import genderROICard from "@/components/imm-components/dashboard/GenderROICard.vue";
import eAndSCard from "@/components/imm-components/dashboard/E&SCard.vue";
import financeCard from "@/components/imm-components/dashboard/FinancialCard.vue";
import climateCard from "@/components/imm-components/dashboard/ClimateCard.vue";
import impactCard from "@/components/imm-components/dashboard/ImpactCard.vue";
import localLoader from "@/components/local-loader.vue";
import energyCard from "@/components/imm-components/dashboard/EnergyCard.vue";
import EnvExclusions from "@/components/imm-components/dashboard/EnvExclusions.vue";
import apiService from "@/helpers/apiServices.js";
import ImmPortfolioCompaniesTable from "@/components/imm-components/ImmPortfolioCompaniesTable.vue";
import { checkWhiteLabel } from "@/components/imm-components/white-label";
import { useUserStore } from "@/stores/user";
import AggregatedPortfolio from "@/components/imm-components/dashboard/aggregated-portfolio/AggregatedPortfolio.vue";
import DealsTable from "@/components/imm-components/dashboard/DealsTable.vue";
import PortfolioSurveys from "@/components/imm-components/dashboard/PortfolioSurveys.vue";
import TabView from "@/components/imm-components/dashboard/TabView.vue";

export default {
  name: "IMMDashboardPage",
  components: {
    ImmPortfolioCompaniesTable,
    BreadCrumbs,
    twoXCard,
    genderROICard,
    eAndSCard,
    financeCard,
    climateCard,
    impactCard,
    localLoader,
    energyCard,
    EnvExclusions,
    AggregatedPortfolio,
    DealsTable,
    PortfolioSurveys,
    TabView,
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      user: {},
      entityType: "",
      immInvestorPreferences: [],
      allowedLevels: [],
      modulesFromPreferences: [],
      extraModules: [],
      loading: true,
      totalModules: [
        "E&S",
        "ESG",
        "Finance",
        "Climate",
        "Impact",
        "2X Certification",
        "Gender ROI™",
      ],
      selectedPortfolio: "",
      investorPreferences: [],
      portfolioDetails: [],
      portfolioCompanies: [],
      showToast: false,
      toastMessage: "",
      showAddModulesModal: false,
      newModuleName: "",
      newModuleFrequency: "",
      editPorftolioDetails: false,
      showAddInvesteesModal: false,
      newInvesteeEmail: "",
      newInvesteePortfolioType: "",
      newInvesteeCompany: "",
      showPortfolioDetails: false,
      investeeId: null,
      error: null,
      status2xCertification: "",
      title: "",
      selectedPortfolioType: "",
      selectedRows: Array(0).fill(false),
      showEntityDetails: false,
      companyProfile: null,
      teamId: null,
      tabView: "portfolio",
    };
  },
  async mounted() {
    const investeeId = this.$route.query.id;
    this.teamId = this.$route.query?.teamId;
    if (investeeId) {
      this.investeeId = investeeId;
      this.user = await this.fetchInvesteeUser();
      this.companyProfile = await this.fetchInvesteeCompanyProfile();
    } else {
      this.user = this.userStore.user;
      if (!this.user) {
        await this.userStore.fetchUser();
        this.user = this.userStore.user;
      }
    }
    try {
      this.teamId = this.$route.query?.teamId ?? this.user?.organization?._id;
      const response = await apiService.fetch2xCertRequest(this.teamId);
      this.status2xCertification = response?.data?.data?.status;
    } catch (error) {
      console.error("Error fetching 2x certification status:", error);
    }

    this.title = this.getWhiteLabel().title;

    // PREFERENCES
    if (this.isPortfolioCompanyView) {
      await this.fetchCompanyPreferences();
    } else {
      await this.fetchInvestorPreferences();
    }

    this.$store.commit("setNewBreadcrumbs", [
      { name: this.title, url: "/imm/landing-page" },
      { name: "Dashboard", url: "/imm/dashboard" },
    ]);
  },
  methods: {
    async fetchCompanyPreferences() {
      try {
        const url = this.investeeId
          ? `${env.apiUrl}/imm-preferences/investee-preferences?email=${this.user.email}`
          : `${env.apiUrl}/imm-preferences/investee-preferences`;

        const {
          data: {
            data: { immInvestorPreferences, allowedLevels },
          },
        } = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });

        const climateFrequency = immInvestorPreferences[0]?.preferences?.find(
          (pref) => pref.title === "Climate"
        )?.frequency;

        this.totalModules = !this.user.featureFlags?.includes("imm-sweef")
          ? this.totalModules.filter((module) => module !== "Energy")
          : climateFrequency === "Quarterly"
          ? this.totalModules.filter(
              (module) =>
                module !== "Climate" && module !== "E&S" && module !== "ESG"
            )
          : this.totalModules.filter(
              (module) =>
                module !== "Climate" &&
                module !== "E&S" &&
                module !== "ESG" &&
                module !== "Energy"
            );

        // remove 2X from sweef
        if (this.user.featureFlags.includes("imm-sweef")) {
          this.totalModules = this.totalModules.filter(
            (module) => module !== "2X Certification"
          );
        }

        this.immInvestorPreferences =
          immInvestorPreferences[0].preferences || [];

        this.modulesFromPreferences = this.totalModules.filter((module) =>
          this.immInvestorPreferences.map((pref) => pref.title).includes(module)
        );
        this.allowedLevels = allowedLevels;
        this.extraModules = this.totalModules.filter(
          (module) => !this.modulesFromPreferences.includes(module)
        );

        // add 2X for sweef
        if (this.user.featureFlags.includes("imm-sweef")) {
          this.extraModules.push("2X Certification");
        }

        this.loading = false;
      } catch (error) {
        console.error("Error fetching company preferences:", error);
        this.error = {
          message: "We couldn't find your preferences. Please contact support.",
          error: true,
        };
        this.modulesFromPreferences = this.totalModules;
        this.loading = false;
      }
    },
    getComponentName(module) {
      const componentMap = {
        "2X Certification": "twoXCard",
        "Gender ROI™": "genderROICard",
        Finance: "financeCard",
        Climate: "climateCard",
        Impact: "impactCard",
        Energy: "energyCard",
        "E&S": "eAndSCard",
        EnvExclusions: "EnvExclusions",
      };
      return componentMap[module] || null;
    },
    async fetchInvestorPreferences() {
      try {
        const {
          data: { data },
        } = await axios.get(`${env.apiUrl}/imm-preferences/`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        this.investorPreferences = data;
      } catch (error) {
        console.error("Error fetching investor preferences:", error);
        this.error = {
          message:
            "We couldn't fetch investor preferences. Please try again later.",
          error: true,
        };
      }
    },
    async fetchByPortfolio() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/portfolio/${this.selectedPortfolio.portfolioName}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.portfolioDetails = data;
        // reset the portfolio type filter when changing portfolios
        this.selectedPortfolioType = "";

        console.log(data);

        // Getting unique companies based on sent invitations
        this.portfolioCompanies = [];
        for (let i = 0; i < data.length; i++) {
          const invitation = data[i];
          if (this.portfolioCompanies.length === 0) {
            this.portfolioCompanies.push({
              userId: invitation.userId,
              name: invitation.companyName,
              teamId: invitation.userOrgId,
              email: invitation.email,
              inviterId: invitation.inviter,
              inviterOrgId: invitation.organization,
              portfolioType: invitation.portfolioType,
              _id: invitation._id,
            });
            continue;
          }

          if (
            !this.portfolioCompanies.some(
              (company) => company.name === invitation.companyName
            )
          ) {
            this.portfolioCompanies.push({
              userId: invitation.userId,
              name: invitation.companyName,
              teamId: invitation.userOrgId,
              email: invitation.email,
              inviterId: invitation.inviter,
              inviterOrgId: invitation.organization,
              portfolioType: invitation.portfolioType,
              _id: invitation._id,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching portfolio preferences:", error);
        this.error = {
          message:
            "We couldn't fetch portfolio preferences. Please try again later.",
          error: true,
        };
      }
    },
    async saveCompanyChanges(investee) {
      try {
        await axios.put(
          `${env.apiUrl}/imm-preferences/investee-details/${investee._id}`,
          {
            portfolioType: investee.portfolioType,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.showToast = true;
        this.toastMessage = "Changes saved successfully";
        setTimeout(() => {
          this.showToast = false;
        }, 2000);
      } catch (error) {
        console.error("Error saving investee changes:", error);
        this.error = {
          message: "We couldn't save your changes. Please try again later.",
          error: true,
        };
      }
    },
    computeVariable() {
      // define the list of variables
      const variables = [
        "2X Certification",
        "Gender ROI™",
        "Impact",
        "ESG",
        "Climate",
        "Finance",
        "E&S",
        "EnvExclusions",
      ];
      // filter out the variables present in selectedPortfolio preferences
      const filteredVariables = variables.filter(
        (variable) =>
          !this.selectedPortfolio.preferences.some(
            (pref) => pref.title === variable
          )
      );
      // return the new array with the filtered values
      return filteredVariables;
    },
    async addModule() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            preferences: [
              {
                title: this.newModuleName,
                frequency: this.newModuleFrequency,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );

        this.selectedPortfolio = data;
        this.showAddModulesModal = false;
      } catch (error) {
        console.error("Error adding module:", error);
      }
    },
    async updatePortfolio() {
      try {
        const {
          data: { data },
        } = await axios.put(
          `${env.apiUrl}/imm-preferences/${this.selectedPortfolio._id}`,
          {
            portfolioName: this.selectedPortfolio.portfolioName,
            level: this.selectedPortfolio.level,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        this.selectedPortfolio = data;
        await this.fetchInvestorPreferences();
        this.editPorftolioDetails = false;
      } catch (error) {
        console.error("Error updating portfolio:", error);
      }
    },
    async addInvestee() {
      const payload = {
        emails: [this.newInvesteeEmail],
        companyName: this.newInvesteeCompany,
        portfolioName: this.selectedPortfolio.portfolioName,
      };
      if (this.newInvesteePortfolioType) {
        payload.portfolioType = this.newInvesteePortfolioType;
      }
      await axios.post(
        `${env.apiUrl}/imm-preferences/investee-invites`,
        payload,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );

      await this.fetchByPortfolio();
      this.showAddInvesteesModal = false;
    },
    async fetchInvesteeUser() {
      try {
        const {
          data: { user },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/user/${this.investeeId}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return user;
      } catch (error) {
        console.error("Error fetching investee user:", error);
        this.error = {
          message:
            "We couldn't fetch the investee user. Please contact support.",
          error: true,
        };
        return null;
      }
    },
    getWhiteLabel() {
      return checkWhiteLabel(this.user.featureFlags);
    },
    async fetchInvesteeCompanyProfile() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${env.apiUrl}/imm-preferences/company-profile-survey/${this.teamId}`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return data;
      } catch (error) {
        console.error("Error fetching company profile:", error);
        this.error = {
          message:
            "We couldn't fetch the company profile. Please contact support.",
          error: true,
        };
        return null;
      }
    },
  },
  computed: {
    isPortfolioCompanyView() {
      return !this.user?.investor || this.investeeId;
    },
    filteredPortfolioDetails() {
      if (!this.selectedPortfolioType) {
        return this.portfolioDetails;
      }
      return this.portfolioDetails.filter(
        (investee) => investee.portfolioType === this.selectedPortfolioType
      );
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  th,
  td {
    white-space: nowrap;
    text-align: center;
  }

  td {
    vertical-align: middle;
  }

  a {
    display: inline-block;
    width: 100%;
    text-align: center;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.main-container {
  color: #2c2e35;

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .time-filter-section {
    .filter-options-section {
      gap: 32px;

      .filter-options {
        height: 32px;
        background: #f4f6f9;
        border-radius: 8px;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #9ea4ba;

        div {
          padding: 6px 12px 6px 12px;
        }

        .active {
          background: #2177c1;
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }

    .download-button {
      button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        background-color: #2177c1;
        gap: 8px;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
      }
    }
  }
}
</style>
