<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";

export default {
  components: { EquiloButton },
  props: {
    text: {
      type: String,
      required: false,
      default: "View Evaluation Results"
    },
    isAllowed: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  emits: ["on-click"]
};
</script>

<template>
  <EquiloButton
    :text="text"
    classes="[ full-assessment-button ]"
    @on-click="$emit('on-click', $event)">
    <template v-slot:suffix>
      <svg
        v-if="isAllowed"
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="white" />
      </svg>
      <img
        v-else
        src="@/assets/images/lock_white.svg"
        alt="lock-white"
      >
    </template>
  </EquiloButton>
</template>

<style scoped lang="scss">
.full-assessment-button {
  font-weight: bold;
  color: white;
  background: #19486a;
  padding: 12px 24px 12px 24px;
  font-size: 16px;
}
</style>