<template>
  <div :key="'updateListIndicatorViewComponents' + updateKey">
    <div class="my-[1rem] w-full">
      <div v-for="(value, k) in values" :key="k">
        <h3 class="font-bold max-w-[32rem]">
          {{ value.title }}
        </h3>
        <div class="flex">
          <div v-if="value.value && typeof value.value === 'string'">
            {{ value.value }}
          </div>
          <div v-else-if="value.value.length">
            <ul class="list-disc ml-[2rem]">
              <li v-for="ans in value.value" :key="ans">
                <span>{{ ans }}</span>
              </li>
            </ul>
          </div>
          <span
            v-if="value.textForChartInfo"
            class="bg-blue-500 rounded-md p-2 text-md text-white text-center max-w-[25rem]"
            v-html="createInteractiveText(value)"
            @click="handleSpanClick"
          ></span>
          <div
            class="flex items-center gap-3 justify-end pl-4 pr-4 pb-6"
            v-if="
              $route.path.includes('/admin') &&
              value?.surveyQType != 'Calculated' &&
              value?.surveyQType
            "
          >
            <img
              src="@/assets/images/edit-dark.svg"
              alt="edit-icon"
              class="h-6 w-6 cursor-pointer"
              @click="openEditModal(value)"
            />
            <verification-dropdown :value="value" />
            <div>
              <MLVerifierValue :value="value" :teamId="$route.query?.teamId" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import { createInteractiveText, handleSpanClick } from "./helpers";

export default {
  name: "ListIndicatorView",
  data: function () {
    return {
      updateKey: 0,
    };
  },
  props: {
    values: Array,
    COM_Commitment: {
      type: [Array],
      default: null,
    },
  },
  components: { MLVerifierValue, VerificationDropdown },
  methods: {
    openEditModal(item) {
      this.$emit("openEditModal", item);
    },
    createInteractiveText(value) {
      return createInteractiveText(value);
    },
    handleSpanClick(event) {
      handleSpanClick(event, this.openEditModal);
    },
  },
};
</script>
