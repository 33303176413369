<template>
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
  >
    <div class="bg-white w-full max-w-md p-6 rounded-lg shadow-lg" @click.stop>
      <h2 class="text-[1.2rem] font-semibold text-center mb-1">
        2X Challenge Self-Assessment Modules
      </h2>

      <div class="mb-4">
        <label class="block text-gray-600 text-sm text-center mb-3">
          To qualify a 2X Challenge deal there are some mandatory modules.
          However, you only need to qualify on ONE 2X Criteria from the
          following below. Select one or more that you wish to provide data for
          in order to qualify this as a 2X Challenge deal
        </label>
        <ul>
          <li
            v-for="module in surveyModules"
            :key="module.surveyUrl"
            class="flex items-center space-x-2 mb-2"
          >
            <input
              type="checkbox"
              v-model="selectedModules"
              :value="module"
              class="form-checkbox h-5 w-5 text-blue-600 disabled:opacity-50 disabled:bg-gray-300 disabled:cursor-not-allowed"
            />
            <span class="text-gray-900">
              {{ module.name }}
            </span>
          </li>
        </ul>
      </div>

      <p v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</p>

      <div class="flex justify-end items-center mt-4">
        <button
          @click="submitSelectedModules"
          class="bg-[#201747] text-white py-2 px-4 rounded-md hover:bg-[#191b45]"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    surveyModules: Array,
  },
  data() {
    return {
      selectedModules: [],
      errorMessage: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    submitSelectedModules() {
      if (this.selectedModules.length === 0) {
        this.errorMessage = "Please select at least one survey module.";
      } else {
        this.$emit("submit", this.selectedModules);
        this.errorMessage = "";
      }
    },
  },
};
</script>
