<template>
  <div class="static ecosystem-landing" ref="annotatable">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="container mx-auto pt-20 px-4 lg:px-0 pb-1"
      :key="'updateComponent' + updateKey"
      v-if="endofcreated && certificationScoreData"
    >
      <div class="flex justify-between items-center">
        <BreadCrumbs style="margin-top: 0"></BreadCrumbs>
      </div>

      <div ref="contentWrapper">
        <h3
          class="text-4xl tracking-tighter font-bold print:text-7xl text-[#19486A] mt-5"
        >
          <span
            >{{ certificationScoreData?.team_id?.name }}'s 2X Certification
            Self-Assessment Results</span
          >
        </h3>

        <div
          class="mb-3 text-lg font-semibold text-gray-500"
          v-if="dateOfSubmission"
        >
          Submission Date:
          {{ moment(dateOfSubmission).format("DD MMMM, YYYY") }}
        </div>

        <div class="[ w-full ][ flex justify-end gap-2 ]">
          <!-- Added Email in condition for testing on PROD -->
          <EquiloButton v-if="user.email.includes('simformsolutions') || user.email.includes('equilo')"
            class="hide-in-pdf"
            text="Show Insights for Action"
            classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
            :bg-color="'transparent'"
            @on-click="isInsightsModalVisible = true"
          />
          <EquiloButton
            text="Download XLSX"
            class="hide-in-pdf"
            data-html2canvas-ignore="true"
            classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
            :bg-color="'transparent'"
            @on-click="downloadXlsx()"
          />
          <EquiloButton
            class="hide-in-pdf"
            data-html2canvas-ignore="true"
            classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
            :bg-color="isAllowedDownloadFiles ? 'transparent' : 'cancel'"
            :disabled="isDownloadingPDF"
            :text="isDownloadingPDF ? 'Downloading...' : 'Download PDF'"
            @on-click="handleExportToPDF()"
          />
        </div>

        <div class="ContentWrapper w-12/12 mt-8">
          <div class="container mx-auto">
            <div class="overview-box mt-8">
              <div class="p-8">
                <h3 class="text-2xl tracking-tighter font-bold">Overview</h3>
                <div class="flex justify-between">
                  <div class="flex items-center">
                    <div
                      class="flex text-white text-xs items-center pl-2 pr-3 my-2 mr-2 py-1 font-bold"
                      :style="
                        'border-radius: 20px; background-color:' +
                        (twoxEligible == 'Yes' ? '#4C9F38' : '#FF3A21')
                      "
                    >
                      <svg
                        v-if="twoxEligible == 'Yes'"
                        width="13"
                        height="10"
                        viewBox="0 0 13 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                          fill="white"
                        />
                      </svg>
                      <img v-else src="@/assets/images/white-x.svg" />
                      <span class="ml-2">{{
                        twoxEligible == "Yes"
                          ? "2X Eligible"
                          : "Not 2X Eligible"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col w-3/5">
                    <k-progress
                      class="ml-16 relative"
                      :percent="this.twoXScoreOverall + 1"
                      :show-text="false"
                      color="#4C9F38"
                    ></k-progress>
                    <div
                      class="steps-option flex justify-between text-black-90"
                    >
                      <div :class="getStepClass(0)">
                        <!-- for step 1 % would be 0 -->
                        <div class="text-center">0</div>
                        <div>More work to do</div>
                      </div>
                      <div :class="getStepClass(1)">
                        <!-- for step 1 % would be 35 -->
                        <div class="text-center">1</div>
                        <div>Good</div>
                      </div>
                      <div :class="getStepClass(2)">
                        <!-- for step 1 % would be 67 -->
                        <div class="text-center">2</div>
                        <div>Advanced</div>
                      </div>
                      <div :class="getStepClass(3)">
                        <!-- for step 1 % would be 100 -->
                        <div class="text-center">3</div>
                        <div>Best in class</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="" style="border-top: 1px solid #ccc">
                <div>
                  <div class="flex">
                    <div
                      class=""
                      style="flex: 50%; border-right: 1px solid #ccc"
                    >
                      <div class="p-8" style="border-bottom: 1px solid #ccc">
                        <div class="flex gap-4 items-center">
                          <h3 class="text-xl tracking-tighter font-bold">
                            Entity Type:
                          </h3>
                          <p class="text-lg font-medium">
                            {{ entityDisplay[entityChosen] }}
                          </p>
                        </div>
                      </div>
                      <div class="p-8">
                        <h3 class="text-2xl tracking-tighter font-bold">
                          Gender Equality & Social Inclusion Context
                        </h3>
                        <div class="flex mt-4 items-center">
                          <img
                            class="mr-5 h-16 print:h-auto print:w-64"
                            :src="countryData?.flagUrl"
                          />
                          <span
                            class="font-bold"
                            style="color: #2c2e35; font-size: 14px"
                            >{{ countryData?.humanName }}</span
                          >
                        </div>
                        <div class="mt-5 CountryOverviewText">
                          <span
                            class="font-bold text-xl"
                            style="color: #2c2e35; font-size: 16px"
                            >Country overview</span
                          >
                          <transition
                            name="slide-fade"
                            v-if="countryData?.intro"
                          >
                            <item
                              :text="countryData?.intro"
                              :country="countryData"
                            ></item>
                          </transition>
                          <div class="flex" style="float: right">
                            <a
                              class="font-bold"
                              style="color: #2177c1"
                              href="javascript:void(0)"
                              @click="readMore('Country Overview', countryData)"
                              >Read more</a
                            >
                            <img
                              src="@/assets/images/2x_certify/long-arrow-right.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="p-8" style="flex: 50%">
                      <div class="mt-5 CountryOverviewText">
                        <span
                          class="font-bold"
                          style="color: #2c2e35; font-size: 16px"
                          >Sector overview -
                          {{ certificationScoreData?.GEN_General_13 }}</span
                        >
                        <transition name="slide-fade">
                          <item
                            :text="sectorData?.intro"
                            :country="sectorData"
                          ></item>
                        </transition>
                        <div
                          v-if="sectorData?.intro"
                          class="flex"
                          style="float: right"
                        >
                          <a
                            class="font-bold"
                            style="color: #2177c1"
                            href="javascript:void(0)"
                            @click="readMore('Sector Overview', sectorData)"
                            >Read more</a
                          >
                          <img
                            src="@/assets/images/2x_certify/long-arrow-right.svg"
                          />
                        </div>
                      </div>
                      <div class="mt-5">
                        <p class="mt-2" style="color: #21191d; font-size: 14px">
                          Want to do a deeper dive into the GESI contextual
                          analysis? Go
                          <span
                            style="color: #2177c1"
                            class="cursor-pointer"
                            @click="
                              $router.push({
                                path: '/gesi-analysis/choose-country',
                              })
                            "
                            >here</span
                          >
                          to select a country and sector GESI analysis along
                          with the What Works library of actions. If you do not
                          yet have a subscription, you can upgrade.
                        </p>
                        <div
                          class="flex justify-between"
                          v-if="
                            $route.path.includes(
                              '/Self-Assessment-detailed-results'
                            )
                          "
                        >
                          <button
                            @click="
                              $router.push({
                                path: '/gesi-analysis/choose-country',
                              })
                            "
                            class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          >
                            <span class="pr-1"
                              >Explore 2X GESI Contextual Analysis</span
                            >
                          </button>
                          <button
                            @click="
                              $router.push({
                                path: '/gbv-risk/choose-country/true',
                              })
                            "
                            class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                          >
                            <span class="pr-1">Explore GBV Risk</span>
                          </button>
                        </div>
                        <button
                          v-if="
                            $route.path.includes(
                              '/Self-Assessment-detailed-results'
                            )
                          "
                          class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                        >
                          <span class="pr-1"
                            >Explore full GESI Context Deep-Dive</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="chart-box my-8 p-8"
              v-if="
                spiderData && Array.isArray(spiderData) && spiderData.length > 0
              "
            >
              <div class="items-center">
                <div>
                  <h3 class="text-2xl tracking-tighter font-bold">
                    2X Assessment Results
                  </h3>
                </div>
                <div class="mt-8 flex gap-6">
                  <div class="flex gap-1 text-sm font-normal items-center">
                    <div
                      style="width: 24px; height: 4px; background: #e8ad17"
                    ></div>
                    {{ certificationScoreData?.assessmentYear }}
                  </div>
                </div>
              </div>
              <div class="spiderchartEligibleWrepper mt-8">
                <!-- spiderchart -->
                <div class="spiderchartContent" id="chart">
                  <div class="relative">
                    <div
                      v-if="
                        !$store.state.certPageAccess
                          .isVerificationRecommendsCertification
                      "
                      style="z-index: -1"
                      class="opacity-70 absolute inset-0 translate-x-1/2 translate-y-1/2"
                    >
                      <p class="text-gray-300 text-6xl sm:-ml-72 sm:-mt-14">
                        Pending Verification
                      </p>
                    </div>
                    <spiderChart
                      class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :info="spiderData"
                      :certificationScoreData="certificationScoreData"
                    />
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[0]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[0]?.color +
                        '; position: absolute; left: 50%; top: 0%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[0]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[0]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[1]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[1]?.color +
                        '; position: absolute; left: 80%; top: 8%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[1]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[1]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[2]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[2]?.color +
                        '; position: absolute; right: 8%; top: 18%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[2]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[2]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[3]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[3]?.color +
                        '; position: absolute; right: 5%; top: 30%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[3]?.icon)"
                      />
                      <span style="font-weight: bold"
                        >{{ spiderSpaces[3]?.domain.split("&")[0] }}<br />
                        <span v-if="spiderSpaces[3]?.domain?.split('&')[1]">
                          {{ " & " + spiderSpaces[3]?.domain.split("&")[1] }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[4]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[4]?.color +
                        '; position: absolute; right: 12%; top:44%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[4]?.icon)"
                      />
                      <span style="font-weight: bold">
                        {{ spiderSpaces[4]?.domain.substring(0, 17) }}<br />
                        {{ spiderSpaces[4]?.domain.substring(17) }}
                      </span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[5]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[5]?.color +
                        '; position: absolute; right: 10%; top: 63%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[5]?.icon)"
                      />
                      <span
                        style="
                          font-weight: bold;
                          display: block;
                          width: 150px;
                          word-wrap: break-word;
                        "
                        >{{ spiderSpaces[5]?.domain }}</span
                      >
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[6]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[6]?.color +
                        '; position: absolute; right: 8%; bottom: 15%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[6]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[6]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[7]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[7]?.color +
                        '; position: absolute; right: 28%; bottom: 2%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[7]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[7]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[8]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[8]?.color +
                        '; position: absolute; left: 35%; bottom: 0;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[8]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[8]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[9]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[9]?.color +
                        '; position: absolute; left: 5%; bottom: 0%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[9]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[9]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[10]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[10]?.color +
                        '; position: absolute; left: 0; bottom: 30%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[10]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[10]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[11]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[11]?.color +
                        '; position: absolute; left: 0; top: 58%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[11]?.icon)"
                      />
                      <span style="font-weight: bold"
                        >{{ spiderSpaces[11]?.domain.split("&")[0] }} <br />
                        <span v-if="spiderSpaces[3]?.domain?.split('&')[1]">
                          {{ " & " + spiderSpaces[3]?.domain.split("&")[1] }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[12]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[12]?.color +
                        '; position: absolute; left: 0; top: 30%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[12]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[12]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[13]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[13]?.color +
                        '; position: absolute; left: 8%; top: 17%;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[13]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[13]?.domain
                      }}</span>
                    </div>
                    <div
                      v-if="
                        spiderSpaces &&
                        Array.isArray(spiderSpaces) &&
                        spiderSpaces.length > 0 &&
                        spiderSpaces[14]
                      "
                      class="text-center"
                      :style="
                        'color: ' +
                        spiderSpaces[14]?.color +
                        '; position: absolute; left: 28%; top: 0;'
                      "
                    >
                      <img
                        class="m-auto"
                        :src="getImgUrl(spiderSpaces[14]?.icon)"
                      />
                      <span style="font-weight: bold">{{
                        spiderSpaces[14]?.domain
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="verificationAssessment"
              class="flex items-center justify-end my-5"
            >
              <EquiloButton
                text="View Verification Progress"
                classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
                :bg-color="'transparent'"
                @on-click="showVerificationProgress = true"
              />
              <VerificationProgressModal
                v-if="showVerificationProgress"
                :progressData="verificationProgress"
                @close="showVerificationProgress = false"
              />
            </div>
            <div
              class="chart-box py-8 mb-8"
              v-for="(dimension, index) in structuredData"
              :key="index"
              :style="{ display: dimension.show ? 'block' : 'none' }"
            >
              <div class="flex px-8">
                <h3 class="w-full text-2xl tracking-tighter font-bold">
                  {{ dimension.title }}
                </h3>
                <router-link
                  v-if="dimension.title.toLowerCase().trim() != 'general' && dimension.title.toLowerCase().trim() != 'themes'"
                  class="pr-5 ml-auto"
                  :to="
                    !query
                      ? `${getBasePath()}/sub-dimension?q=${encodeURIComponent(
                          dimension.title
                        )}&d=1&fromVerifier=true`
                      : `${getBasePath()}/sub-dimension?q=${encodeURIComponent(
                          dimension.title
                        )}&teamId=${query}&entity=${entityChosen}&d=1&fromVerifier=true`
                  "
                >
                  <img
                    class="cursor-pointer"
                    src="@/assets/images/2x_certify/Button.svg"
                  />
                </router-link>
              </div>
              <div class="flex flex-col">
                <!-- For triangle charts -->
                <div
                  class="CriteriaTitleText p-8 flex flex-col justify-between px-8"
                  style="border-bottom: 1px solid #ccc"
                  v-if="
                    dimension?.triangle &&
                    Array.isArray(dimension?.triangle) &&
                    dimension?.triangle.length
                  "
                >
                  <div class="flex relative">
                    <triangle-chart
                      class="z-10 overflow-visible"
                      style="height: 100%; width: 100%"
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :info="dimension?.triangle"
                      :certificationScoreData="certificationScoreData"
                    />

                    <div>
                      <div
                        v-for="triValue in dimension?.triangle"
                        :key="triValue.key"
                        class="flex items-start gap-3 mt-16 justify-end"
                        v-show="
                          $route.path.includes('/admin') &&
                          triValue.surveyQType != 'Calculated'
                        "
                      >
                        <img
                          src="@/assets/images/edit-dark.svg"
                          alt="edit-icon"
                          class="h-6 w-6 cursor-pointer"
                          @click="openEditModal(triValue)"
                        />
                        <verification-dropdown :value="triValue" />
                        <MLVerifierValue
                          :value="triValue"
                          :teamId="$route.query?.teamId"
                        />
                      </div>
                    </div>

                    <div
                      v-if="
                        !$store.state.certPageAccess
                          .isVerificationRecommendsCertification
                      "
                      style="z-index: -1"
                      class="opacity-70 absolute inset-0 translate-x-1/2 translate-y-1/2"
                    >
                      <p class="text-gray-300 text-3xl">Pending Verification</p>
                    </div>
                  </div>
                </div>

                <!-- For non-triangular charts -->
                <IndicatorView
                  class="CriteriaTitleText p-8 flex flex-col justify-between px-8"
                  v-for="(indicator, j) in dimension.indicators"
                  v-bind:indicator="indicator"
                  :query="query"
                  :subDimensionPath="`${getBasePath()}/sub-dimension`"
                  :certificationScoreData="certificationScoreData"
                  :entityChosen="entityChosen"
                  :MLverifiedData="MLverifiedData"
                  @openEditModal="openEditModal"
                  @setVerifiedStatus="setVerifiedStatus"
                  :key="j"
                  :style="{
                    display: indicator.hasValues ? 'block' : 'none',
                    borderBottom:
                      j <
                      dimension?.indicators?.filter((ele) => ele?.hasValues)
                        ?.length -
                        1
                        ? '1px solid #ccc'
                        : 'none',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
    <div
      class="bottom-button-section"
      v-if="endofcreated && certificationScoreData"
    >
      <div class="flex justify-between">
        <div
          @click="documentIsActive = !documentIsActive"
          class="my-auto font-bold text-xl"
        >
          Documents
          <img
            v-if="documentIsActive"
            src="@/assets/images/arrow-up.svg"
            class="cursor-pointer"
          />
          <img
            v-if="!documentIsActive"
            src="@/assets/images/arrow-down.svg"
            class="cursor-pointer"
          />
        </div>
        <div class="text-right flex justify-end">
          <button
            v-if="verificationAssessment"
            class="add-note-button mr-4 flex"
          >
            <img src="@/assets/images/white-note.svg" />
            Add Note
          </button>
          <button @click="$router.push({ path: getOverviewPath() })">
            Back to Overview
          </button>
        </div>
      </div>
      <div
        class="container px-4 lg:px-0 pb-1 mt-10 mx-auto"
        v-if="documentIsActive"
      >
        <UploadDocumentsTable :filelist="filelist"></UploadDocumentsTable>
      </div>
    </div>
    <div class="fixed z-50 inset-0 overflow-y-auto" v-show="isReadMore">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {{ readMoretitle }}
              </h3>
              <transition name="slide-fade">
                <item
                  :text="readMoreInfo"
                  :country="readMoreCountryData"
                  style="font-size: 15px"
                ></item>
              </transition>
            </div>
            <div class="cross_Icon" @click="isReadMore = false">
              <img src="@/assets/images/cros-icon.svg" alt="cross_Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <edit-modal
      v-if="isEditModalVisible"
      :item="selectedItem"
      :entity="entityChosen"
      :showLoader="showLoader"
      :uuid="certificationScoreData?.uuid"
      :COM_Commitment="COM_Commitment"
      @close="closeEditModal"
      @closeAndUpdate="closeEditModalAndFetchCertResults"
    />
    <!-- Insights for Action Modal -->
    <insights-modal
      v-if="isInsightsModalVisible"
      :data="rawData"
      :certificationScoreData="certificationScoreData"
      :closeModal="() => (isInsightsModalVisible = false)"
    />
  </div>
</template>
<script>
import auth from "@/helpers/auth";

const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import pageLoader from "@/components/page-loader.vue";
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import apiService from "@/helpers/apiServices.js";

import EditModal from "./editModal.vue";
import IndicatorView from "./IndicatorView.vue";

import item from "@/components/text.vue";
import { Recogito } from "@recogito/recogito-js";
import "@recogito/recogito-js/dist/recogito.min.css";
import spiderChart from "./spiderChart.vue";
import UploadDocumentsTable from "@/components/2x-certification/upload-documents-table.vue";
import triangleChart from "./triangleChart.vue";
import { saveAs } from "file-saver";
import MLVerifierValue from "./MLVerifierValue.vue";
import VerificationDropdown from "./verificationDropdown.vue";
import pdfExportHelpers from "../../../helpers/pdfExportHelpers";
import InsightsModal from "./actions-insights/InsightsModal.vue";
import EquiloButton from "@/components/basics/EquiloButton.vue";
import SubscriptionAPIs from "@/api/SubscriptionAPIs";
import VerificationProgressModal from "@/pages/thirdPartyVerifier2X/VerificationProgressModal.vue";

export default {
  name: "twoxFullAssessmentsResults",
  data: function () {
    return {
      entityMap: {
        "financial-institution": "Financial Institution",
        "fund-manager": "Fund Manager",
        "start-up": "Start-up",
        sme: "SME",
        corporate: "Corporate",
      },
      query: null,
      twoXScoreOverall: 0,
      triangle: [],
      spiderSpaces: [],
      spiderData: [],
      sectorData: null,
      countryData: null,
      certification_result: {
        name: "Best in Class",
        darkColor: "#4DC9BE",
        lightColor: "#E5FAFA",
      },
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF3A21",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#E8AD17",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4DC9BE",
          lightColor: "#E5FAFA",
        },
      },
      dimensions: {
        Entrepreneurship: {
          color: "#395AFF",
          icon: "entrepreneurship",
          score: 0,
          threshold: 100,
        },
        "Entrepreneurship & Ownership": {
          color: "#395AFF",
          icon: "entrepreneurship",
          score: 0,
          threshold: 100,
        },
        Leadership: {
          color: "#3F7E44",
          icon: "leadership",
          score: 0,
          threshold: 100,
        },
        Employment: {
          color: "#F04D29",
          icon: "employment",
          score: 0,
          threshold: 100,
        },
        Products: {
          color: "#A21942",
          icon: "products",
          score: 0,
          threshold: 100,
        },
        "Products / Services": {
          color: "#A21942",
          icon: "products",
          score: 0,
          threshold: 100,
        },
        SupplyChain: {
          color: "#381B5B",
          icon: "supplychain",
          score: 0,
          threshold: 100,
        },
        "Supply chain": {
          color: "#381B5B",
          icon: "supplychain",
          score: 0,
          threshold: 100,
        },
        Governance: {
          color: "#DDA63A",
          icon: "governance",
          score: 0,
          threshold: 100,
        },
        Portfolio: {
          color: "#8F210D",
          icon: "portfolio",
          score: 0,
          threshold: 100,
        },
      },
      colors: [
        {
          code: "Entrepreneurship",
          color: "#395AFF",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Leadership",
          color: "#3F7E44",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Employment",
          color: "#FF3A21",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Products",
          color: "#A21942",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Supply Chain",
          color: "#381B5B",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Governance",
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        {
          code: "Portfolio",
          color: "#8F210D",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      ],
      governanceColors: {
        strategicAction: "#3F7E44",
        managementSystems: "#395AFF",
        data: "#FF3A21",
      },
      domainChartData: [],
      entrepreneurshipPercent: "",
      leadershipPercent: "",
      employmentPercent: "",
      productServicePercent: "",
      supplyChainPercent: "",
      portfolioPercent: "",
      govStrategicAction: "",
      govManagementSystem: "",
      govData: "",
      govChartData: [],
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      strategicActionTableData: {
        heading: ["", "2022", "2023"],
        data: [
          {
            rowHeading: "Public commitment related to gender equity",
            col1: "right",
            col2: "right",
          },
          {
            rowHeading: "Clear, measurable & time-bound gender targets",
            col1: "right",
            col2: "wrong",
          },
          {
            rowHeading:
              "Action items or action plan linked to gender targets with clear roles/responsibilities and timelines",
            col1: "wrong",
            col2: "wrong",
          },
          {
            rowHeading:
              "Financial resources specifically allocated to gender and/or to implement gender action plans",
            col1: "wrong",
            col2: "right",
          },
          {
            rowHeading:
              "Standalone gender strategy and/or gender explicitly integrated into strategic / business plan",
            col1: "right",
            col2: "right",
          },
        ],
      },
      isResultByDimesnion: true,
      isESGScreenSection: true,
      isGovAcctSection: true,
      isEntreAndOwnerSection: true,
      isLeadershipSection: true,
      isEmpDiversitySection: true,
      isSupplyChainSection: true,
      isPortfolioSection: true,
      entityChosen: "",
      certificationSurveyData: [],
      certificationScoreData: [],
      isSupplyChainEligible: "",
      isProductsEligible: "",
      isEmploymentEligible: "",
      isLeadershipEligible: "",
      isEntOwnEligible: "",
      isAllMinReqSurveysCompleted: false,
      isAllFullSurveysCompleted: false,
      status2xCertification: "",
      csvData: [],
      COM_Commitment: [],
      updateKey: 0,
      showLoader: false,

      //FROM my2xassement PAGE
      endofcreated: false,
      isReadMore: false,
      readMoreInfo: null,
      readMoreCountryData: null,
      title: null,
      user: [],
      isDownloadPDF: false,
      structuredData: {},
      options_eligible: {
        text: {
          color: "#4C9F38",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#4C9F38",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      options_notEligible: {
        text: {
          color: "#FE5000",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FE5000",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      recogito: null,
      annotationsData: [],
      commentsData: [],
      baseColors: [
        "#19486A",
        "#FF3A21",
        "#A21942",
        "#7C7C7C",
        "#395AFF",
        "#3F7E44",
        "#883535",
        "#5d212e",
        "#4e4a62",
        "#234681",
        "#35463a",
        "#c53f1c",
        "#b66850",
        "#8a646f",
        "#9d6428",
        "#8b5448",
        "#605068",
        "#725020",
        "#50789b",
        "#637854",
        "#387473",
        "#883535",
      ],
      spiderColor: {},
      filelist: [],
      documentIsActive: false,
      readMoretitle: "",
      entityDisplay: {
        "start-up": "Start up",
        corporate: "Corporate",
        sme: "SME",
        "fund-manager": "Fund Manager",
        "financial-institution": "Financial Institution",
      },
      verifiedDataPoints: [],
      isEditModalVisible: false,
      selectedItem: null,
      MLverifiedData: [],
      dateOfSubmission: null,
      isDownloadingPDF: false,
      isInsightsModalVisible: false,
      rawData: [],
      showVerificationProgress: false,
      isPortfolioVisible: true,
    };
  },
  props: {
    verificationAssessment: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EquiloButton,
    BreadCrumbs,
    pageLoader,
    spiderChart,
    "triangle-chart": triangleChart,
    item,
    UploadDocumentsTable,
    EditModal,
    IndicatorView,
    MLVerifierValue,
    VerificationDropdown,
    InsightsModal,
    VerificationProgressModal,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
  },
  async created() {},
  methods: {
    async getThemesDimension() {
      const entity = this.entityMap[this.entityChosen] || this.entityChosen;
      let response = await apiService.fetch2xCertDataPageWise(
        "Theme Results",
        entity
      );

      let dimension = {
        isActive: true,
        title: "Themes",
        indicators: [
          {
            hasValues: true,
            isActive: false,
            title: "2X Score Overall",
            values: [],
          },
        ],
        triangle: [],
        show: true,
      };

      for (let key in response.data) {
        if (
          key === null ||
          key === undefined ||
          !response.data[key] ||
          !response.data[key]["2X Score Overall"]
        ) {
          continue;
        }
        let scores = response.data[key]["2X Score Overall"];
        for (let subKey in scores) {
          let value = scores[subKey];
          if (value.value === null || value.value === undefined) {
            continue;
          }
          value.title = value.surveyQuestion;
          value.chartType = "Text";
          value.fieldsUsed = [];
          value.textForChartInfo = "";
          value.originalTextForChartInfo = "";
          value.fieldsUsedWithNames = [];
          value.fieldsWithAnswers = [];
          value.MLVerifiedData = null;
          value.citations = null;
          value.surveyQType = "Open Text";
          value.isVerified = this.verifiedDataPoints[value.key];
          if (typeof value.value === "number") {
            value.value = Math.round(value.value);
          }
          value.value = String(value.value).toString();
          dimension.indicators[0].values.push(value);
        }
      }
      return dimension;
    },
    fetchCsvData() {
      let url = "";
      const _self = this;
      if (this.$route.query?.teamId) {
        url =
          "/certify2x/export-data?teamId=" +
          this.$route.query?.teamId +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill ? "Full" : "Min") +
          "&tag=" +
          this.entityChosen;
      } else {
        url =
          "/certify2x/export-data?teamId=" +
          this.user?.organization?._id +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill ? "Full" : "Min") +
          "&tag=" +
          this.entityChosen;
      }
      return axios
        .get(env.apiUrl + url, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(function (res) {
          _self.csvData = res.data;
        })
        .catch(function (res) {
          console.error("Error fetching CSV data:", res);
        });
    },
    downloadXlsx() {
      saveAs(
        this.csvData,
        this.certificationScoreData?.team_id?.name +
          "_2x" +
          " Assessment Results.XLSX"
      );
    },
    getImgUrl(icon) {
      if (icon) {
        try {
          return require(`@/assets/images/2x_certify/2x_challenge/${icon}.svg`);
        } catch (e) {
          console.error(`Failed to load icon: ${icon}`, e);
        }
      }
    },
    getAllDocuments() {
      apiService
        .fetchUploadDocumentsData(
          this.$route?.query?.teamId
            ? this.$route.query?.teamId
            : this.user?.organization?._id
        )
        .then((response) => {
          this.filelist = response.data.data;
        });
    },
    async updateRecogito() {
      this.recogito = await new Recogito({
        content: this.$refs.annotatable,
        readOnly: this.$route.path == "/admin/full-assessment" ? false : true,
        // readOnly: true, uncomment this if you don't want to edit annotations, user can only see annotations.
        widgets: [
          {
            widget: "COMMENT",
            args: { placeholder: "Add a comment" },
          },
        ],
      });

      await this.loadAnnotations();
      this.recogito.on("createAnnotation", async (annotation) => {
        await this.annotationsData.push(annotation);
        await this.saveVerifiersComment(annotation);
        await this.getVerifiersComments();
      });

      this.recogito.on("updateAnnotation", async (annotation, previous) => {
        this.annotationsData = this.annotationsData.filter(
          (data) => data.id !== previous.id
        );
        await this.annotationsData.push(annotation);
        await this.updateVerifiersComment(annotation);
        await this.getVerifiersComments();
      });

      this.recogito.on("deleteAnnotation", async (annotation) => {
        this.annotationsData = this.annotationsData.filter(
          (data) => data.id !== annotation.id
        );
        await this.deleteVerifiersComment(annotation);
        await this.getVerifiersComments();
      });
    },
    async getVerifiersComments() {
      await apiService
        .fetchVerifiersComments(this.$route?.query?.teamId)
        .then(async (response) => {
          this.commentsData = response.data.data;
          this.commentsData.forEach((data, i) => {
            data["index"] = i + 1;
          });
          this.$emit("commentsData", this.commentsData);
          this.commentsData.forEach((data) => {
            if (data?.comment?.id && data?.comment?.type == "Annotation") {
              this.annotationsData.push(data.comment);
            }
          });
        });
    },
    async saveVerifiersComment(comment) {
      await apiService
        .postVerifiersComments(comment, this.$route?.query?.teamId)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    async updateVerifiersComment(comment) {
      let commentId;
      this.commentsData.forEach((data) => {
        if (comment?.id == data?.comment?.id) {
          commentId = data?._id;
        }
      });
      await apiService
        .putVerifiersComments(comment, commentId)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    async deleteVerifiersComment(comment) {
      let commentId;
      this.commentsData.forEach((data) => {
        if (comment?.id == data?.comment?.id) {
          commentId = data?._id;
        }
      });
      await apiService
        .removeVerifiersComments(commentId)
        .then(async (response) => {
          console.log("response", response);
        });
    },
    loadAnnotations() {
      if (this.annotationsData?.length > 0) {
        this.annotationsData.forEach((annotation) => {
          this.recogito.addAnnotation(annotation);
        });
      }
    },
    getColors(dimension) {
      if (this.spiderColor[dimension]) {
        return this.spiderColor[dimension];
      } else {
        let color = this.baseColors.pop();
        this.spiderColor[dimension] = color;
        return color;
      }
    },
    fetchCountryData(iso) {
      return axios.get(`${env.apiUrl}/countries/${iso}/sectors/country`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$store.state.certPageAccess.isFullSurveyFill ? "Full" : "Min"
        )
        .then(async (res) => {
          this.certificationScoreData = await res.data.data;
          this.isPortfolioVisible = !this.certificationScoreData?.['PORT_Portfolio_900']?.includes('none');
          if (this.certificationScoreData?.ISO) {
            await this.getCountryData();
            await this.getSectorData();
          }
          await this.calculateCertLevel();
          await this.getStructuredData();
          await this.calculateThreshold();
          if (this.certificationScoreData.level == "Good") {
            this.certification_result = this.cert_categories.Good;
          } else if (
            this.certificationScoreData.level == "Best in class" ||
            this.certificationScoreData.level == "Best in Class"
          ) {
            this.certification_result = this.cert_categories.BestInClass;
          } else if (this.certificationScoreData.level == "Advanced") {
            this.certification_result = this.cert_categories.Advanced;
          }
          if (this.entityChosen == "fund-manager") {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_104;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_104;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_104;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_104;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_104;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == "NA"
                ? 0
                : this.certificationScoreData?.PORT_Portfolio_1000;

            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_103;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_103;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_114;
          } else {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_101;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_101;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_101;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_101;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_101;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == "NA"
                ? 0
                : this.certificationScoreData?.PORT_Portfolio_1000;

            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_102;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_102;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_113;
          }
          if (this.isPortfolioVisible &&
            (this.entityChosen == "fund-manager" ||
            this.entityChosen == "financial-institution")
          ) {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
              this.portfolioPercent,
            ];
          } else {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
            ];
          }
          this.govChartData = [
            this.govStrategicAction,
            this.govManagementSystem,
            this.govData,
          ];
          if (this.entityChosen == "fund-manager") {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_304"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_304"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_304"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_304"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_304"];
          } else {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_300"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_300"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_300"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_300"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_300"];
          }
        })
        .catch(() => {
          this.endofcreated = true;
          this.$emit("endofcreated", this.endofcreated);
        });
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;
      this.COM_Commitment = [
        this.workforceThreshold?.split("%")?.[0],
        this.srManagerThreshold?.split("%")?.[0],
        this.boardThreshold?.split("%")?.[0],
      ];
    },
    async getThresholdsData(recordId, sector) {
      await apiService
        .fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
        });
    },
    async getCountryData() {
      await apiService
        .fetchCountryData(this.certificationScoreData?.ISO)
        .then(async (response) => {
          this.countryData = response.data.data;
        });
    },
    async getSectorData() {
      await apiService
        .fetchSectorData(
          this.certificationScoreData?.ISO,
          this.certificationScoreData?.GEN_General_13
        )
        .then(async (response) => {
          this.sectorData = response.data.data;
        });
    },
    readMore(title, data) {
      this.isReadMore = true;
      this.readMoreTitle = title;
      this.readMoreInfo = data.intro;
      this.readMoreCountryData = data;
    },
    async getStructuredData() {
      const entity = this.entityMap[this.entityChosen] || this.entityChosen;

      try {
        const url = this.$route.query.teamId
          ? `${env.apiUrl}/twox-framework/fetch-data?teamId=${this.$route.query.teamId}`
          : `${env.apiUrl}/twox-framework/fetch-data`;
        const res = await axios.post(
          url,
          {
            page: "Full Assessment Summary Results",
            entity,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        if (res.status == 200) {
          this.twoXScoreOverall = this.certificationScoreData.level;
        }
        this.rawData = res.data;

        switch (this.twoXScoreOverall) {
          case "Good":
            this.twoXScoreOverall = 37;
            break;
          case "Advanced":
            this.twoXScoreOverall = 67;
            break;
          case "Best in Class":
            this.twoXScoreOverall = 100;
            break;
          default:
            this.twoXScoreOverall = 0;
        }

        const spiderData = [];
        const twoxSpider = "2X Spider Chart Score Value";
        const triangleType = "2X Governance Triangle Chart Score Value";
        const ALLOWED = [
          "Yes/No",
          "Donut chart",
          "List",
          "Bar graph",
          "Text",
          "Number",
          "Single select"
        ];

        const data = Object.entries(res.data)
          .filter(([key]) => key !== "2X")
          .map(([key, value]) => {
            const triangleData = [];
            const result = {
              isActive: true,
              title: key,
              indicators: [],
              triangle: [],
              show: false,
            };

            result.indicators = Object.entries(value).map(
              ([keyInternal, values]) => {
                const full = values.filter(
                  (item) =>
                    item.value !== "NA" &&
                    item.value !== null &&
                    ALLOWED.includes(item.chartType) &&
                    item.value
                );

                const spider = values
                  .filter(
                    (item) =>
                      item.chartType === twoxSpider ||
                      item.chartType === "2X Spider Chart Score Value"
                  )
                  .map((item) => ({
                    ...item,
                    value: item.value ? item.value : 0,
                    sub: keyInternal.trim(),
                    domain: key.trim(),
                    color:
                      this.colors.find((color) =>
                        key.toLowerCase().includes(color.code.toLowerCase())
                      )?.color || this.getColors(key),
                  }));
                if (spider.length) {
                  spiderData.push(...spider);
                }

                const triangle = values
                  .filter(
                    (item) =>
                      item.value !== null && item.chartType === triangleType
                  )
                  .map((item) => ({
                    ...item,
                    sub: keyInternal.trim(),
                    domain: key.trim(),
                    color:
                      this.colors.find((color) =>
                        key.toLowerCase().includes(color.code.toLowerCase())
                      )?.color || this.getColors(key),
                    isVerified: this.verifiedDataPoints[item.key],
                  }));

                if (triangle.length) {
                  triangleData.push(...triangle);
                }
                // const dataPoints = this.extractKeys(full);
                const valuesVerified = full.map((value) => {
                  const keyVal =
                    value.surveyQType === "Calculated"
                      ? value.oldKey
                      : value.key;
                  return {
                    ...value,
                    isVerified: this.verifiedDataPoints[keyVal],
                  };
                });

                return {
                  isActive: false,
                  title: keyInternal,
                  values: valuesVerified,
                  hasValues: full.length > 0,
                };
              }
            );

            result.triangle = triangleData;
            result.show = result.indicators.some(
              (indicator) => indicator.hasValues
            );
            return result;
          });
          this.spiderData = spiderData.filter(data => !(data.domain == 'Portfolio' && !this.isPortfolioVisible))
        // this.spiderData = spiderData;

        const domainCounts = spiderData.reduce((acc, item) => {
          if (!acc[item.domain]) {
            acc[item.domain] = {
              count: 0,
              color: item.color,
              domain: item.domain,
            };
          }
          acc[item.domain].count++;
          return acc;
        }, {});

        const targetLength = 15;
        const diff = targetLength - spiderData.length;
        const totalCount = spiderData.length + Math.abs(diff);

        let spiderSpaces = new Array(totalCount).fill(null);
        let index = 0;
        for (const domain in domainCounts) {
          if (index >= spiderSpaces.length) break;
          spiderSpaces[index] = {
            domain,
            count: domainCounts[domain].count,
            color: this.dimensions[domain]?.color,
            icon: this.dimensions[domain]?.icon
              ? this.dimensions[domain]?.icon
              : null,
          };
          index += domainCounts[domain].count;
        }

        if (diff !== 0) {
          const nullSpaces = new Array(Math.abs(diff)).fill(null);
          const step = Math.ceil(spiderSpaces.length / nullSpaces.length);
          for (let i = 0; i < nullSpaces.length; i++) {
            spiderSpaces.splice(i * step, 0, nullSpaces[i]);
          }
        }
        this.spiderSpaces = spiderSpaces.filter(data => !(data?.domain == 'Portfolio' && !this.isPortfolioVisible))
        // this.spiderSpaces = spiderSpaces;

        data.forEach((domain) =>
          domain?.indicators?.forEach((indicator) => {
            const yn = indicator.values.filter(
              (item) => item.chartType === "Yes/No"
            );
            indicator.values = indicator.values.filter(
              (item) => item.chartType !== "Yes/No"
            );
            if (yn.length) {
              indicator.values.push({
                chartType: "CustomTable",
                questions: yn,
              });
            }
          })
        );

        data.forEach((domain) => {
          domain?.indicators?.forEach((indicator) => {
            const groupedValues = {};
            indicator.values.forEach((value) => {
              if (value.clusteredCharts) {
                if (!groupedValues[value.title]) {
                  groupedValues[value.title] = [];
                }
                groupedValues[value.title].push(value);
              }
            });
            if (Object.values(groupedValues)[0]?.length) {
              indicator.values = [
                ...indicator.values,
                ...Object.keys(groupedValues).map((title) => ({
                  title,
                  values: groupedValues[title],
                  cluster: true,
                  chartType: "Clustered",
                })),
              ];
              indicator.values = indicator.values.filter(
                (item) => !item.clusteredCharts
              );
            }
          });
        });

        if (this.$route?.query?.teamId) {
          this.MLverifiedData = await this.getMLVerifiedData();
          if (Array.isArray(this.MLverifiedData)) {
            const updateMLVerifiedData = (values) => {
              values.forEach((value) => {
                if (value.key) {
                  // handle direct key
                  const matchingQuestion = this.MLverifiedData.find(
                    (item) => item.questionId === value.key
                  );
                  value.MLVerifiedData = matchingQuestion
                    ? matchingQuestion?.answer
                    : null;
                  value.citations = matchingQuestion?.citations;
                } else if (Array.isArray(value.questions)) {
                  // handle questions array
                  value.questions.forEach((question) => {
                    const matchingQuestion = this.MLverifiedData.find(
                      (item) => item.questionId === question.key
                    );
                    question.MLVerifiedData = matchingQuestion
                      ? matchingQuestion.answer
                      : null;
                    question.citations = matchingQuestion?.citations;
                  });
                } else if (Array.isArray(value.values)) {
                  // recursively update nested values
                  updateMLVerifiedData(value.values);
                }
              });
            };

            data.forEach((domain) => {
              domain?.indicators?.forEach((indicator) => {
                updateMLVerifiedData(indicator.values);
              });
            });
          } else {
            console.error("MLverifiedData is not an array:");
          }
        }
        data.push(await this.getThemesDimension());
        this.structuredData = data;
        this.structuredData.forEach(data => {
          if(data.title == 'Portfolio' && !this.isPortfolioVisible) {
            data.show = false;
          }
        });
      } catch (error) {
        console.error("Error fetching structured data:", error);
      }
    },
    async setVerifiedStatus(value, status) {
      const teamId = this.$route?.query?.teamId;
      const keyVal =
        value.surveyQType === "Calculated" ? value.oldKey : value.key;
      await apiService.putVerifiedStatus(teamId, keyVal, status);
      value.isVerified = status;
      this.updateKey++;
    },
    async getVerifiedStatus() {
      try {
        const data = await apiService.fetchVerifiedStatus(
          this.$route?.query?.teamId
        );
        this.verifiedDataPoints = data.data.data;
      } catch (error) {
        console.error("Error fetching verified status:", error);
      }
    },
    extractKeys(obj) {
      let keys = [];

      function recurse(value) {
        if (typeof value === "object" && value !== null) {
          for (const key in value) {
            if (key === "key") {
              keys.push(value[key]);
            }
            recurse(value[key]);
          }
        } else if (Array.isArray(value)) {
          value.forEach((item) => recurse(item));
        }
      }

      recurse(obj);
      return keys;
    },
    openEditModal(item) {
      this.selectedItem = item;
      this.isEditModalVisible = true;
    },
    async closeEditModal() {
      this.isEditModalVisible = false;
      this.selectedItem = null;
    },
    async closeEditModalAndFetchCertResults() {
      this.showLoader = true;
      await this.getVerifiedStatus();
      await this.fetchCertificationData();
      await this.getStructuredData();
      this.$emit("auditLogsData", this.$route.query.teamId);
      this.isEditModalVisible = false;
      this.selectedItem = null;
      this.updateKey++;
      this.showLoader = false;
      await this.$swal.fire({
        icon: "success",
        text: "Updated Successfully!",
        showConfirmButton: true,
      });
    },
    getBasePath() {
      if (this.$route.path.includes("/2x-global/admin/full-assessment")) {
        return "/2x-global/admin/full-assessment";
      }
      if (this.$route.path.includes("/admin/full-assessment")) {
        return "/admin/full-assessment";
      }
      return "/2X-Ecosystem/2X-Certification";
    },
    getOverviewPath() {
      if (this.$route.path.includes("/2x-global/admin/full-assessment")) {
        return `/2x-global/admin/assessment?teamId=${this.$route.query.teamId}`;
      }
      if (this.$route.path.includes("/admin/full-assessment")) {
        return `/admin/assessment?teamId=${this.$route.query.teamId}`;
      }
      return "/2X-Ecosystem/2X-Certification";
    },
    async getMLVerifiedData() {
      try {
        const data = await axios.get(
          `${env.apiUrl}/certify2X/get-company-data?companyId=${this.$route.query.teamId}&admin=1`,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        );
        return data.data.data;
      } catch (error) {
        console.error("Error fetching ML verified data:", error);
        return [];
      }
    },
    async checkRequestPaymentStatus() {
      if (this.$route.query.teamId && this.user.isVerifier) {
        await apiService
          .fetchVerifierRequests(this.$route.query.teamId)
          .then(async (response) => {
            const userAssessData = response.data.data;
            // User is Verifier - Check payment
            if (
              userAssessData.paymentStatus !== "Completed" &&
              this.$route.path.includes("/admin")
            ) {
              await this.$router.push({ path: "/admin" });
            }
          });
      }
    },
    getStepClass(step) {
      const stepRanges = [0, 35, 67, 100];
      const nextStep = stepRanges[step + 1] || 101;
      const currentStep = stepRanges[step];
      return {
        "font-bold":
          this.twoXScoreOverall < nextStep &&
          this.twoXScoreOverall >= currentStep,
      };
    },
    async handleExportToPDF() {
      let pdfName =
        this.certificationScoreData?.team_id?.name +
        "s 2X Certification Self-Assessment Results";
      this.isDownloadingPDF = true;
      try {
        const element = this.$refs.contentWrapper;
        await pdfExportHelpers.exportToFullPDF(element, pdfName);
      } catch (error) {
        console.error("Error exporting to PDF:", error);
      } finally {
        this.isDownloadingPDF = false;
      }
    },
  },
  beforeDestroy() {
    if (this.recogito) {
      this.recogito.destroy();
    }
  },
  computed: {
    twoxEligible() {
      let val;
      if (this.entityChosen == "fund-manager") {
        val = this.certificationScoreData["2X_2X_Score_Overall_301"];
      } else if (this.entityChosen == "financial-institution") {
        val = this.certificationScoreData["2X_2X_Score_Overall_302"];
      } else if (
        this.entityChosen == "start-up" ||
        this.entityChosen == "sme" ||
        this.entityChosen == "corporate"
      ) {
        val = this.certificationScoreData["2X_2X_Score_Overall_300"];
      }
      return val;
    },
    blueChartStrokeValue() {
      let value;
      const percent = 80;
      const stroke = 408.2;
      value = (stroke / 100) * (100 - percent);
      return value;
    },
    yellowChartStrokeValue() {
      let value;
      const percent = 80;
      const stroke = 722.2;
      value = (stroke / 100) * (100 - percent);
      return value;
    },
    isAllowedDownloadFiles() {
      return (
        this.user.isVerifier || this.user?.featureFlags?.includes("2xDownload")
      );
    },
  },
  async mounted() {
    this.query = this.$route.query.teamId;
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    // Verification only for "User" users
    if (
      this.$route.path.includes(
        "/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results"
      )
    ) {
      let hasLevelUp = false;
      const subscriptionAPI = new SubscriptionAPIs();
      const purchaseHistoryResponse = await subscriptionAPI.getPurchaseHistory(
        "User",
        this.user?.organization?._id
      );
      const purchaseHistory = purchaseHistoryResponse.data;

      purchaseHistoryLoop: for (let i = 0; i < purchaseHistory.length; i++) {
        const purchase = purchaseHistory[i];
        for (let x = 0; x < purchase.products.length; x++) {
          if (purchase.products[x].name === "Level-Up Package") {
            hasLevelUp = true;
            break purchaseHistoryLoop;
          }
        }
      }

      // User does not have purchased the Level-Up product, redirect to the IndicativeResults page
      // Added teamId in if to bypass redirection for admin users
      if (!hasLevelUp && !this.$route.query.teamId) {
        await this.$router.push({
          path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
        });
      }
    }

    // Verification only for "Verifier" users
    if (
      this.$route.path.includes("/admin/assessment") &&
      this.$route.path.includes("/admin/full-assessment")
    ) {
      await this.checkRequestPaymentStatus();
    }

    await apiService.fetch2xUserSurvey("2x", this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id).then(async (response) => {
      this.certificationSurveyData = await response.data.data;
    });
    await apiService
    .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
    .then(async (response) => {
      this.status2xCertification = response?.data?.data?.status;
    });
    if(!this.certificationSurveyData) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification",
      });
    }
    if (this.certificationSurveyData) {
      await this.certificationSurveyData.forEach((data) => {
        if (data.surveyId === "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    if (this.$route.path === "/admin/full-assessment") {
      await this.getVerifiersComments();
      await this.getVerifiedStatus();
    }
    await this.fetchCertificationData();
    if (this.entityChosen) {
      await this.fetchCsvData();
    }
    await this.getAllDocuments();
    this.endofcreated = true;
    this.$emit("endofcreated", this.endofcreated);
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);

    if (this.$route.path === "/2x-global/admin/full-assessment") {
      this.$store.commit("setNewBreadcrumbs", [
        { name: "2X Global Dashboard", url: "/2x-global/2X-Certification" },
        {
          name: "2X Global Verification",
          url: `/2x-global/admin/assessment?teamId=${this.$route.query.teamId}`,
        },
        {
          name: "2X Detailed Assessment Results",
          url: `/2x-global/admin/full-assessment?teamId=${this.$route.query.teamId}`,
        },
      ]);
      setTimeout(() => {
        this.updateRecogito();
      }, 4000);
    } else if (this.$route.path === "/admin/full-assessment") {
      this.$store.commit("setNewBreadcrumbs", [
        { name: "2X Verification Dashboard", url: "/admin" },
        {
          name: "2X Verification",
          url: `/admin/assessment?teamId=${this.$route.query.teamId}`,
        },
        {
          name: "2X Detailed Assessment Results",
          url: `/admin/full-assessment?teamId=${this.$route.query.teamId}`,
        },
      ]);
      setTimeout(() => {
        this.updateRecogito();
      }, 4000);
    } else {
      this.$store.commit("setNewBreadcrumbs", [
        {
          name: "2X Certification",
          url: "/2X-Ecosystem/2X-Certification",
        },
        {
          name: "2X Global Certification",
          url: "/2X-Ecosystem/2X-Certification/GlobalCertification",
        },
        {
          name: "2X Detailed Assessment Results",
          url: "/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results",
        },
      ]);
    }
  },
};
</script>

<style lang="scss" scoped>
.hide-in-pdf {
  /* Visible in UI */
  display: block;
}

@media print {
  /* Hide the element only in print */
  .hide-in-pdf {
    display: none !important;
  }
}
.esg-pre-screening {
  padding: 40px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;

  .esg-pre-screening-results-section {
    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }
  }

  .OverviewTitle {
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #1b1b1b;
      margin-right: 17px;
      position: relative;
    }
  }

  .GBVdetailsContent {
    justify-content: space-between;

    ul {
      margin-top: 1.5rem;
    }

    ul li {
      display: flex;
      margin: 0;
    }

    .overOverviewDeitail {
      width: 100%;

      span {
        font-weight: bold;
        font-size: 17px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
      }
    }

    .GbvRiskContent {
      max-width: 670px;
      widows: 100%;

      .step_gbvRisk {
        flex-wrap: wrap;
      }

      .GbvRiskTitleWrepper {
        margin-bottom: 40px;

        .GbvRiskTitle {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 60px;
            height: 40px;
            object-fit: contain;
            margin-right: 20px;
          }

          h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #1b1b1b;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #9d9d9c;
        }
      }

      .SevereRiskWrepper {
        h1 {
          margin-top: 60px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #fe5000;

          img {
            width: 40px;
            height: 70px;
            object-fit: contain;
            margin-right: 30px;
          }

          span {
            margin-right: 5px;
          }
        }

        .UbiquitousTextIcon {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 16px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px !important;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #9d9d9c;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.GBVdetailsBlock {
  border-radius: 10px;
  padding: 40px;

  .GBVdetailsContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .overOverviewDeitail {
      width: 100%;

      span {
        font-weight: bold;
        font-size: 17px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
      }
    }

    .GbvRiskContent {
      max-width: 670px;
      widows: 100%;

      .step_gbvRisk {
        flex-wrap: wrap;
      }

      .GbvRiskTitleWrepper {
        margin-bottom: 40px;

        .GbvRiskTitle {
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 60px;
            height: 40px;
            object-fit: contain;
            margin-right: 20px;
          }

          h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #1b1b1b;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          text-align: center;
          color: #9d9d9c;
        }
      }

      .SevereRiskWrepper {
        h1 {
          margin-top: 60px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #fe5000;

          img {
            width: 40px;
            height: 70px;
            object-fit: contain;
            margin-right: 30px;
          }

          span {
            margin-right: 5px;
          }
        }

        .UbiquitousTextIcon {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 16px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px !important;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #9d9d9c;
            margin-right: 20px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.font-arial {
  font-family: Arial;
}

.barchart2xCriteria_Wrepper {
  .CriteriaTitleText {
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
      width: 30px;
      // height: 20px;
      object-fit: contain;
    }

    p {
      font-family: Arial;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      color: #21191d;
    }
  }

  .ChartBarContentWrepper {
    gap: 60px;
    border-bottom: 1px solid #e1e3e9;

    .multiple_employment_results_section {
      p {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .ChartBarContent {
      .Founded_WomanText {
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #21191d;
        margin-bottom: 15px;
        line-height: 24px;
      }

      .Founded_WomanImg {
        display: flex;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      .ChartBarPercentageText {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #21191d;
        margin-bottom: 10px;
      }
    }

    .ChartBarContent_flex {
      display: flex;
      width: 100%;
      max-width: 310px;
      margin-right: 100px;

      .Founded_WomanText {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        color: #1b1b1b;
        margin-bottom: 15px;
      }

      .Founded_WomanImg {
        display: flex;
        align-items: center;

        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      .ChartBarPercentageText {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 10px;
      }
    }
  }

  .Consumption_ListText {
    p {
      margin-bottom: 15px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .Quality_EmploymentText_left {
    .assessment_year_one_results {
      width: 50%;
      justify-content: space-between;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .assessment_year_second_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;
        margin-left: 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .Quality_EmploymentText_right {
    .assessment_year_one_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    .assessment_year_second_results {
      width: 50%;

      p {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
      }
    }

    p {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        list-style: none;
        margin-bottom: 10px;
        margin-left: 0;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 10px;
        }
      }
    }
  }

  .IndirectInvestmentListContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .IndirectContent {
      max-width: 301px;
      width: 100%;

      .IndirectInvWrepper {
        margin-bottom: 45px;

        .IndirectInvText {
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #1b1b1b;
          margin-bottom: 16px;
        }

        .IndirectInvImg {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: contain;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.ContentWrapper {
  .input-select {
    border: 1px solid #e1e3e9;
    padding: 12px 24px 12px 64px;
  }

  .next-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  .standard-button {
    font-weight: bold;
    font-size: 16px;
    color: #191b45;
    border-style: solid;
    border-color: #191b45;
    border-width: 1px;
  }

  .full-assessment-button {
    font-weight: bold;
    color: white;
    background: #395aff;
    padding: 12px 24px 12px 24px;
    font-size: 16px;
  }

  .certification-button {
    font-weight: bold;
    color: white;
    background: #191b45;
  }

  .congrats-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-right: 200px;
    padding-left: 200px;
  }

  .theme-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
  }

  .theme-badge {
    border: 2px solid;
    border-radius: 10px;
    margin: 20px 5px 0px;
    padding: 10px;
    height: 100%;
    font-size: 14px;
  }

  .overview-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    //padding: 20px;
    /* FROM my2xassement PAGE */
    .OverviewTitle {
      display: flex;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
      }

      // span {
      //   display: inline-block;
      //   border-bottom: 2px solid #1b1b1b;
      //   width: 100%;
      //   height: 2px;
      // }
    }

    hr {
      margin: 20px 0;
      width: 103.5%;
      text-align: left;
      margin-left: -1.75%;
      border-color: #ccc;
    }

    .spiderchartEligibleWrepper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .spiderchartContent {
        width: 100%;
      }
    }
  }

  .chart-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    .heading-section {
      border-bottom: 1px solid #e1e3e9;
    }

    .inner-main-heading {
      .inner-section {
        width: -webkit-fill-available;
      }

      p {
        font-family: Arial;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    /* FROM my2xassement PAGE */
    .OverviewTitle {
      display: flex;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
      }

      // span {
      //   display: inline-block;
      //   border-bottom: 2px solid #1b1b1b;
      //   width: 100%;
      //   height: 2px;
      // }
    }

    hr {
      margin: 20px 0;
      width: 103.5%;
      text-align: left;
      margin-left: -1.75%;
      border-color: #ccc;
    }

    .spiderchartEligibleWrepper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .spiderchartContent {
        width: 100%;
      }

      .Xeligibleconten {
        width: 50%;

        .EligibleListWrepper {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          // margin-bottom: 15px;
          .EligibleListImg {
            position: relative;
            z-index: 1;
            margin-right: 30px;
            // &::after {
            //   content: "";
            //   display: inline-block;
            //   border: 1px solid #d0d0d0;
            //   position: absolute;
            //   height: 20px;
            //   top: 60px;
            //   left: 30px;
            //   z-index: -1;
            // }

            img {
              // width: 24px !important;
              // height: 24px;
              border-radius: 50%;
              object-fit: contain;
              margin: 0 !important;
              margin-right: 0 !important;
            }
          }

          .bigImg {
            &::after {
              top: 40px;
              left: 18px;
              height: 20px;
            }

            img {
              width: 40px !important;
              height: 40px;
            }
          }

          .lastAffter {
            &::after {
              content: none;
            }
          }

          .EligibleTitle {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              object-fit: contain;
            }

            h4 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }

            h3 {
              font-size: 25px;
              font-style: normal;
              font-weight: bold;
              line-height: 30px;
              color: #1b1b1b;
            }
          }
        }

        .BorderLine {
          border: 1px solid #d0d0d0;
          max-width: 375px;
          margin: 30px 0;
        }

        .PartyVerifiedToolTip {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .PartyTooltipImg {
            img {
              // width: 20px;
              // height: 20px;
              border-radius: 50%;
            }
          }

          .TooltipImgText {
            display: flex;
            align-items: center;

            img {
              width: 15px;
              height: 15px;
              margin-left: 5px;
            }

            p {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }

            .tooltiptext {
              max-width: 400px;
              top: 20px;
            }
          }
        }

        .DownloadBtn {
          p {
            display: flex;
            align-items: center;
            border: 1px solid #1b1b1b;
            border-radius: 20px;
            color: #1b1b1b;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 11px 17px;
            transition: 0.3s ease;

            img {
              margin-right: 5px;
              width: 15px;
              height: 15px;
            }

            &:hover {
              background: rgb(32, 23, 71);
              color: #fff;
              border-color: rgb(32, 23, 71);
            }
          }

          a {
            display: flex;
            align-items: center;
            border: 1px solid #1b1b1b;
            border-radius: 20px;
            color: #1b1b1b;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            padding: 11px 10px;
            transition: 0.3s ease;

            img {
              margin-right: 8px;
              width: 15px;
              height: 15px;
            }

            &:hover {
              background: rgb(32, 23, 71);
              color: #fff;
              border-color: rgb(32, 23, 71);
            }
          }
        }
      }
    }

    .strategic-plan-section {
    }

    .management-systems-section {
      .gender-policies-section {
      }
    }

    .management-systems-data-section {
      border-bottom: 1px solid #e1e3e9;

      .dimensions-section,
      .themes-section {
        p {
          font-size: 14px;
          font-weight: 400;
          color: #21191d;
        }
      }
    }
  }

  /* Tooltip container */
  .div_tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .div_tooltip .div_tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .div_tooltip:hover .div_tooltiptext {
    visibility: visible;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .download-csv-tooltip {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 290px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -86px;
    bottom: 100%;
    white-space: normal;
    word-wrap: break-word;
  }

  .tooltip .tooltiptext {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 600px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 20px;
    top: -160px;
  }

  .tooltip .twoXEligibleHoverText {
    padding: 20px;
    font-size: medium;
    letter-spacing: normal;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 141px;
    top: -35px;
  }

  .tooltip .text-style {
    font-size: 14px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .header-style {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 1 !important;
  }

  .tooltip .entrepreneurshipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 330px;
    top: -40px;
  }

  .tooltip .leadershipHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 139px;
    top: -40px;
  }

  .tooltip .employmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .consumptionHoverText {
    padding: 20px;
    visibility: hidden;
    width: 400px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 230px;
    top: -40px;
  }

  .tooltip .supplyChainHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .portfolioHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 120px;
    top: -40px;
  }

  .tooltip .commitmentHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 160px;
    top: -40px;
  }

  .tooltip .managementSystemsHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 250px;
    top: -40px;
  }

  .tooltip .dataHoverText {
    padding: 20px;
    visibility: hidden;
    width: 350px;
    background-color: #19486a;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    /* Position the tooltip */
    position: absolute;
    z-index: 99;
    left: 80px;
    top: -40px;
  }

  .tooltip:hover .download-csv-tooltip {
    visibility: visible;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .remaining_companies_tooltiptext {
    visibility: visible;
  }

  .tooltip:hover .twoXEligibleHoverText {
    visibility: visible;
  }

  .tooltip:hover .entrepreneurshipHoverText {
    visibility: visible;
  }

  .tooltip:hover .leadershipHoverText {
    visibility: visible;
  }

  .tooltip:hover .employmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .consumptionHoverText {
    visibility: visible;
  }

  .tooltip:hover .supplyChainHoverText {
    visibility: visible;
  }

  .tooltip:hover .portfolioHoverText {
    visibility: visible;
  }

  .tooltip:hover .commitmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .managementSystemsHoverText {
    visibility: visible;
  }

  .tooltip:hover .dataHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirectInvestmentHoverText {
    visibility: visible;
  }

  .tooltip:hover .indirect_tooltip_text {
    visibility: visible;
  }

  .tooltip:hover .tooltiptextIndirectInvestement {
    visibility: visible;
  }

  .tooltip .download-csv-tooltip::after {
    right: 95%;
  }

  .tooltip .tooltiptext::after {
    right: 95%;
  }

  .tooltip .remaining_companies_tooltiptext::after {
    right: 95%;
  }

  .tooltip .twoXEligibleHoverText::after {
    right: 95%;
  }

  .tooltip .entrepreneurshipHoverText::after {
    right: 95%;
  }

  .tooltip .leadershipHoverText::after {
    right: 95%;
  }

  .tooltip .employmentHoverText::after {
    right: 95%;
  }

  .tooltip .consumptionHoverText::after {
    right: 95%;
  }

  .tooltip .supplyChainHoverText::after {
    right: 95%;
  }

  .tooltip .portfolioHoverText::after {
    right: 95%;
  }

  .tooltip .commitmentHoverText::after {
    right: 95%;
  }

  .tooltip .managementSystemsHoverText::after {
    right: 95%;
  }

  .tooltip .dataHoverText::after {
    right: 95%;
  }

  .tooltip .indirectInvestmentHoverText::after {
    right: 95%;
  }

  .tooltip .indirect_tooltip_text::after {
    right: 95%;
  }

  .tooltip .tooltiptextIndirectInvestement::after {
    right: 95%;
  }

  .tooltip .tooltiptext::after {
    display: none;
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  //font-family: Work Sans !important;
  font-family: Montserrat, Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  .PoweredbyTitle_logo {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #21191d;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .eligible-level-section {
        margin: 28px 0;
        display: flex;
        padding: 16px;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 12px;
        background: #f4f6f9;

        .eligible-level-text {
          color: var(--grey-grey-100, #21191d);
          text-align: center;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        img {
          width: 80px;
        }
      }

      .certification-details {
        .inner-certification-details {
          display: flex;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          .image-div {
            height: 20px;

            img {
              padding: 0;
              height: 36px;
              width: 50px;
            }
          }

          img {
            display: flex;
            width: 36px;
            height: 36px;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 6px;
          }

          .write-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .layer-color {
            background: var(--red-light, #ffece8);
          }

          .letter-color {
            background: var(--yellow-light, #fff8e0);
          }

          .check-color {
            background: var(--turquoise-light, #e5fafa);
          }

          .calendar-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .text {
            color: var(--grey-grey-100, #21191d);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            line-height: 12px;
          }
        }
      }

      .amount-button-section {
        padding: 16px 0 0 0;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid var(--grey-grey-20-line, #e1e3e9);

        .amount {
          color: var(--grey-grey-100, #21191d);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .amount-bold {
          color: var(--grey-grey-100, #21191d);
          text-align: right;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        .amount-details {
          color: var(--grey-grey-100, #21191d);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .button-section {
          button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--primary-100, #191b45);
            margin-right: 5px;
          }

          .cancel-button {
            color: var(--primary-100, #191b45);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 5px;
          }

          .save-button {
            color: var(--white, #fff);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: var(--primary-100, #191b45);
          }
        }

        .privacy-notice-disclosure {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }

        .inner-data-privacy {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration-line: underline;
        }
      }
    }
  }

  .bottom-button-section {
    // height: 120px;
    padding: 36px 10%;
    border-top: 1px solid #e1e3e9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636e801a;

    button {
      height: 48px;
      gap: 20px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      border: 1px solid #191b45;
      color: #191b45;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }

    .add-note-button {
      color: #fff;
      background: #395aff;
      border: none;
      gap: 8px;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

#SPAN_1:hover {
  color: #40a9ff;
}

.text-black-90 {
  color: #21191d;
}

.bottom-border {
  border-bottom: 1px solid #e1e3e9;
}

.CountryOverviewText {
  p,
  .leading-7 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.super-checkbox:checked + .checkbox-label {
  background-color: #4caf50 !important; /* Green color when checked */
}

.super-checkbox:checked + .checkbox-label img {
  display: block; /* Show the SVG checkmark when checked */
}
</style>
