<template>
    <div class="table-container">
        <table class="inner-table">
            <tr class="table-heading-row">
                <th v-for="(head, i) in tableData.heading" :key="'head' + i">
                    {{ head }}
                </th>
            </tr>
            <tr class="table-content-row" v-for="data in tableData.data" :key="data.rowHeading">
                <td class="w-5/12">{{ data.rowHeading }}</td>
                <!-- <td class="text-right font-bold w-1/12">
                    <span v-if="data.percent == 'NA'">NA</span>
                    <span v-else>{{ data.percent }}</span>
                </td> -->
                <!-- <td class="w-2/12">
                    <div>Good > 50%</div>
                    <div>Advanced > 60%</div>
                    <div>Best in class > 70%</div>
                </td> -->
                <td class="w-2/12" style="text-align: center;" v-if="data.rowHeading == '2X Certification Level'">
                  <!-- <ThemeBadge :score="data.badge" :badgeName="data.badgeName"></ThemeBadge> -->
                  <img style="width: 150px; height: 150px;"
                        v-if="data.badge == 'Good'"
                        class="mx-auto mb-3 h-48 w-48"
                        src="@/assets/images/2x_certify/2x_certification_tier_good.svg"
                    />
                    <img style="width: 150px; height: 150px;"
                        v-if="data.badge == 'Advanced'"
                        class="mx-auto mb-3 h-48 w-48"
                        src="@/assets/images/2x_certify/2x_certification_tier_advanced.svg"
                    />
                    <img style="width: 150px; height: 150px;"
                        v-if="data.badge == 'Best in Class'"
                        class="mx-auto mb-3 h-48 w-48"
                        src="@/assets/images/2x_certify/2x_certification_tier_bestinclass.svg"
                    />
                </td>
                <td class="w-2/12 text-center" v-else>
                    <img
                        src="@/assets/images/Rightcircle.svg"
                        alt="background-image"
                        v-if="
                          data.percent == 'Yes'"
                      />

                      <img
                        style="border-radius: 0"
                        src="@/assets/images/Crosscircle.svg"
                        alt="background-image"
                        v-else
                      />
                </td>
                <td class="w-2/12">
                    <div v-if="data?.status && data.status != null" class="flex justify-around">
                        <button :class="{ 'rejected-status': data?.status == 'Rejected', 'accepted-status': data?.status == 'Accepted' }">
                            {{ data?.status }}
                        </button>
                    </div>
                    <div v-else class="flex justify-around">
                        <button @click="openRejectModal(data)"
                            class="red-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                                    fill="#ff3a21" />
                            </svg>
                            Reject
                        </button>
                        <button @click="updateVerificationStatus(data)"
                            class="green-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g id="icon/check">
                                    <path id="Vector"
                                        d="M9.76691 15.6786L6.21092 12.258L5 13.4146L9.76691 18L20 8.1566L18.7976 7L9.76691 15.6786Z"
                                        fill="#3F7E44" />
                                </g>
                            </svg>

                            Approve
                        </button>
                    </div>
                </td>
            </tr>
        </table>
        <VerificationRejectModal v-if="isVerificationModal" @close="isVerificationModal = false, selectedBadge = null"
      :selectedBadge="selectedBadge" @reject="$emit('verificationDataRefresh')" />
    </div>
</template>
<script>
// import ThemeBadge from "@/components/themeBadges.vue";
import VerificationRejectModal from "@/components/2x-certification/verificationRejectionModal.vue";
import apiService from '@/helpers/apiServices.js';
export default {
    name: "VerificationRecommendationTable",
    props: ["tableData"],
    data() {
        return {
            isVerificationModal: false,
            selectedBadge: null,
            status: null,
        };
    },
    components: {
        VerificationRejectModal,
        // ThemeBadge
    },
    methods: {
        updateVerificationStatus(data) {
            this.selectedBadge = data;
            apiService.putVerificationRecommendsStatusByVerifier(this.$route.query.teamId, data, 'Accepted')
                .then(() => {
                    this.$emit("verificationDataRefresh");
                    this.$swal.fire({
                        icon: "success",
                        text: "Approved successfully!",
                        showConfirmButton: true,
                    });
                    this.documentComment = "";
                    this.selectedBadge = null;
                })
                .catch(() => {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong!",
                        showConfirmButton: true,
                    });
                    this.selectedBadge = null;
                });
        },
        openRejectModal(data) {
            this.selectedBadge = data;
            this.isVerificationModal = true;
        }
    }
};
</script>
<style lang="scss" scoped>
.table-container {
    gap: 20px;
    border-radius: 16px;
    border: 1px solid #e1e3e9;

    .red-button {
        font-weight: bold;
        font-size: 14px;
        color: #ff3a21;
        border-style: solid;
        border-color: #ff3a21;
        border-width: 1px;
    }

    .green-button {
        font-weight: bold;
        font-size: 14px;
        color: #3f7e44;
        border-style: solid;
        border-color: #3f7e44;
        border-width: 1px;
    }

    .rejected-status {
        color: #ff3a21;
        padding: 5px 8px;
        border: 1px solid #ff3a21;
        border-radius: 7px;
        cursor: default;
        font-size: 14px;
        font-weight: bold;
    }

    .accepted-status {
        color: #3f7e44;
        padding: 5px 8px;
        border: 1px solid #3f7e44;
        border-radius: 7px;
        cursor: default;
        font-size: 14px;
        font-weight: bold;
    }

    .inner-table {
        width: 100%;

        th,
        td {
            border: 1px solid #e1e3e9;
        }

        .table-heading-row {
            th {
                padding: 16px;
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                    linear-gradient(0deg, #e1e3e9, #e1e3e9);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2c2e35;
            }

            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }

            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }

        .table-content-row {
            img {
                width: 24px;
                height: 24px;
                margin: auto;
            }

            td {
                border-bottom: none;
                height: 85px;
                padding: 16px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;
            }

            td:first-child {
                border-left: none;
            }

            td:last-child {
                border-right: none;
            }
        }
    }

    .red {
        color: #cb2e00;
    }

    .green {
        color: #13a094;
    }
}
</style>