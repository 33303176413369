<template>
  <div class="static flex flex-col">
    <div id="loading" v-if="loading" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="sub-dimension-page-container container mx-auto -mt-10 px-4 lg:px-0"
    >
      <BreadCrumbs></BreadCrumbs>
      <div
        class="inner-dimension-container mt-6 text-black-90"
        :key="'updateComponent' + updateKey"
      >
        <div class="dimesnion-heading font-bold text-base">{{ title }}</div>
        <div class="main-dimension flex justify-between mt-2">
          <p class="font-bold text-4xl">{{ subDomain }}</p>
        </div>
        <div v-if="this.$route.query.d" class="mt-5">
          <div v-for="(subdomain, k) in indicators" :key="k">
            <h2 class="font-bold text-2xl">{{ subdomain.sub }}</h2>
            <div class="gap-6 flex flex-wrap">
              <dynamic-render
                v-for="(item, k) in subdomain.indicators"
                :key="k"
                :type="item.chartType"
                :info="item"
                :certificationScoreData="certificationScoreData"
                :COM_Commitment="COM_Commitment"
                :showLoader="showLoader"
                @close="closeEditModal"
                @closeAndUpdate="closeEditModalAndFetchCertResults"
                @openEditModal="openEditModal"
              />
            </div>
          </div>
        </div>
        <div v-else class="content-section mt-7 gap-6 flex flex-wrap">
          <dynamic-render
            v-for="(item, k) in indicators"
            :key="k"
            :type="item.chartType"
            :info="item"
            :certificationScoreData="certificationScoreData"
            :COM_Commitment="COM_Commitment"
            :showLoader="showLoader"
            @close="closeEditModal"
            @closeAndUpdate="closeEditModalAndFetchCertResults"
            @openEditModal="openEditModal"
          />
        </div>
      </div>
    </div>
    <div class="bottom-button-section text-right mt-auto">
      <button
        @click="
          $router.push({
            path: previousPage,
          })
        "
      >
        Back to Dashboard
      </button>
    </div>
    <edit-modal
      v-if="isEditModalVisible"
      :item="selectedItem"
      :entity="entityChosen"
      :showLoader="showLoader"
      :uuid="certificationScoreData?.uuid"
      :COM_Commitment="COM_Commitment"
      @close="closeEditModal"
      @closeAndUpdate="closeEditModalAndFetchCertResults"
    />
  </div>
</template>
<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import dynamicRender from "./dynamic-render.vue";
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import apiService from "@/helpers/apiServices.js";
import EditModal from "./editModal.vue";

export default {
  name: "My2XCertificationSubDimension",
  components: {
    BreadCrumbs,
    "dynamic-render": dynamicRender,
    pageLoader,
    EditModal,
  },
  data() {
    return {
      structuredData: [],
      indicators: [],
      entityChosen: "",
      entityCode: "",
      subDomain: "",
      title: "",
      user: [],
      loading: true,
      certificationScoreData: [],
      certificationSurveyData: [],
      status2xCertification: "",
      isEditModalVisible: false,
      showLoader: false,
      selectedItem: null,
      updateKey: 0,
      COM_Commitment: [],
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      verifiedDataPoints: [],
      previousPage: "",
    };
  },
  methods: {
    async getVerifiedStatus() {
      try {
        const data = await apiService.fetchVerifiedStatus(
          this.$route?.query?.teamId
        );
        this.verifiedDataPoints = data.data.data;
      } catch (error) {
        console.error("Error fetching verified status:", error);
      }
    },
    openEditModal(item) {
      this.selectedItem = item;
      this.isEditModalVisible = true;
    },
    async closeEditModal() {
      this.isEditModalVisible = false;
      this.selectedItem = null;
    },
    async closeEditModalAndFetchCertResults() {
      this.showLoader = true;
      await this.getVerifiedStatus();
      await this.fetchCertificationData();
      await this.getStructuredData();
      this.isEditModalVisible = false;
      this.selectedItem = null;
      this.showLoader = false;
      this.updateKey++;
      await this.$swal.fire({
        icon: "success",
        text: "Updated Successfully!",
        showConfirmButton: true,
      });
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;
      this.COM_Commitment = [
        this.workforceThreshold?.split("%")?.[0],
        this.srManagerThreshold?.split("%")?.[0],
        this.boardThreshold?.split("%")?.[0],
      ];
    },
    async getThresholdsData(recordId, sector) {
      await apiService
        .fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
        });
    },
    async fetchCertificationData() {
      try {
        const res = await apiService.fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$store.state.certPageAccess.isFullSurveyFill ? "Full" : "Min"
        );

        this.certificationScoreData = res.data.data;
        await this.calculateThreshold();
      } catch (error) {
        console.error("Error fetching certification data:", error);
      }
    },
    async getStructuredData() {
      try {
        const url = this.$route.query.teamId
          ? `${env.apiUrl}/twox-framework/fetch-data?teamId=${this.$route.query.teamId}`
          : `${env.apiUrl}/twox-framework/fetch-data`;
        const response = await axios.post(
          url,
          {
            page: "Sub-Dimension Detailed Results",
            entity: this.entityCode,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        // filter response.data by dimension if provided
        if (this.$route.query.d) {
          response.data = Object.fromEntries(
            Object.entries(response.data).filter(
              ([key]) => key === this.subDomain
            )
          );
        }

        const ALLOWED = [
          "Yes/No",
          "Donut chart",
          "List",
          "Bar graph",
          "2X Spider Chart Score Value",
          "Text",
          "Single select"
        ];
        const data = Object.entries(response.data).map(([key, value]) => {
          if (value[this.subDomain]) {
            this.title = key.trim();
          }

          const baseIndicators = Object.entries(value).map(
            ([keyInternal, values]) => {
              const filteredValues = values
                .filter(
                  (item) =>
                    item.value !== "NA" &&
                    item.value !== null &&
                    ALLOWED.includes(item.chartType) &&
                    item.value &&
                    item.title
                )
                .map((item) => ({
                  ...item,
                  sub: keyInternal,
                }));
              let valuesVerified;
              if (this.$route?.query?.fromVerifier) {
                valuesVerified = filteredValues.map((value) => {
                  const keyVal =
                    value.surveyQType === "Calculated"
                      ? value.oldKey
                      : value.key;
                  return {
                    ...value,
                    isVerified: this.verifiedDataPoints[keyVal],
                  };
                });
              }
              return {
                isActive: false,
                title: keyInternal,
                values: this.$route?.query?.fromVerifier
                  ? valuesVerified
                  : filteredValues,
                hasValues: filteredValues.length > 0,
              };
            }
          );

          return {
            isActive: false,
            title: key,
            indicators: baseIndicators,
            show: baseIndicators.some((indicator) => indicator.hasValues),
          };
        });

        // Custom Table for Yes/No
        data.forEach((domain) =>
          domain?.indicators?.forEach((indicator) => {
            const yn = indicator.values.filter(
              (item) => item.chartType === "Yes/No"
            );
            indicator.values = indicator.values.filter(
              (item) => item.chartType !== "Yes/No"
            );
            if (yn.length) {
              indicator.values.push({
                chartType: "CustomTable",
                questions: yn,
                sub: indicator?.values[0]?.sub,
              });
            }
          })
        );

        // Clustering
        data.forEach((domain) => {
          domain?.indicators?.forEach((indicator) => {
            const groupedValues = {};
            indicator.values.forEach((value) => {
              if (value.clusteredCharts) {
                if (!groupedValues[value.title]) {
                  groupedValues[value.title] = [];
                }
                groupedValues[value.title].push(value);
                groupedValues.sub = value.sub;
              }
            });
            if (Object.values(groupedValues)[0]?.length) {
              indicator.values = [
                ...indicator.values,
                ...Object.keys(groupedValues).map((title) => ({
                  title,
                  values: groupedValues[title],
                  cluster: true,
                  chartType: "Clustered",
                  sub: indicator.title,
                })),
              ];
              indicator.values = indicator.values.filter(
                (item) => !item.clusteredCharts
              );
            }
          });
        });

        this.structuredData = data;

        const allowedIndicators = [
          "Donut chart",
          "CustomTable",
          "List",
          "2X Spider Chart Score Value",
          "Text",
          "Clustered",
          "Single select"
        ];
        this.indicators = [];
        this.structuredData.forEach((domain) =>
          domain?.indicators?.forEach((indicator) => {
            if (!this.$route.query.d) {
              if (indicator.title.trim() === this.subDomain.trim()) {
                indicator.values.forEach((item) => {
                  if (allowedIndicators.includes(item.chartType)) {
                    this.indicators.push(item);
                  }
                });
              }
            } else {
              indicator.values.forEach((item) => {
                if (allowedIndicators.includes(item.chartType)) {
                  this.indicators.push(item);
                }
              });
            }
          })
        );

        // grouping indicators by sub
        if (this.$route.query.d) {
          const groupedIndicators = {};
          this.indicators.forEach((indicator) => {
            const sub = indicator.sub || "Others";
            if (!groupedIndicators[sub]) {
              groupedIndicators[sub] = [];
            }
            groupedIndicators[sub].push(indicator);
          });
          this.indicators = Object.keys(groupedIndicators).map((sub) => ({
            sub,
            indicators: groupedIndicators[sub],
          }));
          // remove any element whose title = sub in indicators
          this.indicators = this.indicators.map((indicator) => ({
            ...indicator,
            indicators: indicator.indicators.filter(
              (item) => item.title !== "sub"
            ),
          }));
        }
        // remove any element whose title = sub in indicators
        this.indicators = this.indicators.filter(
          (indicator) => indicator.title !== "sub"
        );
      } catch (error) {
        console.error("Error fetching structured data:", error);
      }
    },
    async getUser() {
      let entity = "Financial Institution";
      if (this.entityChosen == "financial-institution") {
        entity = "Financial Institution";
      }
      if (this.entityChosen == "fund-manager") {
        entity = "Fund Manager";
      }
      if (this.entityChosen == "start-up") {
        entity = "Start-up";
      }
      if (this.entityChosen == "sme") {
        entity = "SME";
      }
      if (this.entityChosen == "corporate") {
        entity = "Corporate";
      }
      this.entityCode = entity;
    },
  },
  async mounted() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await apiService.fetch2xUserSurvey("2x", this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id).then(async (response) => {
      this.certificationSurveyData = await response.data.data;
    });
    await apiService
    .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
    .then(async (response) => {
      this.status2xCertification = response?.data?.data?.status;
    });
    if(!this.certificationSurveyData) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification",
      });
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData?.forEach((data) => {
        if (data.surveyId == "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    await this.fetchCertificationData();
    if (this.$route.query.fromVerifier) {
      await this.getVerifiedStatus();
    }
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    let query = this.$route.query?.q;
    if (query == null) {
      this.$router.push(
        "/2X-Ecosystem/2X-Certification/full-assessment-results"
      );
    }
    if (query.charAt(0) === "?") {
      query = query.substring(1);
    }
    this.subDomain = query;
    let newBreadcrumbs = [
      {
        name: "2X Certification",
        url: "/2X-Ecosystem/2X-Certification",
      },
      {
        name: "2X Detailed Assessment Results",
        url: "/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results",
      },
    ];
    this.previousPage = "/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results";

    if (this.$route.path === "/2x-global/admin/full-assessment/sub-dimension") {
      newBreadcrumbs = [
        { name: "Dashboard", url: "/2x-global/2X-Certification" },
        {
          name: "2X Global Verification",
          url: `/2x-global/admin/assessment?teamId=${this.$route.query.teamId}`
        },
        {
          name: "2X Detailed Assessment Results",
          url: `/2x-global/admin/full-assessment?teamId=${this.$route.query.teamId}`,
        },
        {
          name: `Sub Dimension ${this.subDomain.trim()}`,
          url: `/2x-global/admin/full-assessment/sub-dimension?${new URLSearchParams(this.$route.query).toString()}`,
        }
      ];
      this.previousPage = `/2x-global/admin/full-assessment?teamId=${this.$route.query.teamId}`;
    }

    if (this.$route.path === "/admin/full-assessment/sub-dimension") {
      newBreadcrumbs = [
        { name: "Dashboard", url: "/admin" },
        {
          name: "2X Verification",
          url: `/admin/assessment?teamId=${this.$route.query.teamId}`,
        },
        {
          name: "2X Detailed Assessment Results",
          url: `/admin/full-assessment?teamId=${this.$route.query.teamId}`,
        },
        {
          name: `Sub Dimension ${this.subDomain.trim()}`,
          url: `/admin/full-assessment/sub-dimension?${new URLSearchParams(this.$route.query).toString()}`,
        }
      ];
      this.previousPage = `/admin/full-assessment?teamId=${this.$route.query.teamId}`;
    }

    this.$store.commit("setNewBreadcrumbs", newBreadcrumbs);
    await this.getUser();
    await this.getStructuredData();
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.border {
  border-radius: 16px;
  border: 1px solid #e1e3e9;
}

.static {
  background-color: #f4f1ed;
  font-family: Work Sans !important;

  .sub-dimension-page-container {
    .inner-dimension-container {
      .dimesnion-heading {
        font-family: Arial;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .main-dimension {
        input {
          width: 616px;
          height: 48px;
          border-radius: 8px;
          gap: 8px;
          border: 1px solid #e1e3e9;
        }

        input::placeholder {
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .content-section {
        .action-plan-box {
          border-radius: 16px;
          border: 1px solid #e1e3e9;
          background: #19486a;
          color: #fff;

          .progress-percent {
          }

          .action-plan-button {
            width: 200px;
            height: 40px;
            padding: 8px 16px 8px 16px;
            border-radius: 8px;
            gap: 8px;
            background: gray;
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
          }
        }

        .overview-section {
          border-radius: 16px;
          gap: 32px;
          border: 1px solid #e1e3e9;
          height: fit-content;

          .ellipses-section {
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .read-more-button {
            font-family: Arial;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #395aff;
            margin-top: 2px;
          }
        }

        .data-charts-section {
          border-radius: 16px;
          border: 1px solid #e1e3e9;

          .dimension-ques {
            font-family: Arial;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }

          .dimension-output {
            .text-output {
              font-family: Arial;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;

              ul {
                list-style: disc;
              }

              img {
                width: 20px;
                height: 20px;
              }
            }

            .square-legend {
              width: 16px;
              height: 16px;
              background: #43467c;
              border-radius: 2px;
            }
          }
        }
      }
    }

    .text-black-90 {
      color: #21191d;
    }
  }

  .bottom-button-section {
    height: 120px;
    padding: 36px 80px 36px 80px;
    border-top: 1px solid #e1e3e9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636e801a;

    button {
      gap: 20px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      border: 1px solid #19486a;
      color: #19486a;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
</style>
