<template>
  <div class="mb-[420px]">
    <div class="mt-6 mb-4">
      <h2 class="font-['Arial'] text-xl font-bold leading-7 text-[#2C2E35]">
        Insert your portfolio/fund name
      </h2>
    </div>
    <div>
      <input
        placeholder="Type a name"
        v-model="portfolioName"
        @input="emitValue"
        class="mt-2 w-[616px] h-12 p-3 rounded-md border border-[#E1E3E9] placeholder:font-['Arial'] placeholder:text-base placeholder:text-[#9EA4BA]"
      />
    </div>
    <!-- Deals -->
    <div class="mt-8 bg-[#F8F9FC] p-4 rounded-md overflow-y-auto">
      <span class="font-['Arial'] text-xl font-bold leading-7 text-[#2C2E35]">
        Want to add a deal instead?
      </span>
      <div
        v-for="(deal, index) in deals"
        :key="index"
        class="flex items-center gap-2 mt-4"
      >
        <input
          v-model="deals[index].name"
          @input="handleDealInput(index)"
          type="text"
          placeholder="Deal name"
          class="w-[616px] h-12 p-3 rounded-md border border-[#E1E3E9] placeholder:font-['Arial'] placeholder:text-base placeholder:text-[#9EA4BA]"
        />
        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md font-semibold"
          @click="removeDeal(index)"
        >
          Remove
        </button>
      </div>
      <div class="flex items-center gap-2 mt-4">
        <input
          v-model="dealName"
          type="text"
          placeholder="Deal name"
          class="w-[616px] h-12 p-3 rounded-md border border-[#E1E3E9] placeholder:font-['Arial'] placeholder:text-base placeholder:text-[#9EA4BA]"
        />
        <button
          class="bg-[#191B45] text-white px-8 py-2 rounded-md font-semibold"
          :disabled="!dealName"
          :class="{ 'opacity-50': !dealName }"
          @click="addDeal"
        >
          Add
        </button>
      </div>
      <div class="mt-8 flex justify-end">
        <button
          class="bg-[#191B45] text-white px-8 py-2 rounded-md font-semibold"
          :class="{ 'opacity-50': deals.length === 0 }"
          :disabled="deals.length === 0"
          @click="completeSetup"
        >
          Finish adding deals
        </button>
      </div>
    </div>
    <span v-if="error" class="text-sm text-[#9EA4BA]">
      An error occurred while adding the deals. Please try again.
    </span>
  </div>
</template>

<script>
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";

export default {
  name: "Step1",
  props: ["fieldValue"],
  data() {
    return {
      portfolioName: "",
      dealName: "",
      deals: [],
      error: false,
    };
  },
  methods: {
    emitValue() {
      this.$emit("input-typed", {
        isStepActive:
          this.portfolioName || this.deals.length > 0 ? true : false,
        portfolioName: this.portfolioName,
        deals: this.deals,
      });
    },
    addDeal() {
      if (this.dealName) {
        this.deals.push({ name: this.dealName });
        this.dealName = "";
        this.emitValue();
      }
    },
    removeDeal(index) {
      this.deals.splice(index, 1);
      this.emitValue();
    },
    handleDealInput(index) {
      if (!this.deals[index].name) {
        this.removeDeal(index);
      } else {
        this.emitValue();
      }
    },
    async completeSetup() {
      try {
        const response = await axios.post(
          `${env.apiUrl}/imm-preferences/deals`,
          {
            deals: this.deals,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        if (response.status === 200) {
          this.$router.push("/imm/dashboard");
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      }
    },
  },
  mounted() {
    if (this.fieldValue) {
      this.portfolioName = this.fieldValue.portfolioName || "";
      this.deals = this.fieldValue.deals || [];
      this.emitValue();
    }
  },
};
</script>
