export const modules = [
  {
    name: "Enterprise Intake Information",
    module: "1",
    surveyName: "roi-one",
    var: "certifiedThatAllResponsesTrueAndAccurate",
  },
  {
    name: "Financials",
    module: "2",
    surveyName: "roi-two",
    var: "modTwoComplete",
  },

  {
    name: "Employment and Leadership",
    module: "3",
    surveyName: "roi-three",
    var: "modThreeComplete",
  },
  {
    name: "Diversity in Recruitment",
    module: "4",
    surveyName: "roi-four",
    var: "modFourComplete",
  },

  {
    name: "Performance, Evaluation, and Progression",
    module: "5",
    surveyName: "roi-five",
    var: "modFiveComplete",
  },
  {
    name: "Professional Development, Networking, and Mentoring",
    module: "6",
    surveyName: "roi-six",
    var: "modSixComplete",
  },

  {
    name: "Gender Lens in Policies and Strategies",
    module: "7",
    surveyName: "roi-seven",
    var: "modSevenComplete",
  },
  {
    name: "Women Workforce Support Policies",
    module: "8",
    surveyName: "roi-eight",
    var: "modEightComplete",
  },
  {
    name: "Survivor-Centered Prevention and Response to GBVH",
    module: "9",
    surveyName: "roi-nine",
    var: "modNineComplete",
  },
  {
    name: "Inclusive Health Insurance Policies",
    module: "10",
    surveyName: "roi-ten",
    var: "modTenComplete",
  },
  {
    name: "Fair Treatment of Part-time or Temporary Workers",
    module: "11",
    surveyName: "roi-eleven",
    var: "modElevenComplete",
  },

  {
    name: "Gender-Responsive Procurement and Gender-disaggregated Data in Supply Chains",
    module: "12",
    surveyName: "roi-twelve",
    var: "modTwelveComplete",
  },
  {
    name: "Gendered Approach to Value Chains and Gender-Disaggregated Value Chain Data",
    module: "13",
    surveyName: "roi-thirteen",
    var: "modThirteenComplete",
  },

  {
    name: "Gender Equality Beyond the Work Place",
    module: "14",
    surveyName: "roi-fourteen",
    var: "modFourteenComplete",
  },
  {
    name: "Climate Risks",
    module: "15",
    surveyName: "roi-fifteen",
    var: "modFifteenComplete",
  },
  {
    name: "Community Engagement through CSR",
    module: "16",
    surveyName: "roi-sixteen",
    var: "modSixteenComplete",
  },
  {
    name: "Financial Products, Services, and Processes",
    module: "17",
    surveyName: "roi-seventeen",
    var: "modSeventeenComplete",
  },
];
