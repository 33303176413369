<script>
import EquiloButton from "@/components/basics/EquiloButton.vue";
import moment from "moment";

export default {
  name: "AddCommentForm",
  components: { EquiloButton },
  props: {
    initialComments: {
      type: Array,
    }
  },
  emits: ["save-comment", "cancel-comment"],
  data() {
    return {
      comments: [],
      newComment: "",
      addNewComment: false,
    };
  },
  methods: {
    parseToday() {
      return moment().format('DD.MM.YYYY');
    },
    parseDateString(dateString){
      if (!dateString) return "";
      return moment(dateString).format('DD.MM.YYYY');
    }
  },
  mounted() {
    this.comments = this.initialComments;
    this.addNewComment = this.initialComments.length === 0;
  }
};
</script>

<template>
  <div class="flex flex-col justify-between mb-4 h-fit">
    <h2 class="text-2xl font-semibold mt-4 mb-8">Comment</h2>
    <!-- List of previous comments -->
    <div class="flex flex-col gap-4">
      <div v-for="comment in comments" :key="comment._id">
        <div class="flex justify-end">
          <span>{{ parseDateString(comment.createdAt) }}</span>
        </div>
        <div class="[ w-full ][ border border-gray-300 rounded-lg ][ p-4 ]">
          <span class="text-tiny text-wrap">{{ comment.comment }}</span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div v-if="addNewComment">
        <div class="flex justify-end">
          <span>{{ parseToday() }}</span>
        </div>
        <textarea
          type="text"
          class="w-full p-4 border border-gray-300 rounded-lg"
          placeholder="Text placeholder"
          row="4"
          v-model="newComment"
        />
      </div>
      <div v-else>
        <button
          type="button"
          class="[ h-[28px] w-auto ][ py-1 ][ text-sm text-[#2177C1] font-bold ][ flex items-center gap-2 ]"
          @click="addNewComment = true"
        >
          Add new comment +
        </button>
      </div>
    </div>
    <div class="flex items-center gap-5 mt-8">
      <div class="add-comment-form__action-buttons">
        <EquiloButton
          :bgColor="'transparent'"
          text="Cancel"
          @on-click="$emit('cancel-comment')"
        />
      </div>
      <div class="add-comment-form__action-buttons">
        <EquiloButton
          text="Save"
          :disabled="!newComment"
          @on-click="$emit('save-comment', newComment)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.add-comment-form__action-buttons {
  width: 268px;

  button {
    width: 100%;
  }
}
</style>
