<template>
  <div class="bg-gray-100 rounded-xl overflow-hidden p-4">
    <div class="card-header">
      <div class="title-section">
        <h3>{{ title }}</h3>
        <span class="date">{{ date }}</span>
      </div>
      <div v-if="showTrend" class="trend">
        <span class="trend-value">{{ trendValue }}% increase</span>
      </div>
    </div>

    <div class="card-content">
      <!--       <component :is="cardContent" v-bind="contentProps" /> -->
      <!--    <RoiContent
        v-if="type === 'roi'"
        :score="score"
        :sub-score="subScore"
        :percentage="percentage"
      /> -->

      <CriteriaContent :metrics="metrics" />
    </div>

    <div class="card-footer">
      <button class="api-btn">
        <i class="api-icon" />
        API Integration
      </button>
      <button class="methodology-btn">
        <i class="methodology-icon" />
        Methodology
      </button>
      <button>View portfolio results</button>
    </div>
  </div>
</template>

<script>
import CriteriaContent from "./CriteriaContent.vue";
//import RoiContent from "./RoiContent.vue";

export default {
  name: "MetricCard",
  components: {
    CriteriaContent,
    // RoiContent,
  },
  props: {
    title: String,
    date: String,
    type: {
      type: String,
      required: true,
      validator: (value) =>
        ["criteria", "roi", "risk", "climate", "financial", "impact"].includes(
          value
        ),
    },
    metrics: Object,
    score: Number,
    subScore: Number,
    percentage: Number,
    breakdown: Object,
    impactRating: Number,
    emissions: Number,
    financials: Object,
    sdgs: Object,
    showTrend: Boolean,
    trendValue: Number,
  },
  computed: {
    cardContent() {
      const contentMap = {
        criteria: "CriteriaContent",
        roi: "RoiContent",
        risk: "RiskContent",
        climate: "ClimateContent",
        financial: "FinancialContent",
        impact: "ImpactContent",
      };
      return contentMap[this.type];
    },
    contentProps() {
      // pass only relevant props based on card type
      const commonProps = {
        date: this.date,
        showTrend: this.showTrend,
        trendValue: this.trendValue,
      };

      const typeProps = {
        criteria: { metrics: this.metrics },
        roi: {
          score: this.score,
          subScore: this.subScore,
          percentage: this.percentage,
        },
        risk: {
          percentage: this.percentage,
          breakdown: this.breakdown,
        },
        climate: {
          impactRating: this.impactRating,
          emissions: this.emissions,
        },
        financial: { financials: this.financials },
        impact: { sdgs: this.sdgs },
      };

      return { ...commonProps, ...typeProps[this.type] };
    },
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat().format(num);
    },
  },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.title-section h3 {
  margin: 0;
  font-size: 18px;
}

.date {
  color: #718096;
  font-size: 14px;
}

.card-footer {
  margin-top: 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.card-footer button {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
}

.view-results-btn {
  margin-left: auto;
  background: #4299e1;
  color: white;
  border: none;
}
</style>
