<template>
  <div class="fixed z-50 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity cursor-pointer">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style="max-width: 624px"
      >
        <div class="Modal_Readmorestyle p-6">
          <div class="ModalInnerStyle">
            <div class="heading-section flex justify-between items-center">
              <h1
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
              Reject application
              </h1>
            </div>
            <div class="textarea-section mt-7">
             <div class="text-lg mb-2">Reason of rejection</div>
              <input type="text" v-model="reasonsText" placeholder="Type something..." />
            </div>
            <!-- <div class="textarea-section mt-7">
             <div class="text-lg mb-2">Actions to take</div>
              <select v-model="actionsValue" id="actions">
                <option value="" selected disabled hidden>Select action</option>
                <option value="Documents Upload">Documents Upload</option>
                <option value="Change Documents">Change Documents</option>
              </select>
            </div> -->
            <div class="mt-7 flex justify-between gap-4">
              <button
                class="cancel-button flex justify-center"
                @click="closeModal()"
              >
                Cancel
              </button>
              <button class="save-button flex justify-center" :class="{ 'pointer-events-none' : !reasonsText }" @click="rejectApplication()">Reject</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiService from '@/helpers/apiServices.js';
export default {
  name: "RejectApplicationModal",
  data() {
    return {
      reasonsText: "",
      actionsValue: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    rejectApplication() {
      try {
        const body = {
          reason: this.reasonsText,
          action: this.actionsValue
        }
        const updateTwoxStatus = this.$route.path.includes("2x-global")
      ? apiService.updateGlobalStatus
      : apiService.updateAssessStatus;
        updateTwoxStatus(this.$route.query.teamId, 'Rejected', body)
          .then(() => {
            this.closeModal();
            this.$swal.fire({
              icon: "success",
              text: "Rejected successfully!",
              showConfirmButton: true,
            }).then(() => {
                // window.location.reload();
                this.$emit("show-feedback-modal");
            });
            this.closeModal();
          }).catch(() => {
            this.closeModal();
            this.$swal.fire({
              icon: "error",
              text: "Somwething went wrong!",
              showConfirmButton: true,
            });
            this.closeModal();
          });
      } catch (e) {
        this.closeModal();
        this.$swal.fire({
          icon: "error",
          text: "Somwething went wrong!",
          showConfirmButton: true,
        });
        this.closeModal();
        throw new Error(e);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.Modal_Readmorestyle {
  position: relative;
  display: flex;
  .ModalInnerStyle {
    background: #fff;
    .heading-section {
      gap: 28px;
    }

    h1 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
      color: #19486a;
    }

    p {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
    }
    .textarea-section {
      width: 552px;
      gap: 12px;
      input {
        margin: 0;
        min-width: 100%;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 16px;
      }
      input::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }
      select {
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
      }
    }
    .cancel-button {
      width: 280px;
      height: 40px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      border: 1px solid #19486a;
      color: #19486a;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
    .save-button {
      width: 280px;
      height: 40px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      gap: 8px;
      background: #19486a;
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
</style>
