<!-- Modal.vue -->
<template>
  <div class="fixed inset-0 z-[100] overflow-y-auto">
    <!-- Backdrop -->
    <div
      class="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
      @click="closeModal"
    ></div>

    <!-- Modal Container -->
    <div class="flex min-h-full items-center justify-center p-4">
      <div
        class="relative w-full max-w-md transform overflow-hidden rounded-xl bg-white shadow-2xl transition-all"
      >
        <!-- Close Button -->
        <button
          @click="closeModal"
          class="absolute right-4 top-4 text-gray-400 hover:text-gray-500 focus:outline-none"
        >
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <!-- Content -->
        <div class="p-6">
          <div
            class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-4"
          >
            <!-- Success Icon -->
            <svg
              class="h-6 w-6 text-green-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>

          <div class="text-center">
            <h3 class="text-lg font-semibold text-gray-900 mb-2">
              Successfully Saved!
            </h3>
            <p class="text-sm text-gray-600 mb-4">
              You successfully saved your work! However, you must click
              "complete" in order to unlock the next survey modules. You can
              still edit it later. Ready to complete the rest of the modules?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaveProgressModal",
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
