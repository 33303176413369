<template>
  <div class="p-4 bg-gray-100 rounded-xl overflow-hidden w-1/2 mt-5">
    <div class="assessment-header">
      <span>2X Assessment</span>
      <div class="filters">
        <button
          :class="['filter-btn', { active: activeFilter === 'top' }]"
          @click="activeFilter = 'top'"
        >
          Top 3
        </button>
        <button
          :class="['filter-btn', { active: activeFilter === 'bottom' }]"
          @click="activeFilter = 'bottom'"
        >
          Bottom 3
        </button>
        <DropdownSelect
          :value="selectedYear"
          @input="$emit('update:selectedYear', $event)"
          :options="yearOptions"
        />
      </div>
    </div>

    <div class="progress-bars">
      <div
        v-for="(company, index) in companies"
        :key="index"
        class="progress-item"
      >
        <span class="company-name">{{ company.name }}</span>
        <div class="progress-bar-wrapper">
          <div class="progress-bar" :style="{ width: `${company.score}%` }" />
        </div>
        <span class="score">{{ company.score }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSelect from "./DropdownSelect.vue";

export default {
  name: "AssessmentProgress",
  components: {
    DropdownSelect,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
    selectedYear: {
      type: String,
      default: "2023",
    },
  },
  data() {
    return {
      activeFilter: "top",
      yearOptions: [
        { label: "2023", value: "2023" },
        { label: "2022", value: "2022" },
        { label: "2021", value: "2021" },
      ],
    };
  },
};
</script>

<style scoped>
.assessment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.filters {
  display: flex;
  gap: 12px;
  align-items: center;
}

.filter-btn {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  background: white;
}

.filter-btn.active {
  background: #4299e1;
  color: white;
}

.progress-bars {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.progress-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.company-name {
  width: 120px;
}

.progress-bar-wrapper {
  flex: 1;
  background: #f7fafc;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #4299e1;
  transition: width 0.3s ease;
}

.score {
  width: 60px;
  text-align: right;
}
</style>
