<template>
    <div class="relative overflow-x-auto document-upload-table-section" v-if="filelist.length" v-cloak>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-gray-700 bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-base">
                <tr class="table-heading-row">
                    <th scope="col" class="px-6 py-4 w-3/5">Document</th>
                    <th scope="col" class="px-6 py-4 w-1/5">Date uploaded</th>
                    <th scope="col" class="px-6 py-4 w-1/5">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr class="table-content-row dark:bg-gray-800 text-base" v-for="(file, i) in filelist" :key="i">
                    <td scope="row" class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-3/5" style="
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-decoration-color: #2c2e35;
                  ">
                        {{ file.name }}
                    </td>
                    <td class="font-medium text-gray-900 whitespace-nowrap dark:text-white w-1/5">
                        {{ moment(file?.createdAt).format("DD MMMM, YYYY") }}
                    </td>
                    <td class="w-1/5">
                        <div class="flex justify-around" v-if="file.status == 'In Review'">
                            <button @click="isDocumentModal = true, selectedDocument = file"
                                class="red-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
                                        fill="#ff3a21" />
                                </svg>
                                Reject
                            </button>
                            <button @click="updateDocumentStatus(file)"
                                class="green-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="icon/check">
                                        <path id="Vector"
                                            d="M9.76691 15.6786L6.21092 12.258L5 13.4146L9.76691 18L20 8.1566L18.7976 7L9.76691 15.6786Z"
                                            fill="#3F7E44" />
                                    </g>
                                </svg>

                                Approve
                            </button>
                        </div>
                        <div v-else>
                            <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold w-fit m-auto"
                                v-if="file?.status == 'Rejected'" style="border-radius: 20px; background-color: #FF3A21; width: fit-content;">
                                <img src="@/assets/images/2x_certify/verification-close.svg" />
                                <span class="ml-2">Rejected</span>
                            </div>
                            <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold w-fit m-auto"
                                v-if="file?.status == 'Validated' || file?.status == 'Accepted'" style="border-radius: 20px; background-color: #395AFF; width: fit-content;">
                                <img src="@/assets/images/2x_certify/check.svg" />
                                <span class="ml-2">Verified</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- Reject application modal -->
        <DocumentRejectModal v-if="isDocumentModal" @close="isDocumentModal = false, selectedDocument = null" :selectedDocument="selectedDocument" />
        <!-- Reject application modal -->
    </div>
</template>
<script>
import apiService from '@/helpers/apiServices.js';
import DocumentRejectModal from "@/components/2x-certification/documentRejectionModal.vue";

export default {
    name: "UploadDocumentsTable",
    data() {
        return {
            documentComment: "",
            isDocumentModal: false,
            selectedDocument: null,
        }
    },
    components: {
        DocumentRejectModal
    },
    props: ["filelist"],
    methods: {
        updateDocumentStatus(file) {
            this.selectedDocument= file;
            apiService.putDocumentStatusByVerifier(this.documentComment, this.selectedDocument?._id, 'Accepted')
                .then(() => {
                    this.$swal.fire({
                        icon: "success",
                        text: "Approved successfully!",
                        showConfirmButton: true,
                    });
                    this.documentComment = "";
                    this.selectedDocument = null;
                })
                .catch(() => {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong!",
                        showConfirmButton: true,
                    });
                    this.selectedDocument = null;
                });
        },
    }
}
</script>
<style lang="scss" scoped>
.document-upload-table-section {
    border-radius: 16px;
    border: 1px solid #e1e3e9;

    table {

        th,
        td {
            border: 1px solid #e1e3e9;
        }

        .table-heading-row {
            th {
                gap: 32px;
                border-top: none;
                background: linear-gradient(0deg, #f4f6f9, #f4f6f9),
                    linear-gradient(0deg, #e1e3e9, #e1e3e9);
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #2c2e35;
            }

            th:first-child {
                border-top-left-radius: 16px;
                border-left: none;
            }

            th:last-child {
                border-top-right-radius: 16px;
                border-right: none;
            }
        }

        .table-content-row {
            img {
                width: 24px;
                height: 24px;
            }

            td {
                border-bottom: none;
                height: 85px;
                padding: 22px 24px;
                gap: 1px;
                font-family: Arial;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #2c2e35;

                .red-button {
                    font-weight: bold;
                    font-size: 14px;
                    color: #ff3a21;
                    border-style: solid;
                    border-color: #ff3a21;
                    border-width: 1px;
                }

                .green-button {
                    font-weight: bold;
                    font-size: 14px;
                    color: #3f7e44;
                    border-style: solid;
                    border-color: #3f7e44;
                    border-width: 1px;
                }
            }

            td:first-child {
                border-left: none;
            }

            td:last-child {
                border-right: none;
            }
        }
    }
}
</style>