<template>
  <div
    class="w-[98%] px-6 h-[calc(100vh-13rem)] flex flex-col justify-end overflow-auto"
  >
    <div>
      <div class="b pr-16 grid grid-cols-2 gap-4">
        <div
          class="py-4 border-2 px-4 rounded-large border-gray-300 cursor-pointer"
          v-for="(suggestion, index) in suggestionList"
          :key="index"
          @click="selectSuggestion(suggestion)"
        >
          <div class="font-semibold text-black">{{ suggestion.title }}</div>
          <div class="text-gray-400">{{ suggestion.subTitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "chatSuggestion",
  emits: ["selected-chat-suggestion"],
  data() {
    return {
      suggestionList: [
        {
          id: 1,
          title: "Explain me what gender analysis is",
          subTitle: "like I am five years old",
        },
        {
          id: 2,
          title: "I want to learn about gender lens investing and GBV risk",
          subTitle: "Gender Base Violence risk",
        },
        {
          id: 3,
          title:
            "Could you give me a detailed gender equality assessment for investors?",
          subTitle: "Gender equality assessment",
        },
        {
          id: 4,
          title: "Tell me about technology for gender analysis",
          subTitle: "Technologies for gender analysis",
        },
      ],
    };
  },
  methods: {
    selectSuggestion(suggestion) {
      this.$emit("selected-chat-suggestion", suggestion);
    },
  },
};
</script>
