import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";

const newMessage = async (userMessage, chatId) => {
  return await axios.post(
    `${env.apiUrl}/chat/newmessage`,
    { message: userMessage, chatId: chatId },
    {
      headers: {
        Authorization: "Bearer " + Cookies.get("session"),
        "Content-Type": "application/json",
      },
    }
  );
};

const getChatHistory = async () => {
  return await axios.get(`${env.apiUrl}/chat/history`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("session"),
      "Content-Type": "application/json",
    },
  });
};

export default {
  newMessage,
  getChatHistory,
};
