import BaseAPI from "@/api/index";

class ImmAPI {
  resourceBasePath = "/imm";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  /**
   *
   * @param year<number>
   * @param month<number>
   * @param teamId<string>
   * @param currency<string>
   */
  getImmFinanceTableData(year, month, teamId, currency) {
    return this.axiosInstance.get(`${this.resourceBasePath}/layout-data?month=${month}&year=${year}&teamId=${teamId}&currency=${currency}`);
  }

  /**
   *
   * @param year<number>
   * @param month<number>
   * @param currency<string>
   * @param portfolioName
   */
  getImmAggregatedFinanceTableData(year, month, currency, portfolioName) {
    return this.axiosInstance.get(`${this.resourceBasePath}/layout-data/aggregated?year=${year}&month=${month}&currency=${currency}&portfolioName=${portfolioName}`);
  }

  /**
   *
   * @param year<number>
   * @param groupBy<'Month' | 'Quarter' | 'Year' | 'All Time'>
   * @param teamId<string>
   * @param currency<string>
   */
  getActualData(year, groupBy, teamId, currency) {
    return this.axiosInstance.get(`${this.resourceBasePath}/actual-data?year=${year}&groupBy=${groupBy}&teamId=${teamId}&currency=${currency}`);
  }

  /**
   *
   * @param year<number>
   * @param groupBy<'Month' | 'Quarter' | 'Year' | 'All Time'>
   * @param currency<string>
   * @param portfolioName
   */
  getAggregatedActualData(year, groupBy, currency, portfolioName) {
    return this.axiosInstance.get(`${this.resourceBasePath}/actual-data/aggregated?year=${year}&groupBy=${groupBy}&currency=${currency}&portfolioName=${portfolioName}`);
  }

  getIMMFinancePeriods(teamId) {
    return this.axiosInstance.get(`${this.resourceBasePath}/imm-finance-periods?teamId=${teamId}`);
  }

  getIMMAggregatedFinancePeriods(portfolioName) {
    return this.axiosInstance.get(`${this.resourceBasePath}/imm-finance-periods/aggregated?portfolioName=${portfolioName}`);
  }

  getInvestorComments(
    portfolioCompanyId,
    module,
    table,
    period
  ) {
    return this.axiosInstance.get(`${this.resourceBasePath}/investor-comments?portfolioCompanyId=${portfolioCompanyId}&module=${module}&table=${table}&period=${period}`);
  }

  postInvestorComment(
    portfolioCompanyId,
    module,
    table,
    period,
    rowHeading,
    comment
  ) {
    const payload = {
      portfolioCompanyId,
      module,
      table,
      period,
      rowHeading,
      comment
    }
    return this.axiosInstance.post(`${this.resourceBasePath}/investor-comment`, payload);
  }

  /**
   * If the portfolio name is not provided, it will return all portfolio companies with their portfolio companies
   * @param portfolioName<string> Portfolio name to fetch companies for
   */
  getPortfolioCompanies(portfolioName=''){
    return this.axiosInstance.get(`${this.resourceBasePath}/portfolio-companies?portfolioName=${portfolioName}`);
  }
}

export default ImmAPI;
