<template>
  <div class="static assementPageStyle">
    <twoxAssessPrivacyModal
      v-if="user.twoXPrivacyPolicy == false"
      @pageReload="pageReload"
    ></twoxAssessPrivacyModal>
    <div class="container" v-if="user.twoXPrivacyPolicy">
      <div class="PoweredbyTitle_logo">
        <div
          class="PoweredbyTitleWrepper"
          v-if="
            initialCompanyData &&
            initialCompanyData.assesments &&
            comapnyDetails &&
            comapnyDetails.length > 0
          "
        >
          <h1 class="text-6xl tracking-tighter font-medium print:text-7xl">
            {{ comapnyDetails[0].organizationName + "'s" }} 2X Assessment
          </h1>
        </div>
        <div
          class="org_profile_image"
          v-if="
            initialCompanyData &&
            initialCompanyData.assesments &&
            comapnyDetails[0] &&
            comapnyDetails[0].logo &&
            comapnyDetails[0].logo[0].url
          "
        >
          <img :src="comapnyDetails[0].logo[0].url" alt="org image" />
        </div>
        <div
          v-if="
            initialCompanyData &&
            initialCompanyData.assesments &&
            comapnyDetails &&
            comapnyDetails[0]
          "
        >
          <div class="Poweredby_logoImg">
            <img
              src="@/assets/images/2X.png"
              alt="background-image"
              style="width: 80px"
            />
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
          <div
            v-if="
              comapnyDetails[0] &&
              comapnyDetails[0].teamId &&
              user.organization._id == comapnyDetails[0].teamId[0] &&
              !sharedOrgRoute &&
              comapnyDetails &&
              comapnyDetails.length > 0 &&
              initialCompanyData &&
              initialCompanyData.assesments.length
            "
          >
            <div class="EmailInvitemember">
              <div class="EmailInput">
                <input
                  type="email"
                  placeholder="Enter Email Address"
                  v-model="invitedUserEmail"
                />
              </div>
              <div class="InviteBtn tooltip">
                <div @click="onInviteClick">
                  <button
                    class="text-white text-sm font-medium"
                    style="width: max-content"
                  >
                    Share
                  </button>
                </div>
                <span class="share-email-tooltip-text"
                  >When you invite someone to view your shared assessment
                  results, they will receive an email with a link. They will
                  need to already have, or create an account, at
                  <router-link to="/" style="color: #599bd8"
                    >app.equilo.io</router-link
                  >
                  to log in and view your shared assessment results.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- will use in 2.0 -->
      <div
        class="favorite-button-section"
        v-if="
          initialCompanyData &&
          initialCompanyData.assesments &&
          comapnyDetails &&
          comapnyDetails[0]
        "
      >
        <div
          class="country-flag-container flex"
          v-if="selectedCountryDetails && selectedCountryDetails.length > 1"
        >
          <div v-for="cont in selectedCountryDetails" :key="cont.countryName">
            <div
              class="flex mr-1"
              style=""
              :class="cont.selected ? 'selected-country' : 'unselected-country'"
              @click="showOtherCountryData(cont)"
            >
              <img :src="cont.flagUrl" width="60" />
              <span style="margin: auto">{{ cont.countryName }}</span>
            </div>
          </div>
        </div>
        <div class="favourite-tooltip">
          <img
            v-if="
              comapnyDetails &&
              comapnyDetails[0] &&
              comapnyDetails[0].isAssessmentSavedOrNot
            "
            src="../../assets/images/red-heart.png"
            class="fav-image"
            @click="savedFavorite"
          />
          <img
            v-else
            src="../../assets/images/white-heart.png"
            class="fav-image"
            @click="savedFavorite"
          />
          <span class="favourite-tooltip-text"> Add to favorites </span>
        </div>
        <div class="send-tooltip" v-if="this.$route.query.dealId">
          <button>
            <img
              src="@/assets/images/arrows/Arrow right teal.png"
              style="width: 32px; height: 32px"
              alt="arrow right teal"
              @click="clickedArrowButton()"
            />
          </button>
          <span class="send-tooltip-text">
            Alert a potential co-investor to this opportunity
          </span>
        </div>
      </div>
      <div
        :class="
          selectedCountryDetails && selectedCountryDetails.length > 1
            ? 'country-flag-margin-positive'
            : 'country-flag-margin-negative'
        "
        v-if="comapnyDetails && comapnyDetails[0]"
      >
        <div
          class="survey"
          v-if="
            user.organization._id == comapnyDetails[0].teamId[0] &&
            (comapnyDetails[0].modTwo == '' ||
              comapnyDetails[0].modTwo == false)
          "
        >
          <router-link
            :to="{
              path: checkOrganizationType,
              query: { recordId: comapnyDetails[0].recordId },
            }"
            class="text-white text-sm font-medium"
            style="width: max-content"
          >
            Complete the full assessment
          </router-link>
        </div>
      </div>
      <transition name="fadeIn" mode="out-in" v-if="user.twoXPrivacyPolicy">
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div
          id="loaded"
          v-if="
            endofcreated &&
            initialCompanyData &&
            initialCompanyData.assesments &&
            comapnyDetails &&
            comapnyDetails.length > 0
          "
          key="content"
        >
          <!-- Assement Page Wrepper -->

          <div class="AssementPageWrepper">
            <!-- Overview Block -->
            <div class="OverviewBlock">
              <div class="OverviewTitle">
                <h2>Overview</h2>
                <span></span>
              </div>
              <div class="spiderchartEligibleWrepper">
                <div class="Xeligibleconten">
                  <div
                    class="EligibleListWrepper"
                    :class="{
                      bigMargin:
                        comapnyDetails[0].type == 'funds' &&
                        comapnyDetails[0].myPortfolio !=
                          'I will directly input aggregated information now' &&
                        !comapnyDetails[0].isPortfolioAvailable,
                    }"
                  >
                    <div
                      class="EligibleListImg"
                      :class="{
                        bigImg:
                          comapnyDetails[0].type == 'funds' &&
                          comapnyDetails[0].myPortfolio !=
                            'I will directly input aggregated information now' &&
                          !comapnyDetails[0].isPortfolioAvailable,
                      }"
                    >
                      <div
                        v-if="
                          comapnyDetails[0].type == 'funds' &&
                          comapnyDetails[0].myPortfolio !=
                            'I will directly input aggregated information now' &&
                          !comapnyDetails[0].isPortfolioAvailable
                        "
                      >
                        <img
                          style="border-radius: 0"
                          src="@/assets/images/help_white.svg"
                          alt="background-image"
                        />
                      </div>
                      <div class="progress-wrap" v-else-if="twoxEligible != -1">
                        <progress-bar
                          :options="
                            comapnyDetails[0].eligible2X &&
                            (comapnyDetails[0].eligible2X === 'Yes' ||
                              comapnyDetails[0].eligible2X === 'true')
                              ? options1
                              : options2
                          "
                          :value="twoxEligible | mathOnlyRound"
                          style="width: 40px; height: 40px; line-height: 0"
                        />
                      </div>
                    </div>
                    <div
                      class="EligibleTitle tooltip"
                      :style="
                        comapnyDetails[0].type == 'funds' &&
                        comapnyDetails[0].myPortfolio !=
                          'I will directly input aggregated information now' &&
                        !comapnyDetails[0].isPortfolioAvailable
                          ? 'top: 0'
                          : ''
                      "
                    >
                      <h3
                        :class="{
                          'gray-color':
                            comapnyDetails[0].type == 'funds' &&
                            comapnyDetails[0].myPortfolio !=
                              'I will directly input aggregated information now' &&
                            !comapnyDetails[0].isPortfolioAvailable,
                        }"
                      >
                        2X Eligible
                      </h3>
                      <span
                        class="remaining_companies_tooltiptext"
                        v-if="
                          remainingNumberOfLinkedCompanies &&
                          remainingNumberOfLinkedCompanies != '0'
                        "
                      >
                        Once companies in your portfolio respond, this will be
                        assessed.
                      </span>
                      <span
                        class="twoXEligibleHoverText"
                        v-if="comapnyDetails[0].type == 'companies'"
                      >
                        2X eligibility requires any one of entrepreneurship,
                        leadership, consumption, or employment criteria to be
                        met.
                      </span>
                      <span
                        class="twoXEligibleHoverText"
                        v-if="
                          comapnyDetails[0].type == 'funds' &&
                          (comapnyDetails[0].myPortfolio ==
                            'I will directly input aggregated information now' ||
                            comapnyDetails[0].isPortfolioAvailable)
                        "
                      >
                        2X eligibility requires any one of your institution's
                        entrepreneurship, leadership, consumption, or employment
                        criteria to meet AND you must meet the 30% threshold for
                        investment through financial intermediaries (e.g. 30% of
                        your institution's loan proceeds or portfolio companies
                        must meet one of the 2X Criteria).
                      </span>
                      <span
                        class="twoXEligibleHoverText"
                        v-if="
                          comapnyDetails[0].type == 'funds' &&
                          comapnyDetails[0].myPortfolio !=
                            'I will directly input aggregated information now' &&
                          remainingNumberOfLinkedCompanies == '0' &&
                          !comapnyDetails[0].isPortfolioAvailable
                        "
                      >
                      Forgive the oversight! Unfortunately your assessment data is not
                        complete enough to provide the full assessment at this
                        time as we require additional information for the 2X
                        Challenge indirect investment criteria that assesses
                        your entire portfolio of companies. Please contact us at
                        <a
                          href="mailto:support@equilo.io"
                          style="color: #599bd8"
                          >support@equilo.io</a
                        >
                        so we can help you select the easiest option to complete
                        this critical part of the assessment with your
                        investee's data.
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper">
                    <div class="EligibleListImg">
                      <div
                        style="position: relative"
                        v-if="entrepreneurship != -1"
                      >
                        <div
                          style="position: relative; top: 6px"
                          v-if="entrepreneurship == 'NA'"
                          class="bigImg"
                        >
                          <img
                            src="@/assets/images/NA-SVG.svg"
                            alt="background-image"
                          />
                        </div>
                        <div class="progress-wrap" v-else>
                          <progress-bar
                            :options="
                              comapnyDetails[0].entrepreneurshipCriteriaMet &&
                              comapnyDetails[0].entrepreneurshipCriteriaMet ===
                                '1'
                                ? options1
                                : options2
                            "
                            :value="entrepreneurship | mathOnlyRound"
                            style="width: 40px; height: 40px; line-height: 0"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <img src="../../assets/images/EligibleIcon1.png" alt="" />
                      <p>Entrepreneurship</p>
                      <span class="entrepreneurshipHoverText"
                        >Entrepreneurship Benchmark Threshold: 51% women
                        ownership OR the business is founded by a woman
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper">
                    <div class="EligibleListImg">
                      <span v-if="leadership != -1">
                        <div v-if="leadership == 'NA'" class="bigImg">
                          <img
                            src="@/assets/images/NA-SVG.svg"
                            alt="background-image"
                          />
                        </div>
                        <div class="progress-wrap" v-else>
                          <progress-bar
                            :options="
                              comapnyDetails[0].leadershipCriteriaMet &&
                              comapnyDetails[0].leadershipCriteriaMet === '1'
                                ? options1
                                : options2
                            "
                            :value="leadership | mathOnlyRound"
                            style="width: 40px; height: 40px; line-height: 0"
                          />
                        </div>
                      </span>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <img src="../../assets/images/EligibleIcon2.png" alt="" />
                      <p>Leadership</p>
                      <span class="leadershipHoverText">
                        <span v-if="leadership == 'NA'"> Not Applicable</span>
                        <span v-else>
                          Leadership Benchmark Threshold: 30% women in senior
                          leadership OR 30% women on the Board or Investment
                          Committee
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="EligibleListWrepper">
                    <div class="EligibleListImg">
                      <div class="progress-wrap" v-if="employment != -1">
                        <progress-bar
                          :options="
                            comapnyDetails[0].employmentCriteriaMet &&
                            comapnyDetails[0].employmentCriteriaMet === '1'
                              ? options1
                              : options2
                          "
                          :value="employment | mathOnlyRound"
                          style="width: 40px; height: 40px; line-height: 0"
                        />
                      </div>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <img
                        src="../../assets/images/EligibleIcon.3.png"
                        alt=""
                      />
                      <p>Employment</p>
                      <span class="employmentHoverText"
                        >Employment Benchmark Threshold: 30-50% share of women
                        in the workforce (depending on sector) AND one "quality"
                        indicator beyond compliance</span
                      >
                    </div>
                  </div>
                  <div class="EligibleListWrepper">
                    <div
                      class="EligibleListImg"
                      :class="
                        comapnyDetails[0].type != 'funds' ? 'lastAffter' : ''
                      "
                    >
                      <div class="progress-wrap" v-if="consumption != -1">
                        <progress-bar
                          :options="
                            comapnyDetails[0].consumptionCriteriaMet &&
                            comapnyDetails[0].consumptionCriteriaMet === '1'
                              ? options1
                              : options2
                          "
                          :value="consumption | mathOnlyRound"
                          style="width: 40px; height: 40px; line-height: 0"
                        />
                      </div>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <img
                        src="../../assets/images/EligibleIcon.4.png"
                        alt=""
                      />
                      <p>Consumption</p>
                      <span class="consumptionHoverText"
                        >Consumption Benchmark Threshold: One practice in place
                        that result in products or services that specifically
                        benefit women</span
                      >
                    </div>
                  </div>
                  <div
                    class="EligibleListWrepper"
                    v-if="comapnyDetails[0].type == 'funds'"
                    :style="
                      comapnyDetails[0].type == 'funds' &&
                      comapnyDetails[0].myPortfolio !=
                        'I will directly input aggregated information now' &&
                      !comapnyDetails[0].isPortfolioAvailable
                        ? 'margin-top:10px;'
                        : ''
                    "
                  >
                    <div class="EligibleListImg lastAffter">
                      <div
                        style="position: relative"
                        v-if="
                          comapnyDetails[0].type == 'funds' &&
                          comapnyDetails[0].myPortfolio !=
                            'I will directly input aggregated information now' &&
                          !comapnyDetails[0].isPortfolioAvailable
                        "
                        class="bigImg"
                      >
                        <img
                          style="border-radius: 0"
                          src="@/assets/images/help_white.svg"
                          alt="background-image"
                        />
                      </div>
                      <div
                        class="progress-wrap"
                        v-else-if="indirectInvestment != -1"
                      >
                        <progress-bar
                          :options="
                            comapnyDetails[0].indirectInvestmentCriteriaMet &&
                            comapnyDetails[0].indirectInvestmentCriteriaMet ===
                              '1' &&
                            indirectInvestment != 0
                              ? options1
                              : options3
                          "
                          :value="indirectInvestment | mathOnlyRound"
                          style="width: 40px; height: 40px; line-height: 0"
                        />
                      </div>
                    </div>
                    <div class="EligibleTitle tooltip">
                      <img
                        src="../../assets/images/EligibleIcon.5.png"
                        alt=""
                      />
                      <p
                        :class="{
                          'gray-color':
                            comapnyDetails[0].type == 'funds' &&
                            comapnyDetails[0].myPortfolio !=
                              'I will directly input aggregated information now' &&
                            !comapnyDetails[0].isPortfolioAvailable,
                        }"
                      >
                        Indirect Investment
                      </p>
                      <span
                        class="indirectInvestmentHoverText"
                        v-if="
                          comapnyDetails[0].myPortfolio ==
                            'I will directly input aggregated information now' ||
                          comapnyDetails[0].isPortfolioAvailable
                        "
                        >Indirect Investment Benchmark Threshold: 30% of loan
                        proceeds or portfolio companies meet the 2X
                        Criteria</span
                      >
                      <span class="indirectInvestmentHoverText" v-else
                        >Forgive the oversight! Unfortunately your assessment data is not
                        complete enough to provide the full assessment at this
                        time as we require additional information for the 2X
                        Challenge indirect investment criteria that assesses
                        your entire portfolio of companies. Please contact us at
                        <a
                          href="mailto:support@equilo.io"
                          style="color: #599bd8"
                          >support@equilo.io</a
                        >
                        so we can help you select the easiest option to complete
                        this critical part of the assessment with your
                        investee's data.</span
                      >
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyVerifiedToolTip">
                    <div
                      class="PartyTooltipImg"
                      v-if="
                        (comapnyDetails[0].modTwo != '' ||
                          comapnyDetails[0].modTwo != false) &&
                        comapnyDetails[0].type == 'companies'
                      "
                    >
                      <img
                        src="@/assets/images/Rightcircle.svg"
                        alt="background-image"
                        v-if="
                          comapnyDetails[0].twoXIntentions === 'Yes' ||
                          comapnyDetails[0].twoXIntentions === 'true' ||
                          comapnyDetails[0].twoXIntentions == 'TRUE'
                        "
                      />

                      <img
                        style="border-radius: 0"
                        src="@/assets/images/3rdPartyVerified.svg"
                        alt="background-image"
                        v-else
                      />
                    </div>
                    <div
                      class="PartyTooltipImg"
                      v-else-if="
                        (comapnyDetails[0].modTwo != '' ||
                          comapnyDetails[0].modTwo != false) &&
                        comapnyDetails[0].type == 'funds'
                      "
                    >
                      <img
                        src="@/assets/images/Rightcircle.svg"
                        alt="background-image"
                        style="margin-left: -3px !important"
                        v-if="
                          comapnyDetails[0].twoXIntentions === 'Yes' ||
                          comapnyDetails[0].twoXIntentions === 'true' ||
                          comapnyDetails[0].twoXIntentions == 'TRUE'
                        "
                      />

                      <img
                        style="border-radius: 0"
                        src="@/assets/images/3rdPartyVerified.svg"
                        alt="background-image"
                        v-else
                      />
                    </div>
                    <div class="PartyTooltipImg" v-else>
                      <img
                        style="border-radius: 0; width: 20px"
                        src="@/assets/images/help_white.svg"
                        alt="background-image"
                      />
                    </div>

                    <div class="tooltip TooltipImgText">
                      <p>Plans to improve 2X Criteria</p>
                      <span
                        class="tooltiptext"
                        v-if="
                          (comapnyDetails[0].modTwo != '' ||
                            comapnyDetails[0].modTwo != false) &&
                          comapnyDetails[0].type == 'companies'
                        "
                      >
                        Regardless of the 2X eligibility criteria being met, an
                        organization is marked as having plans to improve 2X
                        Criteria if the organization either: <br />1) Does not
                        currently meet 2X Criteria but has robust plans and
                        targets to meet the criteria, or <br />2) Does currently
                        meet 2X Criteria and has robust plans and targets to
                        continue improving 2X performance.
                      </span>
                      <span
                        class="tooltiptext"
                        v-else-if="
                          (comapnyDetails[0].modTwo != '' ||
                            comapnyDetails[0].modTwo != false) &&
                          comapnyDetails[0].type == 'funds'
                        "
                      >
                        Regardless of the 2X eligibility criteria being met, an
                        organization is marked as having plans to improve 2X
                        Criteria if the organization either: <br />1) Does not
                        currently meet 2X Criteria but has robust plans and
                        targets to meet the criteria, or <br />2) Does currently
                        meet 2X Criteria and has robust plans and targets to
                        continue improving 2X performance.
                      </span>
                      <span
                        class="tooltiptext"
                        style="
                          max-width: 327px;
                          top: 25px;
                          left: 139px;
                          padding: 8px;
                        "
                        v-else-if="checkAssesmentIsCompleted"
                        >Unknown -
                        <router-link
                          :to="{
                            path: checkOrganizationType,
                            query: { recordId: comapnyDetails[0].recordId },
                          }"
                          class="text-white text-sm font-medium"
                          style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                          >Complete the full assessment</router-link
                        ></span
                      >
                      <span
                        class="tooltiptext"
                        style="
                          max-width: 133px;
                          top: 25px;
                          left: 139px;
                          padding: 8px;
                        "
                        v-else
                        >Unknown</span
                      >
                      <img
                        src="@/assets/images/help_white.svg"
                        alt="background-image"
                      />
                    </div>
                  </div>
                  <div class="BorderLine"></div>
                  <div class="PartyVerifiedToolTip">
                    <div class="PartyTooltipImg">
                      <img
                        style="margin-left: 5px !important"
                        src="@/assets/images/Rightcircle.svg"
                        alt="background-image"
                        v-if="
                          comapnyDetails[0].dFIOr3rdpartyvalidator == 'Yes' ||
                          comapnyDetails[0].dFIOr3rdpartyvalidator == 'true'
                        "
                      />
                      <img
                        style="border-radius: 0; margin-left: 5px !important"
                        src="@/assets/images/3rdPartyVerified.svg"
                        alt="background-image"
                        v-else
                      />
                    </div>
                    <div class="tooltip TooltipImgText">
                      <p>3rd Party Verified</p>
                      <span class="tooltiptext">
                        These 2X Assessment results are the product of an
                        unverified self-assessment. Although your organization
                        may qualify for 2X funding, eligibility must be
                        determined by a potential investor or a 3rd party.
                        Contact Equilo at
                        <span style="text-decoration: underline; color: #599bd8"
                          >2XCValidation@equilo.io</span
                        >
                        to inquire about 3rd party verification.
                      </span>
                      <img
                        src="@/assets/images/help_white.svg"
                        alt="background-image"
                      />
                    </div>
                  </div>
                  <div class="DownloadBtn">
                    <div class="tooltip">
                      <a @click="checkDownloadPDFPermission" target="_blank">
                        <img src="../../assets/images/DownloadBtn.png" alt="" />
                        <span>{{
                          downloadPDF ? "Downloading..." : "Download PDF"
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <br />
                  <!-- download csv btn -->
                  <!-- <div v-if="json_data && json_data.length > 0"> -->
                  <div class="DownloadBtn">
                    <div class="tooltip">
                      <a @click="checkDownloadCSVPermission" target="_blank">
                        <img src="../../assets/images/DownloadBtn.png" alt="" />
                        <span>{{
                          downloadCSV ? "Downloading..." : "Download CSV"
                        }}</span>
                      </a>
                      <download-csv
                        v-if="endofcreated && comapnyDetails"
                        :data="json_data"
                        :name="comapnyDetails[0].organizationName + '.csv'"
                        id="my2xcsv"
                        style="display: none"
                      >
                      </download-csv>
                      <span class="download-csv-tooltip" v-if="user.listed2x">
                        Download the raw data from this organization's 2X
                        Assessment survey.
                      </span>
                      <!-- <span class="download-csv-tooltip" v-if="!user.listed2x" v-html="getContentOfDownloadCSV()"></span> -->
                    </div>
                  </div>
                  <!-- </div> -->
                </div>

                <!-- spiderchart -->
                <div class="spiderchartContent" id="chart">
                  <div class="z-20 relative">
                    <repeatSpiderchart
                      v-if="comapnyDetails.length == 2"
                      :iso="comapnyDetails[0].countryHq.iso"
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :countData="countData"
                      :country="country"
                      :details="comapnyDetails[0]"
                      :detailsOld="comapnyDetails[1]"
                      class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"
                    ></repeatSpiderchart>
                    <spiderchart
                      v-else
                      :iso="comapnyDetails[0].countryHq.iso"
                      :paddingL="40"
                      :paddingR="40"
                      :paddingT="40"
                      :paddingB="40"
                      :details="comapnyDetails[0]"
                      :countData="countData"
                      :country="country"
                      class="z-10 overflow-visible"
                      style="position: relative; height: 100%; width: 100%"
                    ></spiderchart>
                    <template v-for="(color, taab) in taabs">
                      <!--Leadership-->
                      <div
                        v-if="color === '#2BD4C4'"
                        class="Ind_Inv_Icon"
                        :class="
                          comapnyDetails[0].type == 'companies'
                            ? 'leadership-taab-company'
                            : 'leadership-taab-fund'
                        "
                        id="2uur"
                        v-bind:key="taab.name"
                        style="color: #2bd4c4"
                        @click="openContextualAnalysis(taab)"
                      >
                        <img
                          src="../../assets/images/LeadershipIcon.svg"
                          alt="Indirect Investment"
                        />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Entrepreneurship-->
                      <div
                        v-if="color === '#0077C8'"
                        class="Ind_Inv_Icon"
                        id="8uur"
                        :class="
                          comapnyDetails[0].type == 'companies'
                            ? 'entrepreneurship-taab-company'
                            : 'entrepreneurship-taab-fund'
                        "
                        v-bind:key="taab.name"
                        style="color: #0077c8"
                        @click="openContextualAnalysis(taab)"
                      >
                        <img
                          src="../../assets/images/EntrepreneurshipIcon.svg"
                          alt="Indirect Investment"
                        />
                        <span> {{ taab }}</span>
                      </div>

                      <!--Consumption-->
                      <div
                        v-if="color === '#F5D10F'"
                        class="Ind_Inv_Icon"
                        id="10uur"
                        :class="
                          comapnyDetails[0].type == 'companies'
                            ? 'consumption-taab-company'
                            : 'consumption-taab-fund'
                        "
                        v-bind:key="taab.name"
                        style="color: #f5d10f"
                        @click="openContextualAnalysis(taab)"
                      >
                        <img
                          src="../../assets/images/ConsumptionIcon.svg"
                          alt="Indirect Investment"
                        />
                        <span> {{ taab }}</span>
                      </div>
                      <!--Employment-->
                      <div
                        v-if="color === '#FE5000'"
                        class="Ind_Inv_Icon"
                        id="12uur"
                        :class="
                          comapnyDetails[0].type == 'companies'
                            ? 'employment-taab-company'
                            : 'employment-taab-fund'
                        "
                        v-bind:key="taab.name"
                        style="color: #fe5000"
                        @click="openContextualAnalysis(taab)"
                      >
                        <img
                          src="../../assets/images/EmploymentIcon.svg"
                          alt="Indirect Investment"
                        />
                        <span>{{ taab }}</span>
                      </div>

                      <!--Indirect Investment-->
                      <div
                        v-if="
                          color == 'red' && comapnyDetails[0].type == 'funds'
                        "
                        class="Ind_Inv_Icon Indirect_Investment tooltip"
                        id="12uur"
                        v-bind:key="taab.name"
                        style="color: #201747"
                        @click="openContextualAnalysis(taab)"
                      >
                        <img
                          src="../../assets/images/2X-assessment/IndirectInvestmentA.svg"
                          alt="Indirect Investment"
                        />
                        <span
                          class="indirect_tooltip_text"
                          v-if="
                            remainingNumberOfLinkedCompanies &&
                            remainingNumberOfLinkedCompanies != 0
                          "
                        >
                          Awaiting completion of assessments by companies in
                          your portfolio to analyze.
                        </span>
                        <span>{{ taab }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="Profile_Criteria_Wrepper">
              <!-- profile Block -->
              <div class="Profile_CountryWrepper w-2/5">
                <div class="Profile_Block">
                  <div class="OverviewTitle">
                    <h2>Profile</h2>
                    <span></span>
                  </div>
                  <!-- details in list -->
                  <div class="Profile_blockListText">
                    <div
                      class="About_CompanyListText"
                      v-if="
                        comapnyDetails &&
                        comapnyDetails.length > 0 &&
                        comapnyDetails[0].aboutTheCompany
                      "
                    >
                      <b>About the Organization:</b>
                      <br />
                      {{ comapnyDetails[0].aboutTheCompany }}
                    </div>
                    <div
                      class="About_CompanyListText"
                      v-if="
                        comapnyDetails &&
                        comapnyDetails.length > 0 &&
                        comapnyDetails[0]
                          .objectivesonGenderEqualityandWomenEmpowerment
                      "
                    >
                      <b>Gender Equality Objectives:</b><br />
                      {{
                        comapnyDetails[0]
                          .objectivesonGenderEqualityandWomenEmpowerment
                      }}
                    </div>
                    <div
                      class="About_CompanyListText"
                      style="display: flex"
                      v-if="
                        comapnyDetails &&
                        comapnyDetails.length > 0 &&
                        (comapnyDetails[0].website ||
                          comapnyDetails[0].linkedin ||
                          comapnyDetails[0].twitter)
                      "
                    >
                      <a
                        :href="comapnyDetails[0].website"
                        target="_blank"
                        v-if="comapnyDetails[0].website"
                      >
                        <img src="../../assets/images/Earth.png" alt="Earth" />
                      </a>
                      <a
                        :href="comapnyDetails[0].linkedin"
                        target="_blank"
                        v-if="comapnyDetails[0].linkedin"
                      >
                        <img
                          src="../../assets/images/linkedin.png"
                          alt="Linkedin"
                        />
                      </a>
                      <a
                        :href="comapnyDetails[0].twitter"
                        target="_blank"
                        v-if="comapnyDetails[0].twitter"
                      >
                        <img
                          src="../../assets/images/twitter.png"
                          alt="Twitter"
                        />
                      </a>
                    </div>
                    <ul>
                      <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].contactName
                        "
                      >
                        <b>Organization Contact Name:</b>
                        {{ comapnyDetails[0].contactName }}
                      </li>
                      <!-- <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].contactEmail
                        "
                      >
                        <b>Organization Email:</b>
                        {{ comapnyDetails[0].contactEmail }}
                      </li>
                      <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].contactPhone
                        "
                      >
                        <b>Organization Contact no:</b>
                        {{ comapnyDetails[0].contactPhone }}
                      </li> -->
                      <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].authorizingAgentandContactSame
                        "
                      >
                        <b>Authorizing Agent:</b>
                        {{
                          comapnyDetails[0].authorizingAgentandContactSame ==
                            "Yes" && comapnyDetails[0].type == "companies"
                            ? comapnyDetails[0].contactName
                            : comapnyDetails[0].authorizingAgentName
                        }}
                      </li>
                      <!-- <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].authorizingAgentandContactSame
                        "
                      >
                        <b>Agent Contact no:</b>
                        {{
                          comapnyDetails[0].authorizingAgentandContactSame ===
                          "Yes"
                            ? comapnyDetails[0].contactPhone
                            : comapnyDetails[0].authorizingAgentPhone
                        }}
                      </li>
                      <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].authorizingAgentandContactSame
                        "
                      >
                        <b>Agent Email:</b>
                        {{
                          comapnyDetails[0].authorizingAgentandContactSame ===
                          "Yes"
                            ? comapnyDetails[0].contactEmail
                            : comapnyDetails[0].authorizingAgentEmail
                        }}
                      </li> -->
                      <li
                        style="word-break: break-word"
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].sector &&
                          comapnyDetails[0].type === 'companies'
                        "
                      >
                        <b>Organization Sector:</b>
                        {{ comapnyDetails[0].sector }}
                      </li>
                      <li
                        style="word-break: break-word"
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].sector &&
                          comapnyDetails[0].type === 'funds'
                        "
                      >
                        <b>Organization Sector:</b>
                        {{ sectorAry[0].name }}
                      </li>

                      <li
                        style="word-break: break-word"
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].sector &&
                          comapnyDetails[0].type === 'funds'
                        "
                      >
                        <b>Sectors Invested in:</b>
                        {{ fundsSector }}
                      </li>
                      <li
                        v-if="
                          comapnyDetails &&
                          comapnyDetails.length > 0 &&
                          comapnyDetails[0].countryHq.countryName
                        "
                      >
                        <b>Organization Country:</b>
                        {{ comapnyDetails[0].countryHq.countryName }}
                      </li>
                      <li
                        v-if="
                          comapnyDetails[0].whatKindOfSupportAreYouLookingFor &&
                          comapnyDetails[0].whatKindOfSupportAreYouLookingFor
                            .length > 0
                        "
                      >
                        <b style="width: 205px">
                          What kind of support are you looking for :</b
                        >
                        {{ whatKindOfSupport }}
                      </li>
                    </ul>
                    <br />
                    <ul>
                      <li
                        v-if="
                          comapnyDetails[0].interestInFunding &&
                          comapnyDetails[0].interestInFunding.length > 0
                        "
                      >
                        <b>Types of funding interest:</b>
                        {{ fundingInterests }}
                      </li>
                    </ul>
                    <div
                      class="DownloadBtnDeck"
                      v-if="
                        comapnyDetails[0].actionPlanWithTargets &&
                        comapnyDetails[0].actionPlanWithTargets.length > 0 &&
                        comapnyDetails[0].actionPlanWithTargets[0].url
                      "
                    >
                      <a
                        :href="comapnyDetails[0].actionPlanWithTargets[0].url"
                        target="_blank"
                      >
                        <img src="../../assets/images/DownloadBtn.png" alt="" />
                        <span>Download Gender Equality Action Plan</span>
                      </a>
                    </div>
                  </div>
                  <div class="mt-8">
                    <ul>
                      <li
                        class="ml-0 flex"
                        v-if="comapnyDetails[0].type == 'companies'"
                      >
                        <img
                          v-if="
                            comapnyDetails[0]
                              .doYouHaveTimeBoundMeasurableGenderGoalsAndTargetsInTheCompanyStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />

                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .doYouHaveTimeBoundMeasurableGenderGoalsAndTargetsInTheCompanyStrategy ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Targets and goals for gender equality in company
                        strategy
                      </li>

                      <li class="ml-0 flex" v-else>
                        <img
                          v-if="
                            comapnyDetails[0]
                              .timeBoundMeasurableGenderEqualityGoalsAndTargetsInTheCompanyStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .timeBoundMeasurableGenderEqualityGoalsAndTargetsInTheCompanyStrategy ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Targets and goals for gender equality in company
                        strategy
                      </li>
                      <li
                        class="ml-0 flex"
                        v-if="comapnyDetails[0].type == 'companies'"
                      >
                        <img
                          v-if="
                            comapnyDetails[0]
                              .haveYouBudgetedOrAreLookingForResourcesToImplementAGenderPlanStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .haveYouBudgetedOrAreLookingForResourcesToImplementAGenderPlanStrategy ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Budget allocated to implement action plan
                      </li>
                      <li class="ml-0 flex" v-else>
                        <img
                          v-if="
                            comapnyDetails[0]
                              .budgetedOrAreLookingForResourcesToImplementAGenderPlanStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .budgetedOrAreLookingForResourcesToImplementAGenderPlanStrategy ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Budget allocated to implement action plan
                      </li>
                    </ul>
                  </div>
                  <!-- country & sector intro -->
                </div>
                <div class="Profile_Block">
                  <div class="OverviewTitle CountryLine">
                    <h2>Gender Equality & Social Inclusion Context</h2>
                    <span></span>
                  </div>
                  <div class="GbvRiskTitle ContryNameImg">
                    <img
                      class="mr-5 h-16 print:h-auto print:w-64"
                      :src="country.flagUrl ? country.flagUrl : country.flagurl"
                      v-if="country.flagUrl || country.flagurl"
                    />
                    <h1>
                      {{
                        comapnyDetails[0].countryHq.active == "true"
                          ? country.humanName
                          : country.countryName
                      }}
                    </h1>
                  </div>
                  <!-- country intro start -->
                  <div
                    class="CountryOverviewText"
                    v-if="country && Object.keys(country).length > 0"
                  >
                    <h3 v-if="country.intro">Country Overview</h3>
                    <transition name="slide-fade" v-if="country.intro">
                      <item :text="country.intro" :country="country"></item>
                    </transition>
                    <a
                      class="readMoreBtn"
                      href="javascript:void(0)"
                      @click="readMore('Country Overview', country.intro)"
                      v-if="country.intro"
                      >Read more
                      <img
                        src="../../assets/images/plushIcon.png"
                        alt="plush icon"
                    /></a>
                  </div>
                  <div
                    class="CountryOverviewText"
                    v-if="sector && Object.keys(sector).length > 0"
                  >
                    <h3>Sector Overview</h3>
                    <transition name="slide-fade">
                      <item :text="sector.intro" :country="sector"></item>
                    </transition>
                    <a
                      class="readMoreBtn"
                      href="javascript:void(0)"
                      @click="readMore('Sector Overview', sector.intro)"
                      >Read more
                      <img
                        src="../../assets/images/plushIcon.png"
                        alt="plush icon"
                    /></a>
                  </div>
                  <div class="WanttoUnderstandHere" v-if="country.intro">
                    Want to do a deeper dive into the GESI contextual analysis?
                    Go
                    <b><a href="/#/gesi-analysis" target="_blank">here</a></b>
                    to select a country and sector GESI analysis along with the
                    What Works library of actions. If you do not yet have a
                    subscription, you can upgrade.
                  </div>
                  <div>
                    <div
                      class="tooltip TooltipImgText"
                      v-if="comapnyDetails[0].countryHq.active == 'false'"
                    >
                      <div class="ExploreBtnDeck pointer-event-none">
                        <div
                          href="/#/2X-Ecosystem/My-2X-Assessments/contextual-analysis"
                          target="_blank"
                        >
                          <span>Explore contextual analysis</span>
                        </div>
                      </div>
                      <span
                        class="tooltiptext"
                        style="width: 200px; left: 5px; top: -40px"
                      >
                        Coming Soon!
                      </span>
                    </div>
                    <div
                      class="ExploreBtnDeck"
                      @click="goToExploreContext"
                      v-else
                    >
                      <div
                        href="/#/2X-Ecosystem/My-2X-Assessments/contextual-analysis"
                        target="_blank"
                      >
                        <span>Explore contextual analysis</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 2xCriteria Block -->
              <div class="barchart2xCriteria_Wrepper w-3/5 ml-4">
                <!-- entrepreneurship barchart start -->
                <div class="OverviewTitle">
                  <h2>2X Criteria</h2>
                  <span></span>
                </div>
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon1.png" alt="" />
                  <p>Entrepreneurship</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div v-if="comapnyDetails[0].organizationType === 'Fund'">
                    <div v-if="country" class="ChartBarContent">
                      <div class="ChartBarPercentageText">
                        % Carry Allocation to Women
                      </div>
                      <b
                        v-if="
                          comapnyDetails[0].percentShares == '' ||
                          isNaN(comapnyDetails[0].percentShares)
                        "
                      >
                        Not Applicable
                      </b>

                      <barChartVerticalFund
                        v-else
                        :chartLabels="assessmentYear"
                        style="margin-left: -33px"
                        :width="300"
                        :height="400"
                        label1="Women"
                        label2="Men"
                        :totalValue="[Number(100), Number(100)]"
                        :filledValue="[
                          Number(comapnyDetails[0].percentShares * 100),
                          comapnyDetails[1]
                            ? Number(comapnyDetails[1].percentShares * 100)
                            : '',
                        ]"
                      >
                      </barChartVerticalFund>
                      <!-- <assessmentPiechart style="margin-left: -33px" :width="500" :height="500"
                        :sectorLabels="['Women', 'Men']" :totalValue="[
                          Math.round(
                            (comapnyDetails[0].percentShares * 100)
                          ),
                          Math.round(
                            100 - (comapnyDetails[0].percentShares * 100)
                          ),
                        ]"></assessmentPiechart> -->
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="country" class="ChartBarContent">
                      <div class="ChartBarPercentageText">Women's Shares</div>
                      <b
                        v-if="
                          (comapnyDetails[0]
                            .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization ==
                            '0' ||
                            comapnyDetails[0]
                              .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization ==
                              '0.0') &&
                          (comapnyDetails[0]
                            .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen ==
                            '0' ||
                            comapnyDetails[0]
                              .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen ==
                              '0.0')
                        "
                      >
                        Not Applicable
                      </b>
                      <barChartVertical
                        v-else
                        :chartLabels="assessmentYear"
                        style="margin-left: -33px"
                        :width="300"
                        :height="400"
                        label1="Women"
                        label2="Men"
                        :totalValue="[
                          Number(
                            comapnyDetails[0]
                              .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization
                          ),
                          comapnyDetails[1]
                            ? Number(
                                comapnyDetails[1]
                                  .whatIsTheTotalNumberOfAuthorizedSharesForThisOrganization
                              )
                            : '',
                        ]"
                        :filledValue="[
                          Number(
                            comapnyDetails[0]
                              .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen
                          ),
                          comapnyDetails[1]
                            ? Number(
                                comapnyDetails[1]
                                  .whatIsTheTotalNumberOfTheOrganizationSAuthorizedSharesOwnedByWomen
                              )
                            : '',
                        ]"
                      >
                      </barChartVertical>
                    </div>
                  </div>
                  <!-- companies  -->
                  <div class="ChartBarContent">
                    <div
                      v-if="country && comapnyDetails[0].type === 'companies'"
                    >
                      <div>
                        <div class="Founded_WomanText">Founded by a woman</div>
                        <div class="Founded_WomanImg">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            style="
                              width: 20px;
                              display: inline-flex;
                              margin: auto;
                            "
                            v-if="comapnyDetails[0].foundedByAWoman === 'Yes'"
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else
                            style="
                              width: 20px;
                              display: inline-flex;
                              margin: auto;
                            "
                          />
                          <p>{{ comapnyDetails[0].foundedByAWoman }}</p>
                        </div>
                      </div>
                      <br />
                    </div>
                    <!-- funds  -->
                    <!-- numberBusinessesTheFundFiOnLendingOrganizationHasInExistingPortfolioThatMeets2XCriteriaThatItsInvesteeWasFoundedByAWomanOrAGroupOfWomenThatRetainSAnActiveRoleInTheOrg1b -->
                    <div
                      v-if="country && comapnyDetails[0].type !== 'companies'"
                    >
                      <div class=" ">
                        <div class="Founded_WomanText">Founded by a woman</div>
                        <div class="Founded_WomanImg">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            v-if="comapnyDetails[0].foundedByAWoman === 'Yes'"
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else
                          />
                          <p>{{ comapnyDetails[0].foundedByAWoman }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- leadership barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon2.png" alt="" />
                  <p>Leadership</p>
                </div>
                <div class="ChartBarContentWrepper">
                  <div v-if="country" class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Women Senior Managers
                    </div>
                    <b
                      v-if="
                        (comapnyDetails[0]
                          .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners ==
                          '0' ||
                          comapnyDetails[0]
                            .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners ==
                            '0.0') &&
                        (comapnyDetails[0]
                          .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners ==
                          '0' ||
                          comapnyDetails[0]
                            .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners ==
                            '0.0')
                      "
                    >
                      Not Applicable
                    </b>
                    <barChartVertical
                      v-else
                      :chartLabels="assessmentYear"
                      style="margin-left: -33px"
                      :width="300"
                      :height="400"
                      type="leadership"
                      label1="Women"
                      label2="Men"
                      :totalValue="[
                        Number(
                          comapnyDetails[0]
                            .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners
                        ),
                        comapnyDetails[1]
                          ? Number(
                              comapnyDetails[1]
                                .whatIsTheTotalNumberOfSeniorManagersAtTheOrganizationEGCSuiteOrEquivalentEGManagingDirectorsPartners
                            )
                          : '',
                      ]"
                      :filledValue="[
                        Number(
                          comapnyDetails[0]
                            .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners
                        ),
                        comapnyDetails[1]
                          ? Number(
                              comapnyDetails[1]
                                .whatIsTheTotalNumberOfWomenInSeniorManagerRolesAtTheOrganizationEGCSuiteOrEquivalentOfEmployeesInCSuiteOrEquivalentEGManagingDirectorsPartners
                            )
                          : '',
                      ]"
                    >
                    </barChartVertical>
                  </div>
                  <div v-if="country" class="ChartBarContent">
                    <div class="ChartBarPercentageText">
                      Women Board Members
                    </div>
                    <b
                      v-if="
                        (comapnyDetails[0]
                          .howManyBoardMembersOrInvestmentCommitteeMembersAreThere ==
                          '0' ||
                          comapnyDetails[0]
                            .howManyBoardMembersOrInvestmentCommitteeMembersAreThere ==
                            '0.0') &&
                        (comapnyDetails[0]
                          .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere ==
                          '0' ||
                          comapnyDetails[0]
                            .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere ==
                            '0.0')
                      "
                    >
                      Not Applicable
                    </b>
                    <barChartVertical
                      v-else
                      :chartLabels="assessmentYear"
                      style="margin-left: -33px"
                      :width="430"
                      :height="400"
                      type="leadership"
                      label1="Women"
                      label2="Men"
                      :totalValue="[
                        Number(
                          comapnyDetails[0]
                            .howManyBoardMembersOrInvestmentCommitteeMembersAreThere
                        ),
                        comapnyDetails[1]
                          ? Number(
                              comapnyDetails[1]
                                .howManyBoardMembersOrInvestmentCommitteeMembersAreThere
                            )
                          : '',
                      ]"
                      :filledValue="[
                        Number(
                          comapnyDetails[0]
                            .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere
                        ),
                        comapnyDetails[1]
                          ? Number(
                              comapnyDetails[1]
                                .howManyWomenBoardMembersOrInvestmentCommitteeMembersAreThere
                            )
                          : '',
                      ]"
                    >
                    </barChartVertical>
                  </div>
                </div>

                <!-- employment barchart start -->
                <div class="CriteriaTitleText">
                  <img src="../../assets/images/EligibleIcon.3.png" alt="" />
                  <p>Employment</p>
                </div>
                <div
                  class="ChartBarContentWrepper"
                  style="display: block"
                  v-if="comapnyDetails.length == 1"
                >
                  <div
                    v-if="country"
                    class="ChartBarContent"
                    style="max-width: 630px; margin: auto; margin-bottom: 20px"
                  >
                    <div class="ChartBarPercentageText text-center">
                      Women in the Workforce
                    </div>
                    <div
                      style="font-weight: bolder"
                      v-if="
                        (comapnyDetails[0].totalPtFtEmployees == '0' ||
                          comapnyDetails[0].totalPtFtEmployees == '0.0') &&
                        (comapnyDetails[0].midManagerTot == '0' ||
                          comapnyDetails[0].midManagerTot == '0.0') &&
                        (comapnyDetails[0].ftEmployees == '0' ||
                          comapnyDetails[0].ftEmployees == '0.0') &&
                        (comapnyDetails[0].ptEmployees == '0' ||
                          comapnyDetails[0].ptEmployees == '0.0') &&
                        (comapnyDetails[0]
                          .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                          '0' ||
                          comapnyDetails[0]
                            .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                            '0.0') &&
                        (comapnyDetails[0]
                          .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                          '0' ||
                          comapnyDetails[0]
                            .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                            '0.0')
                      "
                      class="mt-10 mb-10 text-center"
                    >
                      Not Applicable
                    </div>
                    <employmentBarChart
                      v-else
                      :chartData="comapnyDetails"
                      :assessmentYear="assessmentYear"
                      :chartLabels="[
                        'Total',
                        'Middle Managers',
                        'FT employees',
                        'PT employees',
                        'Contractual',
                        'Supplier',
                      ]"
                    ></employmentBarChart>
                  </div>
                  <div>
                    <div
                      class="ChartBarContent"
                      :style="
                        assessmentYear.length == 1 ? 'max-width: 100%' : ''
                      "
                    >
                      <div class="Quality_EmploymentText">
                        <p>Employment Quality Policies & Programs</p>
                        <p>{{ assessmentYear[0] }}</p>
                        <ul class="list-disc">
                          <li
                            v-for="employment_data in comapnyDetails[0]
                              .policies"
                            :key="employment_data.name + 2"
                            v-show="employment_data?.type != 'Other'"
                          >
                            <img
                              v-if="employment_data.flag"
                              src="@/assets/images/Rightcircle.svg"
                              alt="background-image"
                            />
                            <img
                              v-else
                              src="@/assets/images/3rdPartyVerified.svg"
                              alt="background-image"
                              style="border-radius: 2px"
                            />
                            {{ employment_data.name }}
                          </li>
                          <li
                            v-for="employment_data in comapnyDetails[0]
                              .policies"
                            :key="employment_data.name + 1"
                            v-show="
                              employment_data.flag &&
                              employment_data?.type == 'Other'
                            "
                          >
                            <img
                              v-if="employment_data.flag"
                              src="@/assets/images/Rightcircle.svg"
                              alt="background-image"
                            />
                            <img
                              v-else
                              src="@/assets/images/3rdPartyVerified.svg"
                              alt="background-image"
                              style="border-radius: 2px"
                            />
                            {{ employment_data.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      v-if="
                        comapnyDetails[1] &&
                        comapnyDetails[1]
                          .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                        comapnyDetails[1]
                          .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization
                          .length > 0
                      "
                      class="ChartBarContent"
                      style="margin-top: 2.5rem !important"
                    >
                      <div class="Quality_EmploymentText">
                        <p>{{ assessmentYear[1] }}</p>
                        <ul class="list-disc">
                          <li
                            v-for="(
                              employment_data, employment_dataIndex
                            ) in comapnyDetails[1]
                              .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization"
                            :key="employment_dataIndex"
                          >
                            <img
                              src="@/assets/images/Rightcircle.svg"
                              alt="background-image"
                            />
                            {{ employment_data }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="ChartBarContentWrepper"
                  v-else
                  style="display: block"
                >
                  <div
                    v-if="country"
                    class="ChartBarContent"
                    style="max-width: 650px; margin: auto; margin-bottom: 20px"
                  >
                    <div class="ChartBarPercentageText text-center">
                      Women in the Workforce
                    </div>
                    <div
                      style="font-weight: bolder"
                      v-if="
                        ((comapnyDetails[0].totalPtFtEmployees == '0' &&
                          comapnyDetails[1].totalPtFtEmployees == '0') ||
                          (comapnyDetails[0].totalPtFtEmployees == '0.0' &&
                            comapnyDetails[1].totalPtFtEmployees == '0.0')) &&
                        ((comapnyDetails[0].midManagerTot == '0' &&
                          comapnyDetails[1].midManagerTot == '0') ||
                          (comapnyDetails[0].midManagerTot == '0.0' &&
                            comapnyDetails[1].midManagerTot == '0.0')) &&
                        ((comapnyDetails[0].ftEmployees == '0' &&
                          comapnyDetails[1].ftEmployees == '0') ||
                          (comapnyDetails[0].ftEmployees == '0.0' &&
                            comapnyDetails[1].ftEmployees == '0.0')) &&
                        ((comapnyDetails[0].ptEmployees == '0' &&
                          comapnyDetails[1].ptEmployees == '0') ||
                          (comapnyDetails[0].ptEmployees == '0.0' &&
                            comapnyDetails[1].ptEmployees == '0.0')) &&
                        ((comapnyDetails[0]
                          .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                          '0' &&
                          comapnyDetails[1]
                            .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                            '0') ||
                          (comapnyDetails[0]
                            .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                            '0.0' &&
                            comapnyDetails[1]
                              .howManyThirdPartyWorkersAreEngagedToPerformWorkRelatedToCoreBusinessProcessesForASubstantialDurationEGASeasonInTheCaseOfAnAgribusiness ==
                              '0.0')) &&
                        ((comapnyDetails[0]
                          .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                          '0' &&
                          comapnyDetails[1]
                            .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                            '0') ||
                          (comapnyDetails[0]
                            .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                            '0.0' &&
                            comapnyDetails[1]
                              .howManyFullTimeOrPartTimeWorkersAreEngagedByPrimarySuppliersOfTheOrganization ==
                              '0.0'))
                      "
                      class="mt-10 mb-10 text-center"
                    >
                      Not Applicable
                    </div>
                    <employmentBarChart
                      v-else
                      :chartData="comapnyDetails"
                      :assessmentYear="assessmentYear"
                      :chartLabels="[
                        'Total',
                        'Middle Managers',
                        'FT employees',
                        'PT employees',
                        'Contractual',
                        'Supplier',
                      ]"
                    ></employmentBarChart>
                  </div>
                  <div class="mt-5 multiple_employment_results_section">
                    <p class="text-center">
                      Employment Quality Policies & Programs
                    </p>
                    <div class="">
                      <div
                        v-if="
                          comapnyDetails[0]
                            .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                          comapnyDetails[0]
                            .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization
                            .length > 0
                        "
                        class=""
                      >
                        <div class="Quality_EmploymentText flex">
                          <div class="assessment_year_one_results mr-2">
                            <p>{{ assessmentYear[0] }}</p>
                            <ul class="list-disc">
                              <li
                                v-for="employment_data in comapnyDetails[0]
                                  .policies"
                                :key="employment_data.name + 3"
                                v-show="employment_data?.type != 'Other'"
                              >
                                <img
                                  v-if="employment_data.flag"
                                  src="@/assets/images/Rightcircle.svg"
                                  alt="background-image"
                                />
                                <img
                                  v-else
                                  src="@/assets/images/3rdPartyVerified.svg"
                                  alt="background-image"
                                  style="border-radius: 2px"
                                />
                                {{ employment_data.name }}
                              </li>
                              <li
                                v-for="employment_data in comapnyDetails[0]
                                  .policies"
                                :key="employment_data.name + 4"
                                v-show="employment_data?.type == 'Other'"
                              >
                                <img
                                  v-if="employment_data.flag"
                                  src="@/assets/images/Rightcircle.svg"
                                  alt="background-image"
                                />
                                <img
                                  v-else
                                  src="@/assets/images/3rdPartyVerified.svg"
                                  alt="background-image"
                                  style="border-radius: 2px"
                                />
                                {{ employment_data.name }}
                              </li>
                            </ul>
                          </div>
                          <div
                            v-if="
                              comapnyDetails[1] &&
                              comapnyDetails[1]
                                .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization &&
                              comapnyDetails[1]
                                .whatPoliciesOrProgramsBeyondThoseRequiredForComplianceAddressBarriersToWomenSQualityEmploymentAtTheOrganization
                                .length > 0
                            "
                            class="assessment_year_second_results ml-2"
                          >
                            <p>{{ assessmentYear[1] }}</p>
                            <ul class="list-disc">
                              <li
                                v-for="employment_data in comapnyDetails[1]
                                  .policies"
                                :key="employment_data.name + 5"
                                v-show="employment_data?.type != 'Other'"
                              >
                                <img
                                  v-if="employment_data.flag"
                                  src="@/assets/images/Rightcircle.svg"
                                  alt="background-image"
                                />
                                <img
                                  v-else
                                  src="@/assets/images/3rdPartyVerified.svg"
                                  alt="background-image"
                                  style="border-radius: 2px"
                                />
                                {{ employment_data.name }}
                              </li>
                              <li
                                v-for="employment_data in comapnyDetails[1]
                                  .policies"
                                :key="employment_data.name + 6"
                                v-show="employment_data?.type == 'Other'"
                              >
                                <img
                                  v-if="employment_data.flag"
                                  src="@/assets/images/Rightcircle.svg"
                                  alt="background-image"
                                />
                                <img
                                  v-else
                                  src="@/assets/images/3rdPartyVerified.svg"
                                  alt="background-image"
                                  style="border-radius: 2px"
                                />
                                {{ employment_data.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Consumption barchart start -->
                <div>
                  <div class="CriteriaTitleText">
                    <img src="../../assets/images/EligibleIcon.4.png" alt="" />
                    <p>
                      {{
                        comapnyDetails[0].type == "funds"
                          ? "Gender Lens"
                          : "Consumption"
                      }}
                    </p>
                  </div>

                  <div class="Consumption_ListText flex">
                    <div class="assessment_year_one_results">
                      <p>{{ assessmentYear[0] }}</p>
                      <ul class="list-disc">
                        <li
                          v-for="consumption in comapnyDetails[0]
                            .consumptionList"
                          :key="consumption.name + 1"
                        >
                          <img
                            v-if="consumption.flag"
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                          />
                          <img
                            v-else
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            style="border-radius: 2px"
                          />
                          {{ consumption.name }}
                        </li>
                        <!-- <li v-for="(consumption) in comapnyDetails[0]
                        .consumptionList" :key="consumption.name+2" v-show="!consumption.flag">
                          <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image" />
                          <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                            style="border-radius: 2px" />
                          {{ consumption.name }}
                        </li> -->
                      </ul>
                    </div>
                    <div
                      class="assessment_year_second_results"
                      v-if="comapnyDetails.length == 2"
                    >
                      <p>{{ assessmentYear[1] }}</p>
                      <ul class="list-disc">
                        <li
                          v-for="consumption in comapnyDetails[1]
                            .consumptionList"
                          :key="consumption.name + 3"
                        >
                          <img
                            v-if="consumption.flag"
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                          />
                          <img
                            v-else
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            style="border-radius: 2px"
                          />
                          {{ consumption.name }}
                        </li>
                        <!-- <li v-for="(consumption) in comapnyDetails[1]
                        .consumptionList" :key="consumption.name+4" v-show="!consumption.flag">
                          <img v-if="consumption.flag" src="@/assets/images/Rightcircle.svg" alt="background-image" />
                          <img v-else src="@/assets/images/3rdPartyVerified.svg" alt="background-image"
                            style="border-radius: 2px" />
                          {{ consumption.name }}
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <div
                    class="flex ChartBarContentWrepper"
                    v-if="
                      comapnyDetails[0].type === 'companies' &&
                      (comapnyDetails[0]
                        .howManyDirectCustomersOrClientsDoYouHave ||
                        (comapnyDetails[1] &&
                          comapnyDetails[1]
                            .howManyDirectCustomersOrClientsDoYouHave) ||
                        comapnyDetails[0]
                          .howManyPeopleCurrentlyUseYourProduct ||
                        (comapnyDetails[1] &&
                          comapnyDetails[1]
                            .howManyPeopleCurrentlyUseYourProduct))
                    "
                  >
                    <div v-if="country" class="ChartBarContent">
                      <div class="ChartBarPercentageText">Direct Customers</div>
                      <b
                        v-if="
                          comapnyDetails[0]
                            .howManyDirectCustomersOrClientsDoYouHave == '0' ||
                          comapnyDetails[0]
                            .howManyDirectCustomersOrClientsDoYouHave == '0.0'
                        "
                      >
                        Not Applicable
                      </b>
                      <span v-else>
                        <barChartVertical
                          v-if="
                            comapnyDetails[0]
                              .howManyDirectCustomersOrClientsDoYouHave ||
                            (comapnyDetails[1] &&
                              comapnyDetails[1]
                                .howManyDirectCustomersOrClientsDoYouHave)
                          "
                          :chartLabels="assessmentYear"
                          style="margin-left: -33px"
                          label1="Women"
                          label2="Men"
                          :width="300"
                          :height="400"
                          :totalValue="[
                            Number(
                              comapnyDetails[0]
                                .howManyDirectCustomersOrClientsDoYouHave
                            ),
                            comapnyDetails[1]
                              ? Number(
                                  comapnyDetails[1]
                                    .howManyDirectCustomersOrClientsDoYouHave
                                )
                              : '',
                          ]"
                          :filledValue="[
                            Number(
                              comapnyDetails[0]
                                .howManyOfYourDirectCustomersOrClientsAreWomen
                            ),
                            comapnyDetails[1]
                              ? Number(
                                  comapnyDetails[1]
                                    .howManyOfYourDirectCustomersOrClientsAreWomen
                                )
                              : '',
                          ]"
                        >
                        </barChartVertical>
                        <b v-else> Not Applicable</b>
                      </span>
                    </div>
                    <div v-if="country" class="ChartBarContent">
                      <div class="ChartBarPercentageText">
                        Product/ service Users
                      </div>

                      <b
                        v-if="
                          comapnyDetails[0]
                            .howManyPeopleCurrentlyUseYourProduct == '0' ||
                          comapnyDetails[0]
                            .howManyPeopleCurrentlyUseYourProduct == '0.0'
                        "
                      >
                        Not Applicable
                      </b>
                      <span v-else>
                        <barChartVertical
                          v-if="
                            comapnyDetails[0]
                              .howManyPeopleCurrentlyUseYourProduct ||
                            (comapnyDetails[1] &&
                              comapnyDetails[1]
                                .howManyPeopleCurrentlyUseYourProduct)
                          "
                          :chartLabels="assessmentYear"
                          style="margin-left: -33px"
                          label1="Women"
                          label2="Men"
                          :width="300"
                          :height="400"
                          :totalValue="[
                            Number(
                              comapnyDetails[0]
                                .howManyPeopleCurrentlyUseYourProduct
                            ),
                            comapnyDetails[1]
                              ? Number(
                                  comapnyDetails[1]
                                    .howManyPeopleCurrentlyUseYourProduct
                                )
                              : '',
                          ]"
                          :filledValue="[
                            Number(
                              comapnyDetails[0]
                                .howManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen
                            ),
                            comapnyDetails[1]
                              ? Number(
                                  comapnyDetails[1]
                                    .howManyPeopleCurrentlyUseYourProductOrServiceWhoAreWomen
                                )
                              : '',
                          ]"
                        >
                        </barChartVertical>
                        <b v-else> Not Applicable</b>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- Indirect Investment barchart start -->
                <div
                  class="CriteriaTitleText"
                  v-if="comapnyDetails[0].type === 'funds'"
                  style="margin-left: -6px"
                >
                  <img
                    src="../../assets/images/2X-assessment/IndirectInvestmentB.png"
                    style="
                      width: 40px;
                      height: 40px;
                      margin-right: 6px !important;
                    "
                    alt=""
                  />
                  <p>Indirect Investment</p>
                </div>
                <div v-if="comapnyDetails[0].type === 'funds'">
                  <div class="IndirectInvestmentListContent">
                    <div class="IndirectContent">
                      <div class="IndirectInvWrepper">
                        <div class="IndirectInvText">
                          Do 30% or more of FI loan proceeds or funds support
                          businesses that meet 2X direct criteria?
                        </div>
                        <div class="IndirectInvImg div_tooltip">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            v-if="
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                '1' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                1 ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                'Yes'
                            "
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else-if="
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                '0' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                0 ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                'No'
                            "
                          />
                          <div v-else>
                            <img
                              src="@/assets/images/help_white.svg"
                              alt="background-image"
                            />
                            <span class="div_tooltiptext" style="width: 100%"
                              >You opted to have Equilo collect and analyze data
                              on your behalf. <br /><router-link
                                to="/upgrade"
                                style="color: #0077c8"
                                >Upgrade your account to proceed</router-link
                              ></span
                            >
                          </div>
                          <span
                            v-if="
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                '1' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                1 ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                'Yes' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                '0' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                0 ||
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                                'No'
                            "
                            >{{
                              comapnyDetails[0]
                                .percentOrMoreOfFiLoanProceedsOrFundsSupportBusinessesMeet2XDirectCriteria30 ==
                              1
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                          <span v-else>Unknown</span>
                        </div>
                      </div>
                      <div class="IndirectInvWrepper">
                        <div class="IndirectInvText">
                          Are 30% or more of future committed loan proceeds or
                          funds to support businesses that meet 2X criteria?
                        </div>
                        <div class="IndirectInvImg">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            v-if="
                              comapnyDetails[0]
                                .percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30 ==
                                '1' ||
                              comapnyDetails[0]
                                .percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30 ==
                                'Yes'
                            "
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else
                          />
                          <span>{{
                            comapnyDetails[0]
                              .percentOrMoreOfFutureCommittedLoanProceedsOrFundsToSupportBusinessesThatMeet2XCriteria30 ==
                            1
                              ? "Yes"
                              : "No"
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="IndirectContent">
                      <div class="IndirectInvWrepper">
                        <div class="IndirectInvText">
                          Do 30% or more of companies receiving funds/
                          on-lending FI loan support meet any of the 2X direct
                          criteria?
                        </div>
                        <div class="IndirectInvImg div_tooltip">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            v-if="
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                '1' ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                1 ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                'Yes'
                            "
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else-if="
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                '0' ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                0 ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                'No'
                            "
                          />
                          <div v-else>
                            <img
                              src="@/assets/images/help_white.svg"
                              alt="background-image"
                            />
                            <span class="div_tooltiptext" style="width: 100%"
                              >You opted to have Equilo collect and analyze data
                              on your behalf. <br /><router-link
                                to="/upgrade"
                                style="color: #0077c8"
                                >Upgrade your account to proceed</router-link
                              ></span
                            >
                          </div>
                          <span
                            v-if="
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                '1' ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                1 ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                'Yes' ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                '0' ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                0 ||
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                                'No'
                            "
                            >{{
                              comapnyDetails[0]
                                .percentOrMoreOfCompaniesReceivingFundsOnLendingFiLoanSupportMeetingAny2XDirectCriteriaCriteria5_30 ==
                              1
                                ? "Yes"
                                : "No"
                            }}</span
                          >
                          <span v-else>Unknown</span>
                        </div>
                      </div>
                      <div class="IndirectInvWrepper" style="margin-bottom: 180px;">
                        <!-- <div class="IndirectInvText">
                          Does the fund specifically target women’s economic
                          empowerment as part of its investment strategy?
                        </div>
                        <div class="IndirectInvImg">
                          <img
                            src="@/assets/images/Rightcircle.svg"
                            alt="background-image"
                            v-if="
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                '1' ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                'Yes'
                            "
                          />
                          <img
                            src="@/assets/images/3rdPartyVerified.svg"
                            alt="background-image"
                            v-else-if="
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                '0' ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                'No'
                            "
                          />
                          <div class="div_tooltip" v-else>
                            <img
                              src="@/assets/images/help_white.svg"
                              alt="background-image"
                            />
                            <span
                              class="div_tooltiptext"
                              v-if="checkAssesmentIsCompleted"
                              style="width: 330px"
                              >Unknown -
                              <router-link
                                :to="{
                                  path: checkOrganizationType,
                                  query: {
                                    recordId: comapnyDetails[0].recordId,
                                  },
                                }"
                                class="text-white text-sm font-medium"
                                style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                                >Complete the full assessment</router-link
                              ></span
                            >
                            <span class="div_tooltiptext" v-else>Unknown</span>
                          </div>
                          <span
                            v-if="
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                '1' ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                'Yes' ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                '0' ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                'No'
                            "
                          >
                            {{
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                "1" ||
                              comapnyDetails[0]
                                .doesTheFundSpecificallyTargetWomen2019SEconomicEmpowermentAsPartOfItsInvestmentStrategyWithALowAndMiddleIncomeCountryFocus ==
                                "Yes"
                                ? "Yes"
                                : "No"
                            }}
                          </span>
                          <span v-else>Unknown</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ESG Pre-screening -->
            <div class="esg-pre-screening">
              <div class="OverviewTitle">
                <h2>ESG Pre-screening</h2>
                <span></span>
              </div>
              <div class="GBVdetailsContent">
                <div class="overOverviewDeitail">
                  <span>Overview</span>
                  <p>
                    As part of any due diligence, in-depth environmental,
                    social, and governance (ESG) is best practice. These
                    responses below indicate a high-level pre-screening of key
                    ESG questions.
                  </p>
                </div>
                <div class="esg-pre-screening-results-section flex mt-5">
                  <div class="assessment_year_one_results">
                    <p>{{ assessmentYear[0] }}</p>
                    <ul>
                      <li v-if="comapnyDetails[0].eSClaims == 'Yes'">
                        <img
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Does have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li v-else-if="comapnyDetails[0].eSClaims == ''">
                        <div class="div_tooltip">
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        Does not have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li v-else>
                        <img
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        Does not have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[0].genderInProcurementStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0].genderInProcurementStrategy == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gender & procurement strategy
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[0]
                              .valueChainStrategiesThatPromoteEquality == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .valueChainStrategiesThatPromoteEquality == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gender & value chain strategy
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[0]
                              .initiativesToIncludeDisadvantagedGroups == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .initiativesToIncludeDisadvantagedGroups == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Active initiatives to include disadvantaged groups
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[0].genderedApproachToCovid19 == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0].genderedApproachToCovid19 == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gendered approach to COVID-19
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[0]
                              .doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Adopt practices that improve agency, decision-making and
                        empower women in their communities/households
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[0]
                              .workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Work with financing or implementation partners who work
                        with women value chain partners
                      </li>
                      <li v-if="comapnyDetails[0].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[0]
                              .engageWomenInPaidIncomeOpportunitiesInSupplyChain ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[0]
                              .engageWomenInPaidIncomeOpportunitiesInSupplyChain ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Engage women in paid income opportunities in supply
                        chain
                      </li>
                    </ul>
                  </div>
                  <div
                    class="assessment_year_second_results"
                    v-if="comapnyDetails.length == 2"
                  >
                    <p>{{ assessmentYear[1] }}</p>
                    <ul>
                      <li v-if="comapnyDetails[1].eSClaims == 'Yes'">
                        <img
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Does have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li v-else-if="comapnyDetails[1].eSClaims == ''">
                        <div class="div_tooltip">
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        Does not have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li v-else>
                        <img
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        Does not have existing ESG claims, including sexual
                        harassment.
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[1].genderInProcurementStrategy ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1].genderInProcurementStrategy == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gender & procurement strategy
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[1]
                              .valueChainStrategiesThatPromoteEquality == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1]
                              .valueChainStrategiesThatPromoteEquality == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gender & value chain strategy
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[1]
                              .initiativesToIncludeDisadvantagedGroups == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1]
                              .initiativesToIncludeDisadvantagedGroups == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Active initiatives to include disadvantaged groups
                      </li>
                      <li>
                        <img
                          v-if="
                            comapnyDetails[1].genderedApproachToCovid19 == 'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1].genderedApproachToCovid19 == ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Gendered approach to COVID-19
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[1]
                              .doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1]
                              .doYouAdoptPracticesThatImproveAgencyDecisionMakingAndEmpowerWomenInTheirCommunitiesHouseholds ==
                            ''
                          "
                          class="div_tooltip"
                          style="margin-right: 15px"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Adopt practices that improve agency, decision-making and
                        empower women in their communities/households
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[1]
                              .workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1]
                              .workWithFinancingOrImplementationPartnersWhoWorkWithWomenValueChainPartners ==
                            ''
                          "
                          class="div_tooltip"
                          style="margin-right: 11px"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Work with financing or implementation partners who work
                        with women value chain partners
                      </li>
                      <li v-if="comapnyDetails[1].type == 'companies'">
                        <img
                          v-if="
                            comapnyDetails[1]
                              .engageWomenInPaidIncomeOpportunitiesInSupplyChain ==
                            'Yes'
                          "
                          src="@/assets/images/Rightcircle.svg"
                          alt="background-image"
                        />
                        <div
                          v-else-if="
                            comapnyDetails[1]
                              .engageWomenInPaidIncomeOpportunitiesInSupplyChain ==
                            ''
                          "
                          class="div_tooltip"
                        >
                          <img
                            src="@/assets/images/help_white.svg"
                            alt="background-image"
                            style="width: 24px; padding: 2px"
                          />
                          <span
                            class="div_tooltiptext"
                            v-if="checkAssesmentIsCompleted"
                            style="width: 330px"
                            >Unknown -
                            <router-link
                              :to="{
                                path: checkOrganizationType,
                                query: { recordId: comapnyDetails[0].recordId },
                              }"
                              class="text-white text-sm font-medium"
                              style="width: max-content;text-decoration:underline; color:#599bd8;!important"
                              >Complete the full assessment</router-link
                            ></span
                          >
                          <span class="div_tooltiptext" v-else>Unknown</span>
                        </div>
                        <img
                          v-else
                          src="@/assets/images/3rdPartyVerified.svg"
                          alt="background-image"
                          style="width: 24px; padding: 2px"
                        />
                        Engage women in paid income opportunities in supply
                        chain
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- ESG Pre-screening End -->

            <!-- GBV details -->
            <div
              class="GBVdetailsBlock"
              v-if="comapnyDetails[0].countryHq.active == 'true'"
            >
              <div class="OverviewTitle">
                <h2>GBV Risk</h2>
                <span></span>
              </div>
              <div class="GBVdetailsContent">
                <div class="overOverviewDeitail">
                  <span>Overview</span>
                  <p>
                    The risk of gender-based violence is ubiquitous, and
                    threatens individuals' wellbeing- and the success of
                    organizations. Read more about GBV risk in
                    {{ country.humanName }}
                    <b v-if="comapnyDetails[0].countryHq.active">
                      <!-- <a style="text-decoration: underline;" :href="'/#/reports/' +comapnyDetails[0].countryHq.iso +'/country/gbv-risk/risk-score'" target="_blank">here.</a> -->
                      <a
                        v-if="sector && sector.id"
                        style="text-decoration: underline"
                        :href="
                          '/#/gbv-risk/' +
                          comapnyDetails[0].countryHq.iso +
                          '/' +
                          sector.id +
                          '/risk-score'
                        "
                        target="_blank"
                        >here.</a
                      >
                      <a
                        v-else
                        style="text-decoration: underline"
                        :href="
                          '/#/gbv-risk/' +
                          comapnyDetails[0].countryHq.iso +
                          '/' +
                          'country' +
                          '/risk-score'
                        "
                        target="_blank"
                        >here.</a
                      >
                    </b>
                    <b
                      v-else
                      style="
                        pointer-events: default;
                        font-weight: 600;
                        font-size: 15px;
                      "
                    >
                      <span class="gbv-country-tooltip"
                        >here.
                        <span class="gbv-country-tooltip-text">
                          Not enough data is available for this country to
                          calculate the score.
                        </span>
                      </span>
                    </b>
                  </p>
                </div>
                <div class="GbvRiskContent">
                  <div class="GbvRiskTitleWrepper">
                    <div class="GbvRiskTitle">
                      <img
                        class="mr-5 h-16 print:h-auto print:w-64"
                        :src="country.flagUrl"
                      />
                      <h1>GBV Risk in {{ country.humanName }}</h1>
                    </div>
                    <p>
                      Region:
                      {{
                        country.region == "Eastern Europe & Central Asia"
                          ? "Europe & Central Asia"
                          : country.region
                      }}
                      | Income Group:
                      {{ country.income | capitalizeFirstLetter }}
                      <span v-if="sector && sector.id"
                        >| Sector: {{ sector.name }}</span
                      >
                    </p>
                  </div>

                  <!-- <div class="step_gbvRisk">
                    <a-steps progress-dot :current="3" class="step_gbvRisk">
                      <a-step title="Home" style=" width: 80px !important;" />
                      <a-step title="Materiality" />
                      <a-step title="Risk models" />
                      <a-step title="Risk score" />
                      <a-step title="Heat map" />
                      <a-step title="Indicators" />
                      <a-step title="Take action" />
                      <a-step title="Methodology" />
                    </a-steps>
                  </div> -->
                  <!-- RISK SCORE -->
                  <transition name="fadeIn">
                    <div class="SevereRiskWrepper">
                      <h1 v-if="comapnyDetails[0].countryHq.active">
                        <div>
                          <img
                            v-if="risk_level == 'Extreme Risk'"
                            class="h-20"
                            src="../../assets/images/GBV-red.png"
                          />
                          <img
                            v-if="risk_level == 'Severe Risk'"
                            class="h-20"
                            src="../../assets/images/GBV-orange.png"
                          />
                          <img
                            v-if="risk_level == 'Significant Risk'"
                            class="h-20"
                            src="../../assets/images/GBV-light-orange.png"
                          />
                          <img
                            v-if="risk_level == 'Ubiquitous Risk'"
                            class="h-20"
                            src="../../assets/images/GBV-yellow.png"
                          />
                        </div>
                        <p>
                          <span
                            v-if="risk_level == 'Extreme Risk'"
                            style="color: #ed0c04; font-size: 30px"
                            class="risk-score"
                            >{{
                              Math.round(gbvrisk.selected_country.value)
                            }}</span
                          >
                          <span
                            v-if="risk_level == 'Severe Risk'"
                            style="color: #fe5000; font-size: 30px"
                            class="risk-score"
                            >{{
                              Math.round(gbvrisk.selected_country.value)
                            }}</span
                          >
                          <span
                            v-if="risk_level == 'Significant Risk'"
                            style="color: #eda804; font-size: 30px"
                            class="risk-score"
                            >{{
                              Math.round(gbvrisk.selected_country.value)
                            }}</span
                          >
                          <span
                            v-if="risk_level == 'Ubiquitous Risk'"
                            style="color: #fae053; font-size: 30px"
                            class="risk-score"
                            >{{
                              Math.round(gbvrisk.selected_country.value)
                            }}</span
                          >
                        </p>
                        <span>|</span>
                        <div>
                          <div
                            v-if="risk_level == 'Extreme Risk'"
                            style="color: #ed0c04"
                          >
                            Extreme Risk
                          </div>
                          <div
                            v-if="risk_level == 'Severe Risk'"
                            style="color: #fe5000"
                          >
                            Severe Risk
                          </div>
                          <div
                            v-if="risk_level == 'Significant Risk'"
                            style="color: #eda804"
                          >
                            Significant Risk
                          </div>
                          <div
                            v-if="risk_level == 'Ubiquitous Risk'"
                            style="color: #fae053"
                          >
                            Ubiquitous Risk
                          </div>
                        </div>
                      </h1>
                      <h1 v-else>
                        <div>
                          <p style="color: grey">Not enough data</p>
                        </div>
                      </h1>

                      <div class="UbiquitousTextIcon">
                        <img src="../../assets/images/GBV-yellow.png" />
                        <span>Ubiquitous Risk (&#60;20)</span>
                        <img src="../../assets/images/GBV-light-orange.png" />
                        <span>Significant Risk (20-39)</span>
                        <img src="../../assets/images/GBV-orange.png" />
                        <span>Severe Risk (40-59)</span>
                        <img src="../../assets/images/GBV-red.png" />
                        <span>Extreme Risk (60+)</span>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
        <div v-else-if="initialCompanyData.haveData" key="content_else_if">
          <div class="flex flex-row justify-left items-center mt-6 ml-20">
            <div class="flex flex-col item-center justify-left">
              <div
                class="flex flex-row items-center justify-left print:flex-col"
              >
                <h1
                  class="text-6xl tracking-tighter font-medium text-center print:text-7xl"
                >
                  Hi, {{ user.firstname }} {{ user.lastname }}
                </h1>
              </div>
              <div
                class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col"
              >
                Your survey calculation is in progress and your survey will be
                available in a few minutes.
              </div>
              <div
                class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
              >
                <a
                  href="/#/2X-Ecosystem/My-2X-Assessments"
                  @click="pageReload()"
                  class="text-white text-sm font-medium"
                  style="width: max-content"
                >
                  Refresh Page
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="content_else">
          <div class="flex flex-row justify-left items-center mt-6 ml-20">
            <div class="flex flex-col item-center justify-left">
              <div
                class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col"
              >
                Uh-oh! You have not given Equilo the data required to populate
                this assessment!.
              </div>
              <div
                class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
              >
                <a
                  href="/#/2X-Ecosystem/New-2XAssessment"
                  class="text-white text-sm font-medium mr-3"
                  style="width: max-content"
                >
                  Start your assessment now
                </a>
              </div>
              <div
                class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5"
              >
                You gave us the data but it seems missing?
              </div>
              <div
                class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
              >
                <a
                  href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                  class="text-white text-sm font-medium mr-3"
                  style="width: max-content"
                >
                  Contact support now
                </a>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- Modal -->
      <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isReadMore">
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ title }}
                </h3>
                <transition name="slide-fade">
                  <item
                    :text="readMoreInfo"
                    :country="title == 'Country Overview' ? country : sector"
                    style="font-size: 15px"
                  ></item>
                </transition>
              </div>
              <div class="cross_Icon" @click="closePopup()">
                <img src="../../assets/images/cros-icon.svg" alt="cross_Icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <!-- Modal Start -->
      <div id="consentModal" class="modal">
        <!-- Modal content -->
        <div class="modal-content" v-if="showData">
          <span class="close" @click="closeModal">&times;</span>
          <div style="margin: 8px 0">
            <h2 style="font-weight: 600">
              Send other co-investors a notification about this opportunity for
              {{ orgModalTitle }}
            </h2>
          </div>
          <label
            class="block text-sm font-medium leading-5 text-gray-700 w-full"
            v-show="selectedOrganizations.length > 0"
            >Co-investors you already sent this deal to:
          </label>
          <div class="flex flex-row">
            <div
              class="m-2 p-2 rounded-lg"
              v-for="item in consentsData"
              :key="item.matchOrgName + 1"
              v-show="item.isOrganizationSelected"
              style="background-color: gainsboro; display: block"
            >
              <span class="">{{ item.matchOrgName }}</span>
            </div>
          </div>
          <label
            class="block text-sm font-medium leading-5 text-gray-700 w-full"
          >
            Select other co-investors to notify about this opportunity</label
          >
          <select
            class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
            @click="organizationSelected()"
            v-model="selectedOrganization"
          >
            <option
              v-for="item in consentsData"
              :key="item.matchOrgName + 2"
              :value="item.otherMembership"
              :class="item.isOrganizationSelected ? 'selected-class' : ''"
            >
              <span>{{ item.matchOrgName }}</span>
            </option>
          </select>
          <div class="save-opportunity-button" v-if="user.admin">
            <button
              class="text-white text-sm font-medium"
              style="cursor: pointer"
              @click="savedOpportunity()"
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <!-- End Modal -->
      <!-- Request Admin Modal Start for Contextual Analysis-->
      <div
        class="fixed z-50 inset-0 overflow-y-auto"
        v-if="requestAdminModalForContextualAnalysis"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="closeRequestModal()" style="right: 8px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle" style="margin: 45px; padding: 0">
              <div class="ModalInnerStyle" style="padding: 0">
                <p style="font-weight: 600" class="">
                  Your organization do not have plan that allow this feature to
                  access. Email your account administrator to upgrade and
                  unlock.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="text-white px-4 py-4 text-medium gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-5"
                  @click="checkRequestPermission()"
                >
                  Email request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->
    </div>
    <div class="pdfexportbtn">
      <my2xassementPrint
        v-if="endofcreated && comapnyDetails"
        :key="updateKey"
        class="bridgeContainer"
        :userFromParent="user"
        :favouriteOrgDataFromParent="favouriteOrgData"
        :initialCompanyDataFromParent="initialCompanyData"
        :comapnyDetailsFromParent="comapnyDetails"
        :countDataFromParent="countData"
        :selectedCountryDetailsFromParent="selectedCountryDetails"
        :consentsDataFromParent="consentsData"
        :assessmentYearFromParent="assessmentYear"
        :countryFromParent="country"
        :sectorFromParent="sector"
        :amountFundedDataFromParent="amountFundedData"
        id="my2xaccessment"
      ></my2xassementPrint>
    </div>
  </div>
</template>

<script>
import auth from "@/helpers/auth";
import pageLoader from "@/components/page-loader.vue";
import spiderchart from "@/components/charts/my2xAssessmentSpiderChart.vue";
import repeatSpiderchart from "@/components/charts/my2xAssessmentRepeatSpiderChart.vue";
import barChartVertical from "@/components/charts/my2xAssessmentBarChart.vue";
import barChartVerticalFund from "@/components/charts/my2AssessmentBarChartFund.vue";
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import item from "@/components/text.vue";
import Vue from "vue";
import JsonCSV from "vue-json-csv";
import html2pdf from "html2pdf.js";
import twoxAssessPrivacyModal from "@/components/2xAssessPrivacyModal.vue";
import employmentBarChart from "@/components/charts/employmentBarChart.vue";
import my2xassementPrint from "@/components/my2xassesment-print.vue";

Vue.component("downloadCsv", JsonCSV);
export default {
  name: "my2xassement",
  data: function () {
    return {
      sectorAry: [
      {
          name: "Financial Services",
          label: "2X_FNCE",
        },
        {
          name: "Financial and Insurance Activities",
          label: "2X_FNCE",
        },
        {
          name: "Agribusiness & Food",
          label: "2X_AGFOOD",
        },
        {
          name: "Agriculture",
          label: "2X_AGFOOD",
        },
        {
          name: "Consumer Services",
          label: "2X_CONSUMER",
        },
        {
          name: "Education",
          label: "2X_EDUC",
        },
        {
          name: "Healthcare",
          label: "2X_HLTH",
        },
        {
          name: "Healthcare and Social work",
          label: "2X_HLTH",
        },
        {
          name: "Infrastructure",
          label: "2X_INFR",
        },
        {
          name: "Transportation and Storage Heavy",
          label: "2X_INFR",
        },
        {
          name: "Water and Waste Management", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Construction", // use heavy
          label: "2X_INFRHVY",
        },
        {
          name: "Manufacturing- Heavy",
          label: "2X_MANFHVY",
        },
        {
          name: "Manufacturing",
          label: "2X_MANFHVY",
        },
        {
          name: "Mining and Quarrying",
          label: "2X_MANFHVY",
        },
        {
          name: "Power",
          label: "2X_POWER",
        },
        {
          name: "Energy and Power",
          label: "2X_POWER",
        },
        {
          name: "Professional Services",
          label: "2X_PROF",
        },
        {
          name: "Professional, Scientific, and Technical activities",
          label: "2X_PROF",
        },
        {
          name: "Telecoms",
          label: "2X_TELECOMS",
        },
        {
          name: "Manufacturing- Light",
          label: "2X_MANFLT",
        },
        {
          name: "Wholesale and Retail Trade",
          label: "2X_MANFLT",
        },
      ],
      dynamicGesiURL: "",
      dynamicGbvURL: "",
      json_data: [],
      assessmentYear: [],
      sharedOrgRoute: false,
      invitedUserEmail: "",
      indicatorBlurb: [],
      comapnyDetails: [],
      active: false,
      endofcreated: false,
      country: {
        income: "",
      },
      theme: {},
      gbvrisk: {},
      sector: { name: "" },
      env,
      current_step: 1,
      risk_level: "",
      openPanels: [],
      taabs: {
        Entrepreneurship: env.taabColors.humanDignity,
        Leadership: env.taabColors.knowledgeAndBeliefs,
        Employment: env.taabColors.accessToResources,
        Consumption: env.taabColors.powerAndDecision,
        "Indirect Investment": env.taabColors.indirectInvestement,
      },
      isReadMore: false,
      readMoreInfo: null,
      title: null,
      favouriteOrgData: [],
      user: [],
      consentsData: [],
      dealConsentData: [],
      selectedOrganization: "",
      showData: false,
      orgModalTitle: "",
      amountFundedData: [],
      fundedFundingAmount: 0,
      consideringFundingAmount: 0,
      committedFundingAmount: 0,
      fundingAmountValue: [],
      enteredFundedAmount: "",
      selectedFundType: "Considering",
      defaultFundTypes: ["Considering", "Committed", "Funded"],
      selectedCountryDetails: [],
      fixedAssessmentsAPIBody: [],
      selectedOrganizations: [],
      downloadPDF: false,
      downloadCSV: false,
      initialCompanyData: [],
      countData: [],
      countOf2xAssess: 0,
      options1: {
        text: {
          color: "#2BD4C4",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      options2: {
        text: {
          color: "#FE5000",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FE5000",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      options3: {
        text: {
          color: "#FE5000",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 12,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2BD4C4",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 40,
          verticalTextAlign: 20,
          horizontalTextAlign: 0,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 2,
          type: "circle",
        },
      },
      criteriaValue: "",
      leadership: -1,
      employment: -1,
      entrepreneurship: -1,
      consumption: -1,
      twoxEligible: -1,
      indirectInvestment: -1,
      updateKey: 0,
      requestAdminModalForContextualAnalysis: false,
      emailBody: {},
    };
  },
  updated() {
    if (this.enteredFundedAmount < 0) {
      this.enteredFundedAmount = 0;
    }
  },
  components: {
    pageLoader,
    spiderchart,
    repeatSpiderchart,
    barChartVertical,
    barChartVerticalFund,
    item,
    twoxAssessPrivacyModal,
    employmentBarChart,
    my2xassementPrint,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    numberWithCommas(x) {
      var numFormat = new Intl.NumberFormat("en-US");
      return numFormat.format(x);
    },
  },
  async created() {
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    if (this.user.twoXPrivacyPolicy) {
      let assessmentsAPIBody = {
        email: this.user.email,
      };
      if (this.$route.query.id) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = {
          isShared: true,
          userId: this.$route.query.id,
        };
      }
      if (this.$route.query.email) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = {
          email: this.$route.query.email,
        };
      }
      if (this.$route.query.dealId) {
        assessmentsAPIBody.dealId = this.$route.query.dealId;
      }

      if (this.$route.query.recordId) {
        assessmentsAPIBody.recordId = this.$route.query.recordId;
        assessmentsAPIBody.tableName = this.$route.query.type;
      }

      this.fixedAssessmentsAPIBody = assessmentsAPIBody;
      this.favouriteOrgData = await this.getFavouriteData();
      let companyData = await this.getCompanyDetails(assessmentsAPIBody);
      this.initialCompanyData = companyData;
      this.comapnyDetails = companyData.assesments;
      this.countData = companyData.countData;
      this.selectedCountryDetails = companyData.countries;
      if (this.initialCompanyData && this.initialCompanyData.assesments) {
        this.assessmentSavedOrNot();
      }
      this.consentsData = await this.getConsentsData();
      this.consentsData.map((data) => {
        data["isOrganizationSelected"] = false;
      });
      if (this.comapnyDetails && this.comapnyDetails.length > 0) {
        // this.amountFundedData = await this.getAmountFundedData();
        this.filterAmountFundedData();
      }

      if (this.comapnyDetails && this.comapnyDetails.length > 0) {
        this.dynamicGesiURL = env.domainRoute + "/#/gesi-analysis";
        this.dynamicGbvURL =
          env.domainRoute +
          "/#/reports/" +
          this.comapnyDetails[0].countryHq.iso +
          "/country/gbv-risk/risk-score";

        this.assessmentYear.push(
          new Date(this.comapnyDetails[0].createdTime).getFullYear() +
            " / " +
            new Date(this.comapnyDetails[0].createdTime).toLocaleString(
              "en-US",
              { month: "short" }
            )
        );
        if (this.comapnyDetails[1]) {
          this.assessmentYear.push(
            new Date(this.comapnyDetails[1].createdTime).getFullYear() +
              " / " +
              new Date(this.comapnyDetails[1].createdTime).toLocaleString(
                "en-US",
                { month: "short" }
              )
          );
        }
        if (
          this.comapnyDetails &&
          this.comapnyDetails[0].countryHq &&
          this.comapnyDetails[0].countryHq.iso &&
          this.comapnyDetails[0].countryHq.active == "false"
        ) {
          this.country = this.comapnyDetails[0].countryHq;
        } else {
          this.country = await this.getCountry(
            this.comapnyDetails[0].countryHq.iso
          );
        }
        if (
          this.comapnyDetails &&
          this.comapnyDetails[0].countryHq &&
          this.comapnyDetails[0].countryHq.active == "true"
        ) {
          this.gbvrisk = await this.getGBVRisk(
            this.comapnyDetails[0].countryHq.iso
          );
        }

        let sectorValue = "Other";
        let sectorData = this.sectorAry.find(
          (sector) => sector.name == this.comapnyDetails[0].sector
        );
        if (sectorData) {
          sectorValue = sectorData.label;
        }

        if (this.comapnyDetails[0].type == "funds") {
          sectorValue = "2X_FNCE";
        }

        this.sector = await this.getSector(sectorValue);

        this.country.values = { ...this.country.values, ...this.sector.values };

        // RISK CATEGORIES
        if (
          this.comapnyDetails &&
          this.comapnyDetails[0].countryHq &&
          this.comapnyDetails[0].countryHq.active == "true"
        ) {
          if (Math.round(this.gbvrisk.selected_country.value) < 20) {
            this.risk_level = "Ubiquitous Risk";
          } else if (
            (Math.round(this.gbvrisk.selected_country.value) >= 20) &
            (Math.round(this.gbvrisk.selected_country.value) < 40)
          ) {
            this.risk_level = "Significant Risk";
          } else if (
            (Math.round(this.gbvrisk.selected_country.value) >= 40) &
            (Math.round(this.gbvrisk.selected_country.value) < 60)
          ) {
            this.risk_level = "Severe Risk";
          } else if (Math.round(this.gbvrisk.selected_country.value) >= 60) {
            this.risk_level = "Extreme Risk";
          }
        }

        this.$store.commit("resetCitations");
        this.$store.commit("downloadable", false);
        this.$store.commit("isFreeState", true);
        this.$store.commit("setBreadcrumbs", [
          {
            name: this.sharedOrgRoute
              ? this.comapnyDetails[0].organizationName
              : "My 2X Assessments",
            url: "/2X-Ecosystem/My-2X-Assessments",
          },
        ]);
      }
      this.endofcreated = true;
      // this.favouriteOrgData = await this.getFavouriteData();
      if (this.initialCompanyData && this.initialCompanyData.assesments) {
        this.assessmentSavedOrNot();
      }
    }

    if (localStorage.getItem("2XAssessUrl")) {
      localStorage.removeItem("2XAssessUrl");
    }
    this.criteriaCalc();

    //this.getMatchingCountofInvestor()
  },
  methods: {
    checkRequestPermission() {
      axios
        .post(
          env.apiUrl + "/assesment2x/gesi-contextual-request-to-admin",
          this.emailBody,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeRequestModal() {
      this.requestAdminModalForContextualAnalysis = false;
    },
    async criteriaCalc() {
      let companyDetail = await this.comapnyDetails[0];

      if (companyDetail) {
        if (companyDetail.type == "funds") {
          this.indirectInvestment = Math.round(
            Number(companyDetail.indirectInvestmentPercentage)
          );
        }

        this.leadership = companyDetail.leadershipPercentage;

        this.employment = companyDetail.employmentPercentage;

        this.entrepreneurship = companyDetail.entrepreneurshipPercentage;

        this.consumption = companyDetail.consumptionPercentage;
        this.twoxEligible = companyDetail.twoXeligibilityPercentage;
      }
    },
    pageReload() {
      window.location.reload();
    },
    filterCSVData(result) {
      var csvData = result.data;
      var assessYear = [];

      let sectore_invested_in = "";
      let notModifyZeroArray = [];

      csvData.map((item) => {
        if (item[0] == "Contact Phone") {
          item.filter((i, index) => {
            i = String(i[index]);
          });
        }
        if (item[0] == "Country HQ") {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = "";
              item[index] = String(
                this.comapnyDetails[0].countryHq.countryName
              );
            }
          });
        }
        if (item[0] == "Countries where Investing") {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = String(
                this.comapnyDetails[0].countryHq.countryName
              );
            }
          });
        }
        if (item[0] == "Amount of current funding desired") {
          item.map((i, index) => {
            if (
              index > 0 &&
              item[index] != "" &&
              item[index] != null &&
              item[index] != "Unknown"
            ) {
              item[index] = String("$" + item[index]);
            }
          });
        }
        if (item[0] == "Total amount of current or past 2X qualified funds") {
          item.map((i, index) => {
            if (
              index > 0 &&
              item[index] != "" &&
              item[index] != null &&
              item[index] != "Unknown"
            ) {
              item[index] = String("$" + item[index]);
            }
          });
        }
        if (
          item[0] == "Average ticket size for investors" &&
          this.comapnyDetails[0].averageTicketSizeForInvestors
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              item[index] != "" &&
              item[index] != null &&
              item[index] != "Unknown"
            ) {
              item[index] = String("$" + item[index]);
            }
          });
        }
        if (item[0] == "2X Intentions" && (item[1] == 1 || item[1] == 0)) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (item[0] == "Submission Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (
          item[0] == "#2X Criteria Met" &&
          this.comapnyDetails[0].criteriaMet2X
        ) {
          item.map((i, index) => {
            if (index > 0 && this.comapnyDetails[index - 1]) {
              item[index] = this.comapnyDetails[index - 1].criteriaMet2X;
            }
          });
        }
        if (item[0] == "% 2X Criteria Met") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = Math.round(item[index]) + "%";
            }
          });
        }

        if (item[0] == "2X eligible") {
          item.map((i, index) => {
            if (index > 0) {
              if (
                this.comapnyDetails[0].type == "funds" &&
                this.comapnyDetails[0].myPortfolio !=
                  "I will directly input aggregated information now" &&
                !this.comapnyDetails[0].isPortfolioAvailable
              )
                item[index] = "Unknown";
            }
          });
        }

        if (
          item[0] == "Leadership Criteria Met" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (item[0] == "% of women senior managers") {
          notModifyZeroArray.push(item[0]);

          item.map((i, index) => {
            if (index > 0) {
              if (item[index] != "" && item[index] != null) {
                item[index] = Math.round(item[index]) + "%";
              } else if (index > 0 && item[index] == null) {
                item[index] = "Not Applicable";
              }
            }
          });
        }
        if (item[0] == "% women on board/ IC") {
          notModifyZeroArray.push(item[0]);

          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              if (item[index] != "" && item[index] != null) {
                item[index] = Math.round(item[index] * 100) + "%";
              } else if (index > 0 && item[index] == null) {
                item[index] = "Not Applicable";
              }
            }
          });
        }
        if (item[0] == "Target: Women Senior Managers") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }
        if (item[0] == "Women Senior Managers Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (item[0] == "Target: Women on Board") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }
        if (item[0] == "Women on Board Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (item[0] == "Target: Female Ownership Shares") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }
        if (item[0] == "Female Shares Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (
          item[0] == "Employment Criteria Met" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] !== "" && item[index] !== null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (item[0] == "Percent shares") {
          item.map((i, index) => {
            if (index > 0) {
              if (item[index] !== "" && item[index] !== null) {
                item[index] = Math.round(item[index]) + "%";
              }
            }
          });
        }
        if (item[0] == "Carry allocation") {
          item.map((i, index) => {
            if (index > 0) {
              if (
                item[index] !== "" &&
                item[index] !== null &&
                !item[index].toString().includes("%")
              ) {
                item[index] = Math.round(item[index]) + "%";
              }
            }
          });
        }
        if (item[0] == "% women in overall workforce") {
          notModifyZeroArray.push(item[0]);
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = Math.round(item[index]) + "%";
            } else if (index > 0 && item[index] == null) {
              item[index] = "Not Applicable";
            }
          });
        }
        if (
          item[0] ==
          "% of future committed loan proceeds or funds to support businesses that meet 2X criteria"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = Math.round(item[index]) + "%";
            }
          });
        }

        if (item[0] == "Target: Women direct Part Time / Full Time employees") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }

        if (
          item[0] ==
          "% FI loan proceeds or investment funds that meet 2X Criteria"
        ) {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = Math.round(item[index] * 100) + "%";
            }
          });
        }

        if (
          item[0] ==
          "What is the total current value of FI loan or proceeds or fund portfolio?"
        ) {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = "$" + Math.round(item[index]);
            }
          });
        }
        if (
          item[0] ==
          "What is the total current value of FI loan proceeds or fund portfolio that support businesses that meet direct 2X criteria?"
        ) {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = "$" + Math.round(item[index]);
            }
          });
        }

        if (
          item[0] ==
          "Target: Companies in Portfolio that Meet Direct Loan Criteria"
        ) {
          item.map((i, index) => {
            if (index > 0) {
              item[index] = Math.round(item[index] * 100) + "%";
            }
          });
        }

        if (item[0] == "Women direct employee Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (item[0] == "Planned Products Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (item[0] == "Indirect Investment Criteria Met") {
          if (item[1] == 1 || item[1] == 0) {
            item.map((i, index) => {
              if (index > 0 && item[index] != "" && item[index] != null) {
                item[index] = item[index] == 1 ? "Yes" : "No";
              }
            });
          } else {
            item.map((i, index) => {
              if (index > 0) {
                if (
                  this.comapnyDetails[0].type == "funds" &&
                  this.comapnyDetails[0].myPortfolio !=
                    "I will directly input aggregated information now" &&
                  !this.comapnyDetails[0].isPortfolioAvailable
                ) {
                  item[index] = "Unknown";
                }
              }
            });
          }
        }
        if (
          item[0] == "Entrepreneurship Criteria Met" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (item[0] == "Planned Policies and Programs Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (
          item[0] == "Consumption Criteria Met" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (
          item[0] ==
            "How many women third party workers are engaged to perform work related to core business processes for a substantial duration (e.g. a season in the case of an agribusiness)?" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }
        if (
          item[0] ==
            "How many different organizations have provided you with debt or equity investment currently or in the past?" &&
          (item[1] == 1 || item[1] == 0)
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index] == 1 ? "Yes" : "No";
            }
          });
        }

        if (
          item[0] ==
          "What policies or programs, beyond those required for compliance, address barriers to women's quality employment at the organization?"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              if (item[index].search("Other") > -1) {
                let ifOtherWhatIsIt =
                  this.comapnyDetails[index - 1]?.ifOtherWhatIsIt;
                item[index] = item[index].replace("Other", ifOtherWhatIsIt);
              }
              //if none
              item[index] = item[index].replace(",None", "");
              item[index] = item[index].replace("None", "");
            }
          });
        }

        if (
          item[0] ==
            "How does the organization's product or service specifically or disproportionately benefit women? (FIs)" ||
          item[0] ==
            "How does the organization's product or service specifically or disproportionately benefit women? (Funds)"
        ) {
          item.map((i, index) => {
            if (index > 0 && this.comapnyDetails[index]) {
              item[index] = item[index].replace("None of the above", "");
            }
          });
        }

        if (
          item[0] ==
          "How does the organization's product or service specifically or disproportionately benefit women?"
        ) {
          item.map((i, index) => {
            if (index > 0 && this.comapnyDetails[index]) {
              item[index] = item[index].replace("None of the above", "");
              item[index] = item[index].replace("Other (please specify)", "");
            }
          });
        }

        if (item[0] == "Target: Planned Policies and Programs") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              if (item[index].search("Other") > -1) {
                let plannedProgramsOther =
                  this.comapnyDetails[index - 1]?.plannedProgramsOther;
                item[index] = item[index].replace(
                  "Other",
                  plannedProgramsOther
                );
              }
              //if none
              item[index] = item[index].replace(",None", "");
              item[index] = item[index].replace("None", "");
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }

        if (
          item[0] == "Target: Planned Product or service that benefits women"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              if (item[index].search("Other (please specify)") > -1) {
                item[index] = item[index].replace("Other (please specify)", "");
              }

              //if none
              item[index] = item[index].replace("None of the above", "");
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }

        if (item[0] == "Investee 2X Criteria Target Date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (
          item[0] ==
          "Was the organization founded by a woman or a group of women that retain(s) an active role in the organization?"
        ) {
          item.map((i, index) => {
            if (index > 0 && this.comapnyDetails[index]) {
              item[index] = String(this.comapnyDetails[index].foundedByAWoman);
            }
          });
        }
        if (item[0] == "Supply chain women workers target date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }

        if (
          item[0] == "Target: Women PT/FT workers at companies in supply chain"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }

        if (
          item[0] ==
          "Target: Women Part Time / Full Time workers at companies in supply chain"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }
        if (
          item[0] ==
            "Budgeted or are looking for resources to implement a gender plan/strategy?" ||
          item[0] ==
            "Have you budgeted or are looking for resources to implement a gender plan/strategy?"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] == "") {
              item[index] = "Unknown";
            }
          });
        }

        if (
          item[0] ==
            "Do you have time-bound, measurable gender goals and targets in the company strategy?" ||
          item[0] ==
            "Time-bound, measurable gender equality goals and targets in the company strategy?"
        ) {
          item.map((i, index) => {
            if (index > 0 && item[index] == "") {
              item[index] = "Unknown";
            }
          });
        }

        if (item[0] == "3rd party women workers target date") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] = item[index].split(",")[0];
            }
          });
        }
        if (item[0] == "Target: Women 3rd party workers") {
          item.map((i, index) => {
            if (index > 0 && item[index] != "" && item[index] != null) {
              item[index] =
                item[index] * 100 == 0
                  ? "Unknown"
                  : Math.round(item[index] * 100) + "%";
            } else if (index > 0) {
              item[index] = "Unknown";
            }
          });
        }
        if (item[0] == "E&S Claims" && this.comapnyDetails[0].eSClaims) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].eSClaims
            ) {
              item[index] = String(this.comapnyDetails[index].eSClaims);
            }
          });
        }
        if (
          item[0] == "E&S Claim Description" &&
          this.comapnyDetails[0].eSClaims
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].eSClaims
            ) {
              item[index] = String(this.comapnyDetails[index].eSClaims);
            }
          });
        }
        if (
          item[0] == "Gendered Approach to COVID-19" &&
          this.comapnyDetails[0].genderedApproachToCovid19
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].genderedApproachToCovid19
            ) {
              item[index] = String(
                this.comapnyDetails[index].genderedApproachToCovid19
              );
            }
          });
        }
        if (
          item[0] == "COVID-19 Gender Approach" &&
          this.comapnyDetails[0].covid19GenderApproachDescription
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].covid19GenderApproachDescription
            ) {
              item[index] = String(
                this.comapnyDetails[index].covid19GenderApproachDescription
              );
            }
          });
        }
        if (
          item[0] == "Gender in Procurement" &&
          this.comapnyDetails[0].genderInProcurementStrategy
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].genderInProcurementStrategy
            ) {
              item[index] = String(
                this.comapnyDetails[index].genderInProcurementStrategy
              );
            }
          });
        }
        if (
          item[0] == "Procurement description" &&
          this.comapnyDetails[0].procurementDescription
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].procurementDescription
            ) {
              item[index] = String(
                this.comapnyDetails[index].procurementDescription
              );
            }
          });
        }
        if (
          item[0] == "Value chain strategies that promote gender equality" &&
          this.comapnyDetails[0].valueChainStrategiesThatPromoteEquality
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].valueChainStrategiesThatPromoteEquality
            ) {
              item[index] = String(
                this.comapnyDetails[index]
                  .valueChainStrategiesThatPromoteEquality
              );
            }
          });
        }
        if (
          item[0] == "Value Chain Strategies Description" &&
          this.comapnyDetails[0].valueChainStrategiesDescription
        ) {
          item.map((i, index) => {
            if (
              index > 0 &&
              this.comapnyDetails[index] &&
              this.comapnyDetails[index].valueChainStrategiesDescription
            ) {
              item[index] = String(
                this.comapnyDetails[index].valueChainStrategiesDescription
              );
            }
          });
        }
        if (
          item[0] == "Countries of Operations" &&
          this.comapnyDetails[0].countryofOperations &&
          this.comapnyDetails[0].type == "companies"
        ) {
          item[0] = "";
          item[0] = "Countries of Operation";
          item.map((i, index) => {
            let arr = [];
            if (index > 0 && this.comapnyDetails[index]) {
              this.comapnyDetails[index].countryofOperations.forEach((data) => {
                arr.push(data.countryName);
                item[index] = arr.join(", ");
              });
            }
          });
        }
        if (
          item[0] == "Countries where Investing" &&
          this.comapnyDetails[0].countriesWhereInvesting &&
          this.comapnyDetails[0].type == "funds"
        ) {
          item[0] = "";
          item[0] = "Countries where Investing";
          item.map((i, index) => {
            let arr = [];
            if (index > 0 && this.comapnyDetails[index]) {
              this.comapnyDetails[index].countriesWhereInvesting.forEach(
                (data) => {
                  arr.push(data.countryName);
                  item[index] = arr.join(", ");
                }
              );
            }
          });
        }
        if (
          item[0] == "Countries of Operations" &&
          this.comapnyDetails[0].countriesWhereInvesting &&
          this.comapnyDetails[0].type == "funds"
        ) {
          item[0] = "";
          item[0] = "Countries where Investing";
          item.map((i, index) => {
            let arr = [];
            if (index > 0 && this.comapnyDetails[index]) {
              this.comapnyDetails[index].countriesWhereInvesting.forEach(
                (data) => {
                  arr.push(data.countryName);
                  item[index] = arr.join(", ");
                }
              );
            }
          });
        }

        if (
          item[0] == "Sector Focus" &&
          this.comapnyDetails[0].type == "funds"
        ) {
          sectore_invested_in = item[1];

          if (sectore_invested_in.search("Other") > -1) {
            let otherSector = this.comapnyDetails[0]?.otherSector;

            sectore_invested_in = item[1].replace("Other", otherSector);
          }

          item[0] = "Organization Sector";
          item[1] = "Financial Services";
        }

        if (
          item[0] == "Sectors invested in" &&
          this.comapnyDetails[0].type == "funds"
        ) {
          item[1] = "Financial Services";
        }

        if (item[0].startsWith("%")) {
          item.map((i, index) => {
            if (index > 0 && i == 0) {
              if (item[index] != "0%") {
                item[index] = item[index] + "%";
              }
            }
          });
          item.map((i, index) => {
            if (index > 0 && i == "%") {
              item[index] = "";
            }
          });
        }

        if (item[0].startsWith("%")) {
          if (notModifyZeroArray.includes(item[0])) {
            item.map((i, index) => {
              if (
                index > 0 &&
                (item[index] == "NaN%" || item[index] == "null%")
              ) {
                item[index] = "Not Applicable";
              }
            });
          } else {
            item.map((i, index) => {
              if (
                index > 0 &&
                (item[index] == "0%" ||
                  item[index] == "NaN%" ||
                  item[index] == "null%")
              ) {
                item[index] = "Not Applicable";
              }
            });
          }
        }
      });

      if (this.comapnyDetails[0].type == "funds") {
        csvData.push(["Sectors invested in", sectore_invested_in]);
      }

      csvData.splice(0, 0, assessYear);
      if (csvData) {
        csvData.forEach((data) => {
          this.json_data.push(data);
        });
      }
    },
    showOtherCountryData(cont) {
      this.updateKey++;
      this.endofcreated = false;
      this.fixedAssessmentsAPIBody.countryIso = cont.iso;
      if (this.$route.query.dealId) {
        this.fixedAssessmentsAPIBody.dealId = this.$route.query.dealId;
      }

      if (this.$route.query.recordId) {
        this.fixedAssessmentsAPIBody.recordId = this.$route.query.recordId;
        this.fixedAssessmentsAPIBody.tableName = this.$route.query.type;
      }
      this.$emit("showOtherCountryDataForPDF", this.fixedAssessmentsAPIBody);
      return axios
        .post(
          env.apiUrl + "/assesment2x/all-assesment",
          this.fixedAssessmentsAPIBody,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.favouriteOrgData = await this.getFavouriteData();
          let companyData = res.data.data;
          this.comapnyDetails = companyData.assesments;
          this.selectedCountryDetails = companyData.countries;
          this.assessmentSavedOrNot();
          this.consentsData = await this.getConsentsData();
          this.consentsData.map((data) => {
            data["isOrganizationSelected"] = false;
          });
          // this.amountFundedData = await this.getAmountFundedData();
          this.filterAmountFundedData();

          if (this.comapnyDetails && this.comapnyDetails.length > 0) {
            this.dynamicGesiURL = env.domainRoute + "/#/gesi-analysis";
            this.dynamicGbvURL =
              env.domainRoute +
              "/#/reports/" +
              this.comapnyDetails[0].countryHq.iso +
              "/country/gbv-risk/risk-score";

            // let CSVData = await this.getDownloadCSV();
            // this.json_data = [];
            // if (CSVData && CSVData.data.length) {
            //   this.filterCSVData(CSVData)
            // }
            this.assessmentYear = [];
            this.assessmentYear.push(
              new Date(this.comapnyDetails[0].createdTime).getFullYear() +
                " / " +
                new Date(this.comapnyDetails[0].createdTime).toLocaleString(
                  "en-US",
                  { month: "short" }
                )
            );
            if (this.comapnyDetails[1]) {
              this.assessmentYear.push(
                new Date(this.comapnyDetails[1].createdTime).getFullYear() +
                  " / " +
                  new Date(this.comapnyDetails[1].createdTime).toLocaleString(
                    "en-US",
                    { month: "short" }
                  )
              );
            }
            if (
              this.comapnyDetails &&
              this.comapnyDetails[0].countryHq &&
              this.comapnyDetails[0].countryHq.iso &&
              this.comapnyDetails[0].countryHq.active == "false"
            ) {
              this.country = this.comapnyDetails[0].countryHq;
            } else {
              this.country = await this.getCountry(
                this.comapnyDetails[0].countryHq.iso
              );
            }
            if (
              this.comapnyDetails &&
              this.comapnyDetails[0].countryHq &&
              this.comapnyDetails[0].countryHq.active == "true"
            ) {
              this.gbvrisk = await this.getGBVRisk(
                this.comapnyDetails[0].countryHq.iso
              );
            }

            let sectorValue = "Other";
            let sectorData = this.sectorAry.find(
              (sector) => sector.name === this.comapnyDetails[0].sector
            );
            if (sectorData) {
              sectorValue = sectorData.label;
            }

            if (this.comapnyDetails[0].type == "funds") {
              sectorValue = "2X_FNCE";
            }

            this.sector = await this.getSector(sectorValue);
            this.country.values = {
              ...this.country.values,
              ...this.sector.values,
            };

            // RISK CATEGORIES
            if (
              this.comapnyDetails &&
              this.comapnyDetails[0].countryHq &&
              this.comapnyDetails[0].countryHq.active == "true"
            ) {
              if (Math.round(this.gbvrisk.selected_country.value) < 20) {
                this.risk_level = "Ubiquitous Risk";
              } else if (
                (Math.round(this.gbvrisk.selected_country.value) >= 20) &
                (Math.round(this.gbvrisk.selected_country.value) < 40)
              ) {
                this.risk_level = "Significant Risk";
              } else if (
                (Math.round(this.gbvrisk.selected_country.value) >= 40) &
                (Math.round(this.gbvrisk.selected_country.value) < 60)
              ) {
                this.risk_level = "Severe Risk";
              } else if (
                Math.round(this.gbvrisk.selected_country.value) >= 60
              ) {
                this.risk_level = "Extreme Risk";
              }
            }

            this.$store.commit("resetCitations");
            this.$store.commit("downloadable", false);
            this.$store.commit("isFreeState", true);
          }
          this.json_data = [];
          this.endofcreated = true;
          // this.favouriteOrgData = await this.getFavouriteData();
          this.assessmentSavedOrNot();
          this.criteriaCalc();
        });
    },
    viewProfile(data) {
      let routeData = this.$router.resolve({
        name: "TwoXCMembership",
        params: {
          investorOrg: data._id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    filterAmountFundedData() {
      this.fundingAmountValue = [];
      this.fundedFundingAmount = 0;
      this.consideringFundingAmount = 0;
      this.committedFundingAmount = 0;
      this.amountFundedData.forEach((data) => {
        data.fundingData.forEach((item) => {
          if (data.fundtype === "Funded") {
            this.fundedFundingAmount =
              this.fundedFundingAmount + item.fundingAmount;
          }
          if (data.fundtype === "Considering") {
            this.consideringFundingAmount =
              this.consideringFundingAmount + item.fundingAmount;
          }
          if (data.fundtype === "Committed") {
            this.committedFundingAmount =
              this.committedFundingAmount + item.fundingAmount;
          }
        });
      });
      this.fundingAmountValue.push(
        Math.floor(this.fundedFundingAmount),
        Math.floor(this.committedFundingAmount),
        Math.floor(this.consideringFundingAmount),
        Math.floor(this.comapnyDetails[0].amountOfCurrentFundingDesired)
      );
    },
    saveFundedData() {
      return axios
        .post(
          env.apiUrl + "/assesment2x/update-deal-transaction",
          {
            dealId: this.comapnyDetails[0].dealData.recordId,
            fundingAmount: this.enteredFundedAmount,
            fundingStatus: this.selectedFundType,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          if (res) {
            this.amountFundedData = [];
            // this.amountFundedData = await this.getAmountFundedData();
            this.filterAmountFundedData();
            this.closeModal();
            this.enteredFundedAmount = "";
            if (res) {
              this.$swal.fire({
                icon: "success",
                text: "Successfully Saved!",
                showConfirmButton: true,
              });
            } else {
              this.$swal.fire({
                icon: "error",
                text: "Please try again!",
                showConfirmButton: true,
              });
            }
          }
        });
    },
    getAmountFundedData() {
      let recordID = this.comapnyDetails[0].recordId;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-deal-transaction", {
            params: {
              recordId: recordID,
            },
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getConsentsData() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-investors-consents-list", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    async closeModal() {
      var modal = document.getElementById("consentModal");
      modal.style.display = "none";
      this.dealConsentData = [];
      this.consentsData = await this.getConsentsData();
      this.consentsData.map((data) => {
        data["isOrganizationSelected"] = false;
      });
      this.showData = false;
      this.orgModalTitle = "";
      this.enteredFundedAmount = "";
    },
    async clickedArrowButton() {
      var modal = document.getElementById("consentModal");
      modal.style.display = "block";
      this.orgModalTitle = this.comapnyDetails[0].organizationName;
      return axios
        .get(env.apiUrl + "/assesment2x/get-investors-consents-list", {
          params: {
            dealId: this.$route.query.dealId,
          },
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.showData = true;
          this.dealConsentData = res.data.data;
          this.selectedOrganizations = [];
          if (this.dealConsentData && this.dealConsentData.co_investors) {
            this.consentsData.map((data) => {
              this.dealConsentData.co_investors.forEach((item) => {
                if (data.otherMembership == item) {
                  this.selectedOrganizations.push(data.otherMembership);
                  data.isOrganizationSelected = true;
                }
              });
            });
          }
        });
    },
    savedOpportunity() {
      let opportunityData = [];
      opportunityData = [
        {
          dealId: this.$route.query.dealId,
          coinvestorsIds: [],
        },
      ];
      var coInvestorData = [];
      var removeCoInvestorData = [];
      this.consentsData.forEach((item) => {
        if (item.isOrganizationSelected == true) {
          coInvestorData.push(item.otherMembership);
        }
        if (item.isOrganizationSelected == false) {
          if (
            this.dealConsentData &&
            this.dealConsentData.co_investors &&
            this.dealConsentData.co_investors.length > 0
          ) {
            this.dealConsentData.co_investors.forEach((d) => {
              if (d == item.otherMembership) {
                removeCoInvestorData.push(item.otherMembership);
              }
            });
          }
        }
      });
      opportunityData[0].coinvestorsIds = coInvestorData;
      if (removeCoInvestorData && removeCoInvestorData.length > 0) {
        opportunityData[0]["removecoinvestorsIds"] = [];
        opportunityData[0]["removecoinvestorsIds"] = removeCoInvestorData;
      }

      return axios
        .post(
          env.apiUrl + "/assesment2x/send-opportunity-investors",
          opportunityData[0],
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          if (res) {
            this.closeModal();
            if (res) {
              this.$swal.fire({
                icon: "success",
                text: "Your notification has been sent!",
                showConfirmButton: true,
              });
            } else {
              this.$swal.fire({
                icon: "error",
                text: "Please try again!",
                showConfirmButton: true,
              });
            }
          }
        });
    },
    organizationSelected() {
      this.consentsData.map((data) => {
        if (data.otherMembership == this.selectedOrganization) {
          data.isOrganizationSelected == true
            ? (data.isOrganizationSelected = false)
            : (data.isOrganizationSelected = true);
        }
      });
      this.selectedOrganization = "";
    },
    goToExploreContext() {
      if (this.user.organization.accessTwoXGESIContextLight) {
        let context_sector = this.comapnyDetails[0].sector;

        let query = {
          recordId: this.$route.query.recordId,
          countryId: Math.round(this.comapnyDetails[0].countryHq.country_id),
        };

        if (this.$route.query.type) {
          query.type = this.$route.query.type;
        }

        if (this.comapnyDetails[0].type == "funds") {
          context_sector = "Financial Services";
          query.sector = context_sector;
        } else if (context_sector == this.comapnyDetails[0].otherSector) {
          //if other selected
          context_sector = "Other";
          query.sector = context_sector;
        } else {
          query.sector = context_sector;
        }

        let routeData = this.$router.resolve({
          path: "/2X-Ecosystem/My-2X-Assessments/contextual-analysis",
          query: query,
        });
        window.open(routeData.href, "_blank");
      } else {
        this.checkUserIsAdminOrNot("2X Assess GESI Context Light");
      }
    },
    checkUserIsAdminOrNot(paramName) {
      if (this.user.admin) {
        if (
          this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectforcustomerportal();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus != "Subscribed"
        )
          this.redirecttoupgrade();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectToEquilo();
      } else {
        this.emailBody = {
          productName: paramName,
          product: paramName,
        };
        this.requestAdminModalForContextualAnalysis = true;
      }
    },

    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    redirectforcustomerportal() {
      axios
        .post(
          env.apiUrl + "/payments/create-customer-portal-session",
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          window.location.href = res.data.data.url;
        });
    },
    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },
    getFavouriteData() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/get-save-assesments", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    assessmentSavedOrNot() {
      // const _self = this;
      this.favouriteOrgData.map((data) => {
        if (
          this.comapnyDetails[0] &&
          data.assesments._id == this.comapnyDetails[0]._id
        ) {
          this.comapnyDetails[0]["isAssessmentSavedOrNot"] = true;
        }
      });
    },
    async savedFavorite() {
      await this.assessmentSaved();

      let assessmentsAPIBody = {
        email: this.user.email,
      };

      if (this.$route.query.id) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = {
          isShared: true,
          userId: this.$route.query.id,
        };
      }
      if (this.$route.query.email) {
        this.sharedOrgRoute = true;
        assessmentsAPIBody = {
          email: this.$route.query.email,
        };
      }

      if (this.$route.query.dealId) {
        assessmentsAPIBody.dealId = this.$route.query.dealId;
      }

      if (this.$route.query.recordId) {
        assessmentsAPIBody.recordId = this.$route.query.recordId;
        assessmentsAPIBody.tableName = this.$route.query.type;
      }

      this.favouriteOrgData = await this.getFavouriteData();
      let companyData = await this.getCompanyDetails(assessmentsAPIBody);
      this.comapnyDetails = companyData.assesments;
      this.selectedCountryDetails = companyData.countries;
      this.assessmentSavedOrNot();
    },
    assessmentSaved() {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .post(
            env.apiUrl + "/assesment2x/save-assesments",
            { recordId: _self.comapnyDetails[0].id },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    openContextualAnalysis(taab) {
      if (this.user.organization.accessTwoXGESIContextLight) {
        let context_sector = this.comapnyDetails[0].sector;

        let query = {
          recordId: this.$route.query.recordId,
          countryId: Math.round(this.comapnyDetails[0].countryHq.country_id),
        };

        if (this.$route.query.type) {
          query.type = this.$route.query.type;
        }

        if (this.comapnyDetails[0].type == "funds") {
          context_sector = "Financial Services";
          query.sector = context_sector;
        } else if (context_sector == this.comapnyDetails[0].otherSector) {
          //if other selected
          context_sector = "Other";
          query.sector = context_sector;
        } else {
          query.sector = context_sector;
        }
        if (taab == "Indirect Investment") {
          query.indicator = "Investment through Financial Intermediaries";
        } else {
          query.indicator = taab;
        }
        let routeData = this.$router.resolve({
          path: "/2X-Ecosystem/My-2X-Assessments/context",
          query: query,
        });
        window.open(routeData.href, "_blank");
      } else {
        this.checkUserIsAdminOrNot("2X Assess GESI Context Light");
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let usersAry = [];
        usersAry.push(this.invitedUserEmail);
        let body = {
          user_id: this.user._id,
          sharedEmails: usersAry,
          recordId: this.comapnyDetails[0].recordId,
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.acknowledged) {
          this.$swal.fire({
            icon: "success",
            text: "Success! Your invitation was sent!",
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "Please try again!",
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    togglePanel: function (panelid) {
      if (this.openPanels.indexOf(panelid) > -1) {
        this.openPanels.splice(this.openPanels.indexOf(panelid), 1);
      } else {
        this.openPanels.push(panelid);
      }
    },
    getCountry: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(
            env.apiUrl + "/countries/" + val + "/sectors/country?page=my2x",
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
            }
          )
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getGBVRisk: function (val) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/reports/" + val + "/sectors/country/gbvrisk", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getSector: function (val) {
      const _self = this;
      return new Promise(function (resolve) {
        if (val) {
          axios
            .get(env.apiUrl + "/sectors/" + val, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso: _self.comapnyDetails[0].countryHq.iso },
            })
            .then(function (res) {
              resolve(res.data.data);
            });
        } else {
          resolve([]);
        }
      });
    },
    getCompanyDetails(assessmentsAPIBody) {
      let url = "/assesment2x/all-assesment";
      // if (this.$route.query.id) {
      //   url = "/assesment2x/all-assesment?teamId=" + this.$route.query.id
      // } else {
      //   url = "/assesment2x/all-assesment"
      // }
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + url, assessmentsAPIBody, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },

    async getMatchingCountofInvestor() {
      await axios
        .get(env.apiUrl + "/assesment2x/count2x-assess", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.countOf2xAssess = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/assesment2x/send-invite", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getDownloadCSV: function () {
      const _self = this;

      let api_url;
      if (_self.$route.query.recordId) {
        let portfolioFlag = "false";

        if (_self.user.god && _self.$route.query.type) {
          portfolioFlag = "true";
        }

        api_url =
          env.apiUrl +
          "/assesment2x/download-csv?tableName=" +
          _self.comapnyDetails[0].type +
          "&recordId=" +
          _self.comapnyDetails[0].id +
          "&countryId=" +
          _self.comapnyDetails[0].countryHq.id +
          "&portfolioFlag=" +
          portfolioFlag;
      } else {
        api_url =
          env.apiUrl +
          "/assesment2x/download-csv?tableName=" +
          _self.comapnyDetails[0].type +
          "&countryId=" +
          _self.comapnyDetails[0].countryHq.id;
      }

      return new Promise(function (resolve) {
        axios
          .get(api_url, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    closePopup() {
      this.isReadMore = false;
    },
    readMore(title, data) {
      this.isReadMore = true;
      this.title = title;
      this.readMoreInfo = data;
    },
    setBreadCrumbs(title) {
      this.$store.commit("setBreadcrumbs", [
        {
          name: title,
          url: "/2X-Ecosystem/My-2X-Assessments/contextual-analysis",
        },
      ]);
    },
    getContentOfDownloadCSV(type) {
      if (type == "pdf")
        return `<u><strong><a href="https://www.equilo.io/2xassessmentservices" target="_blank">Get listed</a></strong></u> to download the PDF`;
      else
        return `<u><strong><a href="https://www.equilo.io/2xassessmentservices" target="_blank">Get listed</a></strong></u> to download the summary CSV`;
    },

    checkDownloadPDFPermission() {
      if (this.user.organization && this.user.organization.access2xPDF) {
        this.exportToPDF();
      } else {
        this.checkUserIsAdminOrNot("2X Assessments PDF");
      }
    },

    exportToPDF() {
      this.downloadPDF = true;
      setTimeout(async () => {
        let element = document.querySelector("#my2xaccessment");
        await html2pdf()
          .set({
            margin: 1,
            filename: "my2xassesment.pdf",
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [55, 35],
            },
          })
          .from(element)
          .save();
        this.downloadPDF = false;
        return;
      }, 1000);
    },

    checkDownloadCSVPermission() {
      if (this.user.organization && this.user.organization.twoXCsv) {
        this.exportToCSV();
      } else {
        this.checkUserIsAdminOrNot("2X Assessments CSV");
      }
    },

    async exportToCSV() {
      this.downloadCSV = true;
      let element = document.querySelector("#my2xcsv");
      let CSVData = await this.getDownloadCSV();
      if (CSVData && CSVData.data && CSVData.data.length) {
        // CSVData.forEach(data => {
        this.filterCSVData(CSVData);
        // })
      }
      element.click();
      this.downloadCSV = false;
    },
  },
  computed: {
    averageTicketSizeForInvestors() {
      var str = this.comapnyDetails[0].averageTicketSizeForInvestors[0].replace(
        /[a-z"A-Z]/g,
        ""
      );
      return str;
    },
    fundsSector() {
      var data = Object.values(this.comapnyDetails[0].sector).join(", ");
      return data;
    },
    fundingInterests() {
      var data = Object.values(this.comapnyDetails[0].interestInFunding).join(
        ", "
      );
      return data;
    },
    whatKindOfSupport() {
      var data = Object.values(
        this.comapnyDetails[0].whatKindOfSupportAreYouLookingFor
      ).join(", ");
      return data;
    },
    remainingNumberOfLinkedCompanies() {
      if (this.comapnyDetails[0].remainingNumberOfLinkedCompanies) {
        return parseInt(
          this.comapnyDetails[0].remainingNumberOfLinkedCompanies
        );
      } else {
        return 0;
      }
    },

    checkOrganizationType() {
      let redirectURL = "";
      if (this.comapnyDetails[0].organizationType == "Financial Institution") {
        redirectURL = "/2X-Ecosystem/New-2XAssessment/Fis";
      } else if (this.comapnyDetails[0].organizationType == "Fund") {
        redirectURL = "/2X-Ecosystem/New-2XAssessment/Funds";
      } else if (this.comapnyDetails[0].organizationType == "Corporate") {
        redirectURL = "/2X-Ecosystem/New-2XAssessment/Org";
      }
      return redirectURL;
    },

    checkAssesmentIsCompleted() {
      let incompleteAssesment = false;
      if (
        this.user.organization._id == this.comapnyDetails[0].teamId[0] &&
        (this.comapnyDetails[0].modTwo == "" ||
          this.comapnyDetails[0].modTwo == false)
      ) {
        incompleteAssesment = true;
      }
      return incompleteAssesment;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip .share-email-tooltip-text {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 737px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  left: -666px;
  top: 37px;
}

.tooltip:hover .share-email-tooltip-text {
  visibility: visible;
}

.tooltip .share-email-tooltip-text::after {
  right: 95%;
}
.pdfexportbtn {
  height: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}
.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .Ind_Inv_Icon {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer !important;

    img {
      width: 30px !important;
      height: 30px;
      object-fit: contain;
      margin-right: 0 !important;
      margin-top: 30px !important;
    }

    span {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      margin-left: 20px !important;
    }
  }

  .Indirect_Investment {
    top: 0;
    left: 0;
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .org_profile_image {
      margin: auto;
      width: 56%;

      img {
        width: 100px;
        border-radius: 10px;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;

    .OverviewBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 0 40px 40px;
      padding-top: 40px;
      margin-bottom: 18px;

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;
          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            // margin-bottom: 15px;
            .EligibleListImg {
              position: relative;
              z-index: 1;
              margin-right: 15px;
              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 20px;
                top: 40px;
                left: 18px;
                z-index: -1;
              }

              img {
                // width: 24px !important;
                // height: 24px;
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 0 !important;
              }
            }
            .bigImg {
              &::after {
                top: 40px;
                left: 18px;
                height: 20px;
              }
              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }
          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }
          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            .PartyTooltipImg {
              img {
                // width: 20px;
                // height: 20px;
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }

            a {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }

        .spiderchartContent {
          width: 60%;
        }
      }
    }

    .Profile_Criteria_Wrepper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;

      .Profile_CountryWrepper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .Profile_Block {
          background: #ffffff;
          border-radius: 10px;
          padding: 40px;
          margin-bottom: 18px;
          // max-width: 523px;
          width: 100%;

          .Profile_blockListText {
            .About_CompanyListText {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              margin-bottom: 30px;

              b {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 5px;
              }

              a {
                img {
                  width: 20px !important;
                  height: 20px;
                  object-fit: contain;
                }
              }
            }

            ul {
              border-top: 1px solid #d0d0d0;
              padding-top: 30px;
              margin: 0;
              margin-bottom: 30px;
              display: flex;
              flex-wrap: wrap;
              // align-items: center;
              justify-content: space-between;

              li {
                width: 48%;
                margin: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: #1b1b1b;
                margin-bottom: 15px;
                word-break: break-all;

                b {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  color: #1b1b1b;
                  display: block;
                  margin-bottom: 5px;
                  word-break: break-all;
                }
              }
            }

            .DownloadBtnDeck {
              display: flex;

              a {
                display: flex;
                align-items: center;
                border: 1px solid #1b1b1b;
                border-radius: 20px;
                color: #1b1b1b;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                padding: 11px 10px;
                transition: 0.3s ease;

                img {
                  margin-right: 8px;
                  width: 15px;
                  height: 15px;
                }

                &:hover {
                  background: rgb(32, 23, 71);
                  color: #fff;
                  border-color: rgb(32, 23, 71);
                }
              }
            }
          }

          .CountryOverviewText {
            margin-bottom: 30px;

            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 5px;
            }

            p,
            .leading-7 {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              display: -webkit-box;
              -webkit-line-clamp: 15;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            a {
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              display: flex;
              align-items: center;

              img {
                margin-left: 5px !important;
                width: 15px !important;
                height: 15px;
                object-fit: contain;
              }
            }
          }

          .WanttoUnderstandHere {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;

            a {
              text-decoration: underline;
            }
          }

          .ContryNameImg {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
              width: 60px;
              height: 40px;
              object-fit: contain;
              margin-right: 15px;
            }

            h1 {
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: #1b1b1b;
            }
          }

          .ExploreBtnDeck {
            display: flex;
            margin-top: 30px;

            div {
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 10px;
              transition: 0.3s ease;

              img {
                margin-right: 8px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }

      .barchart2xCriteria_Wrepper {
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;
        // max-width: 740px;
        // width: 100%;
        // margin-left: auto;

        .CriteriaTitleText {
          margin-bottom: 30px;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

          p {
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #1b1b1b;
          }
        }

        .ChartBarContentWrepper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;

          .multiple_employment_results_section {
            p {
              margin-bottom: 20px;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              color: #1b1b1b;
            }
          }

          .ChartBarContent {
            width: 100%;
            max-width: 310px;

            .Founded_WomanText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              color: #1b1b1b;
              margin-bottom: 15px;
            }

            .Founded_WomanImg {
              display: flex;
              align-items: center;

              img {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                object-fit: contain;
              }
            }

            .ChartBarPercentageText {
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
              margin-bottom: 10px;
            }
          }
        }

        .Consumption_ListText {
          margin-bottom: 60px;

          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .Quality_EmploymentText {
          .assessment_year_one_results {
            width: 50%;

            p {
              margin-bottom: 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
            }
          }

          .assessment_year_second_results {
            width: 50%;

            p {
              margin-bottom: 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              color: #1b1b1b;
            }
          }

          p {
            margin-bottom: 10px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }

        .IndirectInvestmentListContent {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .IndirectContent {
            max-width: 301px;
            width: 100%;

            .IndirectInvWrepper {
              margin-bottom: 45px;

              .IndirectInvText {
                font-style: normal;
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                color: #1b1b1b;
                margin-bottom: 16px;
              }

              .IndirectInvImg {
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: contain;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .GBVdetailsBlock {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;

      .GBVdetailsContent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .esg-pre-screening {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 20px;

      .esg-pre-screening-results-section {
        p {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #1b1b1b;
        }
      }

      .OverviewTitle {
        h2 {
          width: 320px;
        }
      }

      .GBVdetailsContent {
        justify-content: space-between;

        ul {
          margin-top: 1.5rem;
        }

        ul li {
          display: flex;
          margin: 0;
        }

        .overOverviewDeitail {
          width: 100%;

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 5px;
          }

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #1b1b1b;
          }
        }

        .GbvRiskContent {
          max-width: 670px;
          widows: 100%;

          .step_gbvRisk {
            flex-wrap: wrap;
          }

          .GbvRiskTitleWrepper {
            margin-bottom: 40px;

            .GbvRiskTitle {
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 60px;
                height: 40px;
                object-fit: contain;
                margin-right: 20px;
              }

              h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                color: #1b1b1b;
              }
            }

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              text-align: center;
              color: #9d9d9c;
            }
          }

          .SevereRiskWrepper {
            h1 {
              margin-top: 60px;
              margin-bottom: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              color: #fe5000;

              img {
                width: 40px;
                height: 70px;
                object-fit: contain;
                margin-right: 30px;
              }

              span {
                margin-right: 5px;
              }
            }

            .UbiquitousTextIcon {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                width: 16px;
                height: 30px;
                object-fit: contain;
                margin-right: 10px !important;
              }

              span {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                color: #9d9d9c;
                margin-right: 20px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .amount-funded {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px;
      width: 100%;
      margin-top: 20px;

      .funding-status-title {
        h2 {
          width: 250px;
        }
      }

      .funding-bar-chart {
        width: 50%;
      }

      .funding-status-info {
        width: 50%;

        .Quality_EmploymentText {
          p {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
          }

          ul {
            li {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1b1b1b;
              list-style: none;
              margin-bottom: 10px;
              margin-left: 0;

              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: contain;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .CriteriaTitleText {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        p {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #1b1b1b;
        }
      }

      .ChartBarContentWrepper {
        // display: flex;
        justify-content: space-between;
        // margin-bottom: 60px;
        margin: 0 auto 60px auto;

        .ChartBarContent {
          width: 100%;
          margin: auto;
          max-width: 400px;

          .Founded_WomanText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 15px;
          }

          .Founded_WomanImg {
            display: flex;
            align-items: center;

            img {
              margin-right: 10px;
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }

          .ChartBarPercentageText {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: #1b1b1b;
            margin-bottom: 10px;
          }
        }
      }

      .get-it-on-deal-button {
        text-align: right;
        margin-bottom: 20px;
      }

      .get-it-on-deal-button button {
        background: #fff;
        color: #1b1b1b;
        border-color: #201747;
        border: 1px solid #1b1b1b;
        height: 36px;
        color: #1b1b1b;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        padding: 9px 11px;
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
      }

      .get-it-on-deal-button button:hover {
        background: #201747;
        color: #fff;
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 185px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.chartjs-render-monitor {
  margin-left: -33px;
  height: 530px !important;
}

.consumption-taab-company {
  left: 0;
  top: 0;
}

.consumption-taab-fund {
  left: 0;
  top: 120px;
}

.leadership-taab-company {
  top: 0px;
  right: 10rem;
}

.leadership-taab-fund {
  right: 10rem;
  top: 0;
}

.employment-taab-company {
  bottom: 25%;
  right: 5rem;
}

.employment-taab-fund {
  bottom: 12rem;
  right: 5rem;
}

.entrepreneurship-taab-company {
  left: 0px;
  bottom: 25%;
}

.entrepreneurship-taab-fund {
  left: 0px;
  bottom: 5.6rem;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 80px;
}

img {
  margin: 0 !important;
  margin-right: 15px !important;
}

.bridgeContainer {
  .DownloadBtnDeck {
    display: none !important;
  }

  .readMoreBtn {
    display: none !important;
  }

  .ExploreBtnDeck {
    display: none !important;
  }

  .get-it-on-deal-button {
    display: none !important;
  }

  .leading-7 {
    -webkit-line-clamp: unset !important;
    -webkit-line-clamp: none;
    overflow: visible;
    padding-bottom: 10px;
  }

  .DownloadBtn {
    display: none !important;
  }
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>

<style scoped>
.selected-country {
  pointer-events: none;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1);
  font-weight: bold;
}

.unselected-country {
  cursor: pointer;
  padding: 0 1rem;
  border: solid black 1pt;
  border-radius: 10px;
}

.favorite-button-section {
  margin-top: 20px;
  display: flex;
  /* justify-content: flex-end */
}

.favourite-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.matching-companies-count {
  background-color: rgb(32, 23, 71);
  border: none;
  color: white !important;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  margin: 5px 0 0 0;
  min-height: 100%;
  min-width: 100%;
}

.send-tooltip {
  width: 32px;
  height: 32px;
  /* margin: auto !important;
    margin-right: 15px !important; */
  margin-top: 10px;
  padding: 0px;
  cursor: pointer;
}

.favourite-tooltip .favourite-tooltip-text,
.send-tooltip .send-tooltip-text {
  visibility: hidden;
  width: 180px;
  /* right: 90px; */
  margin: 5px;
  background-color: #a0aec0;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.favourite-tooltip:hover .favourite-tooltip-text,
.send-tooltip:hover .send-tooltip-text {
  visibility: visible;
}

.gbv-country-tooltip {
  width: 32px;
  height: 32px;
  margin: auto !important;
  margin-right: 15px !important;
  padding: 0px;
  cursor: pointer;
}

.gbv-country-tooltip-text {
  visibility: hidden;
  width: 180px;
  margin: 5px;
  background-color: #201747;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;

  font-family: "Montserrat", arial !important;
  padding: 20px;
  font-size: 12px !important;
  letter-spacing: normal;
}

.gbv-country-tooltip:hover .gbv-country-tooltip-text {
  visibility: visible;
}

/* Tooltip container */
.div_tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.div_tooltip .div_tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.div_tooltip:hover .div_tooltiptext {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .download-csv-tooltip {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 290px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -86px;
  bottom: 100%;
  white-space: normal;
  word-wrap: break-word;
}

.tooltip .tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 20px;
  top: -160px;
}

.tooltip .tooltiptextIndirectInvestement {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 20px;
  top: -160px;
}

.tooltip .indirect_tooltip_text {
  font-family: "Montserrat", arial !important;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -109px;
  top: 80px;
}

.tooltip .remaining_companies_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 150px;
  top: -35px;
}

.tooltip .twoXEligibleHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 141px;
  top: -35px;
}

.tooltip .entrepreneurshipHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 207px;
  top: -40px;
}

.tooltip .leadershipHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 139px;
  top: -54px;
}

.tooltip .employmentHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 155px;
  top: -62px;
}

.tooltip .consumptionHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 162px;
  top: -40px;
}

.tooltip .indirectInvestmentHoverText {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 220px;
  top: -52px;
}

.tooltip:hover .download-csv-tooltip {
  visibility: visible;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .remaining_companies_tooltiptext {
  visibility: visible;
}

.tooltip:hover .twoXEligibleHoverText {
  visibility: visible;
}

.tooltip:hover .entrepreneurshipHoverText {
  visibility: visible;
}

.tooltip:hover .leadershipHoverText {
  visibility: visible;
}

.tooltip:hover .employmentHoverText {
  visibility: visible;
}

.tooltip:hover .consumptionHoverText {
  visibility: visible;
}

.tooltip:hover .indirectInvestmentHoverText {
  visibility: visible;
}

.tooltip:hover .indirect_tooltip_text {
  visibility: visible;
}

.tooltip:hover .tooltiptextIndirectInvestement {
  visibility: visible;
}

.tooltip .download-csv-tooltip::after {
  right: 95%;
}

.tooltip .tooltiptext::after {
  right: 95%;
}

.tooltip .remaining_companies_tooltiptext::after {
  right: 95%;
}

.tooltip .twoXEligibleHoverText::after {
  right: 95%;
}

.tooltip .entrepreneurshipHoverText::after {
  right: 95%;
}

.tooltip .leadershipHoverText::after {
  right: 95%;
}

.tooltip .employmentHoverText::after {
  right: 95%;
}

.tooltip .consumptionHoverText::after {
  right: 95%;
}

.tooltip .indirectInvestmentHoverText::after {
  right: 95%;
}

.tooltip .indirect_tooltip_text::after {
  right: 95%;
}

.tooltip .tooltiptextIndirectInvestement::after {
  right: 95%;
}

.disable-csv-download {
  pointer-events: none;
}

.country-flag-margin-positive {
  margin-top: 20px;
}

.country-flag-margin-negative {
  margin-top: -25px;
}
.gray-color {
  color: #898989 !important;
}
.tooltip .tooltiptext::after {
  display: none;
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
</style>
