<template>
  <div>
    <div class="flex gap-8">
      <div class="flex bg-gray-300 rounded-lg cursor-pointer">
        <div class="px-3 py-2 text-sm text-gray-400">Quarters</div>
        <div class="px-3 py-2 text-sm text-white bg-blue-600 rounded-lg">
          Annual
        </div>
      </div>
      <div>
        <select
          name="year"
          id="year"
          class="focus:outline-none cursor-pointer p-2 border border-gray-200 text-gray-900 font-medium rounded-lg w-24"
        >
          <option value="2023">2023</option>
          <option value="2022">2022</option>
        </select>
      </div>
    </div>
    <!-- header section -->
    <div
      class="bg-gray-100 rounded-xl overflow-hidden p-4 flex justify-between mt-4"
    >
      <div class="flex flex-col gap-2">
        <h2>{{ portfolioName }}</h2>
        <p class="target-goal">Portfolio description</p>
      </div>
      <div>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Performance
        </button>
        <button
          class="focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"
        >
          Edit preferences
        </button>
      </div>
    </div>

    <!-- assessment section -->
    <div class="flex gap-4">
      <AssessmentProgress
        :companies="topCompanies"
        :selected-year.sync="selectedYear"
      />
      <AssessmentUpdates :in-progress="1" :outdated="0" :not-started="0" />
    </div>

    <!-- portfolio controls -->
    <div class="portfolio-controls">
      <div class="filters">
        <!-- <DropdownSelect
          :value="selectedPortfolio"
          @input="selectedPortfolio = $event"
          :options="portfolioOptions"
        /> -->
      </div>
    </div>

    <!-- metrics grid -->
    <div class="grid grid-cols-2 gap-6">
      <MetricCard
        v-for="(card, index) in metricCards"
        :key="index"
        v-bind="card"
      />
    </div>
  </div>
</template>

<script>
import AssessmentProgress from "./AssessmentProgress.vue";
import AssessmentUpdates from "./AssessmentUpdates.vue";
import MetricCard from "./MetricCard.vue";
//import DropdownSelect from "./DropdownSelect.vue";

export default {
  name: "AggregatedPortfolio",
  components: {
    AssessmentProgress,
    AssessmentUpdates,
    MetricCard,
    //DropdownSelect,
  },
  props: {
    portfolioName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedPortfolio: null,
      selectedYear: "2023",
      currentDate: "09.09.2023",
      portfolioOptions: [
        { label: "Portfolio 1", value: "p1" },
        { label: "Portfolio 2", value: "p2" },
      ],
      topCompanies: [
        { name: "Company name", score: 60 },
        { name: "Company name", score: 58 },
        { name: "Company name", score: 56 },
      ],
      metricCards: [
        {
          title: "2X Criteria",
          type: "criteria",
          date: this.currentDate,
          metrics: {
            leadership: 45,
            employment: 19,
            supplyChain: 15,
            entrepreneurship: 18,
            products: 19,
            governance: 14,
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
/* ... same styles as before ... */
</style>

<style scoped>
.portfolio-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}
</style>
