<template>
  <div class="static">
    <div style="display: flex; justify-content: space-between">
      <img
        src="@/assets/images/2X.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-left: 285px"
      />
      <img
        src="@/assets/images/logo-navy.png"
        alt="background-image"
        class="object-contain no-print"
        style="height: 6rem; margin-top: 72px; margin-right: 120px"
      />
    </div>
    <div style="height: 5rem"></div>
    <transition name="fadeIn" mode="out-in">
      <template>
        <div id="notLoaded" v-if="!endofcreated" key="loading">
          <page-loader></page-loader>
        </div>
        <div v-if="endofcreated" id="loaded" key="content">
          <main style="grid-template-columns: none !important">
            <div style="width: 100%; text-align: center">
              <section
                class="w-full container mx-auto px-4 lg:px-0 border-t border-gray-300"
              >
                <div id="jotform-box" style="border: 1px solid #ccc">
                  <Survey
                    :survey-data="surveyData"
                    :survey-json="surveyJson"
                    :user="user"
                    :survey-id="surveyId"
                    :answer-again="answerAgain"
                    :id="id"
                    :isComplete="isComplete"
                    :userType="userType"
                    @surveyComplete="surveyComplete"
                    @survyeEdited="survyeEdited"
                  />
                </div>
                <div class="bottom-section flex justify-between">
                  <button
                    class="come-back"
                    @click="$router.push({ path: '/imm/landing-page/company' })"
                  >
                    Come back later
                  </button>
                  <button
                    class="set-up"
                    :class="{ 'not-active': !isSetupPrefActive }"
                  >
                    Try IMM!
                  </button>
                </div>
              </section>
            </div>
          </main>
        </div>
      </template>
    </transition>
  </div>
</template>
<script>
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import Survey from "@/components/surveys/survey.vue";

export default {
  name: "CreateCompanyProfile",
  data: function () {
    return {
      isSetupPrefActive: false,
      user: {},
      surveyData: null,
      surveyJson: {},
      loading: false,
      surveyId: "company-profile",
      isComplete: false,
      answerAgain: false,
      id: null,
      userType: null,
      endofcreated: false,
      isCurrentButtonActive: "",
    };
  },
  components: {
    Survey,
  },
  async mounted() {
    this.user = await auth.me();
    await this.fetchSurvey();
    this.endofcreated = true;
  },
  methods: {
    survyeEdited(val) {
      this.isCurrentButtonActive = val;
    },
    surveyComplete() {
      this.$router.push({ path: "/imm/landing-page" });
    },
    async fetchSurvey() {
      try {
        const {
          data: { data },
          status,
        } = await axios.get(`${env.apiUrl}/surveys/${this.surveyId}?tag=null`, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        });
        if (status == 200) {
          this.surveyJson = data.json;
        }
      } catch (error) {
        console.log("Login error ", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom-section {
  margin-top: 28px;

  .come-back {
    width: 300px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #191b45;
    color: #191b45;

    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .set-up {
    width: 300px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    gap: 8px;
    background: #191b45;
    color: #ffffff;

    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .not-active {
    background: #e1e3e9;
    pointer-events: none;
  }
}
</style>
