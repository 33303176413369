<template>
  <div class="mt-6">
    <div v-if="selectedOption === 'detailed'" class="px-10">
      <commitment-item
        v-for="item in allCommitmentItems"
        :key="item.name"
        :name="item.name"
        :icon="item.icon"
        :is-eligible="isEligible(item.isEligible)"
        :color="item.color"
        class="gap-4"
      />
    </div>
    <div v-else-if="selectedOption === 'redacted' && hasEligibleCommitments" class="px-10">
      <commitment-item
        v-for="item in eligibleCommitmentItems"
        :key="item.name"
        :name="item.name"
        :icon="item.icon"
        :is-eligible="true"
        :color="item.color"
        class="gap-4"
      />
    </div>
    <div v-else class=" text-gray-800 font-medium leading-relaxed text-base">
      No Additional commitments!
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionalCommitments',
  props: {
    isSupplyChainEligible: Boolean,
    isProductsEligible: Boolean,
    isEmploymentEligible: Boolean,
    isLeadershipEligible: Boolean,
    isEntOwnEligible: Boolean,
    isPortfolioEligible: Boolean,
    certificationScoreData: Object,
    entityChosen: String,
    selectedOption : String,
    isPortfolioVisible: Boolean,
  },
  components: {
    CommitmentItem: {
      props: ['name', 'icon', 'isEligible', 'color'],
      template: `
        <ul class="space-y-6 mb-3">
          <li class="flex items-center mb-2 gap-2">
            <svg v-if='isEligible' class="h-11 w-11" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" 
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z" 
                    fill="#fff" />
              <path fill-rule="evenodd" clip-rule="evenodd" 
                    d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM5 12L10 17L19 8L17.59 6.58L10 14.17L6.41 10.59L5 12Z"
                    :fill="color" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-white rounded-full p-1" :style={backgroundColor:color}  viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
            <span class="text-xl font-medium text-gray-900 ml-2" :style="{ color: color}">{{ name }}</span>
          </li>
        </ul>
      `,
    },
  },
  data() {
    return {
      commitmentItems: [
        { name: 'Entrepreneurship & Ownership', icon: require('@/assets/images/2x_certify/entrepreneurship.svg'), isEligible: 'isEntOwnEligible', color: "#19486A"},
        { name: 'Leadership', icon: require('@/assets/images/2x_certify/leadership.svg'), isEligible: 'isLeadershipEligible', color: "#3F7E44" },
        { name: 'Employment', icon: require('@/assets/images/2x_certify/employment.svg'), isEligible: 'isEmploymentEligible', color: "#DDA63A" },
        { name: 'Supply chain', icon: require('@/assets/images/2x_certify/supplychain.svg'), isEligible: 'isSupplyChainEligible', color: "#381B5B"},
        { name: 'Products & Services', icon: require('@/assets/images/2x_certify/products.svg'), isEligible: 'isProductsEligible', color: "#A21942" },
        { name: 'Portfolio', icon: require('@/assets/images/2x_certify/products.svg'), isEligible: 'isPortfolioEligible', color: "#8F210D" }
      ],
    };
  },
  computed: {
    allCommitmentItems() {
      if (this.isPortfolioVisible && (this.entityChosen === 'fund-manager' || this.entityChosen === 'financial-institution')) {
        return this.commitmentItems;
      } else {
        return this.commitmentItems.filter(item => item.name !== 'Portfolio');
      }
    },
    eligibleCommitmentItems() {
      return this.allCommitmentItems.filter(item => this.isEligible(item.isEligible));
    },
    hasEligibleCommitments() {
      return this.eligibleCommitmentItems.length > 0;
    },
  },
  methods: {
    isEligible(key) {
      return this[key] === true;
    }
  }
};</script>
