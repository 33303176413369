export const checkWhiteLabel = (featureFlags = []) => {
  const immFeatureFlag = featureFlags.find((flag) => flag.startsWith("imm-"));
  if (!immFeatureFlag) {
    return companies.default;
  }
  const company = immFeatureFlag.split("-")[1];
  if (company) {
    return companies[company];
  }
  return companies.default;
};

// `displayComment`: This should be implemented to display the comment column on the Portfolio Company Dashboard
const companies = {
  default: {
    title: "Impact Compass",
    logo: null,
    companyLandingSurvey: true,
    displayComment: true,
    portfolioSurveys: [
      "e&s",
      "impact",
      "company-profile",
      "e&s-rating",
      "climate-rating",
      "exclusions",
    ],
  },
  sweef: {
    title: "IMM",
    logo: "sweef",
    companyLandingSurvey: false,
    displayComment: false,
    portfolioSurveys: [
      "e&s",
      "impact",
      "company-profile",
      "e&s-rating",
      "climate-rating",
      "exclusions",
    ],
  },
};

export const surveysMapping = {
  "e&s": "Environmental and Social",
  impact: "Impact",
  "company-profile": "Company Profile",
  "e&s-rating": "E&S Rating",
  "climate-rating": "Climate Rating",
  exclusions: "Exclusions",
};
