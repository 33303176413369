<script>
const ButtonColorClasses = {
  'primary': '[ border-navy ][ bg-navy opacity-100 ][ hover:opacity-75 ]',
  'secondary': '[ border-orange-100 ][ bg-orange-100 ][ hover:bg-orange-80 ]',
  'success': '[ border-orange-100 ][ bg-orange-100 ][ hover:bg-orange-80 ]',
  'danger': '[ border-danger ][ bg-danger opacity-100 ][ hover:opacity-75 ]',
  'caution': '[ border-caution ][ text-black ][ bg-caution opacity-100 ][ hover:opacity-75 ]',
  'cancel': '[ border-grey-80 ][ text-black ][ hover:bg-grey-70 hover:text-white ]',
  'transparent': '[ border-grey-80 ][ text-black ][ bg-transparent opacity-100 ][ hover:opacity-75 ]',
}

export default {
  name: "EquiloButton",
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "button"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      options: ['primary', 'secondary', 'success', 'danger', 'caution', 'cancel'],
      default: 'primary'
    },
    classes: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      bgClasses: '',
    };
  },
  emits: ["on-click"],
  mounted() {
    this.getBackgroundColors(this.bgColor);
  },
  watch: {
    bgColors(newVal) {
      this.getBackgroundColors(newVal);
    }
  },
  methods: {
    getBackgroundColors(color) {
      const availableColors = Object.keys(ButtonColorClasses);
      if (availableColors.includes(color)) {
        this.bgClasses = ButtonColorClasses[this.bgColor];
      } else {
        this.bgClasses = ButtonColorClasses['primary'];
      }
    }
  },
};
</script>

<template>
  <button
    class="[ flex items-center justify-center gap-2 ][ text-xs text-white ][ rounded-md ][ py-2 px-4 ][ border-2 border-transparent rounded-lg ][ transition duration-200 ease-in-out ]"
    :class="{[bgClasses]: true, 'btn-disabled': disabled, [this.classes]: this.classes}"
    :type="type"
    :disabled="disabled"
    @click="$emit('on-click')"
  >
    <span class="whitespace-nowrap">{{ text }}</span>
    <slot name="suffix"></slot>
  </button>
</template>

<style scoped lang="scss">
.btn-disabled {
  @apply cursor-not-allowed;
  @apply bg-grey-80;
  @apply border-grey-80;
  @apply text-white;
  @apply hover:bg-grey-70;
  @apply hover:text-white;
}
</style>