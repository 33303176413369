<template>
  <div
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="bg-white rounded-lg shadow-lg w-full max-w-lg">
      <div class="flex justify-between items-center p-6 bg-gray-100 rounded-lg">
        <h2 class="text-xl font-semibold text-gray-900">
          Total Verification Progress
        </h2>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div v-if="isLoading" class="flex items-center justify-center p-6 h-64">
        <div class="flex space-x-2 animate-pulse">
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
          <div class="w-3 h-3 bg-[#0077C8] rounded-full"></div>
        </div>
      </div>

      <div v-else class="space-y-3 p-6">
        <div class="flex justify-between items-center border-b pb-2">
          <span class="text-gray-600">Total Data Points:</span>
          <span class="font-medium text-[#201747]">{{
            progressData.total
          }}</span>
        </div>
        <div class="flex justify-between items-center border-b pb-2">
          <span class="text-gray-600">Verified Data Points:</span>
          <span class="text-[#2BD4C4] font-medium">{{
            progressData.verifiedCount
          }}</span>
        </div>
        <div class="flex justify-between items-center border-b pb-2">
          <span class="text-gray-600">Unverified Data Points:</span>
          <span class="text-red-500 font-medium">{{
            progressData.unverifiedCount
          }}</span>
        </div>
        <div class="flex justify-between items-center border-b pb-2">
          <span class="text-gray-600">Pending Data Points:</span>
          <span class="text-yellow-500 font-medium">{{
            progressData.pendingCount
          }}</span>
        </div>
        <div class="flex justify-between items-center border-b pb-2">
          <span class="text-gray-600">Completed Percentage:</span>
          <span class="text-[#0077C8] font-medium"
            >{{ Math.round(progressData.completedPercentage) }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/helpers/apiServices.js";

export default {
  name: "VerificationProgressModal",
  data() {
    return {
      progressData: {},
      isLoading: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  async mounted() {
    const response = await apiService.fetchSummaryReportData(
      this.$route.query.teamId
    );
    this.progressData = response.data.data;
    this.isLoading = false;
  },
};
</script>
