import BaseAPI from "@/api/index";

class TeamAPI {
  resourceBasePath = "/organizations";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  getTeamDetails(id) {
    return this.axiosInstance.get(`${this.resourceBasePath}/${id}`);
  }
}

export default TeamAPI;
