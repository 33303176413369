<template>
  <div class="static">
    <div class="main-container mb-20">
      <BreadCrumbs class="breadcrumbs-main-container"></BreadCrumbs>
      <div class="page-title-section flex justify-between mt-6">
        <div class="page-title">Impact</div>
        <div></div>
      </div>

      <div class="mt-11">
        <div
          class="statement-heading flex justify-between"
          :class="{ 'is-fixed fixed-header': fixedComprehensiveSection }"
        >
          Sustainable Development Goals companies overall
          <img
            v-if="isComprehensiveTableVisible"
            @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
            src="@/assets/images/arrow-up.svg"
          />
          <img
            v-if="!isComprehensiveTableVisible"
            @click="isComprehensiveTableVisible = !isComprehensiveTableVisible"
            src="@/assets/images/arrow-down.svg"
          />
        </div>
        <div ref="comprehensiveSection">
          <ImpactPageBarChart
            v-if="isComprehensiveTableVisible"
            :chartData="chartData"
            :chartLabels="chartLabels"
            class="mt-6"
          />
        </div>
      </div>

      <div class="mt-11">
        <div class="statement-heading flex justify-between">
          UN SDGs
          <img src="@/assets/images/arrow-down.svg" />
        </div>
        <div ref="comprehensiveSection">
          <ImpactTable
            class="mt-4"
            :headings="[
              'SDG#4: Quality Education',
              'Positive Impact generated',
            ]"
            :tableData="tableData"
          />
        </div>
      </div>

      <div class="page-title-section flex justify-between mt-6">
        <div class="bar-chart-section flex justify-between items-center">
          <div class="filter-options-section flex">
            <div class="filter-options flex items-center">
              <div>By quarters</div>
              <div class="active">All time</div>
            </div>
            <div class="select-year">
              <select name="year" id="year">
                <option value="2023">2023</option>
                <option value="2022">2022</option>
              </select>
            </div>
          </div>
        </div>
        <div class="download-button">
          <button class="flex">
            Customize and download the report
            <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
          </button>
        </div>
      </div>

      <div class="mt-11">
        <div class="statement-heading flex justify-between">
          IRIS+ metrics
          <img src="@/assets/images/arrow-down.svg" />
        </div>
        <div ref="isUnTableRef">
          <ImpactTable
            class="mt-4"
            :headings="['School Enrolment: Female (PI1081)', '2022', '2023']"
            :tableData="tableDataIRIS"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import ImpactPageBarChart from "../../../components/charts/ImpactPageBarChart.vue";
import ImpactTable from "@/components/imm-components/tables/impact-table.vue";

export default {
  name: "IIMImpactPage",
  data() {
    return {
      isComprehensiveTableVisible: true,
      fixedComprehensiveSection: false,
      isUnTable: true,
      fixedUnTable: false,
      chartLabels: [
        "No poverty",
        "Zero Hunger",
        "Good Health and Well-being",
        "Quality Education",
        "Gender Equality",
        "Clean Water and Sanitation",
        "Affordable and Clean Energy",
        "Other",
      ],
      chartData: [
        {
          label: "Sustainable Development Goals companies overall",
          data: [100, 80, 60, 50, 40, 30, 20, 10],
          barThickness: 32,
          borderRadius: 10,
          backgroundColor: [
            "#FF501C",
            "#00A9E0",
            "#3F704C",
            "#F7CA18",
            "#ED2F4E",
            "#53B77A",
            "#FFCE56",
            "#A5D6A7",
          ],
        },
      ],
      tableData: [
        {
          SDG: "Quality Education",
          "Positive Impact generated": "98%",
          isVisible: false,
        },
        {
          SDG: "Achieve gender equality and empower all women and girls",
          "Positive Impact generated": "98%",
          isVisible: false,
        },
        {
          SDG: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
          "Positive Impact generated": "98%",
          isVisible: false,
        },
      ],
      tableDataIRIS: [
        {
          SDG: "Quality Education",
          "Positive Impact generated": "2,010",
          IRIS: "2,010",
          isVisible: false,
        },
        {
          SDG: "Achieve gender equality and empower all women and girls",
          "Positive Impact generated": "2,010",
          IRIS: "2,010",
          isVisible: false,
        },
        {
          SDG: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
          "Positive Impact generated": "140",
          IRIS: "140",
          isVisible: false,
        },
      ],
    };
  },
  components: {
    BreadCrumbs,
    ImpactPageBarChart,
    ImpactTable,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("setNewBreadcrumbs", [
      { name: "Dashboard", url: "/imm/landing-page" },
      { name: "Investor page", url: "/imm/Investor-page" },
      { name: "Company profile", url: "/imm/Investor-page/company-profile" },
      { name: "Impact", url: "/imm/Investor-page/company-profile/Impact" },
    ]);
  },
  methods: {
    handleScroll() {
      if (this.$refs.comprehensiveSection.getBoundingClientRect().top <= 122) {
        this.fixedComprehensiveSection = true;
      } else {
        this.fixedComprehensiveSection = false;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .fixed-header {
    position: fixed;
    width: 91.6%;
    margin-top: 64px;
    padding: 20px 0;
    z-index: 10;
    transition: top 0.3s ease;
    display: flex;
    justify-content: space-between;
    height: 90px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 68%,
      rgba(255, 255, 255, 0) 83%
    );
  }

  .is-fixed {
    top: 0;
  }

  margin-left: 80px;
  margin-right: 80px;

  .breadcrumbs-main-container {
    margin-top: 100px;
  }

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .sales-table-section {
  }

  .statement-table-section {
    .statement-content-section {
      .metrices-section {
        .charts-section {
          .line-chart-section {
            border: 1px solid #e1e3e9;
            border-radius: 16px;

            .line-chart-heading {
              //styleName: Body2 Bold;
              font-family: Arial;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #2c2e35;
            }
          }
        }
      }
    }
  }
}

.statement-heading {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2c2e35;

  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

.fixed-header {
  position: fixed;
  width: 91.6%;
  margin-top: 64px;
  padding: 20px 0;
  z-index: 10;
  transition: top 0.3s ease;
  display: flex;
  justify-content: space-between;
  height: 90px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 68%,
    rgba(255, 255, 255, 0) 83%
  );
}

.is-fixed {
  top: 0;
}

.download-button {
  button {
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    border: 1px solid #191b45;
    gap: 6px;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #191b45;
  }
}

.bar-chart-section {
  .filter-options-section {
    gap: 12px;

    .filter-options {
      height: 32px;
      background: #f4f6f9;
      border-radius: 8px;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #9ea4ba;

      div {
        padding: 6px 12px 6px 12px;
      }

      .active {
        background: #2177c1;
        border-radius: 8px;
        color: #ffffff;
        padding: 5px 20px;
      }
    }

    .select-year {
      select {
        height: 32px;
        padding: 0 12px;
        border-radius: 8px;
        border: 1px solid #e1e3e9;
        gap: 8px;
        color: #2c2e35;
      }
    }
  }
}

.chart-title {
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.chart-subtitle {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
</style>
