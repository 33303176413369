<template>
  <div class="static">
    <div id="notLoaded" v-if="organizationDetail.length == 0" key="loading">
      <page-loader></page-loader>
    </div>
    <div
      class="w-full mt-20 mb-20 lg:px-0 pb-32"
      v-if="user && user.organization"
    >
      <onboarding :onContinue="onContinue" v-if="isBoarding" :onSkip="onSkip" />
      <div
        v-if="canTour === true"
        class="w-full h-full flex items-center justify-center"
      >
        <div class="flex flex-col items-center mt-[2rem]">
          <div class="tour-title font-bold text-left">
            Do you want to take a quick tour of the platform?
          </div>
          <div class="flex">
            <div
              @click="skip()"
              class="skip flex items-center justify-center arial font-bold transition-colors cursor-pointer select-none"
            >
              No, I’m good
            </div>
            <button
              type="button"
              @click="goDashboard()"
              class="continue flex items-center justify-center arial font-bold transition-colors cursor-pointer select-none"
            >
              Yes, onboard me!
            </button>
          </div>
        </div>
      </div>
      <menu-boarding
        v-if="showMenu"
        :user="user"
        :checkportfolioPermissionpopup="checkportfolioPermissionpopup"
        :checkportfolioPermissionpopupROI="checkportfolioPermissionpopupROI"
        :isTour="isTour"
        :tourSteps="tourSteps"
        :board="board"
        :configureTour="configureTour"
        :createDrive="createDrive"
        :driver="driver"
        :handleRequestPermision="handleRequestPermision"
        :isOpen="isOpen"
        :setPilotRequestModal="setPilotRequestModal"
        :subscriptionDetails="subscriptionDetails"
      ></menu-boarding>

      <!-- start of modals -->

      <!-- Request ROi Admin Modal Start -->
      <div
        class="fixed z-50 inset-0 overflow-y-auto"
        v-if="requestAdminModalForROI"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="closeRequestModal()" style="right: 8px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <p style="font-weight: 600">
                  Thanks for visiting the Gender ROI™ Assess page. Your team's
                  administrator has not shared permissions to allow you to see
                  your organization's Gender ROI™ Assessment results or to
                  initiate a new Gender ROI™ Assessment. Please contact your
                  administrator to request permission to conduct a new survey.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                  @click="handleRequestPermision('ROI')"
                >
                  Request permission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->
      <div
        class="fixed z-50 inset-0 overflow-y-auto"
        v-if="requestAdminModalFor2X"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div class="fixed inset-0 transition-opacity cursor-pointer">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
          ></span
          >&#8203;
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style="max-width: 630px; width: 100%"
          >
            <span class="close" @click="closeRequestModal()" style="right: 8px"
              >&times;</span
            >
            <div class="Modal_Readmorestyle">
              <div class="ModalInnerStyle">
                <p style="font-weight: 600">
                  Thanks for visiting the 2X Assess page. Your team's
                  administrator has not shared permissions to allow you to see
                  your organization's 2X Assessment results or to initiate a new
                  2X Assessment. Please contact your administrator to request
                  permission to conduct a new survey.
                </p>
                <button
                  style="background-color: #fe5000; color: white"
                  class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                  @click="handleRequestPermision('2X')"
                >
                  Request permission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- new assesment Modal-->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="displayNewSharedPopup"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close"
            @click="closeDisplayNewSharedPopup()"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                We are happy to see you back here! It looks like your
                organization already completed a 2X assessment.
                <router-link
                  to="/2X-Ecosystem/My-2X-Assessments"
                  style="color: rgb(0, 119, 200)"
                  >Check it out.
                </router-link>
              </p>
              <p class="mt-3" style="font-weight: 600">
                You can still proceed to create a new assessment now and as many
                times in the future as you want. When you do this, the newest
                assessment survey data submitted will be displayed on your
                dashboard, and previous surveys will be moved to historical data
                points.
              </p>
              <p class="mt-3" style="font-weight: 600">
                In addition, your teammates on this Equilo account will receive
                a new notification when you submit this completed survey so they
                can check out the updated data.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="repeatAssessmentLink()"
              >
                Ready to repeat your assessment?
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- close new assesment Modal -->
    <!-- non-admin user Modal-->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isAdminModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeAdminModal()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                Let your account admin now that you would like to access this
                feature with an subscription by sending them an automated
                message now. They will receive an email letting them know you
                are interested in accessing this feature and will be provided
                with upgrade options.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="closeAdminModal()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- close non-admin user Modal -->
    <!-- Request portfolio permission -->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="requestportfoliopermissionModal"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeRequestModal()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                Your organization has a feature to view portfolio level 2X
                results. However, your organization's admin has not yet granted
                you access to view it. Do you wish to request access?
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="requesttwoxPorfolioPermission()"
              >
                Email request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End portfolio Modal -->
    <!-- Request portfolio roi permission -->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="requestportfoliopermissionRoiModal"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span class="close" @click="closeRequestModal()" style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600">
                Your organization has a feature to view portfolio level Gender
                ROI™ Assessment Portfolio results. However, your organization's
                admin has not yet granted you access to view it. Do you wish to
                request access?
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="requestroixPorfolioPermission()"
              >
                Email request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End portfolio roi Modal -->
    <!-- Pilot Request Modal Start -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="pilotRequestModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close z-10"
            @click="closeRequestModal()"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle" style="display: block">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600; text-align: center">
                Want to pilot the 2X Certification platform?
              </p>
              <p style="font-weight: 600; text-align: center">
                Send 2X Global a request
              </p>
              <button
                style="background-color: #0077c8; color: white"
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="sendRequestToPilot()"
              >
                Send request to pilot
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Pilot Request End Modal -->
  </div>
</template>

<script>
import { driver } from "driver.js";

import Cookies from "@/helpers/cookies";
import env from "@/../env";
import pageLoader from "@/components/page-loader.vue";
import onBoarding from "@/components/onBoarding.vue";
import menuBoarding from "@/components/menu-boarding/menu-boarding.vue";
import tours from "@/components/menu-boarding/tour.js";
import { list } from "../../components/menu-boarding/list";
import Users from "@/helpers/users";
import { useUserStore } from "@/stores/user";
import axios from "axios";

export default {
  name: "InvestorOrFinancial",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data: function () {
    return {
      active: false,
      paid: false,
      gbvpaid: false,
      user: "",
      requestAdminModalForROI: false,
      requestAdminModalFor2X: false,
      displayNewSharedPopup: false,
      requestportfoliopermissionModal: false,
      requestportfoliopermissionRoiModal: false,
      sharedAssesmentData: [],
      organizationDetail: [],
      isAdminModal: false,
      isBoarding: false,
      canTour: false,
      showMenu: false,
      tourSteps: null,
      isTour: false,
      board: null,
      driver: null,
      isOpen: false,
      pilotRequestModal: false,
      subscriptionDetails: "",
    };
  },
  components: {
    pageLoader,
    onboarding: onBoarding,
    "menu-boarding": menuBoarding,
  },
  methods: {
    checkEquiloToolsPermission() {
      list.forEach((item) => {
        if (item.parent == "GESI Contextual Analysis") {
          item.isAccess = this.user.organization.accessGesi;
        }
      });
    },
    onContinue(type) {
      this.type = type;
      this.saveInterest({ type: type });
      this.configureTour();
      this.handleDashboard();
      this.isBoarding = false;
      this.canTour = true;
    },
    onSkip() {
      this.type = "BASIC";
      this.saveInterest({ type: "BASIC" });
      this.configureTour();
      this.handleDashboard();
      this.isBoarding = false;
      this.canTour = false;
      this.showMenu = true;
    },
    skip() {
      this.canTour = false;
      this.showMenu = true;
      this.isBoarding = false;
    },
    goDashboard() {
      this.isBoarding = false;
      this.canTour = false;
      this.isTour = true;
      this.showMenu = true;
    },
    configureTour() {
      const result = tours[this.type].filter((tour) => {
        const position = tour.element.search("-");
        let parentCode = "";
        let code = null;
        if (position >= 0) {
          code = tour.element.substring(position + 1, tour.element.length);
          parentCode = tour.element.substring(1, position);
        } else {
          parentCode = tour.element.substring(1, tour.element.length);
        }

        const dash = this.board ?? list;
        if (code === null) {
          const result = dash.find(
            (item) => item.code === parentCode && item.isActive
          );
          return !!result;
        } else {
          const result = dash.find(
            (item) => item.code === parentCode && item.isActive
          );
          if (result) {
            return !!result.actions.find(
              (item) => item.code === code && item.isActive
            );
          }
        }

        return false;
      });

      this.tourSteps = result;
      this.createDrive();
    },
    createDrive() {
      this.driver = driver({
        animate: false,
        showProgress: true,
        smoothScroll: true,
        doneBtnText: "Finish",
        nextBtnText: "Next",
        prevBtnText: "Previous",
        showButtons: ["next", "previous", "close"],
        popoverClass: "my-custom-popover-class",
        overlayColor: "rgba(0, 0, 0, 0.2)",
        steps: this.tourSteps,
      });
    },
    closeAdminModal() {
      this.isAdminModal = false;
    },
    checkAssesmentIsShared() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/assesment2x/assessment-count", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    repeatAssessmentLink() {
      this.$router.push({ path: "/2X-Ecosystem/New-2XAssessment" });
    },
    viewNewAssessmentPermission() {
      if (!this.user.twoXAssessment) {
        this.$router.push({ path: "/2X-Ecosystem/New-2XAssessment" });
      } else if (
        this.user.twoXAssessment &&
        this.sharedAssesmentData.assesmnetLength > 1
      ) {
        this.displayNewSharedPopup = true;
      } else {
        this.$router.push({ path: "/2X-Ecosystem/New-2XAssessment" });
      }
    },
    async closeDisplayNewSharedPopup() {
      this.displayNewSharedPopup = false;
    },
    viewAssessmentPermission() {
      if (!this.user.twoXAssessment) {
        this.requestAdminModalFor2X = true;
      } else {
        this.$router.push({ name: "my2xassement" });
      }
    },
    mouseOver: function (index) {
      this.active = index;
    },
    viewAssessmentRoiPermission() {
      if (!this.user.genderRoiAssessment) {
        this.requestAdminModalForROI = true;
      } else {
        this.$router.push({ name: "roi" });
      }
    },
    sendRequestToPilot() {
      return axios
        .post(
          env.apiUrl + "/certify2x/2x-certification-verification-request",
          {
            pilot: true,
          },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then((res) => {
          this.pilotRequestModal = false;
          if (res) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data,
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          this.pilotRequestModal = false;
          this.$swal.fire({
            icon: "error",
            text: err.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    async closeRequestModal() {
      this.requestAdminModalForROI = false;
      this.requestAdminModalFor2X = false;
      this.requestportfoliopermissionModal = false;
      this.requestportfoliopermissionRoiModal = false;
      this.pilotRequestModal = false;
    },
    async handleRequestPermision(type) {
      let url = "";
      if (type == "ROI") {
        url = "/roi/roiAccess-request-to-admin";
      }
      if (type == "2X") {
        url = "/assesment2x/share-request-to-admin";
      }
      if (type === "IP") {
        url = "/assesment2x/shared2x-access-portfolio-permission";
      }
      axios
        .get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkportfolioPermissionpopupROI() {
      if (!this.user.accessROIPortfolio) {
        this.requestportfoliopermissionRoiModal = true;
      } else {
        this.$router.push({ name: "roiPortfolio" });
      }
    },
    async checkportfolioPermissionpopup() {
      if (!this.user.accessPortfolio) {
        this.requestportfoliopermissionModal = true;
      } else {
        this.$router.push({ name: "myPortfolio" });
      }
    },
    async requesttwoxPorfolioPermission() {
      axios
        .get(env.apiUrl + "/assesment2x/shared2x-access-portfolio-permission", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkportfolioPermissionRoipopup() {
      if (!this.user.accessROIPortfolio) {
        this.requestportfoliopermissionRoiModal = true;
      } else {
        this.$router.push({ name: "roiPortfolio" });
      }
    },
    async requestroixPorfolioPermission() {
      axios
        .get(env.apiUrl + "/roi/roiPortfolioAccess-request-to-admin", {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(async (res) => {
          this.closeRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationDetail(organization_id) {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/organizations/" + organization_id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },

    redirectTo() {
      if (
        this.user.organization.productApiAccess.includes(
          "ROI-Assessment-API"
        ) &&
        this.user.organization.productApiAccess.includes("2x-Assessment-API")
      ) {
        this.$router.push({ path: "/settings/twox_assessment_api_settings" });
      } else if (
        this.user.organization.productApiAccess.includes("ROI-Assessment-API")
      ) {
        this.$router.push({ path: "/settings/roi_assessment_api_settings" });
      } else if (
        this.user.organization.productApiAccess.includes("2x-Assessment-API")
      ) {
        this.$router.push({ path: "/settings/twox_assessment_api_settings" });
      }
    },
    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    personalizeList(items) {
      let sortOrder = null;
      const type = this.user?.interest || this.type || "BASIC";
      if (type === "PID" || type === "HA" || type === "AR") {
        sortOrder = ["EQ", "GBVA", "GIRL", "twoXC", "IC", "GR", "GGSF", "IP"];
      } else if (type === "IVM") {
        sortOrder = ["IP", "twoXC", "IC", "GR", "GGSF", "EQ", "GBVA", "GIRL"];
      } else if (type === "ACPGSNTID") {
        sortOrder = ["twoXC", "IC", "GR", "GGSF", "EQ", "GBVA", "IP", "GIRL"];
      } else {
        sortOrder = ["twoXC", "IC", "GR", "GGSF", "IP", "EQ", "GBVA", "GIRL"];
      }

      items.sort(function (a, b) {
        return sortOrder.indexOf(a.code) - sortOrder.indexOf(b.code);
      });
      return items;
    },
    checkDashboardIntegrity() {
      let integrity = true;
      list.forEach((parent) => {
        const parentResult = this.user.dashboard.find(
          (title) => title?.code === parent?.code
        );
        if (!parentResult) {
          integrity = false;
          return false;
        }

        parentResult.actions.forEach((child) => {
          const childResult = parent.actions.find(
            (actions) => actions?.code === child?.code
          );
          if (!childResult) {
            integrity = false;
            return false;
          }
        });

        parent.actions.forEach((child) => {
          const childResult = parentResult.actions.find(
            (actions) => actions?.code === child?.code
          );
          if (!childResult) {
            integrity = false;
            return false;
          }
        });
      });

      return integrity;
    },
    handleDashboard() {
      if (
        this.user.dashboard === null ||
        this.user.dashboard === undefined ||
        !Array.isArray(this.user.dashboard) ||
        (Array.isArray(this.user.dashboard) && this.user.dashboard.length === 0)
      ) {
        this.board = this.personalizeList(list);
      } else if (!this.checkDashboardIntegrity()) {
        this.board = this.personalizeList(list);
      } else {
        const reconstructed = this.user.dashboard.map((service) => {
          const dashboardItem = list.find((item) => item.code === service.code);

          const actions = service.actions.map((action) => {
            const dashboardAction = dashboardItem.actions.find(
              (item) => item.code === action.code
            );
            return {
              ...dashboardAction,
              code: action.code,
              isActive: action.isActive,
            };
          });

          return {
            ...dashboardItem,
            code: service.code,
            isActive: service.isActive,
            actions: actions,
          };
        });
        this.board = reconstructed;
      }
    },
    saveInterest(type) {
      Users.updateInterest(type)
        .then()
        .catch((err) => {
          console.log(`Error saving Interest ${err}`);
        });
    },
    getSubscriptions() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/subscriptions/get-subscription-info", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    setPilotRequestModal(value = false) {
      this.pilotRequestModal = value;
    },
  },
  async mounted() {
    if (localStorage.getItem("GbvhAssessUrl")) {
      this.$router.push({ path: localStorage.getItem("GbvhAssessUrl") });
    }
    this.$store.commit("resetCitations");
    await this.userStore.fetchUser(false, true);
    this.user = this.userStore.user;
    const subscriptionInfo = await this.getSubscriptions();
    this.subscriptionDetails = subscriptionInfo?.productArr[0];

    if (subscriptionInfo?.productArr?.length == 0) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }

    if (!this.user?.interest) {
      this.isBoarding = true;
    } else {
      this.type = this.user.interest;
      this.configureTour();
      this.handleDashboard();
      this.isBoarding = false;
      this.showMenu = true;
    }

    this.sharedAssesmentData = await this.checkAssesmentIsShared();
    this.organizationDetail = await this.getOrganizationDetail(
      this.user.organization._id
    );

    // if (this.user.organization.subscription.price < 1 ) {
    //     this.paid = false;
    // }else{
    //     this.paid = true;
    // }
    if (env.gbvteams.includes(this.user.organization.name)) {
      this.gbvpaid = true;
    } else {
      this.gbvpaid = false;
    }
    await this.checkEquiloToolsPermission();
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.$store.commit("setBreadcrumbs", []);
  },
};
</script>
<style lang="scss" scoped>
.blue_color {
  color: #0077c8;
}

.yellow_color {
  color: #fae053;
}

.orange_color {
  color: #fe5000;
}

.cyan_color {
  color: #2bd4c4;
}

.white_color {
  color: #ffffff;
}

.favorite_text {
  position: relative;
  top: 18px;
}

.favorite_image_container {
  .favorite_image {
    img {
      height: 55px;
    }
  }
}

.short_container {
  height: 340px !important;
}

.long_container {
  height: 555px !important;

  .bottom_section {
    border-top: 1px solid #d0d0d0;
  }
}

.product_container {
  height: 380px;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 10px;

  .product_image {
    img {
      max-width: unset;
      height: 100%;
    }

    .inner_image {
      max-width: 430px;
      width: 430px;
      object-fit: cover;
      border-radius: 10px 0 0 10px;
    }

    .unlock_text_for_image {
      font-size: 1.5rem;
      position: absolute;
      top: 5%;
      left: 6%;
      border-radius: 20px;
      padding: 8px;
    }

    .unlock_button_for_image {
      background-color: #ffffff;
      position: absolute;
      bottom: 45%;
      left: 6%;
      border-radius: 20px;
      padding: 8px 10px;
    }

    .contact_account_admin_button {
      width: 300px;
    }

    .unlock_image_for_text {
      width: 55px;
      height: 55px;
      background-color: #ffffff;
      position: absolute;
      bottom: 6%;
      left: 6%;
      border-radius: 50px;
      padding: 8px;
    }
  }

  div {
    .product_small_icon {
      min-width: 80px;

      img {
        height: 55px;
      }
    }
  }
}

.vertical_align_middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  border-radius: 20px;

  .selected-class {
    background-color: green;
  }
}

.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }

    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.skip {
  border: 1px solid rgba(25, 27, 69, 1);
  width: 18.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    border: 1px solid rgba(25, 27, 69, 1);
    background-color: rgba(235, 236, 250, 1);
  }

  &:active {
    transform: translateY(1px);
    background-color: rgba(235, 236, 250, 1);
    color: rgba(67, 70, 124, 1);
  }
}

.continue {
  width: 18.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: white;
  background-color: rgba(25, 27, 69, 1);
  margin-left: 0.5rem;

  &:hover {
    color: white;
    background-color: rgba(45, 47, 95, 1);
  }

  &:active {
    transform: translateY(1px);
    color: white;
    background-color: rgba(67, 70, 124, 1);
  }

  &:disabled {
    border: 1px solid grey;
    background-color: grey;
    color: white;
    cursor: not-allowed;
  }
}

.tour-title {
  font-family: "Montserrat";
  font-size: 2.25rem;
  line-height: 40px;
  color: rgba(44, 46, 53, 1);
  margin-top: 30%;
  max-width: 70%;
  margin-bottom: 3rem;
}
</style>
