<template>
  <div class="relative" v-on:clickout="dropdownOpen = false">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      stroke-width="3"
      stroke="currentColor"
      class="mr-2 cursor-pointer w-6 h-6"
      @click="dropdownOpen = true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="currentColor"
        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
      />
    </svg>
    <div class="w-40 absolute top-5 right-2">
      <transition name="fadeIn-faster" mode="out-in">
        <div
          v-if="dropdownOpen"
          class="bg-white z-50 shadow-xl rounded-lg border border-gray-200mt-10 overflow-hidden absolute z-1"
        >
          <div
            class="w-full h-full flex flex-col justify-start items-start text-xs"
          >
            <div
              v-for="(option, index) in options"
              :key="index"
              @click="optionSelected(option)"
              class="cursor-pointer w-full py-3 px-4 text-left hover:bg-gray-100 font-semibold text-gray-500 hover:text-indigo-900 transition duration-200"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "chatOptions",
  emits: ["option-selected"],
  data() {
    return {
      dropdownOpen: false,
      // options: ["See History", "Upload sources", "Full screen mode", "Help"],
      options: ["Full screen mode"],
    };
  },

  methods: {
    optionSelected(option) {
      this.dropdownOpen = false;
      this.$emit("option-selected", option);
    },
  },
};
</script>
