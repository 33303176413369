<!-- StatusSelector.vue -->
<template>
    <div class="flex justify-center items-center gap-4" :style="containerStyle">
      <!-- Check Icon -->
      <div class="relative group">
        <button
          @click="updateStatus('doing')"
          :class="[
            'w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-200',
            status === 'doing' ? 'bg-[#3B7D4A] status-not-clickable' : 'bg-gray-400 hover:bg-gray-500'
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            :class="status === 'doing' ? 'text-white' : 'text-gray-100'"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </button>
        <!-- Tooltip -->
        <div class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10 hidden group-hover:block">
          <div class="bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
            Currently doing
          </div>
          <div class="w-2 h-2 bg-gray-800 transform rotate-45 absolute -bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      </div>
      <!-- Handshake Icon -->
      <div class="relative group">
        <button
          @click="updateStatus('committed')"
          :class="[
            'w-9 h-9 rounded-full flex items-center justify-center transition-colors duration-200',
            status === 'committed' ? 'bg-white border-2 border-[#FE5000] status-not-clickable' : 'bg-gray-400 hover:bg-gray-500'
          ]"
        >
          <svg class="w-7 h-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.01 490.01">
            <g>
              <path
                d="M169.994,106.097l-6.1,3c-7.6,3.7-16.5,4.2-24.4,1.4l-10.2-3.6c-0.6-0.4-1.2-0.7-1.8-0.9l-62.8-21.5c-4.7-1.6-9.9,0.9-11.5,5.6l-52.7,153.5c-0.8,2.3-0.6,4.8,0.4,6.9s2.9,3.8,5.2,4.6l62.7,21.5c1,0.3,2,0.5,2.9,0.5c3.8,0,7.3-2.4,8.6-6.1l49.7-144.6l3.4,1.2c12.5,4.4,26.5,3.6,38.5-2.2l6.1-3c7.5-3.7,16.3-4.2,24.2-1.5l115.3,39.7c-1.2,4.8-3.6,10.5-8,13.6c-4.9,3.4-12.2,3.4-21.8,0.1l-51.4-17.6c-2.4-0.8-5-0.6-7.2,0.6c-2.2,1.2-3.8,3.3-4.5,5.7c-0.1,0.3-7.2,26.9-29.9,39.1c-14.3,7.7-32.1,8-53,0.9c-4.7-1.6-9.9,0.9-11.5,5.6c-1.6,4.7,0.9,9.9,5.6,11.5c12,4.1,23.3,6.2,33.8,6.2c12.2,0,23.4-2.7,33.6-8.2c20.3-10.9,30.8-30,35.6-41.4l42.9,14.7c15.3,5.3,28.1,4.5,38-2.4c15.9-11,16.7-33.3,16.8-34.3c0.1-4-2.4-7.5-6.1-8.8l-122.4-42.2C195.694,99.497,181.794,100.397,169.994,106.097z"
                :style="{ fill: status === 'committed' ? '#FE5000' : '#ffffff' }"  
              />
              <path
                d="M410.194,266.797l-21.5,19.8c-19.5,17.9-41.5,33-65.3,44.6l-114.1,55.8c-5,2.5-11.1,0.4-13.6-4.7c-2.5-5-0.4-11.1,4.7-13.6l1.4-0.7l0,0l62.4-30.5c4.5-2.2,6.4-7.6,4.2-12.1c-2.2-4.5-7.6-6.4-12.1-4.2l-62.4,30.5l0,0l-31.4,15.4c-5,2.5-11.1,0.4-13.6-4.7c-1.2-2.4-1.4-5.2-0.5-7.7c0.9-2.6,2.7-4.6,5.1-5.8l23.7-11.6l0,0l67.8-33.2c4.5-2.2,6.4-7.6,4.2-12.1s-7.6-6.4-12.1-4.2l-67.9,33.3l0,0l-2.6,1.3l-32.4,15.9c-2.4,1.2-5.2,1.4-7.7,0.5c-2.6-0.9-4.6-2.7-5.8-5.1c-2.5-5-0.4-11.1,4.7-13.6l11.2-5.5l0,0l22.9-11.2l6.4-3.1l0,0l52.6-25.8c4.5-2.2,6.4-7.6,4.2-12.1s-7.6-6.4-12.1-4.2l-57,27.9l-24.9,12.2c-5,2.4-11.1,0.4-13.6-4.7c-1.2-2.4-1.4-5.2-0.5-7.7c0.9-2.6,2.7-4.6,5.1-5.8l43.5-21.3c4.5-2.2,6.4-7.6,4.2-12.1s-7.6-6.4-12.1-4.2l-43.5,21.3c-6.8,3.3-11.9,9.1-14.3,16.2s-2,14.8,1.3,21.6c2.1,4.2,5.1,7.7,8.7,10.3c-6.3,8.3-7.9,19.7-3,29.7c3.3,6.8,9.1,11.9,16.2,14.3c3,1,6,1.5,9,1.5c-0.1,4.5,0.8,9,2.9,13.1c4.9,10,15,15.8,25.4,15.8c4.2,0,8.4-0.9,12.4-2.9l6.4-3.1c0.3,3.4,1.2,6.7,2.7,9.9c4.9,10,15,15.8,25.4,15.8c4.2,0,8.4-0.9,12.4-2.9l114.1-55.8c25.4-12.4,48.8-28.4,69.6-47.5l25.5-23.5l58.4-28.6c4.5-2.2,6.4-7.6,4.2-12.1l-71.4-145.5c-1.1-2.2-2.9-3.8-5.2-4.6c-2.3-0.8-4.8-0.6-6.9,0.4l-59.6,29.1c-4.5,2.2-6.4,7.6-4.2,12.1L410.194,266.797z"
                :style="{ fill: status === 'committed' ? '#FE5000' : '#ffffff' }"              
                />
            </g>
          </svg>
        </button>
        <!-- Tooltip -->
        <div class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10 hidden group-hover:block">
          <div class="bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
            Commitment to do this
          </div>
          <div class="w-2 h-2 bg-gray-800 transform rotate-45 absolute -bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      </div>
  
      <!-- X Icon -->
      <div class="relative group">
        <button
          @click="updateStatus('not_doing')"
          :class="[
            'w-8 h-8 rounded-full flex items-center justify-center transition-colors duration-200',
            status === 'not_doing' ? 'bg-[#B2281E] status-not-clickable' : 'bg-gray-400 hover:bg-gray-500'
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            :class="status === 'not_doing' ? 'text-white' : 'text-gray-100'"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <!-- Tooltip -->
        <div class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-10 hidden group-hover:block">
          <div class="bg-gray-800 text-white text-sm py-1 px-2 rounded whitespace-nowrap">
            Not currently doing this or committing to do it
          </div>
          <div class="w-2 h-2 bg-gray-800 transform rotate-45 absolute -bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StatusSelector',
    props: {
      status: {
        type: String,
        default: null,
        validator: (value) => ['doing', 'committed', 'not_doing', null].includes(value)
      },
      position: {
        type: Object,
        default: () => ({ bottom: '3%', left: '25%' })
      }
    },
    computed: {
      containerStyle() {
        return {
          position: 'absolute',
          ...this.position
        }
      }
    },
    methods: {
      updateStatus(status) {
        this.$emit('updateStatus', status)
      }
    }
  }
  </script>
<style scoped>
.status-not-clickable {
  pointer-events: none;
}
</style>