<template>
  <div class="mt-24 sm:mt-16">
    <eqChat/>
  </div>
</template>
<script>
import eqChat from '@/components/chat/eqChat.vue'
export default{
  components:{
    eqChat
  }
}
</script>
