const createInteractiveText = (value) => {
  const { originalTextForChartInfo, fieldsWithAnswers, surveyQType } = value;

  // create a map of field names to their answers
  const answerMap = Object.fromEntries(
    fieldsWithAnswers.map((item) => [item.field, item.answer])
  );

  // create a regex pattern from the field names
  const fieldPattern = new RegExp(Object.keys(answerMap).join("|"), "g");

  // replace the keys in the original text with interactive spans
  const htmlContent = originalTextForChartInfo.replace(
    fieldPattern,
    (match) => {
      const answer = answerMap[match] || match;
      return `<span class="data-point" data-field="${match}" data-survey-q-type="${surveyQType}">${answer}</span>`;
    }
  );

  return htmlContent;
};

const handleSpanClick = (event, openEditModal) => {
  if (event.target.classList.contains("data-point")) {
    const field = event.target.dataset.field;
    const surveyQType = event.target.dataset.surveyQType;
    openEditModal({
      key: field,
      oldKey: field,
      surveyQType: surveyQType,
    });
  }
};

export { createInteractiveText, handleSpanClick };
