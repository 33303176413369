import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";

class BaseAPI {
  static instance = null;

  constructor() {
    if (!BaseAPI.instance) {
      BaseAPI.instance = axios.create({
        baseURL: `${env.apiUrl}`,
        headers: {
          Authorization: `Bearer ${Cookies.get("session")}`
        }
      });
    }
    return BaseAPI.instance;
  }

  static getInstance() {
    if (!BaseAPI.instance) {
      new BaseAPI();
    }
    return BaseAPI.instance;
  }
}

export default BaseAPI;
