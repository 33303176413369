<template>
  <router-link to="/upgrade" v-if="!route">
    <button
      class="flex justify-center bg-[#fe5000] text-white px-5 py-3 rounded-lg font-bold my-3"
    >
      Upgrade
    </button>
  </router-link>
  <a :href="route" v-else target="_blank">
    <button
      class="flex justify-center bg-[#fe5000] text-white px-5 py-3 rounded-lg font-bold my-3"
    >
      Upgrade
    </button>
  </a>
</template>

<script>
export default {
  name: "UpgradeButton",
  props: {
    route: {
      type: String || null,
    },
  },
};
</script>
